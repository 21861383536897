import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { DxPieChartComponent } from "devextreme-angular";
import { Observable, Subscription } from "rxjs";
import { CostsBySegment } from "src/app/shared/models/financial/costsBySegment.model";

@Component({
  selector: "app-costbysegmentandratiopiechart",
  templateUrl: "./costbysegmentandratiopiechart.component.html",
  styleUrls: ["./costbysegmentandratiopiechart.component.scss"],
})
export class CostbysegmentandratiopiechartComponent implements OnInit {
  @ViewChild(DxPieChartComponent, { static: false })
  pieChart: DxPieChartComponent;

  private rerenderSubscription: Subscription;

  @Input()
  pieChartDataSet: CostsBySegment[];

  @Input()
  rerenderEvent: Observable<void>;

  resolveOverlappingTypes = ["shift", "hide", "none"];

  constructor() {}

  ngOnInit() {
    this.rerenderSubscription = this.rerenderEvent.subscribe(() => {
      this.pieChart.instance.render();
    });
  }

  customizeLabel(point) {
    return point.argumentText + ": " + point.valueText + "€";
  }
}
