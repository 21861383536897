import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";

import { AuthService, AppInfoService } from "../../services";
// import { DxButtonModule } from 'devextreme-angular/ui/button';
// import { DxCheckBoxModule } from 'devextreme-angular/ui/check-box';
// import { DxTextBoxModule } from 'devextreme-angular/ui/text-box';
// import { DxValidatorModule } from 'devextreme-angular/ui/validator';
// import { DxValidationGroupModule } from 'devextreme-angular/ui/validation-group';
import { first } from "rxjs/operators";
import { MatSnackBar } from "@angular/material/snack-bar";
import { UserService } from "../../services/Authentication/user.service";

@Component({
  selector: "app-recovery-form",
  templateUrl: "./recovery-form.component.html",
  styleUrls: ["./recovery-form.component.scss"],
})
export class RecoveryFormComponent implements OnInit {
  returnUrl: string;
  userName = "";
  password = "";
  submitted = false;
  error = "";

  constructor(
    private authService: AuthService,
    public appInfo: AppInfoService,
    private router: Router,
    private userSvc: UserService,
    private snackbar: MatSnackBar,
    private route: ActivatedRoute
  ) {
    this.route.queryParams.subscribe((params) => {
      this.userName = params.username;
    });
  }

  ngOnInit() {}

  onRecoveryClick(args) {
    this.submitted = true;

    this.authService
      .sendRecoverPwdMail(this.userName)
      .pipe(first())
      .subscribe(
        (data) => {
          this.router.navigate(["/login"]);
          this.snackbar.open(
            "An email to reset your password has been sent.",
            null,
            {
              duration: 5000,
            }
          );
        },
        (error) => {
          this.snackbar.open(
            "Something went wrong please try again later",
            null,
            {
              duration: 5000,
              panelClass: "alert-red",
            }
          );
        }
      );
  }
}
