import { Component, Input, OnInit } from "@angular/core";
import { Observable } from "rxjs/internal/Observable";
import { RoleService } from "src/app/shared/services/Authentication/role.service";
import { UserService } from "src/app/shared/services/Authentication/user.service";

@Component({
  selector: "app-user-role",
  templateUrl: "./user-role.component.html",
  styleUrls: ["./user-role.component.scss"],
})
export class UserRoleComponent implements OnInit {
  roleList: Observable<string[]>;

  userRoles: string[];

  @Input()
  currentUserId: string;

  constructor(readonly roleSvc: RoleService, private userSvc: UserService) {
    this.roleList = roleSvc.roles;
    roleSvc.userRoles$.subscribe((x) => (this.userRoles = x));
  }

  ngOnInit(): void {
    this.roleSvc.fetchAllRoles();
    this.roleSvc.getUserRoles(this.currentUserId);
  }

  handleRoleSelectionChange(e) {
    if (e.addedItems.length > 0) {
      e.addedItems.forEach((element) => {
        if (!this.userRoles.includes(element.name))
          this.userRoles.push(element.name);
      });
    }

    if (e.removedItems.length > 0) {
      e.removedItems.forEach((element) => {
        this.userRoles.splice(this.userRoles.indexOf(element.name), 1);
      });
    }
  }

  // Push the role changes to the database.
  saveRoleChanges() {
    this.userSvc
      .updateUserRoles(this.currentUserId, this.userRoles)
      .subscribe();
  }
}
