<dx-vector-map #FinancialMap id="vector-map" [bounds]="[-180, 85, 180, -60]">
    <dxo-title text="IP Family costs">
        <dxo-subtitle text="(in EUR)"></dxo-subtitle>
    </dxo-title>
    <dxo-tooltip 
        [enabled]="true" 
        [zIndex]=1000
        [customizeTooltip]="customizeTooltip">
    </dxo-tooltip>
    <dxi-layer
        palette="Vintage" 
        [colorGroups]='colorRanges'
        [customize]="customizeLayers"
        colorGroupingField="costs"
        [dataSource]="worldMap"
        name="country"
    >
    <dxo-label [enabled]="true" dataField="country"></dxo-label>
    </dxi-layer>
    <dxi-legend 
      [customizeText] = "customizeLegendText"
      [customizeItems]= "customizeItems"
      title = "Costs"
      horizontalAlignment="right"
      verticalAlignment="bottom"
      visible="true"
    >
    <dxo-source layer="country" grouping="color"></dxo-source>
    </dxi-legend>
    <dxo-export [enabled]="true"></dxo-export>
</dx-vector-map>
