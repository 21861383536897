import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ERPSegment } from "src/app/shared/models/ERP/segment.erp.model";
import { IPService } from "src/app/shared/models/ipservice.model";
import { ERPSegmentService } from "src/app/shared/services/ERP/erp.segment.service";
import { IPServiceService } from "src/app/shared/services/IPService.service";

@Component({
  selector: "app-ipservice",
  templateUrl: "./ipservice.component.html",
  styleUrls: ["./ipservice.component.scss"],
})
export class IPServiceComponent implements OnInit {
  service: IPService;
  serviceId: number;

  segments: ERPSegment[];

  constructor(
    private ipService: IPServiceService,
    private activatedRoute: ActivatedRoute,
    private segmentService: ERPSegmentService
  ) {
    this.activatedRoute.params.subscribe(
      (params) => (this.serviceId = params.id)
    );
    this.UpdateSegments = this.UpdateSegments.bind(this);
  }

  ngOnInit() {
    this.segmentService.getContactSegments().subscribe((res: ERPSegment[]) => {
      this.segments = res;
    });

    this.ipService
      .getServiceDetails(this.serviceId)
      .subscribe((data: IPService) => {
        this.service = data;
        this.serviceId = data.AssetId;
      });
  }

  UpdateSegments() {
    this.segmentService
      .updateAssetSegments(this.service.AssetId, this.service.Segments)
      .subscribe();
  }
}
