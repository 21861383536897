import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Dictionary } from '../models/dictionary.model';
import { environment } from 'src/environments/environment';
import { SubscribedOrganization, SubscribedOrganizationAdapter } from '../models/Portal/subscribedOrganization';
import { map } from 'rxjs/operators';

const apiUrl = environment.baseUrl + '/api/erp/organization';

@Injectable({
  providedIn: 'root'
})

export class OrganizationService {

  constructor(private http: HttpClient,
              private subscribedOrganizationAdapter: SubscribedOrganizationAdapter
    ) {}

    getOrganizationDictionary() {
      return this.http.get<Dictionary[]>(apiUrl + '/dictionary');
    }

    getSubscribedOrganizationDictionary() {
      return this.http.get<Dictionary[]>(apiUrl + '/subscribeddictionary');
    }

    getRolesDictionary() {
      return this.http.get<Dictionary[]>(apiUrl + '/rolesdictionary');
    }

    getCaseMasterDictionary() {
      return this.http.get<Dictionary[]>(apiUrl + '/casemasterdict');
    }

    getInstructionCatDictionary() {
      return this.http.get<Dictionary[]>(apiUrl + '/instructioncatdict');
    }
    // SubscriptionModelRelated.

    getSubscribedOrganizations() {
      return this.http.get<SubscribedOrganization[]>(apiUrl + '/subscribedorganizations')
                            .pipe(map((res: any) =>
                                    res.data.map(item => this.subscribedOrganizationAdapter.adapt(item))));
    }

    getSubscriptionModelDictionary() {
      return this.http.get<Dictionary[]>(apiUrl + '/subscriptionmodelsdict');
    }

    updateSubscriptionModel(SubscribedOrganizationId: number, SubscriptionData: SubscribedOrganization) {
      return this.http.put(apiUrl + '/subscription/' + SubscribedOrganizationId + '/update', SubscriptionData);
     }

    createSubscription(ClientId: number) {
      return this.http.post<SubscribedOrganization>(apiUrl + '/subscription', ClientId)
                  .pipe(map((res: any) =>
                      this.subscribedOrganizationAdapter.adapt(res)));
    }

    deleteSubscription(id: number) {
      return this.http.delete(apiUrl + '/subscription/' + id + '/delete');
    }
}
