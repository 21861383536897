import { Component, OnInit, ViewChild } from "@angular/core";
import { ERPDeadlineService } from "src/app/shared/services/ERP/erp.deadline.service";
import DataSource from "devextreme/data/data_source";
import { ERPDeadline } from "src/app/shared/models/ERP/deadline.erp.model";
import { ERPDeadlineCategory } from "src/app/shared/models/ERP/deadlinecategory.erp.model";
import { DxFileUploaderComponent } from "devextreme-angular";
import { DeadlineInstruction } from "src/app/shared/models/deadlineInstruction.model";
import { ERPInstructionService } from "src/app/shared/services/ERP/erp.instruction.service";
import { Dictionary } from "src/app/shared/models/dictionary.model";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { ERPDocumentService } from "src/app/shared/services/ERP/erp.document.service";
import { ERPDocument } from "src/app/shared/models/ERP/document.erp.model";
import { Workbook } from "exceljs";
import { exportDataGrid } from "devextreme/excel_exporter";
import { saveAs } from "file-saver";

@Component({
  selector: "app-deadlines",
  templateUrl: "./deadlines.component.html",
  styleUrls: ["./deadlines.component.scss"],
})
export class DeadlinesComponent implements OnInit {
  currentDate: Date;
  deadlineDataSource: DataSource;
  deadlineCategoryDict: ERPDeadlineCategory[];

  currentDeadline: ERPDeadline;
  deadlineInstruction: DeadlineInstruction;
  possibleInstructions: Dictionary[];
  value: any[] = [];
  fileToUpload: File[];

  documentPreview: any;
  currentTemplate: string;
  PopupVisible: boolean;
  PopupTitle: string;
  selectedOperation = "between";
  filterValue: any;

  loadingVisible: any;

  uploadedFile: File;

  submitButtonOptions: any = {
    text: "Send",
    type: "success",
    useSubmitBehavior: true,
  };

  loadingPreview: boolean = false;

  @ViewChild(DxFileUploaderComponent) uploader: DxFileUploaderComponent;

  constructor(
    private deadlineSvc: ERPDeadlineService,
    private instructionSvc: ERPInstructionService,
    private router: Router,
    private snackbar: MatSnackBar,
    private documentSvc: ERPDocumentService
  ) {
    this.setFilterValue();
    this.PopupTitle = "Preview";
    this.currentDate = new Date();
    this.PopupVisible = false;
    this.openReplyFrame = this.openReplyFrame.bind(this);
    this.previewRelatedDocument = this.previewRelatedDocument.bind(this);
    this.previewRelatedDocumentbis = this.previewRelatedDocumentbis.bind(this);
    this.downloadRelatedDocument = this.downloadRelatedDocument.bind(this);
    this.downloadRelatedDocumentbis =
      this.downloadRelatedDocumentbis.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.loadingVisible = false;
    this.onDetailsClick = this.onDetailsClick.bind(this);
  }

  setFilterValue() {
    const startDate = new Date();
    startDate.setMonth(startDate.getMonth() - 1);

    const endDate = new Date();
    endDate.setMonth(endDate.getMonth() + 6);

    this.filterValue = [startDate, endDate];
  }

  ngOnInit() {
    this.deadlineSvc
      .getDeadlineCategories()
      .subscribe((res: ERPDeadlineCategory[]) => {
        this.deadlineCategoryDict = res;
      });

    this.deadlineSvc.getDeadlines().subscribe((res: ERPDeadline[]) => {
      this.deadlineDataSource = new DataSource({
        store: res,
        key: "deadlineId",
      });
    });
  }

  /// Called when clicking on the icon to open file details.
  // Open a service window in case the asset is a service.
  // Open an ipasset window otherwise.
  onDetailsClick(e): void {
    if (e.row.data.assetIsService) {
      const url = this.router.serializeUrl(
        this.router.createUrlTree(["/ipservice/" + e.row.data.caseId])
      );
      window.open(url);
    } else {
      const url = this.router.serializeUrl(
        this.router.createUrlTree(["/ipasset/" + e.row.data.caseId])
      );
      window.open(url);
    }
  }

  isDocumentAvailable(e): boolean {
    if (e.row.data.hasDocument) {
      return true;
    }
    return false;
  }

  // Here we have to check if a reply via the portal is possible or not.
  isReplyPossible(e) {
    if (1 === 1) {
      return true;
    }
    return false;
  }

  previewRelatedDocument(e) {
    if (e.row.data.documents.length === 1) {
      this.loadingPreview = true;
      this.documentSvc
        .LoadDocumentPreviewV2(e.row.data.documents[0].documentId)
        .subscribe((res) => {
          this.loadingPreview = false;
          this.currentTemplate = "pdfpreview";
          this.documentPreview = res;
          this.PopupVisible = true;
        });
    } else if (e.row.data.documents.length > 1 && !e.row.isExpanded) {
      e.component.collapseAll(-1);
      e.component.expandRow(e.row.key);
    } else if (e.row.data.documents.length > 1 && e.row.isExpanded) {
      e.component.collapseRow(e.row.key);
    }
  }

  previewRelatedDocumentbis(e) {
    this.loadingPreview = true;
    this.documentSvc
      .LoadDocumentPreviewV2(e.row.data.documentId)
      .subscribe((res) => {
        this.loadingPreview = false;
        this.currentTemplate = "pdfpreview";
        this.documentPreview = res;
        this.PopupVisible = true;
      });
  }

  downloadAllDocuments(e) {
    let source: DataSource = e.component.getDataSource();
    let documents: ERPDocument[] = source.items();
    if (documents.length > 1) {
      this.documentSvc
        .getManyDocuments(documents.map((x) => x.documentId))
        .subscribe((res: any) => {
          const blob = res;
          const fileName = "zip";
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = fileName;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(link.href);
        });
    }
  }

  downloadSelectedDocuments(e) {
    let selectedDocuments: ERPDocument[] = e.component.getSelectedRowsData();
    if (selectedDocuments.length > 1) {
      this.documentSvc
        .getManyDocuments(selectedDocuments.map((x) => x.documentId))
        .subscribe((res: any) => {
          const blob = res;
          const fileName = "zip";
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = fileName;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(link.href);
        });
    } else if ((selectedDocuments.length = 1)) {
      this.documentSvc
        .getDocument(selectedDocuments[0].documentId)
        .subscribe((res: any) => {
          const blob = res;
          const fileName = selectedDocuments[0].fileName;
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = fileName;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(link.href);
        });
    }
  }

  downloadRelatedDocument(e) {
    if (e.row.data.documents.length === 1) {
      this.documentSvc
        .getDocument(e.row.data.documents[0].documentId)
        .subscribe((res: any) => {
          const blob = res;
          const fileName = e.row.data.documents[0].fileName;
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = fileName;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(link.href);
        });
    } else if (e.row.data.documents.length > 1 && !e.row.isExpanded) {
      e.component.collapseAll(-1);
      e.component.expandRow(e.row.key);
    } else if (e.row.data.documents.length > 1 && e.row.isExpanded) {
      e.component.collapseRow(e.row.key);
    }
  }

  downloadAllRelatedDocuments() {}

  downloadRelatedDocumentbis(e) {
    this.documentSvc
      .getDocument(e.row.data.documentId)
      .subscribe((res: any) => {
        const blob = res;
        const fileName = e.row.data.fileName;
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(link.href);
      });
  }

  openReplyFrame(e) {
    this.currentDeadline = e.row.data;

    this.deadlineInstruction = new DeadlineInstruction(
      this.currentDeadline.deadlineId,
      this.currentDeadline.caseId,
      this.currentDeadline.date,
      null,
      null,
      this.currentDeadline.combinedTextClient,
      null,
      null,
      null,
      null
    );

    this.instructionSvc.getResponseType(e.row.data.deadlineId).subscribe(
      (res: any) => {
        if (res.hasResponseTypeOptions) {
          this.deadlineInstruction.OptionsVisible = true;
          this.possibleInstructions = res.responseOptions.map((a) => ({
            keyValue: a.responseOptionId,
            stringValue: a.responseOptionLabel,
          }));
        } else {
          this.deadlineInstruction.OptionsVisible = false;
        }
        if (res.allowDocumentUpload) {
          this.deadlineInstruction.DocumentUploadVisible = true;
        } else {
          this.deadlineInstruction.DocumentUploadVisible = false;
        }
        if (res.allowFreeTextResponse) {
          this.deadlineInstruction.FreeTextVisible = true;
        } else {
          this.deadlineInstruction.FreeTextVisible = false;
        }
        this.currentTemplate = "openresponse";
        this.PopupVisible = true;
      },
      () => {},
      () => {}
    );
  }

  onFormSubmit(e) {
    this.loadingVisible = true;

    if (this.value) {
      this.deadlineInstruction.UploadedFile = this.value;
    }

    const formData: FormData = new FormData();
    if (this.deadlineInstruction.Instruction) {
      formData.append(
        "Instruction",
        this.deadlineInstruction.Instruction.toString()
      );
      formData.append(
        "InstructionLabel",
        this.possibleInstructions.find(
          (x) => x.keyValue === +this.deadlineInstruction.Instruction
        ).stringValue
      );
    }
    formData.append(
      "FreeTextInstruction",
      this.deadlineInstruction.FreeTextInstruction
    );
    if (this.value.length > 0) {
      formData.append("UploadFiles", this.value[0]);
    }
    formData.append("CaseId", this.deadlineInstruction.AssetId.toString());
    formData.append(
      "DeadlineTermId",
      this.deadlineInstruction.EventSchemeId.toString()
    );
    formData.append("DeadlineText", this.currentDeadline.statusTextClient);

    this.instructionSvc.provideInstruction(formData).subscribe(
      (res) => {},
      (err) => {
        this.loadingVisible = false;
        this.snackbar.open(
          "There was an error processing your instruction, please contact support@calysta.eu",
          null,
          {
            duration: 5000,
            panelClass: "alert-red",
          }
        );
      },
      () => {
        this.loadingVisible = false;
        this.PopupVisible = false;
        this.snackbar.open(
          "Your instruction has succesfully been transferred to Calysta",
          null,
          {
            duration: 5000,
            panelClass: "alert-green",
          }
        );
      }
    );

    e.preventDefault();
  }

  onRowPrepared(e) {
    if (e.rowType == "data") {
      e.rowElement
        .querySelector(".dx-command-expand")
        .firstChild.classList.remove("dx-datagrid-group-closed");
      e.rowElement
        .querySelector(".dx-command-expand")
        .classList.remove("dx-datagrid-expand");
    }

    const criticaldate = new Date();
    criticaldate.setDate(criticaldate.getDate() + 3);

    const warningdate = new Date();
    warningdate.setDate(warningdate.getDate() + 7);

    if (e.rowType === "data" && e.data.date < warningdate) {
      e.rowElement.style.color = "orange";
      e.rowElement.className = e.rowElement.className.replace("dx-row-alt", "");
    }

    if (e.rowType === "data" && e.data.date < criticaldate) {
      e.rowElement.style.color = "red";
      e.rowElement.className = e.rowElement.className.replace("dx-row-alt", "");
    }
  }

  clearInstruction() {
    this.currentDeadline = null;
    this.deadlineInstruction = null;
    this.value = [];
    if (this.fileToUpload) {
      this.uploader.instance.reset();
    }
  }

  mapDocumentTypeIcon(filetype: string): string {
    const iconprefix = "dx-icon-";
    switch (filetype) {
      case "xlsx":
        return iconprefix + "xlsxfile";
      case "xls":
        return iconprefix + "xlsfile";
      case "pdf":
        return iconprefix + "pdffile";
      case "docx":
        return iconprefix + "docxfile";
      case "doc":
        return iconprefix + "docfile";
      case "msg":
        return iconprefix + "email";
      case "eml":
        return iconprefix + "email";
      default:
        break;
    }
  }

  onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift(
      {
        location: "after",
        widget: "dxButton",
        options: {
          width: 200,
          text: "Download Selection",
          onClick: this.downloadSelectedDocuments.bind(this, e),
        },
      },
      {
        location: "after",
        widget: "dxButton",
        options: {
          width: 200,
          text: "Download All",
          onClick: this.downloadAllDocuments.bind(this, e),
        },
      }
    );
  }

  onExporting(e) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet("Deadlines");

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(
          new Blob([buffer], { type: "application/octet-stream" }),
          "Deadlines.xlsx"
        );
      });
    });
  }
}
