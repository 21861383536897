import { Injectable } from '@angular/core';
import { Adapter } from '../../adapter/adapter';

export class PortfolioFilingStatistics {
    constructor(
      public Year: string,
      public NumberOfFilings: number
    ) {}
}

@Injectable({
    providedIn: 'root'
  })

  export class PortfolioFilingStatisticsAdapter implements Adapter<PortfolioFilingStatistics> {
    adapt(item: any): PortfolioFilingStatistics {
      return new PortfolioFilingStatistics(
            item.year,
            item.numberOfFilings,
      );
    }
  }
