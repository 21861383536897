<mat-card class="example-card">
  <mat-card-content>
    <mat-grid-list cols="4">
      <mat-grid-tile [colspan]="2">
        <dx-data-grid
          id="detailGrid"
          style="align-self: start"
          [dataSource]="GridDataSource"
          [showBorders]="true"
          [columnAutoWidth]="false"
          [wordWrapEnabled]="true"
          (onExporting)="onExporting($event)"
        >
          <dxi-column dataField="CountryLabel" caption="Country"></dxi-column>
          <dxi-column
            dataField="MappedStatusLabel"
            caption="Status"
          ></dxi-column>
          <dxi-column dataField="Origin" caption="Origin"></dxi-column>
          <dxi-column
            dataField="ContinentLabel"
            caption="Continent"
            [groupIndex]="0"
            groupCellTemplate="groupCellTemplate"
          ></dxi-column>
          <dxi-column
            dataField="classes"
            [allowFiltering]="false"
            [allowSorting]="false"
            cellTemplate="classCellTemplate"
          ></dxi-column>
          <div *dxTemplate="let data of 'classCellTemplate'">
            <span [innerHTML]="listFamilyClasses(data.value)"></span>
          </div>

          <dxo-search-panel [visible]="true"></dxo-search-panel>
          <dxo-scrolling mode="virtual"></dxo-scrolling>
          <dxo-grouping #expand [autoExpandAll]="false"></dxo-grouping>
          <div *dxTemplate="let data of 'groupCellTemplate'">
            {{ data.key }}
          </div>
        </dx-data-grid>
      </mat-grid-tile>
      <mat-grid-tile [colspan]="2">
        <dx-vector-map id="vector-map" [bounds]="[-180, 85, 180, -60]">
          <dxi-layer
            [customize]="customizeLayers"
            [dataSource]="worldMap"
            name="country"
          >
            <dxo-label [enabled]="true" dataField="country"></dxo-label>
          </dxi-layer>
          <dxi-legend
            [customizeText]="customizeLegendText"
            [customizeItems]="customizeItems"
            title="Status"
            horizontalAlignment="right"
            verticalAlignment="bottom"
            visible="true"
          >
            <dxo-source layer="country" grouping="color"></dxo-source>
          </dxi-legend>
          <dxo-tooltip
            [enabled]="true"
            contentTemplate="tooltipContent"
          ></dxo-tooltip>
          <dxo-export [enabled]="true"></dxo-export>
          <div *dxTemplate="let info of 'tooltipContent'" class="state-tooltip">
            <p>{{ info.attribute("name") }}</p>
          </div>
        </dx-vector-map>
      </mat-grid-tile>
    </mat-grid-list>
  </mat-card-content>
</mat-card>
