import { IPAsset } from './ipasset.model';
import { Injectable } from '@angular/core';
import { Adapter } from '../adapter/adapter';
import { TrmClass } from './ERP/trmclass.erp.model';

export class IPAssetFamily {
    family: string;
    clientReference: string;
    statusDetail: StatusInfo;
    statusDetailArray: StatusInfo[];
    geoCoverage: GeographicalCoverage[];
    segmentIds: number[];
    catchword: string;
    caseMasterId: number;
    hasFinancials: boolean;
}

export class StatusInfo {
    Status: string;
    Registered: number;
    Pending: number;
    Closed: number;
}

export class GeographicalCoverage {
    geoContinentId: number;
    geoContinent: string;
    countryId: string;
    country: string;
    statusId: number;
    status: string;
    assetId: number;
    coverScore: number;
    origin: string;
}

export class FamilyCoverage {
    constructor(
    public Family: string,
    public ContinentLabel: string,
    public ContinentId: number,
    public CountryLabel: string,
    public CountryId: string,
    public MappedStatusId: number,
    public MappedStatusLabel: string,
    public AssetId: number,
    public Origin: string,
    public classes: TrmClass[],
    public ShowOnTable: boolean,
    public ShowOnMap: boolean) {}
}

export class GeographicalCoverageTRM {
    constructor(
        public geoContinentId: number,
        public geoContinent: string,
        public countryId: string,
        public country: string,
        public statusId: number,
        public status: string,
        public origin: string,
        public classes: TrmClass[],
        public ShowOnTable: boolean,
        public ShowOnMap: boolean
    ) {}
}

@Injectable({
    providedIn: 'root'
  })
  export class GeographicalCoverageTRMAdapter implements Adapter<GeographicalCoverageTRM> {
    adapt(item: any): GeographicalCoverageTRM {
      return new GeographicalCoverageTRM(
          item.geoContinentId,
          item.geoContinent,
          item.countryId,
          item.country,
          item.statusId,
          item.status,
          item.origin,
          item.classCoverage,
          item.showOnTable,
          item.showOnMap
      );
    }
  }

@Injectable({
    providedIn: 'root'
  })
  export class FamilyCoverageAdapter implements Adapter<FamilyCoverage> {
    adapt(item: any): FamilyCoverage {
      return new FamilyCoverage(
          item.family,
          item.continentLabel,
          item.continentId,
          item.countryLabel,
          item.countryId,
          item.mappedStatusId,
          item.mappedStatusLabel,
          item.assetId,
          item.origin,
          item.classCoverage,
          item.showOnTable,
          item.showOnMap,
      );
    }
  }
