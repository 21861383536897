<div *ngIf="isLoading" class="spinner-border" role="status"></div>

<div *ngIf="showError"  class="alert alert-danger" role="alert">
    {{errorMessage}}
</div>

<div *ngIf="!isLoading">
    <div *ngIf ="tfaEnabled">
        <p class="alert alert-primary">Two-factor authentication has been enabled</p>
        <div class="box">
            <h2>Current settings</h2>
            <p>QR code:</p>
            <img [src]="qrInfo" width="200px" height="200px">
            <p>Secret key:</p>
            <pre>{{authenticatorKey}}</pre>
            <button class="btn btn-info" (click)="disableTFA()">Disable Two-Factor Authentication</button>
        </div>
    </div>
    <div *ngIf ="!tfaEnabled">
      <p class="alert alert-danger">Two-factor authentication is currently not enabled</p>
      <div>
          <div class="box">
              <h2>Step 1</h2>
              <p>Using the Google Authenticator app, you should scan the following QR code:</p>
              <img [src]="qrInfo" width="200px" height="200px">
              <p>Alternatively, you may enter the following code to the Google Authenticator app:</p>
              <pre>{{authenticatorKey}}</pre>
          </div>

          <div class="box">
              <h2>Step 2</h2>
              <div id="2fa-auth-container">
                <form (submit)="handleSubmit($event)">
                  <dx-form id="form" [(formData)]="twoAuthConfirmation" [colCount]="1" onsubmit="handlesubmit($event)">
                      <dxi-item dataField="AuthenticatorKey">
                        <dxi-validation-rule
                              type="required"
                              message="Authenticator code is required">
                        </dxi-validation-rule>
                      </dxi-item>
                    <dxi-item
                        itemType="button"
                        horizontalAlignment="left"
                        [buttonOptions]="buttonOptions">
                    </dxi-item>
                  </dx-form>
                </form>
              </div>
          </div>
      </div>
  </div>
</div>
