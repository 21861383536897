<h2 class="content-block">Documents overview</h2>

<dx-data-grid
  id="documentsGrid"
  keyExpr="documentId"
  [dataSource]="documentDataSrc"
  [showBorders]="true"
  height="85vh"
  [columnAutoWidth]="true"
  [rowAlternationEnabled]="true"
  [allowColumnReordering]="true"
>
  <dxo-paging [pageSize]="20" />
  <dxo-load-panel [enabled]="true" />

  <dxo-pager
    [showPageSizeSelector]="true"
    [allowedPageSizes]="[20, 50, 100, 250]"
    [showInfo]="true"
  />
  <dxo-search-panel
    [visible]="true"
    [width]="240"
    placeholder="Search for anything..."
  />
  <dxo-filter-row [visible]="true" />
  <dxi-column
    dataField="logDate"
    caption="Date added"
    dataType="date"
    sortOrder="desc"
    [width]="150"
  />
  <dxi-column
    dataField="clientReference"
    caption="Client Reference"
    [width]="150"
  />
  <dxi-column dataField="countryId" caption="Country" [width]="100" />
  <dxi-column
    dataField="calystaReference"
    caption="Calysta Reference"
    [width]="150"
  />
  <dxi-column dataField="categoryLabel" caption="Category" [width]="200" />
  <dxi-column
    dataField="subCategoryLabel"
    caption="Sub Category"
    [width]="200"
  />
  <dxi-column
    dataField="documentType"
    caption=""
    [width]="50"
    [allowFiltering]="false"
    [allowSorting]="false"
    cellTemplate="fileTypeTemplate"
  />
  <dxi-column dataField="description" caption="Name" [width]="500" />
  <dxi-column type="buttons" [width]="110" caption="">
    <dxi-button
      hint="Preview Related Document"
      icon="search"
      [onClick]="previewRelatedDocument"
    />
    <dxi-button
      hint="Download Related Document"
      icon="download"
      [onClick]="downloadRelatedDocument"
    />
    <!--<dxi-button
      hint="See Related Document In Another Tab"
      icon="link"
      [onClick]="tabPreview"
    />-->
  </dxi-column>

  <div *dxTemplate="let data of 'fileTypeTemplate'">
    <i
      class="{{ mapDocumentTypeIcon(data.value) }}"
      style="font-size: 28px"
    ></i>
    <!-- <i class= 'dx-icon-pdffile' ></i> -->
  </div>
</dx-data-grid>

<dx-popup
  [width]="'50vw'"
  [height]="'80vh'"
  [showTitle]="true"
  [title]="PopupTitle"
  [dragEnabled]="false"
  [closeOnOutsideClick]="true"
  [(visible)]="PopupVisible"
>
  <pdf-preview [BlobFile]="documentPreview" />
</dx-popup>

<dx-load-panel
  #loadPanel
  shadingColor="rgba(0,0,0,0.4)"
  [position]="{ of: 'body' }"
  [(visible)]="loadingPreview"
  [showIndicator]="true"
  [showPane]="true"
  [shading]="true"
  [closeOnOutsideClick]="false"
/>
