import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { TermsAndConditions } from "../../models/termsAndConditions.model";
import { UserService } from "../../services/Authentication/user.service";
import { AuthService } from "../../services";

@Component({
  selector: "app-terms-and-conditions",
  templateUrl: "./terms-and-conditions.component.html",
  styleUrls: ["./terms-and-conditions.component.scss"],
})
export class TermsAndConditionsComponent implements OnInit {
  private LatestTermsAndConditions: TermsAndConditions;

  constructor(private AuthService: AuthService, private router: Router) {
    this.AcceptTAndC = this.AcceptTAndC.bind(this);
  }

  ngOnInit() {
    this.AuthService.getLatestTermsAndConditions().subscribe(
      (res) => (this.LatestTermsAndConditions = res)
    );
  }

  AcceptTAndC(e) {
    this.AuthService.acceptTermsAndConditions(
      this.LatestTermsAndConditions
    ).subscribe((res) => {
      this.router.navigate(["/"]);
    });
  }
}
