import { Injectable } from "@angular/core";
import { Adapter } from "../adapter/adapter";

export class UserOrganizationPermissions {
  constructor(
    public UserOrganizationPermissionId: number,
    public OrganizationId: number,
    public OrganizationRoleTypeId: number,
    public CaseTypeId: number,
    public InstructionTypeId: number,
  ) {}
}


@Injectable({
  providedIn: 'root'
})

export class UserOrganizationPermissionsAdapter implements Adapter<UserOrganizationPermissions> {
  adapt(item: any): UserOrganizationPermissions {
    return new UserOrganizationPermissions(
      item.userOrganizationPermissionId,
      item.organizationId,
      item.organizationRoleTypeId,
      item.caseTypeId,
      item.instructionTypeId
    );
  }
}
