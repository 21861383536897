import { Injectable } from '@angular/core';
import { Adapter } from '../../adapter/adapter';

export class IPAssetTitle {
    constructor(
    public AssetId: number,
    public Language: string,
    public Title: string,
    ) {}
}

@Injectable({
    providedIn: 'root'
  })
  export class IPAssetTitleAdapter implements Adapter<IPAssetTitle> {
    adapt(item: any): IPAssetTitle {
      return new IPAssetTitle(
        item.assetId,
        item.language,
        item.title,
      );
    }
  }
