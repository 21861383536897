import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { ERPDocumentService } from "src/app/shared/services/ERP/erp.document.service";

import * as AspNetData from "devextreme-aspnet-data-nojquery";
import { AuthService } from "src/app/shared/services";
import { NgForm } from "@angular/forms";
import { ERPDocument } from "src/app/shared/models/ERP/document.erp.model";
import { DxDataGridComponent } from "devextreme-angular";
import DataSource from "devextreme/data/data_source";
import { Observable } from "rxjs";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: "app-documents",
  templateUrl: "./documents.component.html",
  styleUrls: ["./documents.component.scss"],
})
export class DocumentsComponent implements OnChanges, OnInit {
  @Input()
  assetId: number;

  documentPreview: any;

  newDocument: ERPDocument;
  newFile: File;
  uploadedFile: any;
  filePreview: any;
  currentTemplate: any;
  PopupVisible: boolean;
  PopupTitle: String;
  closeOnOutsideClick: boolean;

  @ViewChild("docForm", { static: true }) form: NgForm;
  @ViewChild(DxDataGridComponent)
  dataGrid: DxDataGridComponent;

  loadingVisible: boolean;
  loadingPreview: boolean = false;

  categoriesData: any;
  subCategoriesData: any;
  uploadUrl: any;
  uploadHeader: any;

  documentsData: DataSource;

  constructor(
    private documentSvc: ERPDocumentService,
    private AuthSvc: AuthService,
    private snackbar: MatSnackBar
  ) {
    this.loadingVisible = false;
    this.previewDocument = this.previewDocument.bind(this);
    this.downloadDocument = this.downloadDocument.bind(this);
  }

  ngOnInit(): void {
    const token = this.AuthSvc.accessTokenValue;

    this.categoriesData = AspNetData.createStore({
      key: "id",
      loadUrl: this.documentSvc.getDocumentCategoriesUrl(),
      onBeforeSend(method, ajaxOptions) {
        (ajaxOptions.xhrFields = { withCredentials: true }),
          (ajaxOptions.headers = {
            Authorization: "Bearer " + token,
          });
      },
    });

    this.subCategoriesData = AspNetData.createStore({
      key: "id",
      loadUrl: this.documentSvc.getDocumentSubCategoriesUrl(),
      onBeforeSend(method, ajaxOptions) {
        (ajaxOptions.xhrFields = { withCredentials: true }),
          (ajaxOptions.headers = {
            Authorization: "Bearer " + token,
          });
      },
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.assetId) {
      const token = this.AuthSvc.accessTokenValue;
      this.assetId = this.assetId;

      this.uploadHeader = {
        Authorization: "Bearer " + token,
        withCredentials: true,
      };

      this.documentSvc.getCaseDocuments(this.assetId).subscribe((res: any) => {
        this.documentsData = new DataSource({
          store: res.data,
          key: "documentId",
        });
      });
    }
  }

  mapDocumentTypeIcon(filetype: string): string {
    const iconprefix = "dx-icon-";
    switch (filetype) {
      case "xlsx":
        return iconprefix + "xlsxfile";
      case "xls":
        return iconprefix + "xlsfile";
      case "pdf":
        return iconprefix + "pdffile";
      case "docx":
        return iconprefix + "docxfile";
      case "doc":
        return iconprefix + "docfile";
      case "msg":
        return iconprefix + "email";
      case "eml":
        return iconprefix + "email";
      default:
        break;
    }
  }

  HandleUploadFeedback(uploadResult: boolean) {
    // When the upload Succeeds
    if (uploadResult) {
      this.snackbar.open("Your document(s) were uploaded successfully.", null, {
        duration: 5000,
        panelClass: "alert-green",
      });
      this.PopupVisible = false;
      this.documentSvc.getCaseDocuments(this.assetId).subscribe((res: any) => {
        this.documentsData = new DataSource({
          store: res.data,
          key: "documentId",
        });
      });
    }
    // When the upload fails
    else {
      this.snackbar.open(
        "Error uploading your documents. Please try again or contact Calysta.",
        null,
        {
          duration: 5000,
          panelClass: "alert-red",
        }
      );
    }
  }

  HandleCloseClick() {
    this.PopupVisible = false;
  }

  addDocumentClick() {
    this.closeOnOutsideClick = false;
    this.currentTemplate = "fileUpload";
    this.PopupTitle = "Upload Document";
    this.PopupVisible = true;
  }

  previewDocument(e) {
    this.loadingPreview = true;
    this.closeOnOutsideClick = true;

    this.documentSvc
      .LoadDocumentPreviewV2(e.row.data.documentId)
      .subscribe((res) => {
        this.loadingPreview = false;
        this.currentTemplate = "pdfpreview";
        this.documentPreview = res;
        this.PopupTitle = "Preview - " + e.row.data.description;
        this.PopupVisible = true;
      });
  }

  downloadDocument(e) {
    this.documentSvc
      .getDocument(e.row.data.documentId)
      .subscribe((res: any) => {
        const blob = res;
        const fileName = e.row.data.fileName;
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(link.href);
      });
  }
}
