import { Injectable } from '@angular/core';
import { Adapter } from '../../adapter/adapter';

export class IpAssetClass {
    constructor(
    public classNumber: number,
    public classDescription: string
    ) {}
}

@Injectable({
    providedIn: 'root'
  })
  export class IpAssetClassAdapter implements Adapter<IpAssetClass> {
    adapt(item: any): IpAssetClass {
      return new IpAssetClass(
        item.classNumber,
        item.classText
      );
    }
  }
