import { Component, Input, OnInit, ViewChild } from "@angular/core";
import DataSource from "devextreme/data/data_source";
import { IPFamilyCost } from "src/app/shared/models/financial/ipFamilyCosts.model";
import { IPAssetFamily } from "src/app/shared/models/ipassetfamily.model";
import { FinancialService } from "src/app/shared/services/Financial/financial.service";

import * as mapsData from "src/assets/Maps/world.js";
import { DxVectorMapComponent } from "devextreme-angular";

@Component({
  selector: "app-portfoliofinancialmap",
  templateUrl: "./portfoliofinancialmap.component.html",
  styleUrls: ["./portfoliofinancialmap.component.scss"],
})
export class PortfoliofinancialmapComponent implements OnInit {
  @ViewChild("FinancialMap") vectorMap: DxVectorMapComponent;

  @Input() FamilyFin: IPAssetFamily;

  dataSource: any;
  familyCosts: IPFamilyCost[];
  familyCostsTotal: IPFamilyCost[];
  colorRanges: number[];

  worldMap: any = mapsData.world;

  constructor(private finService: FinancialService) {
    this.familyCostsTotal = [];
    this.customizeLayers = this.customizeLayers.bind(this);
  }

  ngOnInit() {
    this.finService.getFamilyCosts(this.FamilyFin.family).subscribe(
      (res: IPFamilyCost[]) => {
        this.vectorMap.instance.beginUpdate();
        this.vectorMap.instance.showLoadingIndicator();

        this.dataSource = new DataSource({
          store: res,
          key: "Id",
        });
        this.familyCosts = res;

        this.familyCosts.forEach((element) => {
          const existingElement = this.familyCostsTotal.find(
            (x) => x.CountryId === element.CountryId
          );
          if (existingElement) {
            const costs = existingElement.Costs + element.Costs;
            const agentFees = existingElement.AgentFees + element.AgentFees;
            const calystaFees =
              existingElement.CalystaFees + element.CalystaFees;
            const officialFees =
              existingElement.OfficialFees + element.OfficialFees;
            const ind = this.familyCostsTotal.findIndex(
              (y) => y === existingElement
            );
            this.familyCostsTotal.splice(ind, 1);

            this.familyCostsTotal.push({
              CountryId: element.CountryId,
              CountryLabel: element.CountryLabel,
              Costs: costs,
              CaseId: null,
              Family: element.Family,
              Year: null,
              AgentFees: agentFees,
              CalystaFees: calystaFees,
              OfficialFees: officialFees,
            });
          } else {
            this.familyCostsTotal.push({
              CountryId: element.CountryId,
              CountryLabel: element.CountryLabel,
              Costs: element.Costs,
              CaseId: null,
              Family: element.Family,
              Year: null,
              AgentFees: element.AgentFees,
              CalystaFees: element.CalystaFees,
              OfficialFees: element.OfficialFees,
            });
          }
        });
        this.calculateColorRanges();
        this.vectorMap.instance.hideLoadingIndicator();
      },
      () => {},
      () => {
        this.vectorMap.instance.option("layers[0].dataSource", this.worldMap);
        this.vectorMap.instance.render();
        this.vectorMap.instance.endUpdate();
      }
    );
  }

  customizeLayers(elements) {
    if (this.familyCostsTotal.length > 0) {
      elements.forEach((element) => {
        if (
          this.familyCostsTotal.filter(
            (x) => x.CountryId === element.attribute("iso_a2")
          ).length > 0
        ) {
          element.attribute(
            "costs",
            this.familyCostsTotal.find(
              (x) => x.CountryId === element.attribute("iso_a2")
            ).Costs
          );
        }
      });
    }
  }

  calculateColorRanges() {
    const maxValue = Math.max.apply(
      Math,
      this.familyCostsTotal.map((o) => o.Costs)
    );
    let stepValue = maxValue / 6;
    if (stepValue > 0 && stepValue < 100) {
      stepValue = Math.ceil(stepValue / 10) * 10;
    }
    if (stepValue > 100 && stepValue <= 1000) {
      stepValue = Math.ceil(stepValue / 100) * 100;
    }
    if (stepValue > 1000 && stepValue <= 10000) {
      stepValue = Math.ceil(stepValue / 1000) * 1000;
    }

    this.colorRanges = [];
    for (let i = 0; i <= 6; i++) {
      this.colorRanges.push(i * stepValue);
    }
  }

  customizeLegendText(arg) {
    let text;
    text = arg.start + "€ to " + arg.end + "€";
    // if (arg.start === 1) {
    //     text = 'Registered';
    // } else if (arg.start === 2) {
    //     text = 'Pending';
    // } else {
    //     text = 'Closed';
    // }
    return text;
  }

  customizeTooltip(arg) {
    if (!arg.attribute("costs")) {
      return {
        text: arg.attribute("name") + ": no costs so far.",
      };
    }

    return {
      text: arg.attribute("name") + ": " + arg.attribute("costs") + " €",
    };
  }
  customizeItems(items) {}
}
