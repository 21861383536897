import { BrowserModule } from "@angular/platform-browser";
import { NgModule, APP_INITIALIZER, ErrorHandler } from "@angular/core";
import { AppComponent } from "./app.component";
import {
  SideNavOuterToolbarModule,
  SideNavInnerToolbarModule,
  SingleCardModule,
} from "./layouts";
import { FooterModule } from "./shared/components";
import {
  AuthService,
  AuthGuardService,
  RoleGuardService,
  AuthenticatedService,
} from "./shared/services/Authentication/auth.service";
import { ScreenService, AppInfoService } from "./shared/services";
import { AppRoutingModule } from "./app-routing.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import localeBe from "@angular/common/locales/en-BE";
import { DatePipe, registerLocaleData } from "@angular/common";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { JwtInterceptor } from "./shared/services/jwt.interceptor.service";
import { ErrorInterceptor } from "./shared/services/error.interceptor.service";
import { LOCALE_ID } from "@angular/core";
import { FinancialService } from "./shared/services/Financial/financial.service";
import { RenewalInstructionSet } from "./shared/models/Renewal/RenewalInstruction.model";
import * as Sentry from "@sentry/angular-ivy";
import { Router } from "@angular/router";

registerLocaleData(localeBe);

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    SideNavOuterToolbarModule,
    SideNavInnerToolbarModule,
    SingleCardModule,
    FooterModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    AuthService,
    AuthGuardService,
    AuthenticatedService,
    ScreenService,
    AppInfoService,
    RoleGuardService,
    FinancialService,
    RenewalInstructionSet,
    {
      provide: LOCALE_ID,
      useValue: "fr-FR", // 'de-DE' for Germany, 'fr-FR' for France ...}
    },
    DatePipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
