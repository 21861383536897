import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";

import { BehaviorSubject, Observable, throwError } from "rxjs";
import { environment } from "src/environments/environment";
import { catchError, map } from "rxjs/operators";
import {
  IPFamilyCost,
  IPFamilyCostsAdapter,
} from "../../models/financial/ipFamilyCosts.model";
import {
  InvoiceDetails,
  InvoiceDetails2,
  InvoiceDetailsAdapter,
  InvoiceDetailsAdapter2,
} from "../../models/financial/invoiceDetails.model";
import { InvoiceAdapter } from "../../models/financial/invoice.model";
import {
  IPAssetFinancials,
  IPAssetFinancialsAdapter,
} from "../../models/financial/IPAssetFinancials.model";
import {
  CostsBySegment,
  CostsBySegmentAdapter,
} from "../../models/financial/costsBySegment.model";

const apiUrl = environment.baseUrl + "/api/erp/financial/";

@Injectable()
export class FinancialService {
  constructor(
    private httpClient: HttpClient,
    private ipFamilyCostsAdapter: IPFamilyCostsAdapter,
    private invoiceAdapter: InvoiceAdapter,
    private invoiceDetailsAdapter: InvoiceDetailsAdapter,
    private invoiceDetailsAdapter2: InvoiceDetailsAdapter2,
    private ipAssetFinancialsAdapter: IPAssetFinancialsAdapter,
    private costsBySegmentAdapter: CostsBySegmentAdapter
  ) {}

  getIPAssetOverviewDetails(AssetId: number) {
    return this.httpClient
      .get<IPAssetFinancials[]>(apiUrl + "overview/asset/" + AssetId)
      .pipe(
        map((res: any) =>
          res.data.map((item) => this.ipAssetFinancialsAdapter.adapt(item))
        )
      )
      .pipe(catchError(this.handleError));
  }

  getIPAssetInvoiceDetails(AssetId: number) {
    return this.httpClient
      .get<InvoiceDetails[]>(apiUrl + "invoices/asset/" + AssetId)
      .pipe(
        map((res: any) =>
          res.data.map((item) => this.invoiceDetailsAdapter.adapt(item))
        )
      )
      .pipe(catchError(this.handleError));
  }

  getInvoiceDetails(InvoiceHeaderId: number) {
    return this.httpClient
      .get<InvoiceDetails2[]>(
        apiUrl + "invoices/invoicedetails/" + InvoiceHeaderId
      )
      .pipe(
        map((res: any) =>
          res.data.map((item) => this.invoiceDetailsAdapter2.adapt(item))
        )
      )
      .pipe(catchError(this.handleError));
  }

  getFamilyCosts(FamilyReference: string) {
    return this.httpClient
      .get<IPFamilyCost[]>(apiUrl + "family/" + FamilyReference)
      .pipe(
        map((res: any) =>
          res.data.map((item) => this.ipFamilyCostsAdapter.adapt(item))
        )
      )
      .pipe(catchError(this.handleError));
  }

  getInvoicePreview(invoiceNumber: number) {
    return this.httpClient
      .get(apiUrl + "invoice/" + invoiceNumber, { responseType: "blob" })
      .pipe(catchError(this.handleError));
  }

  getAllInvoices() {
    return this.httpClient
      .get(apiUrl + "invoices")
      .pipe(
        map((res: any) =>
          res.data.map((item) => this.invoiceAdapter.adapt(item))
        )
      )
      .pipe(catchError(this.handleError));
  }

  getCostsBySegmentAndRatio() {
    return this.httpClient
      .get<CostsBySegment[]>(apiUrl + "costanalysis/SegmentAndRatio")
      .pipe(
        map((res: any) =>
          res.map((item) => this.costsBySegmentAdapter.adapt(item))
        )
      )
      .pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error("An error occurred:", error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    // return an observable with a user-facing error message
    return throwError("Something bad happened; please try again later.");
  }
}
