<dx-data-grid
  id="invoiceDetailGrid"
  keyExpr="CaseId"
  [dataSource]="GridDataSource"
  [showBorders]="true"
  [columnAutoWidth]="true"
  [rowAlternationEnabled]="true"
  [allowColumnReordering]="false"
>
  <dxo-paging [pageSize]="15"></dxo-paging>
  <dxo-pager
    [showPageSizeSelector]="true"
    [allowedPageSizes]="[15, 50, 100, 250]"
    [showInfo]="true"
  >
  </dxo-pager>
  <dxo-filter-row [visible]="true"></dxo-filter-row>
  <dxi-column
    dataField="ClientReference"
    caption="Your Ref."
    sortOrder="desc"
  ></dxi-column>
  <dxi-column dataField="Catchword" caption="Catchword"></dxi-column>
  <dxi-column dataField="CalystaReference" caption="Calysta Ref."></dxi-column>
  <dxi-column
    dataField="AmountOnCase"
    caption="Amount (nonVAT)"
    dataType="number"
    format="currency"
    [width]="150"
  ></dxi-column>
  <dxi-column
    dataField="VatAmountOnCase"
    caption="VAT Amount"
    dataType="number"
    format="currency"
    [width]="150"
  ></dxi-column>
  <dxi-column
    dataField="TotalAmountOnCase"
    caption="Total Amount"
    dataType="number"
    format="currency"
    alignment="right"
    [width]="150"
  ></dxi-column>
</dx-data-grid>
