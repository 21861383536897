import { Component, OnInit, ViewChild } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";

import { DxFormComponent } from "devextreme-angular";
import { UserActivation } from "../../models/useractivation.model";
import { UserService } from "../../services/Authentication/user.service";
import { User } from "../../models/user";
import { MatSnackBar } from "@angular/material/snack-bar";
import { AuthService } from "../../services";

@Component({
  selector: "app-activation-form",
  templateUrl: "./activation-form.component.html",
  styleUrls: ["./activation-form.component.scss"],
})
export class ActivationFormComponent implements OnInit {
  @ViewChild(DxFormComponent) form: DxFormComponent;

  userActivation: UserActivation;

  ActivationResultVisible = false;
  ActivationResult: string;

  buttonOptions: any = {
    text: "Activate",
    type: "warning",
    useSubmitBehavior: true,
  };

  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private router: Router,
    private snackBar: MatSnackBar
  ) {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.userActivation = {
        email: params.email,
        password: null,
        token: params.token,
      };
    });
  }

  ngOnInit() {}

  passwordComparison = () => {
    return this.form.instance.option("formData").password;
  };

  onFormSubmit(e) {
    e.preventDefault();

    this.authService.activateUser(this.userActivation).subscribe(
      (res: User) => {
        this.snackBar.open(
          "Your account has been successfully registered.",
          null,
          {
            duration: 10000,
          }
        );
      },
      (error) => {
        this.snackBar.open(
          "Error validating activation token or token expired, please contact Calysta",
          null,
          {
            duration: 10000,
          }
        );
      },
      () => {
        setTimeout(() => {
          this.router.navigate(["/login"], {
            queryParams: { username: this.userActivation.email },
          });
        }, 3000);
      }
    );
    e.preventDefault();
  }
}
