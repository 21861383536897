  <mat-card class="tileCard">
  <mat-card-header>
    <div mat-card-avatar class="example-header-image"></div>
    <mat-card-title>Upcomming Deadlines</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <dx-data-grid
    id="deadlineGrid"
    keyExpr="DeadlineCategoryId"
    [dataSource]="DataSource"
    [showBorders]="false"
    [columnAutoWidth]="true"
    [rowAlternationEnabled]="true"
    [allowColumnReordering]="false"
  >
    <dxo-paging [pageSize]="4"></dxo-paging>
    <dxi-column dataField="DeadlineCategoryLabel" caption="Category"></dxi-column>
    <dxi-column dataField="Count" caption="#"></dxi-column>
    <dxi-column dataField="EarliestDeadline" caption="Earliest" dataType="date" alignment="right"></dxi-column>
  </dx-data-grid>
  </mat-card-content>
  <mat-card-footer>
    <button center mat-button (click)="ToOverviewClick()">> To Deadline Overview</button>
  </mat-card-footer>
</mat-card>
