import { Injectable } from '@angular/core';
import { Adapter } from '../../adapter/adapter';

export class IPAssetFinancials {
  constructor(
    public UserId: string,
    public AssetId: number,
    public CalystaReference: string,
    public ClientReference: string,
    public FamilyNumber: string,
    public InvoiceYear: number,
    public InvoiceCategoryId: number,
    public InvoiceCategoryLabel: string,
    public Amount: number
    ) {}
  }

@Injectable({
    providedIn: 'root'
  })

  export class IPAssetFinancialsAdapter implements Adapter<IPAssetFinancials> {
    adapt(item: any): IPAssetFinancials {
      return new IPAssetFinancials(
        item.userId,
        item.assetId,
        item.calystaReference,
        item.clientReference,
        item.familyNumber,
        item.invoiceYear,
        item.invoiceCategoryId,
        item.invoiceCategoryLabel,
        item.amount,
      );
    }
  }

