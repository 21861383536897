<div class="widget-container">
  <form id="form" #form method="post" action="" enctype="multipart/form-data">
    <div class="dx-fieldset">
      <div class="dx-field">
        <div class="dx-field-label">Description:</div>
        <dx-text-box
          class="dx-field-value"
          name="Description"
          [(value)]="description"
        ></dx-text-box>
      </div>
    </div>
    <dx-file-uploader
      #fileUploader
      uploadUrl="https://js.devexpress.com/Demos/NetCore/FileUploader/Upload"
      [multiple]="true"
      accept="image/*,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation"
      [(value)]="files"
      uploadMode="useFrom"
    ></dx-file-uploader>
    <dx-button
      id="closeButton"
      text="Close"
      type="normal"
      (onClick)="closeClick()"
    >
    </dx-button>
    <dx-button
      id="button"
      type="success"
      (onClick)="uploadClick()"
    >
    <div *dxTemplate="let data of 'content'">
      <dx-load-indicator
        class="button-indicator"
        [visible]="loadIndicatorVisible"
      >
      </dx-load-indicator>
      <span class="dx-button-text">{{ buttonText }}</span>
    </div>
    </dx-button>
  </form>
</div>
