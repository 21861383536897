import { Component, NgModule, Input } from "@angular/core";
import { CommonModule } from "@angular/common";

import { DxListModule } from "devextreme-angular/ui/list";
import { DxContextMenuModule } from "devextreme-angular/ui/context-menu";
import { UserService } from "../../services/Authentication/user.service";
import { User } from "../../models/user";
import { AuthService } from "../../services";
import { Router } from "@angular/router";

@Component({
  selector: "app-user-panel",
  templateUrl: "user-panel.component.html",
  styleUrls: ["./user-panel.component.scss"],
})
export class UserPanelComponent {
  currentUser: User;

  menuItems: any;

  @Input()
  menuMode: string;

  constructor(
    private userSvc: UserService,
    private authSvc: AuthService,
    private router: Router
  ) {
    userSvc.getUserProfile().subscribe((myUser: User) => {
      this.currentUser = myUser;
      this.setUserMenuItems(myUser);
    });
  }

  private setUserMenuItems(user: User) {
    this.menuItems = [
      {
        text: "Profile",
        icon: "user",
        onClick: () => {
          this.router.navigate(["/profile"]);
        },
      },
    ];
    if (user.roles.includes("Admin", 0)) {
      this.menuItems.push({
        text: "Admin",
        icon: "preferences",
        onClick: () => {
          this.router.navigate(["/admin"]);
        },
      });
    }
    this.menuItems.push({
      text: "Logout",
      icon: "runner",
      onClick: () => {
        this.authSvc.logOut();
      },
    });
  }
}

@NgModule({
  imports: [DxListModule, DxContextMenuModule, CommonModule],
  declarations: [UserPanelComponent],
  exports: [UserPanelComponent],
})
export class UserPanelModule {}
