import { DatePipe, formatDate } from "@angular/common";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ERPDocumentService } from "../../services/ERP/erp.document.service";

@Component({
  selector: "app-file-upload",
  templateUrl: "./file-upload.component.html",
  styleUrls: ["./file-upload.component.scss"],
})
export class FileUploadComponent {
  files: any[] = [];
  description: string = null;

  loadIndicatorVisible: boolean = false;
  buttonText: string = "upload";

  @Input()
  assetId: number;

  @Output()
  uploadEvent = new EventEmitter<boolean>();

  @Output()
  closeEvent = new EventEmitter<void>();

  constructor(private docSvc: ERPDocumentService, private datePipe: DatePipe) {}

  closeClick() {
    this.files = [];
    this.description = null;
    this.closeEvent.emit();
  }

  uploadClick() {
    this.buttonText = "Uploading...";
    this.loadIndicatorVisible = true;

    if (this.files.length > 0) {
      this.files.forEach((file) => {
        const formData: FormData = new FormData();

        formData.append("name", file.name);
        formData.append("description", this.description);
        // Category => UploadedByClient.
        // SubCategory => ClientCorrespondance.
        formData.append("categoryId", "20425");
        formData.append("subCategoryId", "20426");

        formData.append("uploadFile", file, file.name);

        this.docSvc.uploadDocument(this.assetId, formData).subscribe(
          (res) => {},
          (err) => {
            this.buttonText = "Upload";
            this.loadIndicatorVisible = false;
            this.uploadEvent.emit(false);
          },
          () => {
            this.buttonText = "Upload";
            this.loadIndicatorVisible = false;
            this.files = [];
            this.description = null;
            this.uploadEvent.emit(true);
          }
        );
      });
    }
  }
}
