<div *ngIf="!TwoFAEnabled">
  <mat-card class="warning-card">
    <mat-card-header
      ><span class="dx-icon-lock" style="color: yellow"></span
      ><u>Important:</u></mat-card-header
    >
    <mat-card-content>
      <p>
        Two factor authentication is available, and can be configured under your
        <a [routerLink]="['/profile']">profile</a> settings.
      </p>
      <p>
        We strongly advise you to do so, to keep your information as secured as
        possible.
      </p>
    </mat-card-content>
  </mat-card>
</div>

<ul class="list">
  <li class="list-item">
    <mat-card>
      <app-map-widget></app-map-widget>
    </mat-card>
  </li>
  <li class="list-item">
    <div class="list-content">
      <app-deadline-widget></app-deadline-widget>
    </div>
  </li>

  <li class="list-item">
    <div class="list-content">
      <app-document-widget></app-document-widget>
    </div>
  </li>

  <li class="list-item">
    <div class="list-content">
      <mat-card style="height: 100%">
        <app-ip-filing-widget></app-ip-filing-widget>
      </mat-card>
    </div>
  </li>
</ul>
