<dx-popup
  class="CreationPopup"
  [width]="350"
  [height]="350"
  [showTitle]="true"
  title="Add New Client"
  [dragEnabled]="false"
  [closeOnOutsideClick]="true"
  [(visible)]="addClientPopupVisible"
>
  <div *dxTemplate="let data of 'content'">
    <form (submit)="addUserFormSubmit($event)">
      <dx-form id="form" [(formData)]="newClient">
        <dxi-item dataField="nameId">
          <dxo-label text="Patricia Name Id"> </dxo-label>
        </dxi-item>
        <dxi-item
          itemType="button"
          horizontalAlignment="center"
          [buttonOptions]="buttonOptions"
        >
        </dxi-item>
      </dx-form>
    </form>
  </div>
</dx-popup>

<dx-data-grid
  #grid
  [dataSource]="organizationSrc"
  [remoteOperations]="true"
  height="85vh"
  showBorders="true"
  keyExpr="id"
  (onRowInserted)="AddSubscription($event)"
  (onRowUpdated)="UpdateSubscription($event)"
  (onRowRemoved)="RemoveSubscription($event)"
  (onToolbarPreparing)="onToolbarPreparing($event)"
>
  <dxo-editing
    mode="row"
    [allowUpdating]="true"
    [allowDeleting]="true"
    [allowAdding]="false"
  >
  </dxo-editing>
  <dxo-filter-row [visible]="true"></dxo-filter-row>
  <dxo-header-filter [visible]="true"></dxo-header-filter>
  <dxo-scrolling mode="virtual" [showScrollbar]="'always'"></dxo-scrolling>
  <dxi-column
    [allowEditing]="false"
    dataField="NameId"
    caption="Patricia Id"
  ></dxi-column>
  <dxi-column
    [allowEditing]="false"
    dataField="OrganizationName"
    caption="OrganizationName"
  ></dxi-column>
  <dxi-column dataField="SubscriptionModelId" caption="Subscription">
    <dxo-lookup
      [dataSource]="subscriptionModelDict"
      valueExpr="keyValue"
      displayExpr="stringValue"
    >
    </dxo-lookup>
  </dxi-column>
  <dxi-column dataField="LicensePayedOn" caption="License Payed On">
  </dxi-column>
</dx-data-grid>
