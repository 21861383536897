import { Component, OnInit, ViewChild } from "@angular/core";
import * as mapsData from "src/assets/Maps/world.js";
import Query from "devextreme/data/query";
import { DxVectorMapComponent } from "devextreme-angular";
import { Router } from "@angular/router";
import { IPFamilyService } from "src/app/shared/services/IPAssetFamily.service";
import { GeographicalCoverageTRM } from "src/app/shared/models/ipassetfamily.model";

@Component({
  selector: "app-map-widget",
  templateUrl: "./map-widget.component.html",
  styleUrls: ["./map-widget.component.scss"],
})
export class MapWidgetComponent implements OnInit {
  @ViewChild(DxVectorMapComponent) bubbleMap: DxVectorMapComponent;

  worldMap: any = mapsData.world;
  coverageData: any[];

  constructor(private IPFamilySvc: IPFamilyService, private router: Router) {
    this.customizeLayers = this.customizeLayers.bind(this);
    this.coverageData = [];
  }

  customizeLayers(elements) {
    elements.forEach((element) => {
      if (
        this.coverageData.filter(
          (x) => x.countryLabel === element.attribute("iso_a2")
        ).length > 0
      ) {
        element.applySettings({ color: "#f76d06" });
      }
    });
  }

  ngOnInit() {
    this.IPFamilySvc.getPortfolioCoverage().subscribe(
      (data: GeographicalCoverageTRM[]) => {
        if (this.bubbleMap) {
          this.bubbleMap.instance.beginUpdate();
          this.bubbleMap.instance.showLoadingIndicator();
          if (data.length > 0) {
            const groupedData = Query(data).groupBy("countryId").toArray();

            this.coverageData = groupedData.map((val: any) => {
              return {
                countryLabel: val.key,
                countryCount: val.items.length,
              };
            });
          }

          this.bubbleMap.instance.hideLoadingIndicator();
          this.bubbleMap.instance.option("layers[0].dataSource", this.worldMap);
          this.bubbleMap.instance.render();
          this.bubbleMap.instance.endUpdate();
        }
      },
      /// On error.
      () => {},
      /// On complete.
      () => {}
    );
  }

  CountryClicked(e) {
    const attributes = e.target.attribute();
    this.router.navigate(["/ipassetoverview"], {
      queryParams: { country: attributes.iso_a2 },
    });
  }
}
