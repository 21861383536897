import { Component, OnInit, ViewChild } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { DxDataGridComponent } from "devextreme-angular";
import DataSource from "devextreme/data/data_source";
import { Dictionary } from "src/app/shared/models/dictionary.model";
import { User } from "src/app/shared/models/user";
import { UserOrganizationPermissions } from "src/app/shared/models/userorganizationpermissions.model";
import { AuthService } from "src/app/shared/services";
import { UserService } from "src/app/shared/services/Authentication/user.service";

@Component({
  selector: "app-admin-user",
  templateUrl: "./admin-user.component.html",
  styleUrls: ["./admin-user.component.scss"],
})
export class AdminUserComponent implements OnInit {
  @ViewChild(DxDataGridComponent) dataGrid: DxDataGridComponent;

  userSrc: DataSource;
  url: string;
  masterDetailDataSource: any;

  newUserId: any;

  organizationDictionary: Dictionary[];
  rolesDictionary: Dictionary[];
  caseMasterDictionary: Dictionary[];
  instructionTypeDictionary: Dictionary[];
  permissionDictionary: Dictionary[];

  addUserPopupVisible = false;
  activationPopupVisible = false;
  loadingVisible = false;

  userActivationPopupTitle: string;
  userActivationResult: string;

  userBeingActivated: User;

  currentUser: User;

  buttonOptions: any = {
    text: "Add User",
    type: "warning",
    useSubmitBehavior: true,
  };

  constructor(
    private userSvc: UserService,
    private authService: AuthService,
    private snackbar: MatSnackBar
  ) {
    this.sendActivationEmailClick = this.sendActivationEmailClick.bind(this);
    this.enableAccount = this.enableAccount.bind(this);
    this.disableAccount = this.disableAccount.bind(this);
  }

  ngOnInit() {
    this.loadData();
  }

  private loadData() {
    this.userSvc.getUsers().subscribe((data: User[]) => {
      this.userSrc = new DataSource({
        store: data,
        key: "id",
      });
    });
  }

  getDataPermissions(userId: string) {
    let data: UserOrganizationPermissions[];
    this.userSvc.getUser(userId).subscribe((userline: User) => {
      data = userline.userOrganizationPermissions;
    });

    return new DataSource({
      store: data,
      key: "userOrganizationPermissionId",
    });
  }

  onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift({
      location: "after",
      widget: "dxButton",
      options: {
        icon: "add",
        text: "add user",
        onClick: this.addUserPopup.bind(this),
      },
    });
  }

  onEditorPreparing(e: any) {
    if (e.row.rowType === "data" && e.dataField === "organizationId") {
      e.editorOptions.searchTimeout = 500;
    }
  }

  addUserPopup() {
    this.addUserPopupVisible = true;
  }

  RemoveUser(e) {
    this.userSvc.deleteUser(e.data.id).subscribe((res: User) => {});
  }

  disableAccount(e) {
    this.userSvc.disableAccount(e.row.data.id).subscribe((res: User) => {
      this.loadData();
      this.dataGrid.instance.refresh();
    });
  }

  enableAccount(e) {
    this.userSvc.enableAccount(e.row.data.id).subscribe((res: User) => {
      this.loadData();
      this.dataGrid.instance.refresh();
    });
  }

  sendActivationEmailClick(e) {
    this.loadingVisible = true;
    this.userBeingActivated = e.row.data;
    this.userActivationPopupTitle = "Activation of user: " + e.row.data.email;

    this.authService.sendActivationMail(e.row.data.id).subscribe(
      (result) => {
        this.userActivationResult =
          "The activation e-mail has been successfully send. You have been put in BCC automatically.";
      },
      (error) => {
        this.userActivationResult =
          "An error occured while sending the activation e-mail, please contact IT.";
      },
      () => {
        this.loadingVisible = false;
        e.row.data.activationEmailSendOn = Date.now();
        this.activationPopupVisible = true;
      }
    );

    e.event.preventDefault();
  }

  isNotActivated(e) {
    if (e.row.data.activationDate) {
      return false;
    }
    return true;
  }

  isAccountActive(e) {
    return e.row.data.active;
  }

  isAccountDisabled(e) {
    return !e.row.data.active;
  }

  addUserFormSubmit(e) {
    this.addUserPopupVisible = false;

    this.userSvc.createNewUser(+this.newUserId.patriciaId).subscribe(
      (res: User[]) => {
        this.userSrc = new DataSource({
          store: res,
          key: "id",
        });
        this.dataGrid.instance.refresh();
      },
      (error) => {
        //toast error
        this.snackbar.open(
          "There was an error adding a new user, " + error,
          null,
          {
            duration: 5000,
            panelClass: "alert-red",
          }
        );
      }
    );

    e.preventDefault();
  }

  calculateFilterExpression(filterValue, selectedFilterOperation, target) {
    if (target === "search" && typeof filterValue === "string") {
      return [(this as any).dataField, "contains", filterValue];
    }
    return function (data) {
      return (data.AssignedEmployee || []).indexOf(filterValue) !== -1;
    };
  }

  cellTemplate(container, options) {
    // tslint:disable-next-line:one-variable-per-declaration
    var noBreakSpace = "\u00A0",
      text = (options.value || [])
        .map((element) => {
          return options.column.lookup.calculateCellValue(element);
        })
        .join(", ");
    container.textContent = text || noBreakSpace;
    container.title = text;
  }

  formatDateToLocal(cellInfo) {
    // Check if the date is null or undefined
    if (cellInfo.value === null || cellInfo.value === undefined) {
      return null;
    }

    const utcDate = new Date(cellInfo.value);
    const localDate = new Date(
      utcDate.getTime() - utcDate.getTimezoneOffset() * 60000
    );

    const formatData = (input: number) =>
      input > 9 ? input.toString() : `0${input}`;

    const day = formatData(localDate.getDate());
    const month = formatData(localDate.getMonth() + 1); // Months are 0-based in JavaScript
    const year = localDate.getFullYear();
    const hours = formatData(localDate.getHours());
    const minutes = formatData(localDate.getMinutes());

    return `${day}/${month}/${year} ${hours}:${minutes}`;
  }

  customSort(a: any, b: any, direction: string): number {
    if (a === null || a === undefined) {
      return 1; // Always sort nulls to the bottom
    }
    if (b === null || b === undefined) {
      return -1; // Always sort nulls to the bottom
    }
    // Assuming a and b are Date objects or can be compared directly
    return direction === "asc" ? a - b : b - a;
  }
}
