<div class="roleSelection">
  <dx-list
    #list
    [dataSource]="roleList | async"
    displayExpr="name"
    keyExpr="name"
    selectionMode="multiple"
    selectAllMode="page"
    [showSelectionControls]="true"
    [selectedItemKeys]="userRoles"
    (onSelectionChanged)="handleRoleSelectionChange($event)"
  ></dx-list>
  <div class="roleSaveButton">
    <dx-button
      stylingMode="contained"
      text="Save"
      style="float: right"
      type="success"
      [width]="120"
      (onClick)="saveRoleChanges()"
    >
    </dx-button>
  </div>
</div>
