import { Injectable } from '@angular/core';
import { Adapter } from '../../adapter/adapter';

export class CostsBySegment {
  constructor(
    public ClientId: number,
    public ClientSegmentId: number,
    public SegmentLabel: string,
    public Year: string,
    public CalystaFees: number,
    public AgentFees: number,
    public OfficialFees: number,
    public TotalFees: number
    ) {}
  }

@Injectable({
    providedIn: 'root'
  })

  export class CostsBySegmentAdapter implements Adapter<CostsBySegment> {
    adapt(item: any): CostsBySegment {
      return new CostsBySegment (
        item.clientId,
        item.clientSegmentId,
        item.segmentLabel,
        item.year,
        item.calystaFees,
        item.agentFees,
        item.officialFees,
        item.totalFees
      );
    }
  }

