import { Injectable } from '@angular/core';
import { Adapter } from '../adapter/adapter';

export class TermsAndConditions {
  constructor(
    public Id: number,
    public HTMLVersion: string,
    public ValidFrom: Date ) {}
  }

@Injectable({
    providedIn: 'root'
  })

  export class TermsAndConditionsAdapter implements Adapter<TermsAndConditions> {
    adapt(item: any): TermsAndConditions {
      return new TermsAndConditions(
        item.id,
        item.hTMLVersion,
        item.validFrom,
      );
    }
  }
