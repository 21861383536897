<div class="buttons-row">
  <dx-button
    text="Upload Document"
    style="float: right"
    (onClick)="addDocumentClick()"
  >
  </dx-button>
</div>

<dx-load-panel
  #loadPanel
  shadingColor="rgba(0,0,0,0.4)"
  [position]="{ of: '#uploadForm' }"
  [(visible)]="loadingVisible"
  [showIndicator]="true"
  [showPane]="true"
  [shading]="true"
  [closeOnOutsideClick]="false"
>
</dx-load-panel>

<div class="row">
  <dx-data-grid
    [dataSource]="documentsData"
    [rowAlternationEnabled]="true"
    keyExpr="documentId"
  >
    <dxo-editing mode="row" [allowAdding]="false"></dxo-editing>creationDate
    <dxi-column
      dataField="creationDate"
      caption="Date"
      dataType="date"
      [width]="150"
      [format]="'dd/MM/yyyy'"
      sortOrder="desc"
    >
    </dxi-column>
    <dxi-column dataField="categoryId" caption="Category" [width]="200">
      <dxo-lookup
        [dataSource]="categoriesData"
        valueExpr="id"
        displayExpr="label"
      >
      </dxo-lookup>
    </dxi-column>
    <dxi-column dataField="subCategoryId" caption="SubCategory" [width]="275">
      <dxo-lookup
        [dataSource]="subCategoriesData"
        valueExpr="id"
        displayExpr="label"
      >
      </dxo-lookup>
    </dxi-column>
    <dxi-column
      dataField="documentType"
      caption=""
      [width]="50"
      [allowFiltering]="false"
      [allowSorting]="false"
      cellTemplate="fileTypeTemplate"
    ></dxi-column>
    <dxi-column dataField="description" caption="Description"></dxi-column>
    <dxi-column type="buttons" [width]="75" caption="">
      <dxi-button
        hint="Preview Document"
        icon="search"
        [onClick]="previewDocument"
      ></dxi-button>
      <dxi-button
        hint="Download Document"
        icon="download"
        [onClick]="downloadDocument"
      >
      </dxi-button>
    </dxi-column>
    <div *dxTemplate="let data of 'fileTypeTemplate'">
      <i
        class="{{ mapDocumentTypeIcon(data.value) }}"
        style="font-size: 28px"
      ></i>
    </div>
  </dx-data-grid>
</div>

<dx-popup
  [width]="800"
  [height]="680"
  [showTitle]="true"
  [title]="PopupTitle"
  [dragEnabled]="false"
  [closeOnOutsideClick]="closeOnOutsideClick"
  [(visible)]="PopupVisible"
  [contentTemplate]="currentTemplate"
>
  <div *dxTemplate="let data of 'pdfpreview'">
    <pdf-preview [BlobFile]="documentPreview" />
  </div>

  <div *dxTemplate="let data of 'fileUpload'">
    <app-file-upload
      [assetId]="assetId"
      (uploadEvent)="HandleUploadFeedback($event)"
      (closeEvent)="HandleCloseClick()"
    ></app-file-upload>
  </div>
</dx-popup>

<dx-load-panel
  #loadPanel
  shadingColor="rgba(0,0,0,0.4)"
  [position]="{ of: 'body' }"
  [(visible)]="loadingPreview"
  [showIndicator]="true"
  [showPane]="true"
  [shading]="true"
  [closeOnOutsideClick]="false"
/>
