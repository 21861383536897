<mat-card #evolutionChart>
  <dx-chart id="chart" title="Cost Evolution" [dataSource]="barChartDataSet">
    <dxi-series valueField="CalystaFees" name="Calysta Fees"></dxi-series>
    <dxi-series valueField="OfficialFees" name="Official Fees"></dxi-series>
    <dxi-series valueField="AgentFees" name="Agent Fees"></dxi-series>
    <dxi-value-axis position="right">
      <dxo-title text="Euro"></dxo-title>
    </dxi-value-axis>
    <dxo-common-series-settings
      argumentField="Year"
      type="stackedBar"
      [ignoreEmptyPoints]="true"
    >
    </dxo-common-series-settings>
    <dxo-legend
      verticalAlignment="bottom"
      horizontalAlignment="center"
      itemTextPosition="top"
    >
    </dxo-legend>
    <dxo-export [enabled]="true"></dxo-export>
    <dxo-tooltip [enabled]="true" location="edge"> </dxo-tooltip>
  </dx-chart>
</mat-card>
