import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import DataSource from "devextreme/data/data_source";
import notify from "devextreme/ui/notify";
import { finalize } from "rxjs/operators";
import { FinancialService } from "src/app/shared/services/Financial/financial.service";

@Component({
  selector: "app-invoice",
  templateUrl: "./invoiceview.component.html",
  styleUrls: ["./invoiceview.component.scss"],
})
export class InvoiceViewComponent implements OnInit {
  documentDataSrc: DataSource;

  loadingPreview: boolean = false;

  documentPreview: any;

  constructor(
    private financialSvc: FinancialService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    const invoiceNumber = this.route.snapshot.paramMap.get("invoiceNumber");

    this.previewRelatedDocument(invoiceNumber);
  }

  previewRelatedDocument(invoiceNumber) {
    this.loadingPreview = true;
    this.financialSvc.getInvoicePreview(invoiceNumber).subscribe({
      next: (res) => {
        this.documentPreview = res;
      },
      error: (err) => {
        this.loadingPreview = false;
        notify("error loading document", "Error", 3000);
      },
      complete: () => {
        this.loadingPreview = false;
      },
    });
  }
}
