import { Component, OnInit, ViewChild } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";

import { AuthService, AppInfoService } from "../../services";
import { MatSnackBar } from "@angular/material/snack-bar";
import { UserService } from "../../services/Authentication/user.service";
import { DxFormComponent } from "devextreme-angular";
import { ResetPassword } from "../../models/resetPassword.model";

@Component({
  selector: "app-resetpassword-form",
  templateUrl: "./resetpassword-form.component.html",
  styleUrls: ["./resetpassword-form.component.scss"],
})
export class ResetPasswordFormComponent implements OnInit {
  @ViewChild(DxFormComponent) form: DxFormComponent;

  resetPasswordRequest: ResetPassword;
  loading: boolean = false;

  returnUrl: string;
  userName = "";
  password = "";
  submitted = false;
  error = "";
  token: string;

  constructor(
    private authService: AuthService,
    public appInfo: AppInfoService,
    private router: Router,
    private userSvc: UserService,
    private snackbar: MatSnackBar,
    private route: ActivatedRoute
  ) {
    this.route.queryParams.subscribe((params) => {
      this.resetPasswordRequest = {
        Email: params.email,
        Password: null,
        Token: params.token,
      };
    });
  }

  ngOnInit() {}

  passwordComparison = () => {
    return this.form.instance.option("formData").Password;
  };

  async onFormSubmit(e) {
    e.preventDefault();
    this.loading = true;
    if (this.resetPasswordRequest) {
      this.authService.resetPassword(this.resetPasswordRequest).subscribe(
        () => {
          this.snackbar.open("Your password has been reset succesfully", null, {
            duration: 10000,
          });
          this.router.navigate(["/login"], {
            queryParams: { username: this.resetPasswordRequest.Email },
          });
        },
        (error) => {
          this.snackbar.open(
            "Error during the reset of you password, please contact Calysta",
            null,
            {
              duration: 10000,
            }
          );
        },
        () => {
          this.loading = false;
          setTimeout(() => {
            this.router.navigate(["/login"], {
              queryParams: { username: this.resetPasswordRequest.Email },
            });
          }, 3000);
        }
      );
    }
  }
}
