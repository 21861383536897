import { Injectable } from "@angular/core";
import { Adapter } from "../../adapter/adapter";

export class InvoiceDetails {
  constructor(
    public CaseId: number,
    public InvoiceHeaderId: number,
    public InvoiceDate: Date,
    public InvoiceNumber: number,
    public AmountOnCase: number,
    public AgentFees: number,
    public CalystaFees: number,
    public OfficialFees: number
  ) {}
}

export class InvoiceDetails2 {
  constructor(
    public AmountOnCase: number,
    public CalystaReference: string,
    public CaseId: number,
    public Catchword: string,
    public ClientReference: string,
    public Country: string,
    public InvoiceDate: Date,
    public InvoiceHeaderId: number,
    public InvoiceIsPayed: boolean,
    public InvoiceNumber: number,
    public InvoiceType: string,
    public TotalAmountOnCase: number,
    public TotalInvoiceAmount: number,
    public VatAmountOnCase: number
  ) {}
}

@Injectable({
  providedIn: "root",
})
export class InvoiceDetailsAdapter implements Adapter<InvoiceDetails> {
  adapt(item: any): InvoiceDetails {
    return new InvoiceDetails(
      item.caseId,
      item.invoiceHeaderId,
      item.invoiceDate,
      item.invoiceNumber,
      item.amountOnCase,
      item.agentFees,
      item.calystaFees,
      item.officialFees
    );
  }
}

@Injectable({
  providedIn: "root",
})
export class InvoiceDetailsAdapter2 implements Adapter<InvoiceDetails2> {
  adapt(item: any): InvoiceDetails2 {
    return new InvoiceDetails2(
      item.amountOnCase,
      item.calystaReference,
      item.caseId,
      item.catchword,
      item.clientReference,
      item.country,
      item.invoiceDate,
      item.invoiceHeaderId,
      item.invoiceIsPayed,
      item.invoiceNumber,
      item.invoiceType,
      item.totalAmountOnCase,
      item.totalInvoiceAmount,
      item.vatAmountOnCase
    );
  }
}
