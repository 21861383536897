import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";

import { AuthService, AppInfoService } from "../../services";
// import { DxButtonModule } from 'devextreme-angular/ui/button';
// import { DxCheckBoxModule } from 'devextreme-angular/ui/check-box';
// import { DxTextBoxModule } from 'devextreme-angular/ui/text-box';
// import { DxValidatorModule } from 'devextreme-angular/ui/validator';
// import { DxValidationGroupModule } from 'devextreme-angular/ui/validation-group';
import { first } from "rxjs/operators";
import { MatSnackBar } from "@angular/material/snack-bar";
import { UserService } from "../../services/Authentication/user.service";
import { User } from "../../models/user";

@Component({
  selector: "app-login-form",
  templateUrl: "./login-form.component.html",
  styleUrls: ["./login-form.component.scss"],
})
export class LoginFormComponent implements OnInit {
  returnUrl: string;
  login = "";
  password = "";
  submitted = false;
  error = "";

  constructor(
    private authService: AuthService,
    public appInfo: AppInfoService,
    private router: Router,
    private userSvc: UserService,
    private snackbar: MatSnackBar,
    private route: ActivatedRoute
  ) {
    // Redirect to the home page if the user is already logged in.
    if (this.authService.currentUserValue) {
      this.router.navigate(["/"]);
    }

    this.authService.removeCacheItems();

    this.route.queryParams.subscribe((params) => {
      this.login = params.username;
    });
  }

  ngOnInit() {
    this.returnUrl = this.route.snapshot.queryParams.returnUrl || "/";
  }

  onLoginClick(args) {
    this.submitted = true;

    this.password = encodeURIComponent(this.password);

    if (!args.validationGroup.validate().isValid) {
      return;
    }

    this.authService
      .logIn(this.login, this.password)
      .pipe(first())
      .subscribe(
        (r) => {
          const checkIfUserHas2FAAuth = r["twofactorenabled"];
          const username = r["username"];

          // Check if the user has 2FA Enabled, if this is the case => Ask for the credentials before allowing a succesfull login.
          // If not, we continue and check if the Terms & conditions have been accepted.
          if (checkIfUserHas2FAAuth == true) {
            this.router.navigate(["/tfauth"], {
              queryParams: { email: username },
            });
          } else {
            this.authService
              .checkIfTermsAndConditionsAccepted()
              .subscribe((res) => {
                this.userSvc.getUserProfile().subscribe(
                  (data: User) => {
                    localStorage.setItem(
                      "userRoles",
                      JSON.stringify(data.roles)
                    );
                    this.authService.userRolesSubject.next(data.roles);
                  },
                  () => {},
                  () => {
                    this.router.navigate([this.returnUrl]);
                    this.snackbar.open(
                      "Welcome @ Calysta Client Portal, enjoy your stay",
                      null,
                      {
                        duration: 3000,
                      }
                    );
                  }
                );
              });
          }
        },
        (error) => {
          this.snackbar.open(
            "Incorrect username or password, please try again",
            null,
            {
              duration: 5000,
              panelClass: "alert-red",
            }
          );
        }
      );
  }
}
