import { Component, OnInit, ViewChild } from "@angular/core";
import { IPAsset } from "src/app/shared/models/ipasset.model";
import { DxDataGridComponent, DxTreeViewComponent } from "devextreme-angular";
import { exportDataGrid as exportDataGridToPdf } from "devextreme/pdf_exporter";
import DataSource from "devextreme/data/data_source";
import { IPFamilyService } from "src/app/shared/services/IPAssetFamily.service";
import * as mapsData from "src/assets/Maps/world.js";
import {
  IPAssetFamily,
  GeographicalCoverage,
} from "src/app/shared/models/ipassetfamily.model";
import { Continent } from "src/app/shared/models/country.model";

import { CountryService } from "src/app/shared/services/Common/common.country.service";
import { ContinentService } from "src/app/shared/services/Common/common.continent.service";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { ERPSegmentService } from "src/app/shared/services/ERP/erp.segment.service";
import { ERPSegment } from "src/app/shared/models/ERP/segment.erp.model";
import { ERPIPTypeService } from "src/app/shared/services/ERP/erp.iptype.service";
import { Dictionary } from "src/app/shared/models/dictionary.model";

@Component({
  selector: "app-portfolio",
  templateUrl: "./portfolio.component.html",
  styleUrls: ["./portfolio.component.scss"],
  providers: [IPFamilyService],
})
export class PortfolioComponent implements OnInit {
  @ViewChild(DxTreeViewComponent) treeView;
  @ViewChild(DxDataGridComponent) dataGrid: DxDataGridComponent;

  // Filter Related
  portfolioFilter: PortfolioFilter;
  CalystaReferences: string[];
  ClientReferences: string[];
  Catchwords: string[];
  IPMasterTypes: Dictionary[];
  ContinentDataSource: any;
  treeBoxValue: string[];
  dataSourceFilter: [];
  ProjectDataSource: any;
  statusBarWidth: number;
  segments: ERPSegment[];

  dataSource: DataSource;
  CoveragedataSource: DataSource;
  CoverageDataSourceStorage;
  IPFamilyStorage: IPAssetFamily[];
  FamilyCoverage: GeographicalCoverage[];

  // Map
  worldMap: any = mapsData.world;

  GridResult: IPAsset[];

  noDataText: string = "Loading Data...";

  constructor(
    private familySvc: IPFamilyService,
    private countrySvc: CountryService,
    private continentSvc: ContinentService,
    private segmentService: ERPSegmentService,
    private ipTypeService: ERPIPTypeService
  ) {
    this.portfolioFilter = new PortfolioFilter();
    this.treeBoxValue = null;
    this.customizeLayers = this.customizeLayers.bind(this);
    this.CoverageDataSourceStorage = [];
  }

  ngOnInit() {
    this.familySvc.IPAssetFamilies.subscribe((res: IPAssetFamily[]) => {
      this.IPFamilyStorage = res;
      this.CalystaReferences = res.map((x) => x.family);
      this.ClientReferences = res
        .map((x) => x.clientReference)
        .filter((a) => a)
        .sort();
      this.Catchwords = res
        .map((x) => x.catchword)
        .filter((a) => a)
        .sort();

      this.dataSource = new DataSource({
        store: res,
        key: "family",
      });

      //this.noDataText = this.DataLoadingText();
    });

    this.segmentService.getContactSegments().subscribe((res: ERPSegment[]) => {
      this.segments = res.sort((a, b) =>
        a.SegmentLabel > b.SegmentLabel ? 1 : -1
      );
    });

    this.continentSvc.getContinents().subscribe((res: Continent[]) => {
      res.forEach((element) => {
        element.countries = element.countries.filter(
          (x) => x.showOnTable === true
        );
      });
      this.ContinentDataSource = new DataSource({
        store: res,
      });
    });

    this.ipTypeService
      .getCaseMasterDictionary()
      .subscribe((res: Dictionary[]) => {
        this.IPMasterTypes = res;
      });
  }

  customizeLayers(elements) {
    elements.forEach((element) => {
      if (
        this.FamilyCoverage.filter(
          (x) => x.country === element.attribute("name")
        ).length === 1
      ) {
        switch (
          this.FamilyCoverage.find(
            (x) => x.country === element.attribute("name")
          ).status
        ) {
          case "Registered":
            element.applySettings({ color: "#008000" });
            element.attribute("status", 1);
            break;

          case "Pending":
            element.applySettings({ color: "#2727ff" });
            element.attribute("status", 2);
            break;

          case "Closed":
            element.applySettings({ color: "#808080" });
            element.attribute("status", 3);
            break;
          default:
            break;
        }
      }
    }, this);
  }

  customizeTooltip(arg: any) {
    return {
      text: arg.seriesName + " - " + arg.valueText + " files",
    };
  }

  getFamilyCoverage(Family) {
    this.FamilyCoverage = [];
    this.FamilyCoverage = this.IPFamilyStorage.find(
      (x) => x.family === Family
    ).geoCoverage.sort();
    return this.FamilyCoverage;
  }

  public templateType(asset: IPAssetFamily): number {
    if (asset.family.substr(0, 3) === "TRM") {
      return 1;
    }
    if (asset.family.substr(0, 3) === "PAT") {
      return 2;
    }
    if (asset.family.substr(0, 3) === "DES") {
      return 3;
    }
    return 1;
  }

  public HasFinancials(asset: IPAssetFamily): boolean {
    return asset.hasFinancials;
  }

  filter_fieldDataChanged(e) {
    if (e.dataField === "Family") {
      this.portfolioFilter.Family = e.value;
    }

    if (e.dataField === "Segment") {
      this.portfolioFilter.Segment = e.value;
    }

    if (e.dataField === "ClientReference") {
      this.portfolioFilter.ClientReference = e.value;
    }

    if (e.dataField === "Catchword") {
      this.portfolioFilter.CatchWord = e.value;
    }

    if (e.dataField === "IPType") {
      this.portfolioFilter.IPType = e.value;
    }

    this.filter_syncDataGrid();
  }

  customizeText(itemInfo) {
    if (itemInfo.color === "#17df00") {
      return "Registered";
    } else if (itemInfo.color === "#2727ff") {
      return "Pending";
    } else if (itemInfo.color === "#000000") {
      return "Abandoned";
    }
    return "default";
  }

  filter_coverageChanged(items: string[]) {
    if (items.length > 0) {
      this.portfolioFilter.Countries = items;
    } else if (items.length === 0) {
      this.portfolioFilter.Countries = null;
    }
    this.filter_syncDataGrid();
  }

  /// Synchronize the changes in the datagrid to reflect the values of the filters.
  filter_syncDataGrid() {
    let filteredStore = this.IPFamilyStorage;

    if (this.portfolioFilter.Countries) {
      filteredStore = filteredStore.filter((a) =>
        a.geoCoverage.some((u) =>
          this.portfolioFilter.Countries.includes(u.countryId)
        )
      );
    }

    if (this.portfolioFilter.Family) {
      filteredStore = filteredStore.filter(
        (a) => a.family === this.portfolioFilter.Family
      );
    }

    if (this.portfolioFilter.ClientReference) {
      filteredStore = filteredStore.filter(
        (a) => a.clientReference === this.portfolioFilter.ClientReference
      );
    }

    if (this.portfolioFilter.Segment) {
      filteredStore = filteredStore.filter((a) =>
        a.segmentIds.includes(this.portfolioFilter.Segment)
      );
    }

    if (this.portfolioFilter.CatchWord) {
      filteredStore = filteredStore.filter(
        (a) => a.catchword === this.portfolioFilter.CatchWord
      );
    }

    if (this.portfolioFilter.IPType) {
      filteredStore = filteredStore.filter(
        (a) => a.caseMasterId === this.portfolioFilter.IPType
      );
    }

    this.dataSource = new DataSource({
      store: filteredStore,
    });
  }

  syncTreeViewSelection(e) {
    const component =
      (e && e.component) || (this.treeView && this.treeView.instance);
    if (!component) {
      return;
    }

    if (!this.treeBoxValue) {
      if (this.portfolioFilter.Countries != null) {
        component.unselectAll();
        this.portfolioFilter.Countries = null;
        this.filter_syncDataGrid();
      }
    }

    if (this.treeBoxValue) {
      this.treeBoxValue.forEach(
        function (value) {
          component.selectItem(value);
        }.bind(this)
      );
    }
  }

  getSelectedItemsText(items) {
    let result = [];
    const that = this;
    items.forEach((item) => {
      if (item.selected && item.items.length === 0) {
        result.push(item.text);
      }
      if (item.items.length) {
        result = result.concat(that.getSelectedItemsText(item.items));
      }
    });
    return result;
  }

  getSelectedItemsKeys(items) {
    let result = [];
    const that = this;
    items.forEach(function (item) {
      if (item.selected && item.items.length === 0) {
        result.push(item.key);
      }
      if (item.items.length) {
        result = result.concat(that.getSelectedItemsKeys(item.items));
      }
    });
    return result;
  }

  treeView_itemSelectionChanged(e) {
    const nodes = e.component.getNodes();
    this.treeBoxValue = this.getSelectedItemsText(nodes);
    this.filter_coverageChanged(this.getSelectedItemsKeys(nodes));
  }

  exportGrid() {
    const doc = new jsPDF();
    exportDataGridToPdf({
      jsPDFDocument: doc,
      component: this.dataGrid.instance,
    }).then(() => {
      doc.save("Portfolio.pdf");
    });
  }

  customizeLegendText(arg) {
    let text;
    if (arg.start === 1) {
      text = "Registered";
    } else if (arg.start === 2) {
      text = "Pending";
    } else {
      text = "Closed";
    }
    return text;
  }

  contentReady($event: any) {
    if (this.dataSource.items().length === 0) {
      return "No data found";
    }
  }
}

export class PortfolioFilter {
  Family: string;
  Countries: string[];
  Segment: number;
  ClientReference: string;
  IPType: number;
  CatchWord: string;
}
