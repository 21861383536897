import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ContinentAdapter, Continent } from '../../models/country.model';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

const apiUrl = environment.baseUrl + '/api/common/continent';

@Injectable({
  providedIn: 'root'
})

export class ContinentService {

  constructor(private http: HttpClient, private adapter: ContinentAdapter) {}

   getContinents() {
      return this.http
              .get<Continent[]>(apiUrl)
              .pipe(map((res: any) => res.data.map(item => this.adapter.adapt(item))
        )
      );
   }
}
