import { Injectable } from '@angular/core';
import { Adapter } from '../../adapter/adapter';

export class IPFamilyCost {
  constructor(
    public Family: string,
    public CaseId: number,
    public CountryId: string,
    public CountryLabel: string,
    public Year: number,
    public Costs: number,
    public AgentFees: number,
    public OfficialFees: number,
    public CalystaFees: number) {}
  }

@Injectable({
    providedIn: 'root'
  })
  export class IPFamilyCostsAdapter implements Adapter<IPFamilyCost> {
    adapt(item: any): IPFamilyCost {
      return new IPFamilyCost(
        item.family,
        item.caseId,
        item.countryId,
        item.countryLabel,
        item.year,
        item.costs,
        item.agentFees,
        item.officialFees,
        item.calystaFees
      );
    }
  }

