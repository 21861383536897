import { Component, Input, NgModule, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'pdf-preview',
  templateUrl: './pdf-preview.component.html',
  styleUrls: ['./pdf-preview.component.scss']
})
export class PdfPreviewComponent implements OnChanges {
  @Input() BlobFile: any;

  sanitizedBlob: any;

  constructor(private sanitizer : DomSanitizer) { }

  ngOnChanges(changes: SimpleChanges): void {
    if(this.BlobFile){
      this.sanitizedBlob = this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(this.BlobFile));
    }
  }

}
