import { Injectable } from '@angular/core';
import { Adapter } from '../../adapter/adapter';

export class DesignDevice {
    constructor(
    public AssetId: number,
    public IsMainPicture: boolean,
    public DesignClass: string,
    public DesignSubClass: string,
    public ViewNumber: string,
    public FileData: string,
    public PictureName: string,
    public DesignGoodsText: string,
    ) {}
}

@Injectable({
    providedIn: 'root'
  })
  export class DesignDeviceAdapter implements Adapter<DesignDevice> {
    adapt(item: any): DesignDevice {
      return new DesignDevice(
        item.assetId,
        item.isMainPicture,
        item.designClass,
        item.designSubClass,
        item.viewNumber,
        item.fileData,
        item.pictureName,
        item.designGoodsText
        );
    }
  }
