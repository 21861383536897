<h2 class="content-block">IP Portfolio</h2>
<mat-card class="example-card">
  <mat-card-header>
    <!-- <div mat-card-avatar class="example-header-image"></div> -->
    <mat-card-title>Filtering</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div id="form-container">
      <dx-form
        id="form"
        [colCount]="3"
        [formData]="portfolioFilter"
        (onFieldDataChanged)="filter_fieldDataChanged($event)"
      >
        <dxi-item
          [label]="{ text: 'Your Reference' }"
          dataField="ClientReference"
          editorType="dxSelectBox"
          [editorOptions]="{
            items: ClientReferences,
            showClearButton: 'true',
            searchEnabled: true,
            searchMode: 'contains'
          }"
        ></dxi-item>
        <dxi-item
          dataField="Family"
          editorType="dxSelectBox"
          [editorOptions]="{
            items: CalystaReferences,
            value: '',
            showClearButton: 'true',
            searchEnabled: true,
            searchMode: 'contains'
          }"
        ></dxi-item>
        <dxi-item [label]="{ text: 'Coverage' }">
          <dx-drop-down-box
            [(value)]="treeBoxValue"
            valueExpr="ISO2"
            displayExpr="label"
            placeholder="Filter by Country/Zone."
            [showClearButton]="true"
            [dataSource]="ContinentDataSource"
            (onValueChanged)="syncTreeViewSelection(null)"
          >
            <div *dxTemplate="let data of 'content'">
              <dx-tree-view
                [dataSource]="ContinentDataSource"
                dataStructure="tree"
                itemsExpr="countries"
                keyExpr="isO2"
                selectionMode="multiple"
                showCheckBoxesMode="normal"
                [selectNodesRecursive]="true"
                displayExpr="label"
                [selectByClick]="true"
                (onContentReady)="syncTreeViewSelection($event)"
                (onItemSelectionChanged)="treeView_itemSelectionChanged($event)"
              >
              </dx-tree-view>
            </div>
          </dx-drop-down-box>
        </dxi-item>
        <dxi-item
          [label]="{ text: 'Segments' }"
          editorType="dxSelectBox"
          dataField="Segment"
          [editorOptions]="{
            items: segments,
            displayExpr: 'SegmentLabel',
            valueExpr: 'SegmentId',
            showClearButton: 'true',
            searchEnabled: true,
            searchMode: 'contains'
          }"
        ></dxi-item>
        <dxi-item
          [label]="{ text: 'IP Type' }"
          editorType="dxSelectBox"
          dataField="IPType"
          [editorOptions]="{
            items: IPMasterTypes,
            displayExpr: 'stringValue',
            valueExpr: 'keyValue',
            showClearButton: 'true',
            searchEnabled: true,
            searchMode: 'contains'
          }"
        >
        </dxi-item>
        <dxi-item
          [label]="{ text: 'Catchword' }"
          editorType="dxSelectBox"
          dataField="Catchword"
          [editorOptions]="{
            items: Catchwords,
            showClearButton: 'true',
            searchEnabled: true,
            searchMode: 'contains'
          }"
        >
        </dxi-item>
      </dx-form>
    </div>
  </mat-card-content>
</mat-card>

<dx-button
  id="exportButton"
  icon="exportpdf"
  text="Export to PDF"
  (onClick)="exportGrid()"
>
</dx-button>

<!-- Portfolio Grid itself.  -->
<dx-data-grid
  id="gridContainer"
  class="dx-card wide-card content-block"
  keyExpr="Family"
  height="75vh"
  [dataSource]="dataSource"
  [columns]="['Family', 'ClientReference', 'Status']"
  [showBorders]="true"
  [showRowLines]="true"
  [allowColumnReordering]="true"
  [masterDetail]="{ enabled: true, template: 'detail', autoExpandAll: false }"
  [noDataText]="noDataText"
  (onContentReady)="contentReady($event)"
>
  <dxo-load-panel [enabled]="true"> </dxo-load-panel>
  <dxi-column
    [width]="200"
    dataField="clientReference"
    dataType="text"
    caption="Your Reference"
  ></dxi-column>
  <dxi-column dataField="family" [width]="200" dataType="text"></dxi-column>
  <!-- Logo toevoegen. -->
  <dxi-column
    dataField="catchword"
    dataType="text"
    caption="Catchword"
  ></dxi-column>
  <dxi-column
    caption="Status"
    [minWidth]="250"
    [width]="statusBarWidth"
    cellTemplate="chartCellTemplate"
  ></dxi-column>

  <!-- Template for the statusbar. -->
  <div *dxTemplate="let d of 'chartCellTemplate'">
    <div class="chart-cell">
      <dx-chart [dataSource]="d.data.statusDetailArray" [rotated]="true">
        <dxo-common-series-settings
          type="fullStackedBar"
          argumentField="status"
        >
          <dxo-label
            [visible]="true"
            [showForZeroValues]="false"
            [format]="{
              type: 'fixedPoint',
              precision: '0'
            }"
          >
          </dxo-label>
        </dxo-common-series-settings>
        <dxi-series
          valueField="registered"
          name="Registered"
          color="green"
        ></dxi-series>
        <dxi-series
          valueField="pending"
          name="Pending"
          color="blue"
        ></dxi-series>
        <dxi-series valueField="closed" name="Closed" color="grey"></dxi-series>
        <dxo-size [width]="statusBarWidth" [height]="40"></dxo-size>
        <dxo-argument-axis [visible]="false">
          <dxo-label [visible]="false"></dxo-label>
        </dxo-argument-axis>
        <dxi-value-axis [visible]="false"> </dxi-value-axis>
        <dxo-common-axis-settings [visible]="false"></dxo-common-axis-settings>
        <dxo-tooltip [enabled]="true" [customizeTooltip]="customizeTooltip">
        </dxo-tooltip>
        <dxo-legend [visible]="true"> </dxo-legend>
      </dx-chart>
    </div>
  </div>

  <!-- Template for the detailview.  -->
  <div *dxTemplate="let coverage of 'detail'">
    <mat-tab-group>
      <mat-tab label="Coverage">
        <div *ngIf="this.templateType(coverage.data) === 1">
          <app-trademarkfamilydetail
            [Family]="coverage.data"
          ></app-trademarkfamilydetail>
        </div>
        <div *ngIf="this.templateType(coverage.data) === 2">
          <app-patentfamilydetail
            [Family]="coverage.data"
          ></app-patentfamilydetail>
        </div>
        <div *ngIf="this.templateType(coverage.data) === 3">
          <app-patentfamilydetail
            [Family]="coverage.data"
          ></app-patentfamilydetail>
        </div>
      </mat-tab>
      <div *ngIf="this.HasFinancials(coverage.data)">
        <mat-tab label="Financial">
          <div class="content">
            <app-portfoliofinancialmap
              [FamilyFin]="coverage.data"
            ></app-portfoliofinancialmap>
            <app-financialdetail
              [FamilyFin]="coverage.data"
            ></app-financialdetail>
          </div>
        </mat-tab>
      </div>
    </mat-tab-group>
  </div>
</dx-data-grid>
