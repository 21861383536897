<mat-card #pieChart>
  <dx-pie-chart
    id="filingChart"
    title="Costs by Segment"
    [dataSource]="pieChartDataSet"
    [resolveLabelOverlapping]="resolveOverlappingTypes[0]"
  >
    <dxi-series argumentField="SegmentLabel" valueField="TotalFees">
      <dxo-label [visible]="true" [customizeText]="customizeLabel">
        <dxo-connector [visible]="true" [width]="1" />
      </dxo-label>
      <dxo-small-values-grouping
        mode="smallValueThreshold"
        [threshold]="25000"
      />
    </dxi-series>
  </dx-pie-chart>
</mat-card>
