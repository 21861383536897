import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import {
  LoginFormComponent,
  RecoveryFormComponent,
  ResetPasswordFormComponent,
} from "./shared/components";
import { HomeComponent } from "./pages/home/home.component";
import { PortfolioComponent } from "./pages/portfolio/portfolio.component";
import { DocumentsComponent } from "./pages/IPAsset/documents/documents.component";
import { DeadlinesComponent } from "./pages/deadlines/deadlines.component";
import {
  DxDataGridModule,
  DxFormModule,
  DxNumberBoxModule,
  DxSelectBoxModule,
  DxCheckBoxModule,
  DxChartModule,
  DxTemplateModule,
  DxBoxModule,
  DxVectorMapModule,
  DxDropDownBoxModule,
  DxTreeViewModule,
  DxTextBoxModule,
  DxValidatorModule,
  DxButtonModule,
  DxValidationGroupModule,
  DxResponsiveBoxModule,
  DxPopupModule,
  DxLoadPanelModule,
  DxPieChartModule,
  DxSortableModule,
  DxPivotGridModule,
  DxTabPanelModule,
  DxScrollViewModule,
  DxFileUploaderModule,
  DxLookupModule,
  DxSchedulerModule,
  DxTagBoxModule,
  DxPopoverModule,
  DxValidationSummaryModule,
  DxListModule,
  DxLoadIndicatorModule,
  DxToastModule,
  DxTooltipModule,
  DxSwitchModule,
} from "devextreme-angular";
import { MatCardModule } from "@angular/material/card";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatInputModule } from "@angular/material/input";
import { MatIconModule } from "@angular/material/icon";
import { MatListModule } from "@angular/material/list";
import { MatTabsModule } from "@angular/material/tabs";
import { MatTableModule } from "@angular/material/table";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { IpassetoverviewComponent } from "./pages/ipassetoverview/ipassetoverview.component";
import { CommonModule } from "@angular/common";
import localeBe from "@angular/common/locales/en-BE";
import { registerLocaleData } from "@angular/common";
import { AdminUserComponent } from "./pages/admin/admin-user/admin-user.component";
import { AdminComponent } from "./pages/admin/admin.component";
import { AuthenticatedService, AuthGuardService } from "./shared/services";
import { RoleGuardService } from "./shared/services";
import { ActivationFormComponent } from "./shared/components/activation-form/activation-form.component";
import { IPAssetWidgetComponent } from "./pages/home/ipasset-widget/ipasset-widget.component";
import { IPKPIWidgetComponent } from "./pages/home/ipkpi-widget/ipkpi-widget.component";
import { StatuskpiWidgetComponent } from "./pages/home/statuskpi-widget/statuskpi-widget.component";
import { MapWidgetComponent } from "./pages/home/map-widget/map-widget.component";
import { IpFilingWidgetComponent } from "./pages/home/ip-filing-widget/ip-filing-widget.component";
import { AssetprojectWidgetComponent } from "./pages/home/assetproject-widget/assetproject-widget.component";
import { FilesbycountryWidgetComponent } from "./pages/home/filesbycountry-widget/filesbycountry-widget.component";
import { FamilypivotWidgetComponent } from "./pages/home/familypivot-widget/familypivot-widget.component";
import { TrmGeneralComponent } from "./pages/IPAsset/trm-general/trm-general.component";
import { IPAssetComponent } from "./pages/IPAsset/ipasset.component";
import { IPAssetDeadlinesComponent } from "./pages/IPAsset/deadlines/deadlines.component";
import { TrademarkfamilydetailComponent } from "./pages/portfolio/trademarkfamilydetail/trademarkfamilydetail.component";
import { FinancialdetailComponent } from "./pages/portfolio/financialdetail/financialdetail/financialdetail.component";
import { SegmentsComponent } from "./pages/segments/segments.component";

import { PdfViewerModule } from "ng2-pdf-viewer";
import { IpserviceoverviewComponent } from "./pages/ipserviceoverview/ipserviceoverview.component";
import { PartiesComponent } from "./pages/IPAsset/parties/parties.component";
import { IpassetpictureComponent } from "./pages/ipassetoverview/common/ipAssetPicture/ipassetpicture.component";
import { AdminClientComponent } from "./pages/admin/clients/client/client.component";
import { IpassetoverviewlitegridComponent } from "./pages/ipassetoverview/lite/ipassetoverviewlitegrid/ipassetoverviewlitegrid.component";
import { IfSubscriptionDirective } from "./shared/directives/ifSubscription.directive";

import { IpassetoverviewsilvergridComponent } from "./pages/ipassetoverview/silver/ipassetoverviewsilvergrid/ipassetoverviewsilvergrid.component";
import { InvoicesComponent } from "./pages/IPAsset/invoices/invoices.component";
import { IPServiceComponent } from "./pages/ipservice/ipservice.component";
import { DocumentsoverviewComponent } from "./pages/documentsoverview/documentsoverview.component";
import { DeadlineWidgetComponent } from "./pages/home/deadline-widget/deadline-widget.component";
import { MatButtonModule } from "@angular/material/button";
import { DocumentWidgetComponent } from "./pages/home/document-widget/document-widget.component";
import { InvoiceWidgetComponent } from "./pages/home/invoice-widget/invoice-widget.component";
import { InvoiceoverviewComponent } from "./pages/invoiceoverview/invoiceoverview.component";
import { PatGeneralComponent } from "./pages/IPAsset/pat-general/pat-general.component";
import { InvoiceoverviewHeaderbarComponent } from "./pages/invoiceoverview/invoiceoverview-headerbar/invoiceoverview-headerbar.component";
import { TermsAndConditionsComponent } from "./shared/components/terms-and-conditions/terms-and-conditions.component";
import { PortfoliofinancialmapComponent } from "./pages/portfolio/financialdetail/portfoliofinancialmap/portfoliofinancialmap.component";
import { CostbysegmentandratioComponent } from "./pages/analytics/costsbysegmentandratio/costbysegmentandratio.component";
import { CostbysegmentandratiopiechartComponent } from "./pages/analytics/costsbysegmentandratio/costbysegmentandratiopiechart/costbysegmentandratiopiechart.component";
import { CostsbysegmentandratiogridComponent } from "./pages/analytics/costsbysegmentandratio/costsbysegmentandratiogrid/costsbysegmentandratiogrid.component";
import { CostsbysegmentandratioevolutionComponent } from "./pages/analytics/costsbysegmentandratio/costsbysegmentandratioevolution/costsbysegmentandratioevolution.component";
import { PatentfamilydetailComponent } from "./pages/portfolio/patentfamilydetail/patentfamilydetail.component";
import { DesGeneralComponent } from "./pages/IPAsset/des-general/des-general.component";
import {
  RenewalPatentsComponent,
  StringifyPatentRenewals,
} from "./pages/renewals/renewal-patents/renewal-patents.component";
import { InvoiceoverviewDetailgridComponent } from "./pages/invoiceoverview/invoiceoverview-detailgrid/invoiceoverview-detailgrid.component";
import { RenewalsComponent } from "./pages/renewals/renewals.component";
import { ProfileComponent } from "./pages/profile/profile/profile.component";
import { UserRoleComponent } from "./pages/admin/admin-user/user-role/user-role.component";
import { IfUserHasRoleDirective } from "./shared/directives/ifRole.directive";
import { ProfilePermissionComponent } from "./pages/profile/profile/profile-permission/profile-permission.component";
import { RenewalsInstructionlogComponent } from "./pages/renewals/renewals-instructionlog/renewals-instructionlog.component";
import { RenewalsDesignComponent } from "./pages/renewals/renewals-design/renewals-design.component";
import { RenewalsTrademarkComponent } from "./pages/renewals/renewals-trademark/renewals-trademark.component";
import { FileUploadComponent } from "./shared/components/file-upload/file-upload.component";
import { UserPermissionComponent } from "./pages/admin/admin-user/user-permission/user-permission.component";
import { ProfileTwofactorauthComponent } from "./pages/profile/profile/profile-twofactorauth/profile-twofactorauth.component";
import { QRCodeModule } from "angularx-qrcode";
import { TwoFactorAuthComponent } from "./shared/components/twofactorauth-form/twofactorauth-form.component";
import { PdfPreviewComponent } from "./components/library/pdf-preview/pdf-preview.component";
import {
  DxoButtonOptionsModule,
  DxoLabelModule,
} from "devextreme-angular/ui/nested";
import { DocumentViewComponent } from "./pages/documentview/documentview.component";
import { InvoiceViewComponent } from "./pages/invoiceview/invoiceview.component";

registerLocaleData(localeBe);

const routes: Routes = [
  {
    path: "portfolio",
    component: PortfolioComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "profile",
    component: ProfileComponent,
    canActivate: [RoleGuardService],
    data: {
      expectedRole: "Employee",
    },
  },
  {
    path: "ipassetoverview",
    component: IpassetoverviewComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "ipserviceoverview",
    component: IpserviceoverviewComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "ipasset/:id",
    component: IPAssetComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "ipservice/:id",
    component: IPServiceComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "home",
    component: HomeComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "deadlines",
    component: DeadlinesComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "renewals",
    component: RenewalsComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "renewals/design",
    component: RenewalsDesignComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "renewals/patent",
    component: RenewalPatentsComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "renewals/trademark",
    component: RenewalsTrademarkComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "renewals/log",
    component: RenewalsInstructionlogComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "documents",
    component: DocumentsoverviewComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "documents/:id",
    component: DocumentViewComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "invoices",
    component: InvoiceoverviewComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "invoices/:invoiceNumber",
    component: InvoiceViewComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "segments",
    component: SegmentsComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "analytics/costbysegment",
    component: CostbysegmentandratioComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "termsAndConditions",
    component: TermsAndConditionsComponent,
    canActivate: [AuthenticatedService],
  },
  {
    path: "admin",
    canActivate: [RoleGuardService],
    data: {
      expectedRole: "Admin",
    },

    component: AdminComponent,
    children: [
      {
        path: "clients",
        canActivate: [RoleGuardService],
        component: AdminClientComponent,
        data: {
          expectedRole: "Admin",
        },
      },
      {
        path: "users",
        canActivate: [RoleGuardService],
        component: AdminUserComponent,
        data: {
          expectedRole: "Employee",
        },
      },
    ],
  },
  {
    path: "activate",
    component: ActivationFormComponent,
  },
  {
    path: "forgotpassword",
    component: RecoveryFormComponent,
  },
  {
    path: "resetpassword",
    component: ResetPasswordFormComponent,
  },
  {
    path: "tfauth",
    component: TwoFactorAuthComponent,
  },
  {
    path: "login",
    component: LoginFormComponent,
  },
  { path: "**", redirectTo: "home" },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
    DxFormModule,
    CommonModule,
    MatCardModule,
    MatExpansionModule,
    MatButtonModule,
    MatInputModule,
    MatIconModule,
    MatListModule,
    MatGridListModule,
    MatSnackBarModule,
    MatTableModule,
    MatTabsModule,
    DxNumberBoxModule,
    DxDataGridModule,
    DxCheckBoxModule,
    DxChartModule,
    DxTemplateModule,
    DxBoxModule,
    DxTextBoxModule,
    DxVectorMapModule,
    DxTreeViewModule,
    DxDropDownBoxModule,
    DxValidatorModule,
    DxButtonModule,
    DxValidationGroupModule,
    DxSelectBoxModule,
    DxResponsiveBoxModule,
    DxPopupModule,
    DxLoadPanelModule,
    DxPieChartModule,
    DxSortableModule,
    DxPivotGridModule,
    DxTabPanelModule,
    DxToastModule,
    DxScrollViewModule,
    PdfViewerModule,
    DxFileUploaderModule,
    DxLookupModule,
    DxSchedulerModule,
    DxTagBoxModule,
    DxPopoverModule,
    DxValidationSummaryModule,
    DxListModule,
    DxLoadIndicatorModule,
    QRCodeModule,
    DxoButtonOptionsModule,
    DxTooltipModule,
    DxSwitchModule,
    DxoLabelModule,
  ],
  exports: [RouterModule, IfSubscriptionDirective, IfUserHasRoleDirective],
  declarations: [
    HomeComponent,
    PortfolioComponent,
    IpassetoverviewComponent,
    LoginFormComponent,
    TwoFactorAuthComponent,
    ResetPasswordFormComponent,
    RecoveryFormComponent,
    ActivationFormComponent,
    AdminComponent,
    AdminUserComponent,
    IPAssetWidgetComponent,
    IPKPIWidgetComponent,
    StatuskpiWidgetComponent,
    MapWidgetComponent,
    IpFilingWidgetComponent,
    AssetprojectWidgetComponent,
    FilesbycountryWidgetComponent,
    FamilypivotWidgetComponent,
    TrmGeneralComponent,
    IPAssetComponent,
    IPAssetDeadlinesComponent,
    DocumentsComponent,
    DeadlinesComponent,
    TrademarkfamilydetailComponent,
    PatentfamilydetailComponent,
    SegmentsComponent,
    IpserviceoverviewComponent,
    PartiesComponent,
    FinancialdetailComponent,
    IpassetpictureComponent,
    AdminClientComponent,
    IpassetoverviewlitegridComponent,
    IpassetoverviewsilvergridComponent,
    InvoicesComponent,
    IfSubscriptionDirective,
    IfUserHasRoleDirective,
    IPServiceComponent,
    DocumentViewComponent,
    DocumentsoverviewComponent,
    InvoiceViewComponent,
    DeadlineWidgetComponent,
    DocumentWidgetComponent,
    InvoiceWidgetComponent,
    InvoicesComponent,
    InvoiceoverviewComponent,
    PatGeneralComponent,
    DesGeneralComponent,
    InvoiceoverviewHeaderbarComponent,
    TermsAndConditionsComponent,
    PortfoliofinancialmapComponent,
    CostbysegmentandratioComponent,
    CostbysegmentandratiopiechartComponent,
    CostsbysegmentandratiogridComponent,
    CostsbysegmentandratioevolutionComponent,
    RenewalPatentsComponent,
    StringifyPatentRenewals,
    InvoiceoverviewDetailgridComponent,
    RenewalsComponent,
    ProfileComponent,
    UserRoleComponent,
    ProfilePermissionComponent,
    RenewalsInstructionlogComponent,
    RenewalsTrademarkComponent,
    RenewalsDesignComponent,
    FileUploadComponent,
    UserPermissionComponent,
    ProfileTwofactorauthComponent,
    PdfPreviewComponent,
  ],
})
export class AppRoutingModule {}
