import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import {
  ERPSegment,
  ERPSegmentAdapter,
} from "../../models/ERP/segment.erp.model";

const apiUrl = environment.baseUrl + "/api/erp/ipasset";

@Injectable({
  providedIn: "root",
})
export class ERPIPAssetService {
  constructor(private http: HttpClient) {}

  updateIPAsset(assetId: number, clientReference: string) {
    var body = {
      ClientReference: clientReference,
    };

    return this.http
      .post(`${apiUrl}/${assetId}`, body)
      .pipe(catchError(this.handleError));
  }

  // Handle an error.
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error("An error occurred:", error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }

    // return an observable with a user-facing error message
    return throwError("Something bad happened; please try again later.");
  }
}
