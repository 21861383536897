<dx-data-grid
  #Detailgrid
  [dataSource]="userOrganizationPermissions"
  [showBorders]="true"
  [columnAutoWidth]="true"
  (onRowInserted)="InsertPermission($event, currentUserId)"
  (onRowUpdated)="UpdatePermission($event, currentUserId)"
  (onRowRemoved)="RemovePermission($event, currentUserId)"
>
  <dxo-editing
    mode="row"
    [allowUpdating]="true"
    [allowDeleting]="true"
    [allowAdding]="true"
  >
  </dxo-editing>
  <dxi-column
    dataField="OrganizationId"
    [editorOptions]="{ minSearchLength: 3 }"
  >
    <dxo-lookup
      [dataSource]="organizationDictionary"
      valueExpr="keyValue"
      displayExpr="stringValue"
      minSearchLength="3"
    >
    </dxo-lookup>
  </dxi-column>
  <dxi-column dataField="OrganizationRoleTypeId">
    <dxo-lookup
      [dataSource]="rolesDictionary"
      valueExpr="keyValue"
      displayExpr="stringValue"
    >
    </dxo-lookup>
  </dxi-column>
  <dxi-column dataField="CaseTypeId">
    <dxo-lookup
      [dataSource]="caseMasterDictionary"
      valueExpr="keyValue"
      displayExpr="stringValue"
      [allowClearing]="true"
    >
    </dxo-lookup>
  </dxi-column>
  <dxi-column dataField="InstructionTypeId">
    <dxo-lookup
      [dataSource]="instructionTypeDictionary"
      valueExpr="keyValue"
      displayExpr="stringValue"
      [allowClearing]="true"
    >
    </dxo-lookup>
  </dxi-column>
  <div *dxTemplate="let cellInfo of 'tagBoxEditor'">
    <dx-tag-box
      [dataSource]="permissionDictionary"
      [value]="cellInfo.value"
      valueExpr="key"
      displayExpr="value"
      [showSelectionControls]="true"
      [maxDisplayedTags]="5"
      [showMultiTagOnly]="false"
      applyValueMode="useButtons"
      [searchEnabled]="true"
      (onValueChanged)="cellInfo.setValue($event.value)"
      (onSelectionChanged)="cellInfo.component.updateDimensions()"
    >
    </dx-tag-box>
  </div>
</dx-data-grid>
