import { Component, OnInit } from "@angular/core";
import { IPAssetService } from "src/app/shared/services/IPAsset.service";
import { IPAsset } from "src/app/shared/models/ipasset.model";
import { ActivatedRoute } from "@angular/router";
import { ERPSegmentService } from "src/app/shared/services/ERP/erp.segment.service";
import { ERPSegment } from "src/app/shared/models/ERP/segment.erp.model";
import { FieldDataChangedEvent } from "devextreme/ui/form";
import { ERPIPAssetService } from "src/app/shared/services/ERP/erp.ipasset.service";
import { SubscriptionTypeService } from "src/app/shared/services/subscription.service";

@Component({
  selector: "app-ipasset",
  templateUrl: "./ipasset.component.html",
  styleUrls: ["./ipasset.component.scss"],
})
export class IPAssetComponent implements OnInit {
  asset: IPAsset;
  assetId: number;

  segments: ERPSegment[];
  subscription: number;

  constructor(
    private IPAssetSvc: IPAssetService,
    private activatedRoute: ActivatedRoute,
    private segmentService: ERPSegmentService,
    private ipAssetService: ERPIPAssetService,
    private subscriptionService: SubscriptionTypeService
  ) {
    this.activatedRoute.params.subscribe(
      (params) => (this.assetId = params.id)
    );
    this.onFieldChanged = this.onFieldChanged.bind(this);
  }

  ngOnInit() {
    this.subscriptionService.getUserSubscriptionModel().subscribe((res) => {
      this.subscription = res;
    });

    this.segmentService.getContactSegments().subscribe((res: ERPSegment[]) => {
      this.segments = res;
    });

    this.IPAssetSvc.getAsset(this.assetId).subscribe((data: IPAsset) => {
      if (data) {
        this.asset = data;
        this.assetId = data.assetId;
      }
    });
  }

  // Todo: relevant name for this function.
  onFieldChanged(e: FieldDataChangedEvent) {
    if (e.dataField === "segments") {
      this.segmentService
        .updateAssetSegments(this.asset.assetId, this.asset.segments)
        .subscribe();
    } else if (e.dataField === "clientReference") {
      const reference = this.asset.clientReference;
      this.ipAssetService
        .updateIPAsset(this.asset.assetId, reference)
        .subscribe();
    }
  }

  getCaseTypeId = () => {
    if (this.asset == undefined) {
      return;
    }

    return this.asset.caseTypeId;
  };

  isSilver = () => {
    return this.subscription >= 2;
  };
}
