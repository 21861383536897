import { Injectable } from "@angular/core";
import { BehaviorSubject, empty, Observable } from "rxjs";
import { Adapter } from "../../adapter/adapter";

export class RenewalInstruction {
  InstructionId: number;
  InstructionType: string;
  CaseType: string;
  CaseId: number;
  OfficialFee: number;
  ServiceFee: number;
  CalystaFee: number;
  Amount: number;
  Reference: string;
  CatchWord: string;
  Country: string;
  DeadlineId: number;
}

@Injectable()
export class RenewalInstructionSet {
  public renewalInstructions$: BehaviorSubject<RenewalInstruction[]> = new BehaviorSubject([]);

  constructor () {
    this.renewalInstructions$.next([]);
  }

  public getPatentRenewalInstructions() : Observable<RenewalInstruction[]>
  {
    return this.renewalInstructions$;
  }

  public getCurrentRenewalInstructions() : RenewalInstruction[]
  {
    return this.renewalInstructions$.value;
  }

  public addRenewalInstruction(instruction: RenewalInstruction) {
    var currentInstructions = this.renewalInstructions$.value;
    currentInstructions.push(instruction);
    this.renewalInstructions$.next(currentInstructions);
  }

  public removeRenewalInstruction(instructionCaseId: number) {
    const IndexInInstructions = this.renewalInstructions$.value.findIndex(
      (x) => x.CaseId === instructionCaseId
    );

    if (IndexInInstructions > -1) {
      var currentInstructions = this.renewalInstructions$.value;
      currentInstructions.splice(IndexInInstructions, 1);
      this.renewalInstructions$.next(currentInstructions);
    }
  }

  public atleastOneInstructionInSet(): boolean {
    if (this.renewalInstructions$.value.length > 0) return true;
    return false;
  }

  public getNumberOfInstructions(): number {
    return this.renewalInstructions$.value.length;
  }

  public removeAllInstructions() {
    var emptyInstructionArray : RenewalInstruction[] = [];
    this.renewalInstructions$.next(emptyInstructionArray);
  }

  public getTotalAmountInInstructions(): number {
    let InstructionsAmount = 0;

    this.renewalInstructions$.value.forEach((x) => {
      if (x.Amount > 0) {
        InstructionsAmount = InstructionsAmount + x.Amount;
      }
    });

    return InstructionsAmount;
  }
}
