import {
  Component,
  OnChanges,
  Input,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { IPAsset } from "src/app/shared/models/ipasset.model";
import { ERPDocumentService } from "src/app/shared/services/ERP/erp.document.service";
import { ERPDeadlineService } from "src/app/shared/services/ERP/erp.deadline.service";
import { ERPDeadline } from "src/app/shared/models/ERP/deadline.erp.model";
import { ERPDeadlineCategory } from "src/app/shared/models/ERP/deadlinecategory.erp.model";
import { DeadlineInstruction } from "src/app/shared/models/deadlineInstruction.model";
import { ERPInstructionService } from "src/app/shared/services/ERP/erp.instruction.service";
import { Dictionary } from "src/app/shared/models/dictionary.model";
import { MatSnackBar } from "@angular/material/snack-bar";
import { DxFileUploaderComponent } from "devextreme-angular";

@Component({
  selector: "app-ipassetdeadlines",
  templateUrl: "./deadlines.component.html",
  styleUrls: ["./deadlines.component.scss"],
})
export class IPAssetDeadlinesComponent implements OnChanges {
  @ViewChild(DxFileUploaderComponent) uploader: DxFileUploaderComponent;

  @Input()
  assetData: IPAsset;
  deadlinesData: ERPDeadline[];
  currentDeadline: ERPDeadline;
  deadlineInstruction: DeadlineInstruction;

  loadingVisible: any;

  value: any[] = [];
  fileToUpload: File[];

  deadlineCategoryDict: ERPDeadlineCategory[];
  documentPreview: any;
  currentTemplate: string;
  possibleInstructions: Dictionary[];
  pdfSrc: any;

  PopupVisible: boolean = false;
  PopupTitle: string;

  meetingMoments: string[] = [];

  replyPopupVisible: boolean;

  submitButtonOptions: any = {
    text: "Send",
    type: "success",
    useSubmitBehavior: true,
  };

  addMomentButtonOptions: any;

  constructor(
    private ipDeadlineSvc: ERPDeadlineService,
    private fileSvc: ERPDocumentService,
    private instructionSvc: ERPInstructionService,
    private snackbar: MatSnackBar
  ) {
    this.PopupTitle = "Preview";
    this.openReplyFrame = this.openReplyFrame.bind(this);
    this.previewRelatedDocument = this.previewRelatedDocument.bind(this);

    this.addMomentButtonOptions = {
      icon: "add",
      text: "Add possible moment",
      onClick: () => {
        this.meetingMoments.push("");
      },
    };
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.assetData && this.assetData) {
      this.ipDeadlineSvc
        .getCaseDeadlines(this.assetData.assetId)
        .subscribe((res: ERPDeadline[]) => (this.deadlinesData = res));
    }

    this.ipDeadlineSvc
      .getDeadlineCategories()
      .subscribe((res: ERPDeadlineCategory[]) => {
        this.deadlineCategoryDict = res;
      });
  }

  isDocumentAvailable(e) {
    if (e.row.data.relatedDocumentId) {
      return true;
    }
    return false;
  }

  isReplyPossible(e) {
    if (1 === 1) {
      return true;
    }
    return false;
  }

  previewRelatedDocument(e) {
    this.fileSvc.getDocument(1).subscribe((res: any) => {
      this.pdfSrc = res;
      this.currentTemplate = "pdfpreview";
      this.replyPopupVisible = true;
    });
  }

  downloadRelatedDocument(e) {
    this.fileSvc.getDocument(1).subscribe((res: any) => {
      const blob = res;
      this.pdfSrc = URL.createObjectURL(blob);
      this.currentTemplate = "pdfpreview";
      const downloadWindow = window.open(this.pdfSrc);
      if (
        !downloadWindow ||
        downloadWindow.closed ||
        typeof downloadWindow.closed === "undefined"
      ) {
        alert("Please disable your Pop-up blocker and try again.");
      }
    });
  }

  clearInstruction() {
    this.currentDeadline = null;
    this.deadlineInstruction = null;
    this.value = [];
    if (this.fileToUpload) {
      this.uploader.instance.reset();
    }
  }

  openReplyFrame(e) {
    this.currentDeadline = e.row.data;

    this.deadlineInstruction = new DeadlineInstruction(
      this.currentDeadline.deadlineId,
      this.currentDeadline.caseId,
      this.currentDeadline.date,
      null,
      null,
      this.currentDeadline.combinedTextClient,
      null,
      null,
      null,
      null
    );

    this.instructionSvc.getResponseType(e.row.data.deadlineId).subscribe(
      (res: any) => {
        if (res.hasResponseTypeOptions) {
          this.deadlineInstruction.OptionsVisible = true;
          this.possibleInstructions = res.responseOptions.map((a) => ({
            keyValue: a.responseOptionId,
            stringValue: a.responseOptionLabel,
          }));
        } else {
          this.deadlineInstruction.OptionsVisible = false;
        }
        if (res.allowDocumentUpload) {
          this.deadlineInstruction.DocumentUploadVisible = true;
        } else {
          this.deadlineInstruction.DocumentUploadVisible = false;
        }
        if (res.allowFreeTextResponse) {
          this.deadlineInstruction.FreeTextVisible = true;
        } else {
          this.deadlineInstruction.FreeTextVisible = false;
        }
        this.currentTemplate = "openresponse";
        this.PopupVisible = true;
      },
      () => {},
      () => {}
    );
  }

  onFormSubmit(e) {
    this.loadingVisible = true;

    if (this.value) {
      this.deadlineInstruction.UploadedFile = this.value;
    }

    const formData: FormData = new FormData();
    if (this.deadlineInstruction.Instruction) {
      formData.append(
        "Instruction",
        this.deadlineInstruction.Instruction.toString()
      );
      formData.append(
        "InstructionLabel",
        this.possibleInstructions.find(
          (x) => x.keyValue === +this.deadlineInstruction.Instruction
        ).stringValue
      );
    }
    formData.append(
      "FreeTextInstruction",
      this.deadlineInstruction.FreeTextInstruction
    );
    if (this.value.length > 0) {
      formData.append("UploadFiles", this.value[0]);
    }
    formData.append("CaseId", this.deadlineInstruction.AssetId.toString());
    formData.append(
      "DeadlineTermId",
      this.deadlineInstruction.EventSchemeId.toString()
    );
    formData.append("DeadlineText", this.currentDeadline.statusTextClient);

    this.instructionSvc.provideInstruction(formData).subscribe(
      (res) => {},
      (err) => {
        this.loadingVisible = false;
        this.snackbar.open(
          "There was an error processing your instruction, please contact support@calysta.eu",
          null,
          {
            duration: 5000,
            panelClass: "alert-red",
          }
        );
      },
      () => {
        this.loadingVisible = false;
        this.PopupVisible = false;
        this.snackbar.open(
          "Your instruction has succesfully been transferred to Calysta",
          null,
          {
            duration: 5000,
            panelClass: "alert-green",
          }
        );
      }
    );

    e.preventDefault();
  }
}
