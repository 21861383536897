import { Component, HostBinding } from "@angular/core";
import { AuthService, ScreenService, AppInfoService } from "./shared/services";
import { locale } from "devextreme/localization";
import { Router } from "@angular/router";
import config from "devextreme/core/config";
import { LoginResponse } from "./shared/models/Authentication/loginresponse";
import { licenseKey } from "src/devextreme-license";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  currentUser: LoginResponse;
  twoFAPassed: boolean = false;
  userRoles: string[];

  @HostBinding("class") get getClass() {
    return Object.keys(this.screen.sizes)
      .filter((cl) => this.screen.sizes[cl])
      .join(" ");
  }

  constructor(
    private authService: AuthService,
    private screen: ScreenService,
    private router: Router,
    public appInfo: AppInfoService
  ) {
    locale(navigator.language);
    config({ defaultCurrency: "EUR", licenseKey });
    this.authService.currentUser.subscribe((x) => (this.currentUser = x));
    this.authService.userRoles.subscribe((x) => (this.userRoles = x));
    this.authService.twoFAPassed.subscribe((x) => (this.twoFAPassed = x));
  }

  logout() {
    this.authService.logOut();
    this.router.navigate(["/login"]);
  }

  isAuthorized() {
    if (this.currentUser && this.userRoles) {
      return true;
    }
    return false;
  }
}
