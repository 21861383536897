import { Injectable } from '@angular/core';
import { Adapter } from '../../adapter/adapter';
import { ERPDeadlineResponseOption } from './deadlineresponseoption.erp.model';

export class ERPDeadlineResponseType {

    constructor(
        public EventSchemeId: number,
        public AllowDocumentUpload: boolean,
        public AllowFreeTextResponse: boolean,
        public HasResponseTypeOptions: boolean,
        public ResponseTypeId: number,
        public ResponseOptions: ERPDeadlineResponseOption[]
        ) {}
}

@Injectable({
    providedIn: 'root'
  })
  export class ERPDeadlineResponseTypeAdapter implements Adapter<ERPDeadlineResponseType> {
    adapt(item: any): ERPDeadlineResponseType {
      return new ERPDeadlineResponseType(
          item.eventSchemeId,
          item.allowDocumentUpload,
          item.allowFreeTextResponse,
          item.hasResponseTypeOptions,
          item.responseTypeId,
          item.responseOptions
      );
    }
  }
