import { Component, OnInit } from "@angular/core";
import notify from "devextreme/ui/notify";
import { Subject } from "rxjs";
import { Observable } from "rxjs/internal/Observable";
import { ERPDESRenewalDeadline } from "src/app/shared/models/ERP/desrenewaldeadline.erp.model";
import { ERPRenewalDeadline } from "src/app/shared/models/ERP/renewaldeadline.erp.model";
import { ERPTRMRenewalDeadline } from "src/app/shared/models/ERP/trmrenewaldeadline.erp.model";
import { RenewalInstructionSet } from "src/app/shared/models/Renewal/RenewalInstruction.model";
import { ERPInstructionService } from "src/app/shared/services/ERP/erp.instruction.service";
import { ERPRenewalDeadlineService } from "src/app/shared/services/ERP/erp.renewaldeadline.service";

@Component({
  selector: "app-renewals",
  templateUrl: "./renewals.component.html",
  styleUrls: ["./renewals.component.scss"],
})
export class RenewalsComponent implements OnInit {
  revertAllSubject: Subject<void> = new Subject<void>();

  atleastOneIstruction: boolean = false;

  patentRenewalData: Observable<ERPRenewalDeadline[]>;
  trademarkRenewalData: Observable<ERPTRMRenewalDeadline[]>;
  designRenewalData: Observable<ERPDESRenewalDeadline[]>;

  renewalInstructions: Observable<RenewalInstruction[]>;
  currentRenewalInstructions: RenewalInstruction[];

  confirmationPopupVisible: boolean;

  closeButtonOptions: any;
  confirmButtonOptions: any;

  OpenPatentRenewals: number = 0;
  OpenTrademarkRenewals: number = 0;
  OpenDesignRenewals: number = 0;

  constructor(
    private instructionService: ERPInstructionService,
    private renewalDeadlineService: ERPRenewalDeadlineService,
    public renewalInstructionSet: RenewalInstructionSet
  ) {
    const that = this;

    this.patentRenewalData = new Observable<ERPRenewalDeadline[]>();
    this.trademarkRenewalData = new Observable<ERPTRMRenewalDeadline[]>();
    this.patentRenewalData =
      this.renewalDeadlineService.getRenewalDeadlinesObs();
    this.trademarkRenewalData =
      this.renewalDeadlineService.getTRMRenewalDeadlinesObs();
    this.designRenewalData =
      this.renewalDeadlineService.getDESRenewalDeadlinesObs();
    this.renewalDeadlineService.loadRenewalDeadlines();

    this.renewalInstructions = new Observable<RenewalInstruction[]>();
    this.renewalInstructions =
      renewalInstructionSet.getPatentRenewalInstructions();

    this.confirmButtonOptions = {
      icon: "chevronright",
      text: "Confirm Instructions",
      onClick(e) {
        instructionService
          .ProvideRenewalInstruction(
            that.renewalInstructionSet.getCurrentRenewalInstructions()
          )
          .subscribe(
            (res) => {},
            (err) => {
              const message = `Error transfering instructions. Try again or contact Calysta.`;
              notify(
                {
                  message,
                  position: {
                    my: "center",
                    at: "center",
                  },
                },
                "error",
                3000
              );
            },
            () => {
              that.renewalDeadlineService.loadRenewalDeadlines();
              that.renewalInstructionSet.removeAllInstructions();
              const message = `Instructions sent succesfully!`;
              notify(
                {
                  message,
                  position: {
                    my: "center",
                    at: "center",
                  },
                },
                "success",
                5000
              );
              that.confirmationPopupVisible = false;
            }
          );
      },
    };

    this.closeButtonOptions = {
      icon: "chevronleft",
      text: "Close",
      onClick(e) {
        that.confirmationPopupVisible = false;
      },
    };
  }

  displayedColumns: string[] = [
    "CatchWord",
    "Reference",
    "Country",
    "InstructionType",
    "Amount",
  ];

  ngOnInit(): void {}

  UpdateOpenPatentRenewals(nrOfRenewals: number) {
    this.OpenPatentRenewals = nrOfRenewals;
  }

  getTotalInstruction(): number {
    return this.renewalInstructionSet.getNumberOfInstructions();
  }

  getTotalInstructionAmount(): number {
    return this.renewalInstructionSet.getTotalAmountInInstructions();
  }

  onConfirmationClick() {
    this.confirmationPopupVisible = true;
  }

  onRevertAllClick() {
    this.renewalInstructionSet.removeAllInstructions();
    this.renewalDeadlineService.clearAllInstructions();
    this.revertAllSubject.next();
  }
}

export class RenewalInstruction {
  InstructionId: number;
  InstructionType: string;
  CaseType: string;
  CaseId: number;
  OfficialFee: number;
  ServiceFee: number;
  CalystaFee: number;
  Amount: number;
  Reference: string;
  CatchWord: string;
  Country: string;
  DeadlineId: number;
}
