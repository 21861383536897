<dx-validation-group>
    <div class="login-header">
        <div class="title">{{appInfo.title}}</div>
        <div>Welcome to our client portal!</div>
        <div>2 Factor authentication is active for your account.</div>
        <div>Please open your authentication app and provide the current authentication code</div>
    </div>
    <div class="dx-field">
        <dx-text-box [(value)]="login" placeholder="Login" width="100%" readOnly="true">
        </dx-text-box>
    </div>
    <div class="dx-field">
        <dx-text-box [(value)]="twoFactorCode" placeholder="Auth code" width="100%" (onEnterKey)="onValidateClick($event)">
            <dx-validator>
                <dxi-validation-rule type="required" message="2FA code required. Contact Calysta if you have no more access."></dxi-validation-rule>
            </dx-validator>
        </dx-text-box>
    </div>
    <div class="dx-field">
        <dx-button type="default" text="Verify" (onClick)="onValidateClick($event)" width="100%"></dx-button>
    </div>
</dx-validation-group>
