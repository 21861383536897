import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import DataSource from "devextreme/data/data_source";
import { Observable } from "rxjs";
import { ERPDocumentService } from "src/app/shared/services/ERP/erp.document.service";

@Component({
  selector: "app-document-widget",
  templateUrl: "./document-widget.component.html",
  styleUrls: ["./document-widget.component.scss"],
})
export class DocumentWidgetComponent implements OnInit {
  DataSource: DataSource;
  PopupVisible: boolean;
  pdfSrc: any;
  PopupTitle: string;
  documentPreview: any;

  constructor(private router: Router, private documentSvc: ERPDocumentService) {
    this.previewRelatedDocument = this.previewRelatedDocument.bind(this);
    this.downloadRelatedDocument = this.downloadRelatedDocument.bind(this);
    this.PopupVisible = false;
  }

  ngOnInit() {
    this.documentSvc.getDocumentOverview().subscribe((res: any) => {
      this.DataSource = new DataSource({
        store: res.data,
        key: "documentId",
      });
      this.DataSource.sort({ selector: "creationDate", desc: true });
    });
  }

  ToOverviewClick() {
    this.router.navigate(["/documents"]);
  }

  previewRelatedDocument(e) {
    this.documentSvc
      .LoadDocumentPreviewV2(e.row.data.documentId)
      .subscribe((res) => {
        this.documentPreview = res;
        this.PopupVisible = true;
        this.PopupTitle = e.row.data.fileName;
      });
  }

  downloadRelatedDocument(e) {
    this.documentSvc
      .getDocument(e.row.data.documentId)
      .subscribe((res: any) => {
        const blob = res;
        const fileName = e.row.data.fileName;
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(link.href);
      });
  }
}
