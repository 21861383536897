<div class="tabContent">
  <mat-card>
    <mat-card-header
      ><p style="text-decoration: underline">
        Involved Parties:
      </p></mat-card-header
    >
    <mat-card-content>
      <dx-data-grid
        [dataSource]="partiesdata"
        [columnMinWidth]="50"
        [columnAutoWidth]="true"
        [rowAlternationEnabled]="true"
        [wordWrapEnabled]="true"
      >
        <dxi-column
          dataField="roleTypeLabel"
          caption="Role"
          sortOrder="asc"
        ></dxi-column>
        <dxi-column dataField="partyName" caption="Name"></dxi-column>
        <dxi-column dataField="address" caption="Address"></dxi-column>
        <dxi-column
          dataField="contactName"
          caption="Contact Person"
        ></dxi-column>
        <dxi-column
          dataField="contactEmail"
          caption="Contact Email"
        ></dxi-column>
      </dx-data-grid>
    </mat-card-content>
  </mat-card>
</div>
