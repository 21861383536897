<dx-validation-group>
    <div class="login-header">
        <div class="title">{{appInfo.title}}</div>
        <div>Welcome to our client portal!</div>
        <div>Please sign in</div>
    </div>
    <div class="dx-field">
        <dx-text-box [(value)]="login" placeholder="Login" width="100%" (onEnterKey)="onLoginClick($event)">
            <dx-validator>
                <dxi-validation-rule type="required" message="Login is required"></dxi-validation-rule>
            </dx-validator>
        </dx-text-box>
    </div>
    <div class="dx-field">
        <dx-text-box mode="password" [(value)]="password" placeholder="Password" width="100%" (onEnterKey)="onLoginClick($event)">
            <dx-validator>
                <dxi-validation-rule type="required" message="Password is required"></dxi-validation-rule>
            </dx-validator>
        </dx-text-box>
    </div>
    <div class="dx-field">
        <dx-check-box [value]="false" text="Remember me"></dx-check-box>
    </div>
    <div class="dx-field">
        <dx-button type="default" text="Login" (onClick)="onLoginClick($event)" width="100%"></dx-button>
    </div>
    <div class="dx-field">
        <a routerLink="/forgotpassword">Forgot password ?</a>
    </div>
</dx-validation-group>
