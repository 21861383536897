<dx-validation-group>
    <div class="login-header">
        <div class="title">Reset Password</div>
        <div>Please enter your new password below</div>
    </div>
    <form (submit)="onFormSubmit($event)">
        <dx-form id="form"
                  [formData]="resetPasswordRequest"
                  [disabled]="loading"
                  [showValidationSummary]="true">
          <dxi-item dataField="Email" [editorOptions]="{ disabled: true, mode: 'email' }">
            <dxo-label text="Email"> </dxo-label>
          </dxi-item>
          <dxi-item dataField="Password" [editorOptions]="{ mode: 'password' }">
            <dxi-validation-rule type="required" message="Password is required"></dxi-validation-rule>
            <dxi-validation-rule type="pattern" pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{6,}$"
                message="Password requires minimum six characters, at least one uppercase letter and one number"></dxi-validation-rule>
          </dxi-item>
          <dxi-item editorType="dxTextBox" [editorOptions]="{ mode: 'password' }">
            <dxo-label text="Confirm Password"> </dxo-label>
            <dxi-validation-rule
              type="required"
              message="Confirm Password is required"
            >
            </dxi-validation-rule>
            <dxi-validation-rule
              type="compare"
              [comparisonTarget]="passwordComparison"
              message="Password and Confirm Password do not match"
            >
            </dxi-validation-rule>
          </dxi-item>
          <dxi-item
            itemType="button"
            horizontalAlignment="center">
            <dxo-button-options
              [text]="'Reset Password'"
              type="default"
              [useSubmitBehavior]="true"
            ></dxo-button-options>
          </dxi-item>
        </dx-form>
      </form>
</dx-validation-group>
