<h2 class="content-block">Deadline overview</h2>

<dx-data-grid
  id="deadlineGrid"
  keyExpr="deadlineId"
  [dataSource]="deadlineDataSource"
  height="85vh"
  [columnAutoWidth]="true"
  [wordWrapEnabled]="true"
  [rowAlternationEnabled]="true"
  [allowColumnReordering]="true"
  (onRowPrepared)="onRowPrepared($event)"
  (onExporting)="onExporting($event)"
>
  <dxo-paging [pageSize]="10"></dxo-paging>
  <dxo-column-chooser [enabled]="true" mode="select"></dxo-column-chooser>
  <dxo-master-detail
    [enabled]="true"
    template="detailtemplate"
  ></dxo-master-detail>
  <dxo-pager
    [showPageSizeSelector]="true"
    [allowedPageSizes]="[10, 25, 50, 100]"
    [showInfo]="true"
  >
  </dxo-pager>
  <dxo-search-panel
    [visible]="true"
    [width]="240"
    placeholder="Search for anything..."
  ></dxo-search-panel>
  <dxo-filter-row [visible]="true"></dxo-filter-row>
  <dxo-export
    [enabled]="true"
    fileName="Deadlines"
    [allowExportSelectedData]="false"
  ></dxo-export>
  <dxi-column
    [showInColumnChooser]="false"
    type="buttons"
    dataType="object"
    [allowReordering]="false"
    fixedPosition="left"
    [width]="35"
  >
    <dxi-button hint="View details" icon="info" [onClick]="onDetailsClick">
    </dxi-button>
  </dxi-column>
  <dxi-column
    dataField="date"
    [format]="'dd/MM/yyyy'"
    caption="Deadline"
    [width]="200"
    sortOrder="asc"
    [(selectedFilterOperation)]="selectedOperation"
    [(filterValue)]="filterValue"
  >
  </dxi-column>
  <dxi-column dataField="deadlineCategoryId" caption="Category">
    <dxo-lookup
      [dataSource]="deadlineCategoryDict"
      valueExpr="DeadlineCategoryId"
      displayExpr="Label"
    ></dxo-lookup>
  </dxi-column>
  <dxi-column
    dataField="caseClientReference"
    caption="Your Ref."
    [width]="175"
  ></dxi-column>
  <dxi-column
    dataField="caseCalystaReference"
    caption="Calysta Ref."
    [width]="175"
  ></dxi-column>
  <dxi-column
    dataField="catchword"
    caption="Catchword"
    [width]="250"
  ></dxi-column>
  <dxi-column
    dataField="combinedTextClient"
    caption="Required Action"
  ></dxi-column>
  <dxi-column
    dataField="officialDueDate"
    caption="Official Deadline"
    [visible]="false"
    dataType="date"
    [format]="'dd/MM/yyyy'"
  ></dxi-column>
  <dxi-column type="buttons" [width]="80" caption="Document(s)">
    <dxi-button
      hint="Preview Related Document"
      icon="search"
      [visible]="isDocumentAvailable"
      [onClick]="previewRelatedDocument"
    ></dxi-button>
    <dxi-button
      hint="Download Related Document"
      icon="download"
      [visible]="isDocumentAvailable"
      [onClick]="downloadRelatedDocument"
    ></dxi-button>
  </dxi-column>
  <dxi-column type="buttons" [width]="75" caption="Instruct">
    <dxi-button
      hint="Give Instruction"
      icon="comment"
      [visible]="isReplyPossible"
      [onClick]="openReplyFrame"
    ></dxi-button>
  </dxi-column>
  <div *dxTemplate="let detaildata of 'detailtemplate'">
    <div class="master-detail-caption">Related Documents:</div>
    <dx-data-grid
      id="detailContainer"
      [dataSource]="detaildata.data.documents"
      (onToolbarPreparing)="onToolbarPreparing($event)"
    >
      <dxo-selection mode="multiple"></dxo-selection>
      <dxi-column
        dataField="documentType"
        caption=""
        [width]="50"
        [allowFiltering]="false"
        [allowSorting]="false"
        cellTemplate="fileTypeTemplate"
      ></dxi-column>
      <dxi-column
        dataField="logDate"
        caption="Date added"
        dataType="date"
        sortOrder="desc"
      ></dxi-column>
      <dxi-column dataField="categoryLabel" caption="Category"></dxi-column>
      <dxi-column
        dataField="subCategoryLabel"
        caption="Sub Category"
      ></dxi-column>
      <dxi-column dataField="description" caption="Name"></dxi-column>
      <dxi-column type="buttons" [width]="75" caption="">
        <dxi-button
          hint="Preview Related Document"
          icon="search"
          [onClick]="previewRelatedDocumentbis"
        ></dxi-button>
        <dxi-button
          hint="Download Related Document"
          icon="download"
          [onClick]="downloadRelatedDocumentbis"
        ></dxi-button>
      </dxi-column>
      <div *dxTemplate="let data of 'fileTypeTemplate'">
        <i
          class="{{ mapDocumentTypeIcon(data.value) }}"
          style="font-size: 28px"
        ></i>
        <!-- <i class= 'dx-icon-pdffile' ></i> -->
      </div>
    </dx-data-grid>
  </div>
</dx-data-grid>

<dx-popup
  [width]="800"
  [height]="680"
  [showTitle]="true"
  [title]="PopupTitle"
  [dragEnabled]="false"
  [closeOnOutsideClick]="true"
  [(visible)]="PopupVisible"
  [contentTemplate]="currentTemplate"
  (onHiding)="clearInstruction()"
  id="PopupWindow"
>
  <div *dxTemplate="let data of 'pdfpreview'">
    <div class="scroll">
      <pdf-preview [BlobFile]="documentPreview" />
    </div>
    <!-- End of scroll view -->
  </div>

  <div *dxTemplate="let data of 'confirmation'">
    <dx-form id="form-confirmation" [formData]="currentDeadline">
      <dxi-item itemType="group" caption="Deadline">
        <dxi-item
          dataField="actionTextClient"
          [label]="{ text: 'Description' }"
          [editorOptions]="{ disabled: true }"
        >
        </dxi-item>
        <dxi-item
          dataField="statusTextClient"
          [label]="{ text: 'To Do' }"
          [editorOptions]="{ disabled: true }"
        >
        </dxi-item>
      </dxi-item>
      <dxi-item itemType="group" caption="Instruction">
        <dxi-item
          dataField="Instruction"
          editorType="dxSelectBox"
          [editorOptions]="{ items: possibleInstructions, value: '' }"
        >
          <dxi-validation-rule
            type="required"
            message="Instruction is required"
          >
          </dxi-validation-rule>
        </dxi-item>
        <dxi-item
          dataField="Remarks"
          editorType="dxTextArea"
          [editorOptions]="{ placeholder: 'Add additional remarks...' }"
        >
        </dxi-item>
        <dxi-item
          itemType="button"
          horizontalAlignment="right"
          [buttonOptions]="submitButtonOptions"
        >
        </dxi-item>
      </dxi-item>
    </dx-form>
  </div>
  <div *dxTemplate="let data of 'openresponse'" id="InstructionForm">
    <dx-load-panel
      #loadPanel
      shadingColor="rgba(0,0,0,0.1)"
      [position]="{ of: '#InstructionForm' }"
      [(visible)]="loadingVisible"
      [showIndicator]="true"
      [showPane]="true"
      [shading]="true"
      [closeOnOutsideClick]="true"
    >
    </dx-load-panel>
    <dx-scroll-view width="100%" height="100%">
      <form
        (submit)="onFormSubmit($event)"
        enctype="multipart/form-data"
        *ngIf="PopupVisible"
      >
        <dx-form id="form-openresponse" [formData]="deadlineInstruction">
          <dxi-item itemType="group" caption="Deadline">
            <dxi-item
              dataField="Todo"
              [label]="{ text: 'Todo: ' }"
              [editorOptions]="{ disabled: true }"
            >
            </dxi-item>
          </dxi-item>
          <dxi-item itemType="group" caption="Instruction">
            <dxi-item
              dataField="Instruction"
              editorType="dxSelectBox"
              [visible]="deadlineInstruction.OptionsVisible"
              [editorOptions]="{
                items: possibleInstructions,
                valueExpr: 'keyValue',
                displayExpr: 'stringValue'
              }"
            >
            </dxi-item>
            <dxi-item
              dataField="FreeTextInstruction"
              editorType="dxTextArea"
              [visible]="deadlineInstruction.FreeTextVisible"
              [label]="{ text: 'Your Remarks:' }"
              [editorOptions]="{
                placeholder: '(Provide your remarks here)',
                autoResizeEnabled: true
              }"
            >
            </dxi-item>
            <dxi-item>
              <div
                *ngIf="deadlineInstruction.DocumentUploadVisible"
                id="fileuploader-container"
              >
                <dx-file-uploader
                  #fileUploader
                  selectButtonText="Upload Document"
                  labelText=""
                  [(value)]="value"
                  accept="*"
                  multiple="true"
                  uploadMode="useForm"
                  name="files[]"
                >
                </dx-file-uploader>
              </div>
            </dxi-item>
            <dxi-item
              itemType="button"
              horizontalAlignment="right"
              [buttonOptions]="submitButtonOptions"
            >
            </dxi-item>
          </dxi-item>
        </dx-form>
      </form>
    </dx-scroll-view>
  </div>
</dx-popup>

<dx-load-panel
  #loadPanel
  shadingColor="rgba(0,0,0,0.4)"
  [position]="{ of: 'body' }"
  [(visible)]="loadingPreview"
  [showIndicator]="true"
  [showPane]="true"
  [shading]="true"
  [closeOnOutsideClick]="false"
/>
