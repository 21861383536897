<h2 class="content-block">IP Service Overview</h2>

<dx-data-grid
  id="gridContainer"
  keyExpr="AssetId"
  height="85vh"
  [dataSource]="dataSource"
  [showBorders]="true"
  [columnAutoWidth]="true"
  [rowAlternationEnabled]="true"
  [allowColumnReordering]="true"
  [remoteOperations]="true"
  (onToolbarPreparing)="onToolbarPreparing($event)"
>
  <dxo-paging [pageSize]="10"></dxo-paging>
  <dxo-pager
    [showPageSizeSelector]="true"
    [allowedPageSizes]="[10, 25, 50]"
    [showInfo]="true"
  ></dxo-pager>
  <dxo-column-chooser [enabled]="true" mode="select"></dxo-column-chooser>
  <dxo-search-panel
    [visible]="true"
    [width]="240"
    placeholder="Search for anything..."
  ></dxo-search-panel>
  <dxo-filter-row [visible]="true"></dxo-filter-row>
  <dxi-column
    type="buttons"
    [showInColumnChooser]="false"
    [fixed]="true"
    [allowReordering]="false"
    [allowFixing]="true"
    fixedPosition="left"
    [width]="50"
  >
    <dxi-button hint="View details" icon="info" [onClick]="onDetailsClick">
    </dxi-button>
  </dxi-column>
  <dxi-column
    dataField="ClientReference"
    [width]="200"
    caption="Your Reference"
    [fixed]="true"
    [allowFixing]="true"
  ></dxi-column>
  <dxi-column
    dataField="CalystaReference"
    caption="Calysta Reference"
    [fixed]="true"
    [allowFixing]="true"
  ></dxi-column>
  <dxi-column dataField="CaseTypeLabel" caption="Asset Type"></dxi-column>
  <dxi-column
    dataField="Catchword"
    caption="Catchword"
    [allowFixing]="true"
  ></dxi-column>
  <dxi-column dataField="MappedStatusLabel" caption="Status"></dxi-column>
  <!-- <dxi-column dataField="statusLabel" caption="DetailedStatus"></dxi-column> -->
  <dxi-column
    dataField="ApplicationType"
    caption="Application Type"
  ></dxi-column>
  <dxi-column dataField="Country" caption="Country"></dxi-column>
  <dxi-column dataField="CreationDate" caption="Creation Date"></dxi-column>
  <dxi-column dataField="OpenDate" caption="Opened on"></dxi-column>
  <dxi-column
    dataField="RenewalDueDate"
    dataType="date"
    caption="Renewal Due Date"
  ></dxi-column>
  <dxi-column
    dataField="Segments"
    [width]="350"
    caption="Segment"
    editCellTemplate="tagBoxEditor"
    [cellTemplate]="cellTemplate"
    [calculateFilterExpression]="calculateFilterExpression"
  >
    <dxo-lookup
      [dataSource]="segments"
      searchEnabled="true"
      valueExpr="SegmentId"
      displayExpr="SegmentLabel"
    >
    </dxo-lookup>
  </dxi-column>

  <dxo-export [enabled]="true" [allowExportSelectedData]="true"></dxo-export>
</dx-data-grid>
