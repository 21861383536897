import { Injectable } from '@angular/core';
import { IPAsset } from '../models/ipasset.model';
import { Country } from '../models/country.model';
import { createStore } from 'devextreme-aspnet-data-nojquery';
import CustomStore from 'devextreme/data/custom_store';
import {
  IPAssetFamily,
  StatusInfo,
  GeographicalCoverage,
  GeographicalCoverageTRMAdapter,
  GeographicalCoverageTRM,
  FamilyCoverage,
  FamilyCoverageAdapter,
} from '../models/ipassetfamily.model';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PortfolioFilingStatisticsAdapter } from '../models/Portfolio/PortfolioFilingStatistics';

const apiUrl = environment.baseUrl + '/api/erp/portfolio';

@Injectable({
  providedIn: 'root'
})

export class IPFamilyService {
  TestIPFamilies: IPAssetFamily[];

  private _ipAssetFamilies: BehaviorSubject<IPAssetFamily[]>;
  private _ipPortfolioCoverage: BehaviorSubject<GeographicalCoverageTRM[]>;

  public readonly IPAssetFamilies: Observable<IPAssetFamily[]>;
  public readonly IPPortfolioCoverage: Observable<GeographicalCoverageTRM[]>;

  TestCountries: Country[];
  GeoCoverage: GeographicalCoverage[];

  constructor(private http: HttpClient,
              private geographicalCoverageTRMAdapter: GeographicalCoverageTRMAdapter,
              private familyCoverageAdapter: FamilyCoverageAdapter,
              private PortfolioFilingsAdapter: PortfolioFilingStatisticsAdapter) {
    this._ipAssetFamilies = new BehaviorSubject<IPAssetFamily[]>([]);
    this._ipPortfolioCoverage = new BehaviorSubject<GeographicalCoverageTRM[]>([]);
    this.IPAssetFamilies = this._ipAssetFamilies.asObservable();
    this.IPPortfolioCoverage = this._ipPortfolioCoverage.asObservable();

    this.loadInitialData();
  }

  loadInitialData() {
    this
      .getAll()
      .subscribe((res: any) => this._ipAssetFamilies.next(res.data));

    this
      .getPortfolioCoverage()
      .subscribe((res: GeographicalCoverageTRM[]) => this._ipPortfolioCoverage.next(res));
  }

   getAll() {
      return this.http.get(apiUrl);
   }

  getPortfolioCoverage() {
    return this.http.get(apiUrl + '/coverage/portfolio')
    .pipe(
      map((res: any) => {
        return res.map(item => this.geographicalCoverageTRMAdapter.adapt(item));
      })
    )
    .pipe(catchError(this.handleError));
  }

  getPortfolioStatistics() {
    return this.http.get(apiUrl + '/statistics')
    .pipe(
      map((res: any) => {
        return res.data.map(item => this.PortfolioFilingsAdapter.adapt(item));
      })
    )
    .pipe(catchError(this.handleError));
  }

  getTRMFamilyCoverage(Family: string) {
    return this.http.get<FamilyCoverage>(apiUrl + '/coverage/trm/' + Family)
    .pipe(
      map((res: any) =>
        res.data.map(item => this.familyCoverageAdapter.adapt(item))
      )
    )
    .pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    // return an observable with a user-facing error message
    return throwError('Something bad happened; please try again later.');
  }
}
