<dx-vector-map  id="vector-map" 
                [bounds]="[-180, 85, 180, -60]"
                (onClick)="CountryClicked($event)">
  <dxi-layer
    [customize]="customizeLayers"
    [dataSource]="worldMap"
    [colorGroups]="[0, 1, 2]"
    colorGroupingField="CoverScore"
    name="Country"
    title="Coverage"
  >
    <dxo-label [enabled]="true" dataField="Country"></dxo-label>
  </dxi-layer>
  <dxo-control-bar [enabled]="false" ></dxo-control-bar>
  <dxo-tooltip [enabled]="true" contentTemplate="tooltipContent"></dxo-tooltip>
  <div *dxTemplate="let info of 'tooltipContent'" class='state-tooltip'>
    <p>{{info.attribute("name")}}</p>
</div>
</dx-vector-map>
