import { Injectable } from '@angular/core';
import { Adapter } from '../../adapter/adapter';

export class ERPPicture {

    constructor(
        public CaseId: number,
        public PictureName: string,
        public PictureData: Blob,
        ) {}
}

@Injectable({
    providedIn: 'root'
  })
  export class ERPPictureAdapter implements Adapter<ERPPicture> {
    adapt(item: any): ERPPicture {
      return new ERPPicture(
          item.caseIdAssociated,
          item.fileName,
          null,
      );
    }
  }
