export const navigation = [
  {
    text: 'Home',
    path: '/home',
    icon: 'home'
  },
  {
    text: 'IP Portfolio',
    path: '/portfolio',
    icon: 'product'
  },
  {
    text: 'IP Asset Overview',
    path: '/ipassetoverview',
    icon: 'product'
  },
  {
    text: 'IP Service Overview',
    path: '/ipserviceoverview',
    icon: 'product'
  },
  {
    text: 'Deadlines',
    path: '/deadlines',
    icon: 'event'
  },
  {
    text: 'Renewals',
    path: '/renewals',
    icon: 'refresh',
    items: [
      {
        text: 'Instruction History',
        path: '/renewals/log'
      },
    ]
  },
  {
    text: 'Invoices',
    path: '/invoices',
    icon: 'money'
  },
  {
    text: 'Documents',
    path: '/documents',
    icon: 'doc'
  },
  {
    text: 'Analytics',
    icon: 'chart',
    items: [
      {
        text: 'Costs By Segment',
        path: '/analytics/costbysegment'
      },
    ]
  },
  {
    text: 'Segments',
    path: '/segments',
    icon: 'toolbox'
  },
];
