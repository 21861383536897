import { Component, OnInit } from "@angular/core";
import DataSource from "devextreme/data/data_source";
import { Observable } from "rxjs";
import { ERPDocumentService } from "src/app/shared/services/ERP/erp.document.service";

@Component({
  selector: "app-documentsoverview",
  templateUrl: "./documentsoverview.component.html",
  styleUrls: ["./documentsoverview.component.scss"],
})
export class DocumentsoverviewComponent implements OnInit {
  documentDataSrc: DataSource;
  PopupVisible: boolean;
  pdfSrc: any;
  currentTemplate;
  PopupTitle: string;

  loadingPreview: boolean = false;

  documentPreview: any;

  constructor(private documentSvc: ERPDocumentService) {
    this.PopupVisible = false;
    this.previewRelatedDocument = this.previewRelatedDocument.bind(this);
    this.downloadRelatedDocument = this.downloadRelatedDocument.bind(this);
  }

  ngOnInit() {
    this.documentSvc.getDocumentOverview().subscribe((res: any) => {
      this.documentDataSrc = new DataSource({
        store: res.data,
        key: "DocumentId",
      });
    });
  }

  previewRelatedDocument(e) {
    this.loadingPreview = true;
    this.documentSvc
      .LoadDocumentPreviewV2(e.row.data.documentId)
      .subscribe((res) => {
        this.documentPreview = res;
        this.PopupVisible = true;
        this.PopupTitle = e.row.data.fileName;
        this.loadingPreview = false;
      });
  }

  mapDocumentTypeIcon(filetype: string): string {
    const iconprefix = "dx-icon-";
    switch (filetype) {
      case "xlsx":
        return iconprefix + "xlsxfile";
      case "xls":
        return iconprefix + "xlsfile";
      case "pdf":
        return iconprefix + "pdffile";
      case "docx":
        return iconprefix + "docxfile";
      case "doc":
        return iconprefix + "docfile";
      case "msg":
        return iconprefix + "email";
      case "eml":
        return iconprefix + "email";
      default:
        break;
    }
  }

  downloadRelatedDocument(e) {
    this.documentSvc
      .getDocument(e.row.data.documentId)
      .subscribe((res: any) => {
        const blob = res;
        const fileName = e.row.data.fileName;
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(link.href);
      });
  }

  tabPreview = (e) => {
    const id = e.row.data.documentId;

    window.open(`/documents/${id}`, "_blank");
  };
}
