import { Injectable } from "@angular/core";
import { Adapter } from "../adapter/adapter";
import { map } from 'rxjs/operators';

export class Country {
  constructor(
    public id: number,
    public ISO2: string,
    public ISO3: string,
    public label: string,
    public continentId: number,
    public continentLabel: string,
    public showOnMap: boolean,
    public showOnTable: boolean,
  ) {}
}

export class Continent {
  constructor(public id: number,
              public ISO2: string,
              public label: string,
              public countries: Country[]) {}
}

@Injectable({
  providedIn: 'root'
})
export class CountryAdapter implements Adapter<Country> {
  adapt(item: any): Country {
    return new Country(
      item.id,
      item.isO2,
      null,
      item.label,
      item.continentId,
      item.continentLabel,
      item.showOnMap,
      item.showOnTable
    );
  }
}

@Injectable({
    providedIn: 'root'
  })
  export class ContinentAdapter implements Adapter<Continent> {
      constructor(private countryadpt: CountryAdapter) {}
    adapt(item: any): Continent {
      return new Continent(
        item.id,
        item.isO2,
        item.label,
        item.countries
      );
    }
  }
