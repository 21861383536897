import { Injectable } from "@angular/core";
import { IPAsset, IpAssetAdapter } from "../models/ipasset.model";
import { Country } from "../models/country.model";
import {
  IPAssetFamily,
  GeographicalCoverage,
} from "../models/ipassetfamily.model";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import {
  IPAssetKPI,
  AssetStatusSummary,
} from "../models/IPAssets/ipassetkpi.model";
import { BehaviorSubject, Observable, throwError } from "rxjs";
import {
  IpAssetClass,
  IpAssetClassAdapter,
} from "../models/IPAssets/ipassetclass.model";
import { catchError, map } from "rxjs/operators";
import { IPAssetDeadline } from "../models/IPAssets/ipassetdeadline.model";
import { environment } from "../../../environments/environment";
import { IPAssetParty } from "../models/IPAssets/ipassetparty.model";
import {
  IPAssetTitle,
  IPAssetTitleAdapter,
} from "../models/IPAssets/ipassetTitle.model copy";
import {
  DesignDevice,
  DesignDeviceAdapter,
} from "../models/IPAssets/designdevice.model";

const apiUrl = environment.baseUrl + "/api/erp/ipasset/";

@Injectable({ providedIn: "root" })
export class IPAssetService {
  TestIPFamilies: IPAssetFamily[];
  TestCountries: Country[];
  GeoCoverage: GeographicalCoverage[];

  private _ipAssets = new BehaviorSubject<IPAsset[]>([]);
  private _ipTypeKPI = new BehaviorSubject<IPAssetKPI[]>([]);

  public FilterApplied: boolean;

  public readonly IPAssets: Observable<IPAsset[]> =
    this._ipAssets.asObservable();
  public readonly IPTypeKpi: Observable<IPAssetKPI[]> =
    this._ipTypeKPI.asObservable();

  constructor(
    private httpClient: HttpClient,
    private classAdapter: IpAssetClassAdapter,
    private ipAssetAdapter: IpAssetAdapter,
    private ipAssetTitleAdapter: IPAssetTitleAdapter,
    private designDeviceAdapter: DesignDeviceAdapter
  ) {
    this._ipAssets.next([]);
    this._ipTypeKPI.next([]);

    this.loadInitialData();
  }

  loadInitialData() {
    this.httpClient
      .get<IPAsset[]>(apiUrl + "all?showClosed=false")
      .subscribe((res: any) => {
        this._ipAssets.next(res.data);
      });
  }

  getAll(): Observable<IPAsset[]> {
    return this.IPAssets;
  }

  urlGetAll() {
    return apiUrl + "all";
  }

  getAllAssets(showClosedCases: boolean) {
    this.httpClient
      .get<IPAsset[]>(apiUrl + "all?showClosed=" + showClosedCases)
      .subscribe((res: any) => {
        this._ipAssets.next(res.data);
      });
  }

  getAsset(assetId: number) {
    return this.httpClient.get(apiUrl + assetId);
  }

  getIPAssetTitle(AssetId: number) {
    return this.httpClient
      .get<IPAssetTitle[]>(apiUrl + "title/" + AssetId)
      .pipe(
        map((res: any) =>
          res.data.map((item) => this.ipAssetTitleAdapter.adapt(item))
        )
      )
      .pipe(catchError(this.handleError));
  }

  getIPAssetKPI() {
    return this.httpClient.get<IPAssetKPI[]>(apiUrl + "summary");
  }

  getStatusKPI() {
    return this.httpClient.get<AssetStatusSummary[]>(apiUrl + "statussummary");
  }

  getClasses(assetId: number) {
    return this.httpClient
      .get<IpAssetClass[]>(apiUrl + "classes/" + assetId)
      .pipe(
        map((res: any) => res.data.map((item) => this.classAdapter.adapt(item)))
      );
  }

  getMainPicture(assetId: number) {
    return this.httpClient
      .get<IpAssetClass[]>(apiUrl + "classes/" + assetId)
      .pipe(
        map((res: any) => res.data.map((item) => this.classAdapter.adapt(item)))
      );
  }

  getDeadlines(assetId: number) {
    return null;
  }

  getDesignDevices(assetId: number) {
    return this.httpClient
      .get<DesignDevice[]>(apiUrl + "devices/" + assetId)
      .pipe(
        map((res: any) =>
          res.data.map((item) => this.designDeviceAdapter.adapt(item))
        )
      );
  }

  getIPAssetParties(assetId: number) {
    return this.httpClient.get<any>(apiUrl + "parties/" + assetId);
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error("An error occurred:", error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    // return an observable with a user-facing error message
    return throwError("Something bad happened; please try again later.");
  }
}
