import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse
} from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { DeadlineInstruction } from '../../models/deadlineInstruction.model';
import { ERPDeadlineResponseTypeAdapter } from '../../models/ERP/deadlineresponsetype.erp.model';
import { RenewalInstruction } from 'src/app/pages/renewals/renewals.component';

const apiUrl = environment.baseUrl + '/api/erp/instruction';

@Injectable({
  providedIn: 'root'
})

export class ERPInstructionService {
  constructor(
    private erpDeadlineResponseTypeAdapter: ERPDeadlineResponseTypeAdapter,
    private http: HttpClient,
  ) {}

  provideInstruction(body: any) {
    const customHeaders = new HttpHeaders();
    customHeaders.append('enctype', 'multipart/form-data');
    customHeaders.append('Accept', 'application/json');

    return this.http.post<DeadlineInstruction>(apiUrl + '/provide', body, { headers: customHeaders })
      .pipe(catchError(this.handleError));
  }

  ProvideRenewalInstruction(body: any) {

    const customHeaders = new HttpHeaders();
    customHeaders.append('enctype', 'multipart/form-data');
    customHeaders.append('Accept', 'application/json');

    return this.http.post<RenewalInstruction>(apiUrl + '/renewals/patent', body, { headers: customHeaders })
      .pipe(catchError(this.handleError));
  }

  getResponseType(eventSchemeId: number) {
    return this.http
    .get(apiUrl + '/responsetype/' + eventSchemeId)
    .pipe(catchError(this.handleError));
  }

  // Handle an error.
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    // return an observable with a user-facing error message
    return throwError('Something bad happened; please try again later.');
  }
}
