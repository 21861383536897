<mat-card class="header-panel">
    <mat-card-header>
      <div id="form-container">
        <dx-form id="form" [colCount]="3" [formData]="service" (onFieldDataChanged)="UpdateSegments()">
          <dxi-item dataField="CaseTypeLabel" [label]="{text: 'IP Type'}" [editorOptions]="{ disabled: true }" > </dxi-item>
          <dxi-item dataField="ClientReference" [label]="{text: 'Your Reference'}" [editorOptions]="{ disabled: true }" ></dxi-item>
          <dxi-item dataField="Country" [label]="{text: 'Country'}" [editorOptions]="{ disabled: true }" > </dxi-item>
          <dxi-item dataField="CalystaReference" [label]="{text: 'Calysta Reference'}" [editorOptions]="{ disabled: true }" > </dxi-item>
          <dxi-item dataField="MappedStatusLabel" [label]="{text: 'Status'}" [editorOptions]="{ disabled: true }" ></dxi-item>
          <dxi-item dataField="OpenDate" [label]="{text: 'Opened On'}" editorType="dxDateBox" [editorOptions]="{ disabled: true }"></dxi-item>
          <dxi-item dataField="Catchword" [label]="{text: 'Title'}" [editorOptions]="{ disabled: true }"></dxi-item>
          <dxi-item dataField="Segments" [label]="{text: 'Segment'}"
                  editorType="dxTagBox"
                  [editorOptions]="{ items: segments, searchEnabled:'true',
                                      multiline:'false', displayExpr:'SegmentLabel',
                                      valueExpr:'SegmentId'}">
          </dxi-item>
        </dx-form>
      </div>
    </mat-card-header>
    <mat-card-content>
      <mat-tab-group>
        <mat-tab label="General">
            <div class="tabContent">
          </div>
        </mat-tab>
        <mat-tab label="Parties">
          <div class="tabContent">
            <app-parties [assetId]="serviceId"></app-parties>
          </div>
      </mat-tab>
        <mat-tab label="Deadlines">
          <div class="content">
          </div>
        </mat-tab>
        <mat-tab label="Documents">
          <div class="content">
            <app-documents [assetId]="serviceId" class="tabContent"></app-documents>
          </div>
        </mat-tab>
        <mat-tab *appIfSubscription=[2,3] label="Invoices">
          <div class="content">
          </div>
        </mat-tab>
      </mat-tab-group>
    </mat-card-content>
  </mat-card>
