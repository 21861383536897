import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from "@angular/core";

import { IPAsset } from "src/app/shared/models/ipasset.model";
import { NgForm } from "@angular/forms";
import { DxDataGridComponent } from "devextreme-angular";
import DataSource from "devextreme/data/data_source";
import { FinancialService } from "src/app/shared/services/Financial/financial.service";
import { InvoiceDetails } from "src/app/shared/models/financial/invoiceDetails.model";

@Component({
  selector: "app-invoices",
  templateUrl: "./invoices.component.html",
  styleUrls: ["./invoices.component.scss"],
})
export class InvoicesComponent implements OnChanges, OnInit {
  @Input()
  assetData: IPAsset;

  assetId: number;

  filePreview: any;
  previewPopUpVisible: any;

  @ViewChild("docForm", { static: true }) form: NgForm;
  @ViewChild(DxDataGridComponent)
  dataGrid: DxDataGridComponent;

  invoicesDataArray: InvoiceDetails[];
  invoicesData: DataSource;
  financialsData: ChartData[];

  constructor(private financialService: FinancialService) {
    this.previewDocument = this.previewDocument.bind(this);
  }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.assetData && this.assetData) {
      this.financialService
        .getIPAssetInvoiceDetails(this.assetData.assetId)
        .subscribe((res: InvoiceDetails[]) => {
          res.forEach((element) => {
            const oneDayLater = new Date(element.InvoiceDate);
            const oneDayBefore = new Date(element.InvoiceDate);

            oneDayLater.setDate(oneDayLater.getDate() + 1);
            oneDayBefore.setDate(oneDayBefore.getDate() - 1);

            if (
              res.findIndex(
                (y) =>
                  -y.AmountOnCase === element.AmountOnCase &&
                  new Date(y.InvoiceDate) <= oneDayLater &&
                  new Date(y.InvoiceDate) >= oneDayBefore
              ) !== -1
            ) {
              const ind = res.findIndex(
                (y) =>
                  -y.AmountOnCase === element.AmountOnCase &&
                  y.InvoiceDate <= oneDayLater &&
                  y.InvoiceDate >= oneDayBefore
              );
              const currentInd = res.findIndex((x) => x === element);
              res.splice(ind, 1);
              res.splice(currentInd, 1);
            }
          });

          this.invoicesDataArray = res;

          this.invoicesData = new DataSource({
            store: res,
            key: "InvoiceNumber",
          });
          let chartDataArray: ChartData[] = [];
          this.invoicesDataArray.forEach((element) => {
            const currentInvoiceDate = new Date(element.InvoiceDate);
            if (
              chartDataArray.findIndex(
                (y) => y.year === currentInvoiceDate.getFullYear().toString()
              ) !== -1
            ) {
              const ind = chartDataArray.findIndex(
                (y) => y.year === currentInvoiceDate.getFullYear().toString()
              );
              let currentcost = chartDataArray[ind].costs;
              currentcost += element.AmountOnCase;
              chartDataArray[ind].costs = currentcost;
              chartDataArray.splice(ind, 1);
              chartDataArray.push({
                costs: currentcost,
                year: currentInvoiceDate.getFullYear().toString(),
              });
            } else {
              chartDataArray.push({
                costs: element.AmountOnCase,
                year: currentInvoiceDate.getFullYear().toString(),
              });
            }

            chartDataArray = chartDataArray.sort((a, b) => +a.year - +b.year);
            this.financialsData = chartDataArray;
          });
        });
    }
  }

  customizeTooltip(arg: any) {
    return {
      text: arg.argumentText + " - " + arg.valueText,
    };
  }

  previewDocument(e) {
    this.filePreview = null;
    this.financialService
      .getInvoicePreview(e.row.data.InvoiceNumber)
      .subscribe((res: any) => {
        this.previewPopUpVisible = true;
        this.filePreview = res;
      });
  }

  downloadDocument(e) {}
}

export class ChartData {
  year: string;
  costs: number;
}
