import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { DxDataGridComponent } from "devextreme-angular";
import { Observable, Subscription } from "rxjs";
import { ERPDESRenewalDeadline } from "src/app/shared/models/ERP/desrenewaldeadline.erp.model";
import {
  RenewalInstruction,
  RenewalInstructionSet,
} from "src/app/shared/models/Renewal/RenewalInstruction.model";

@Component({
  selector: "app-renewals-design",
  templateUrl: "./renewals-design.component.html",
  styleUrls: ["./renewals-design.component.scss"],
})
export class RenewalsDesignComponent implements OnInit {
  private eventsSubscription: Subscription;

  @Input() designRenewalData: Observable<ERPDESRenewalDeadline[]>;
  @Input() desRenewalInstructionSet: RenewalInstructionSet;
  @Input() events: Observable<void>;

  @ViewChild(DxDataGridComponent) dataGrid: DxDataGridComponent;

  selectedRows: number[] = [];

  constructor(private router: Router) {
    this.onRenewalClick = this.onRenewalClick.bind(this);
    this.onAbandonClick = this.onAbandonClick.bind(this);
    this.onRequestAdviceClick = this.onRequestAdviceClick.bind(this);
    this.onRevertClick = this.onRevertClick.bind(this);
    this.onDetailsClick = this.onDetailsClick.bind(this);
  }

  ngOnInit(): void {
    this.selectedRows = [];
    this.eventsSubscription = this.events.subscribe(() =>
      this.revertAllInstructions()
    );
  }

  revertAllInstructions() {
    if (this.dataGrid) {
      this.dataGrid.instance.repaint();
    }
  }

  isInstructionGiven(e) {
    if (e.row.data.InstructionGiven === false) {
      return true;
    }
    return false;
  }

  onContentReady(e) {
    e.component.option("loadPanel.enabled", false);
  }

  onDetailsClick(e) {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(["/ipasset/" + e.row.data.CaseId], {})
    );
    window.open(url, "_blank");
  }

  onRevertClick(e) {
    e.row.data.InstructionGiven = false;

    this.desRenewalInstructionSet.removeRenewalInstruction(e.row.data.CaseId);

    this.dataGrid.instance.repaintRows([e.row.rowIndex]);
  }

  revertVisible(e) {
    if (e.row.data.InstructionGiven === true) {
      return true;
    }
    return false;
  }

  onRenewalClick(e) {
    e.row.data.InstructionGiven = true;

    this.selectedRows.push(e.row.data.CaseId);

    let newRenInstruction: RenewalInstruction = {
      InstructionId: 1,
      InstructionType: "Renew",
      CaseType: "Design",
      CaseId: e.row.data.CaseId,
      OfficialFee: e.row.data.OfficialFee,
      ServiceFee: e.row.data.ServiceFee,
      CalystaFee: e.row.data.CalystaFee,
      Amount: e.row.data.Quote,
      CatchWord: e.row.data.Catchword,
      Country: e.row.data.Country,
      Reference: e.row.data.ClientReference,
      DeadlineId: e.row.data.DeadlineId,
    };

    this.desRenewalInstructionSet.addRenewalInstruction(newRenInstruction);
    this.dataGrid.instance.repaintRows([e.row.rowIndex]);
  }

  onAbandonClick(e) {
    e.row.data.InstructionGiven = true;
    this.selectedRows.push(e.row.data.CaseId);

    let newRenInstruction: RenewalInstruction = {
      InstructionId: 2,
      InstructionType: "Abandon",
      CaseId: e.row.data.CaseId,
      CaseType: "Design",
      OfficialFee: 0,
      ServiceFee: 0,
      CalystaFee: 0,
      Amount: 0,
      CatchWord: e.row.data.Catchword,
      Country: e.row.data.Country,
      Reference: e.row.data.ClientReference,
      DeadlineId: e.row.data.DeadlineId,
    };

    this.desRenewalInstructionSet.addRenewalInstruction(newRenInstruction);
    this.dataGrid.instance.repaintRows([e.row.rowIndex]);
  }

  onRequestAdviceClick(e) {
    e.row.data.InstructionGiven = true;
    this.selectedRows.push(e.row.data.CaseId);

    let newRenInstruction: RenewalInstruction = {
      InstructionId: 3,
      InstructionType: "Verification",
      CaseId: e.row.data.CaseId,
      CaseType: "Design",
      OfficialFee: 0,
      ServiceFee: 0,
      CalystaFee: 0,
      Amount: 0,
      CatchWord: e.row.data.Catchword,
      Country: e.row.data.Country,
      Reference: e.row.data.ClientReference,
      DeadlineId: e.row.data.DeadlineId,
    };

    this.desRenewalInstructionSet.addRenewalInstruction(newRenInstruction);
    this.dataGrid.instance.repaintRows([e.row.rowIndex]);
  }

  onRenewalGroupClick(data) {
    data.data.items.forEach((item: ERPDESRenewalDeadline) => {
      if (item.InstructionGiven != true) {
        let newRenInstruction: RenewalInstruction = {
          InstructionId: 1,
          InstructionType: "Renew",
          CaseType: "Design",
          OfficialFee: item.OfficialFee,
          ServiceFee: item.ServiceFee,
          CalystaFee: item.CalystaFee,
          CaseId: item.CaseId,
          Amount: item.Quote,
          CatchWord: item.Catchword,
          Country: item.Country,
          Reference: item.ClientReference,
          DeadlineId: item.DeadlineId,
        };

        item.InstructionGiven = true;

        this.desRenewalInstructionSet.addRenewalInstruction(newRenInstruction);
        this.dataGrid.instance.repaint();
      }
    });
  }

  onRowPrepared(e: any) {
    if (e.rowType === "data") {
      const dueDate = e.data.PavisInstructionDeadline;

      /*if due date is less than 60 days, highlight the row in yellow,
      if due date is less than 30 days, highlight the row in orange,
      if it is less than 15 days, highlight the row in red*/
      if (dueDate) {
        const dueDateInMilliseconds = new Date(dueDate).getTime();
        const currentDateInMilliseconds = new Date().getTime();
        const differenceInMilliseconds =
          dueDateInMilliseconds - currentDateInMilliseconds;
        const differenceInDays =
          differenceInMilliseconds / (1000 * 60 * 60 * 24);

        if (differenceInDays <= 60) {
          e.rowElement.style.backgroundColor = "yellow";
        }

        if (differenceInDays <= 30) {
          e.rowElement.style.backgroundColor = "orange";
        }

        if (differenceInDays <= 15) {
          e.rowElement.style.backgroundColor = "red";
        }

        //if the due date has passed, highlight the row in dark red
        if (differenceInDays < 0) {
          e.rowElement.style.backgroundColor = "darkred";
        }
      }
    }
  }
}
