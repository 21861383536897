import { Component } from "@angular/core";

@Component({
  selector: "app-ipassetoverview",
  templateUrl: "./ipassetoverview.component.html",
  styleUrls: ["./ipassetoverview.component.scss"],
})
export class IpassetoverviewComponent {
  countryParameter: string;
  countryName: string;

  constructor() {}
}
