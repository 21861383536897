import { Injectable } from '@angular/core';
import { Country } from '../../models/country.model';
import { HttpClient } from '@angular/common/http';
import { User } from '../../models/user';
import { UserOrganizationPermissions } from '../../models/userorganizationpermissions.model';
import { ERPContact } from '../../models/ERP/contact.erp.model';
import { environment } from 'src/environments/environment';

const apiUrl = environment.baseUrl + '/api/erp/contact';

@Injectable({
  providedIn: 'root'
})

/// Service to get all the contact information: note => Git commit.
export class ERPContactService {

  constructor(private http: HttpClient) {}

   getContacts() {
      return this.http.get<ERPContact[]>(apiUrl);
   }
}
