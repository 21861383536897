import { Injectable } from "@angular/core";
import { Adapter } from "../../adapter/adapter";

export class ERPRenewalinstructionLog {
  constructor(
    public RenewalInstructionLogId: number,
    public UserId: string,
    public UserEmail: String,
    public OrganizationId: number,
    public OrganizationName: String,
    public GivenOn: Date,
    public InstructionId: number,
    public InstructionLabel: string,
    public CaseId: number,
    public CalystaReference: string,
    public ClientReference: string,
    public Catchword: string,
    public Country: string,
    public CaseTypeLabel: string,
    public Quote: number,
  ) {}
}

@Injectable({
  providedIn: "root",
})
export class ERPRenewalinstructionLogAdapter implements Adapter<ERPRenewalinstructionLog> {
  adapt(item: any): ERPRenewalinstructionLog {
    return new ERPRenewalinstructionLog(
      item.renewalInstructionLogId,
      item.userId,
      item.userEmail,
      item.organizationId,
      item.organizationName,
      item.givenOn,
      item.instructionId,
      item.instructionLabel,
      item.caseId,
      item.calystaReference,
      item.clientReference,
      item.catchword,
      item.country,
      item.caseTypeLabel,
      item.quote,
    );
  }
}
