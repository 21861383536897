import { Injectable } from '@angular/core';
import { Adapter } from '../../adapter/adapter';

export class ERPDeadlineSummary {

    constructor(
        public DeadlineCategoryId: number,
        public DeadlineCategoryLabel: string,
        public Count: number,
        public EarliestDeadline: Date
        ) {}
}

@Injectable({
    providedIn: 'root'
  })
  export class ERPDeadlineSummaryAdapter implements Adapter<ERPDeadlineSummary> {
    adapt(item: any): ERPDeadlineSummary {
      return new ERPDeadlineSummary(
          item.deadlineCategoryId,
          item.deadlineCategoryLabel,
          item.count,
          item.earliestDeadline
      );
    }
  }
