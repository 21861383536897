import { Component, OnInit } from "@angular/core";
import { User } from "src/app/shared/models/user";
import { UserService } from "src/app/shared/services/Authentication/user.service";
import { SubscriptionTypeService } from "src/app/shared/services/subscription.service";

@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.scss"],
})
export class ProfileComponent implements OnInit {
  currentUser: User;
  subscription: number;

  constructor(
    private userSvc: UserService,
    private subscriptionService: SubscriptionTypeService
  ) {}

  ngOnInit(): void {
    this.userSvc.getUserProfile().subscribe((x) => {
      this.currentUser = x;
    });

    this.subscriptionService.getUserSubscriptionModel().subscribe((res) => {
      this.subscription = res;
    });
  }

  updateProfile = () => {
    this.userSvc.updateUser(this.currentUser).subscribe((x) => {});
  };

  isSilver = () => {
    return this.subscription >= 2;
  };
}
