import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs/internal/Observable';
import { BehaviorSubject } from 'rxjs';

const apiUrl = environment.baseUrl + '/api/roles';

@Injectable({providedIn: 'root'})
export class RoleService {

  public roles$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  public userRoles$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);


  constructor(private httpClient: HttpClient) {
  }

  fetchAllRoles() {
    this.httpClient.get<string[]>(apiUrl)
      .subscribe(result => this.roles$.next(result));
  }

  getUserRoles(userId: string) {
    this.httpClient.get<string[]>(apiUrl + '/user/' + userId)
      .subscribe(result => this.userRoles$.next(result));
  }

  get roles(): Observable<string[]> {
    return this.roles$.asObservable();
  }

  get userRoles(): Observable<string[]> {
    return this.userRoles$.asObservable();
  }
}
