import { Injectable } from '@angular/core';
import { Adapter } from '../../adapter/adapter';
import { ERPDocument } from '../../models/ERP/document.erp.model'

export class ERPDeadline {

    constructor(
        public deadlineId: number,
        public deadlineCategoryId: number,
        public deadlineCategoryLabel: string,
        public statusText: string,
        public actionText: string,
        public statusTextClient: string,
        public actionTextClient: string,
        public combinedTextClient: string,
        public date: Date,
        public associatedFee: number,
        public caseId: number,
        public assetIsService: boolean,
        public caseCalystaReference: string,
        public caseClientReference: string,
        public catchword: string,
        public organizationId: number,
        public organizationLegalName: string,
        public completed: boolean,
        public hasDocument: boolean,
        public documents: ERPDocument[],
        public allDayEvent: boolean,
        public virtualEndDate: Date,
        public file: File,
        public instruction: string,
        public officialDueDate: Date,
        ) {}
}

@Injectable({
    providedIn: 'root'
  })
  export class ERPDeadlineAdapter implements Adapter<ERPDeadline> {
    adapt(item: any): ERPDeadline {
      return new ERPDeadline(
          item.deadlineId,
          item.deadlineCategoryId,
          item.deadlineCategoryLabel,
          item.statusText,
          item.actionText,
          item.statusTextClient,
          item.actionTextClient,
          item.actionTextClient + ' ' + item.statusTextClient,
          new Date(item.date),
          item.associatedFee,
          item.caseId,
          item.assetIsService,
          item.caseCalystaReference,
          item.caseClientReference,
          item.catchword,
          item.organizationId,
          item.organizationLegalName,
          item.completed,
          item.hasDocument,
          item.documents,
          true,
          new Date(new Date(item.date).getFullYear(), new Date(item.date).getMonth(), new Date(item.date).getDate(), 23, 59, 59),
          null,
          null,
          item.officialDueDate
      );
    }
  }
