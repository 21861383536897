import { Injectable } from "@angular/core";
import { Adapter } from "../../adapter/adapter";

export class ERPRenewalDeadline {
  constructor(
    public CaseId: number,
    public ClientReference: string,
    public CalystaReference: string,
    public FamilyNumber: string,
    public Catchword: string,
    public Country: string,
    public ApplicationDate,
    public ApplicationNumber: string,
    public GrantDate,
    public GrantNumber: string,
    public Deadline: Date,
    public PavisInstructionDeadline: Date,
    public OfficialFee: number,
    public ServiceFee: number,
    public CalystaFee: number,
    public Quote: number,
    public DeadlineId: number,
    public InstructionGiven: boolean,
    public InstructionType: number,
    public InstructionBefore: Date
  ) {}
}

@Injectable({
  providedIn: "root",
})
export class ERPRenewalDeadlineAdapter implements Adapter<ERPRenewalDeadline> {
  adapt(item: any): ERPRenewalDeadline {
    return new ERPRenewalDeadline(
      item.caseId,
      item.clientReference,
      item.calystaReference,
      item.familyNumber,
      item.catchword,
      item.country,
      item.applicationDate,
      item.applicationNumber,
      item.grantDate,
      item.grantNumber,
      item.deadline,
      item.pavisInstructionDeadline,
      item.officialFee,
      item.serviceFee,
      item.calystaFee,
      item.quote,
      item.deadlineId,
      false,
      null,
      item.instructionBefore
    );
  }
}
