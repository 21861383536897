import { Injectable } from "@angular/core";
import { Adapter } from "../../adapter/adapter";

export class ERPTRMRenewalDeadline {
  constructor(
    public CaseId: number,
    public ClientReference: string,
    public CalystaReference: string,
    public FamilyNumber: string,
    public Catchword: string,
    public Country: string,
    public GrantDate,
    public GrantNumber: string,
    public HasLogo: Boolean,
    public Classes: string,
    public Deadline: Date,
    public PavisInstructionDeadline: Date,
    public OfficialFee: number,
    public ServiceFee: number,
    public CalystaFee: number,
    public Quote: number,
    public DeadlineId: number,
    public InstructionGiven: boolean,
    public InstructionType: number,
    public InstructionBefore: Date
  ) {}
}

@Injectable({
  providedIn: "root",
})
export class ERPTRMRenewalDeadlineAdapter implements Adapter<ERPTRMRenewalDeadline> {
  adapt(item: any): ERPTRMRenewalDeadline {
    return new ERPTRMRenewalDeadline(
      item.caseId,
      item.clientReference,
      item.calystaReference,
      item.familyNumber,
      item.catchword,
      item.country,
      item.grantDate,
      item.grantNumber,
      item.hasLogo,
      item.classes,
      item.deadline,
      item.pavisInstructionDeadline,
      item.officialFee,
      item.serviceFee,
      item.calystaFee,
      item.quote,
      item.deadlineId,
      false,
      null,
      item.instructionBefore
    );
  }
}
