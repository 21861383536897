import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { Dictionary } from "src/app/shared/models/dictionary.model";
import { User } from "src/app/shared/models/user";
import { UserOrganizationPermissions } from "src/app/shared/models/userorganizationpermissions.model";
import { UserService } from "src/app/shared/services/Authentication/user.service";
import { OrganizationService } from "src/app/shared/services/organization.service";

@Component({
  selector: "app-profile-permission",
  templateUrl: "./profile-permission.component.html",
  styleUrls: ["./profile-permission.component.scss"],
})
export class ProfilePermissionComponent implements OnInit, OnChanges {
  private _orgSvc: OrganizationService;
  private _userSvc: UserService;

  @Input()
  currentUser: User;

  userOrganizationPermissions: UserOrganizationPermissions[] = [];

  organizationDictionary: Dictionary[];
  rolesDictionary: Dictionary[];
  caseMasterDictionary: Dictionary[];

  constructor(orgSvc: OrganizationService, userSvc: UserService) {
    this._orgSvc = orgSvc;
    this._userSvc = userSvc;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.currentUser?.id)
      this._userSvc
        .getUserPermissions(this.currentUser.id)
        .subscribe((x) => (this.userOrganizationPermissions = x));
  }

  ngOnInit(): void {
    this._orgSvc.getOrganizationDictionary().subscribe((dict1: any) => {
      this.organizationDictionary = dict1.data;
    });

    this._orgSvc.getRolesDictionary().subscribe((dict2: any) => {
      this.rolesDictionary = dict2.data;
    });

    this._orgSvc.getCaseMasterDictionary().subscribe((dict3: any) => {
      this.caseMasterDictionary = dict3.data;
    });
  }

  InsertPermission(e) {
    this._userSvc
      .createUserPermission(this.currentUser.id, e.data)
      .subscribe((data: UserOrganizationPermissions) => {});
  }

  UpdatePermission(e) {
    this._userSvc.updateUserPermission(this.currentUser.id, e.data).subscribe();
  }

  RemovePermission(e) {
    this._userSvc
      .deleteUserPermission(this.currentUser.id, e.data)
      .subscribe((data: UserOrganizationPermissions[]) => {});
  }
}
