import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { User } from "../../models/user";
import {
  UserOrganizationPermissions,
  UserOrganizationPermissionsAdapter,
} from "../../models/userorganizationpermissions.model";
import { environment } from "src/environments/environment";
import { Dictionary } from "../../models/dictionary.model";
import { BehaviorSubject, Observable } from "rxjs";
import { ResetPassword } from "../../models/resetPassword.model";
import { map } from "rxjs/operators";

const apiUrl = environment.baseUrl + "/api/accounts";

@Injectable({
  providedIn: "root",
})
export class UserService {
  private currentUser$: BehaviorSubject<User> = new BehaviorSubject<User>(null);

  // When constructed, immediately fetch the UserProfile and put it in cache.
  constructor(
    private http: HttpClient,
    private UserOrganizationPermissionsAdapter: UserOrganizationPermissionsAdapter
  ) {}

  fetchUserProfile() {
    this.http
      .get<User>(apiUrl + "/user")
      .subscribe((x) => this.currentUser$.next(x));
  }

  get CurrentUser(): Observable<User> {
    return this.currentUser$.asObservable();
  }

  getUserProfile() {
    return this.http.get<User>(apiUrl + "/user");
  }

  // Get a list of all users.
  getUsers() {
    return this.http.get<User[]>(apiUrl + "/users");
  }

  // Get one specific user => only your own profile should be obtainable.
  getUser(id: string) {
    return this.http.get<User>(apiUrl + "/user/" + id);
  }

  deleteUser(id: string) {
    return this.http.delete(apiUrl + "/user/" + id);
  }

  disableAccount(id: string) {
    return this.http.put(apiUrl + "/user/" + id + "/disable", null);
  }

  enableAccount(id: string) {
    return this.http.put(apiUrl + "/user/" + id + "/enable", null);
  }

  getUserPermissions(UserId: string) {
    return this.http
      .get<UserOrganizationPermissions>(
        apiUrl + "/user/" + UserId + "/userorganizationpermissions"
      )
      .pipe(
        map((res: any) =>
          res.map((item) => this.UserOrganizationPermissionsAdapter.adapt(item))
        )
      );
  }

  createUserPermission(
    UserId: string,
    Permission: UserOrganizationPermissions
  ) {
    return this.http.post<UserOrganizationPermissions>(
      apiUrl + "/user/" + UserId + "/organizationpermissions",
      Permission
    );
  }

  deleteUserPermission(
    UserId: string,
    Permission: UserOrganizationPermissions
  ) {
    return this.http.delete(
      apiUrl +
        "/user/" +
        UserId +
        "/organizationpermissions/delete/" +
        Permission.UserOrganizationPermissionId
    );
  }

  updateUserPermission(
    UserId: string,
    Permission: UserOrganizationPermissions
  ) {
    return this.http.put(
      apiUrl + "/user/" + UserId + "/organizationpermissions/update",
      Permission
    );
  }

  createNewUser(AnaquaUserId: number) {
    return this.http.post<User[]>(apiUrl + "/Create", AnaquaUserId);
  }

  updateUser(body: any) {
    return this.http.put<User>(apiUrl + "/Update", body);
  }

  getPermissionDictionary() {
    return this.http.get<Dictionary[]>(
      apiUrl + "/user/functionalPermissions/dictionary"
    );
  }
  
  updateUserRoles(userId: string, roles: string[]) {
    return this.http.put(apiUrl + "/user/" + userId + "/roles", roles);
  }
}
