import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { IPAsset } from "src/app/shared/models/ipasset.model";
import { IPAssetTitle } from "src/app/shared/models/IPAssets/ipassetTitle.model copy";
import { IPAssetService } from "src/app/shared/services/IPAsset.service";

@Component({
  selector: "app-pat-general",
  templateUrl: "./pat-general.component.html",
  styleUrls: ["./pat-general.component.scss"],
})
export class PatGeneralComponent implements OnChanges {
  @Input()
  assetData: IPAsset;

  assetTitles: IPAssetTitle[];

  constructor(private ipAssetSvc: IPAssetService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.assetData) {
      this.ipAssetSvc
        .getIPAssetTitle(this.assetData.assetId)
        .subscribe((res: IPAssetTitle[]) => {
          this.assetTitles = res;
        });
    }
  }
}
