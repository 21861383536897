<h2 class="content-block">Document</h2>

<div style="width: 50vw; height: 73vh">
  <pdf-preview [BlobFile]="documentPreview"></pdf-preview>
</div>

<dx-load-panel
  #loadPanel
  shadingColor="rgba(0,0,0,0.4)"
  [position]="{ of: 'body' }"
  [(visible)]="loadingPreview"
  [showIndicator]="true"
  [showPane]="true"
  [shading]="true"
  [closeOnOutsideClick]="false"
/>
