import {
  Directive,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from "@angular/core";
import { Observable, Subscription } from "rxjs";
import { User } from "../models/user";
import { UserService } from "../services/Authentication/user.service";

@Directive({
  selector: "[appIfUserHasRole]",
})
export class IfUserHasRoleDirective implements OnInit {
  private userProfile: Observable<User>;
  private currentUserProfile: User;

  @Input() public appIfUserHasRole: String[];

  constructor(
    private viewContainerRef: ViewContainerRef,
    private templateRef: TemplateRef<any>,
    private userSvc: UserService
  ) {
    this.userSvc.fetchUserProfile();
    this.userProfile = this.userSvc.CurrentUser;
  }

  ngOnInit(): void {
    this.userProfile.subscribe(
      (x) => {
        if (x?.roles.some((x) => this.appIfUserHasRole.includes(x))) {
          this.viewContainerRef.createEmbeddedView(this.templateRef);
        } else {
          this.viewContainerRef.clear();
        }
      },
      () => {},
      () => {}
    );
  }
}
