import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse
} from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ERPSegment, ERPSegmentAdapter } from '../../models/ERP/segment.erp.model';


const apiUrl = environment.baseUrl + '/api/erp/segment';

@Injectable({
  providedIn: 'root'
})
export class ERPSegmentService {
  constructor(
    private http: HttpClient,
    private erpSegmentAdapter: ERPSegmentAdapter
  ) {}

  getContactSegments() {
    return this.http
      .get<ERPSegment[]>(apiUrl + '/contactsegments/')
      .pipe(
        map((res: any) =>
          res.data.map(item => this.erpSegmentAdapter.adapt(item))
        )
      )
      .pipe(catchError(this.handleError));
  }

  createSegment(SegmentLabel: string) {
    return this.http
    .post<ERPSegment[]>(apiUrl + '/create', SegmentLabel)
    .pipe(catchError(this.handleError));
  }

  deleteSegment(SegmentId: number) {
    return this.http
    .delete(apiUrl + '/delete/' + SegmentId)
    .pipe(catchError(this.handleError));
  }

  updateSegment(segment: ERPSegment)
  {
    return this.http
    .post<ERPSegment[]>(apiUrl + '/update/', segment)
    .pipe(catchError(this.handleError));
  }

  updateAssetSegments(AssetId: number, SegmentIds: []) {
    return this.http.post(apiUrl + '/Asset/' + AssetId + '/update', SegmentIds)
                  .pipe();
  }

  // Handle an error.
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }

    // return an observable with a user-facing error message
    return throwError('Something bad happened; please try again later.');
  }
}
