import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { AuthService } from "./Authentication/auth.service";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private authenticationService: AuthService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err) => {
        // auto logout if 401 response returned from api
        // error 401 is an unauthorized error comming in from a webservice.
        if (
          [401, 403].includes(err.status) &&
          this.authenticationService.currentUserValue
        ) {
          this.authenticationService.logOut();
        }

        let error: string;

        if (err.error?.message) {
          error = err.error.message;
        } else if (err.statusText) {
          error = err.statusText;
        } else {
          error = "unknown error";
        }

        console.error(error);
        return throwError(error);
      })
    );
  }
}
