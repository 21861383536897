<h2 class="content-block">Invoices</h2>
<mat-card>
  <mat-card-content>
    <dx-data-grid
      id="invoiceGrid"
      keyExpr="InvoiceHeaderId"
      [dataSource]="DataSource"
      height="82vh"
      [showBorders]="true"
      [columnAutoWidth]="true"
      [allowColumnReordering]="false"
    >
      <dxo-paging [pageSize]="15" />
      <dxo-pager
        [showPageSizeSelector]="true"
        [allowedPageSizes]="[15, 50, 100, 250]"
        [showInfo]="true"
      />
      <dxo-filter-row [visible]="true" />
      <dxi-column
        dataField="InvoiceDate"
        caption="Date"
        dataType="date"
        [width]="150"
        sortOrder="desc"
      />
      <dxi-column dataField="InvoiceNumber" caption="Number" [width]="100" />
      <dxi-column dataField="InvoiceCategory" caption="Category" />
      <dxi-column
        dataField="AmountNonVat"
        caption="Amount (nonVAT)"
        dataType="number"
        format="currency"
        [width]="150"
      />
      <dxi-column
        dataField="VATAmount"
        caption="VAT Amount"
        dataType="number"
        format="currency"
        [width]="150"
      />
      <dxi-column
        dataField="TotalAmount"
        caption="Total Amount"
        dataType="number"
        format="currency"
        alignment="right"
        [width]="150"
      />
      <dxi-column dataField="IsPayed" caption="Is Payed" />
      <dxi-column dataField="HasSubsidy" caption="Has Subsidy" />
      <dxi-column type="buttons" [width]="75" caption="">
        <dxi-button
          hint="Preview Related Document"
          icon="search"
          [onClick]="previewInvoice"
        />
        <dxi-button
          hint="Download Related Document"
          icon="download"
          [onClick]="downloadInvoice"
        />
      </dxi-column>
      <dxo-master-detail [enabled]="true" template="invoiceDetail" />
      <div *dxTemplate="let invoice of 'invoiceDetail'">
        <div class="master-detail-caption">
          {{ invoice.data.InvoiceNumber + "'s Details:" }}
        </div>
        <app-invoiceoverview-detailgrid
          [InvoiceHeaderId]="invoice.data.InvoiceHeaderId"
        />
      </div>
    </dx-data-grid>
  </mat-card-content>
</mat-card>

<dx-popup
  [width]="800"
  [height]="680"
  [showTitle]="true"
  title="Invoice Preview"
  [dragEnabled]="false"
  [closeOnOutsideClick]="true"
  [(visible)]="previewPopUpVisible"
>
  <pdf-preview [BlobFile]="filePreview" />
</dx-popup>
