import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ViewChild,
} from "@angular/core";
import { IPAssetService } from "src/app/shared/services/IPAsset.service";
import { ActivatedRoute, Router } from "@angular/router";
import { Workbook } from "exceljs";
import { saveAs } from "file-saver";
import * as AspNetData from "devextreme-aspnet-data-nojquery";
import { AuthService } from "src/app/shared/services";
import { DxDataGridComponent } from "devextreme-angular";
import { exportDataGrid } from "devextreme/excel_exporter";
import { europeanUnionCountries } from "src/app/shared/utils";
import DataSource from "devextreme/data/data_source";

@Component({
  selector: "app-ipassetoverviewlitegrid",
  templateUrl: "./ipassetoverviewlitegrid.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ["./ipassetoverviewlitegrid.component.scss"],
})
export class IpassetoverviewlitegridComponent implements OnInit {
  @ViewChild(DxDataGridComponent) dataGrid: DxDataGridComponent;
  dataSource: DataSource;
  url: string;
  Logo: string;
  showClosedCases = false;
  grouped = false;
  defaultVisible: boolean;
  popoverTarget: any;
  hoveredkey: number;
  countryParam: string | null;

  constructor(
    private IPAssetSvc: IPAssetService,
    private AuthSvc: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.onDetailsClick = this.onDetailsClick.bind(this);
    this.defaultVisible = false;
  }

  toggleDefault() {
    this.defaultVisible = !this.defaultVisible;
  }
  onDetailsClick(e) {
    this.router.navigate(["/ipasset/" + e.row.data.assetId]);
  }

  ngOnInit() {
    const token = this.AuthSvc.accessTokenValue;

    //get country from url param
    this.countryParam = this.activatedRoute.snapshot.queryParams["country"];

    let filterValue: any[];
    //filter datasource by country
    if (this.countryParam) {
      filterValue = ["countryId", "=", this.countryParam];
      const countryCodes = europeanUnionCountries.map((x) => x.code);
      //if country is in europe take coutryparam + eu code
      if (countryCodes.includes(this.countryParam)) {
        //add country code = EU to the filter array
        filterValue = [
          ["countryId", "=", this.countryParam],
          "or",
          ["countryId", "=", "EU"],
        ];
      }
    }

    this.dataSource = new DataSource({
      store: AspNetData.createStore({
        key: "assetId",
        loadUrl: this.IPAssetSvc.urlGetAll(),
        onBeforeSend(method, ajaxOptions) {
          (ajaxOptions.xhrFields = { withCredentials: true }),
            (ajaxOptions.headers = {
              Authorization: "Bearer " + token,
            });
        },
        loadParams: {
          showClosed: this.showClosedCases,
        },
      }),
      filter: filterValue,
    });
  }

  onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift(
      {
        location: "after",
        widget: "dxButton",
        options: {
          width: 200,
          text: "Group by reference",
          onClick: this.groupByClientReference.bind(this),
        },
      },
      {
        location: "after",
        widget: "dxButton",
        options: {
          width: 200,
          text: "Show Closed Cases",
          onClick: this.collapseAllClick.bind(this),
        },
      },
      {
        location: "after",
        widget: "dxButton",
        options: {
          icon: "refresh",
          onClick: this.refreshDataGrid.bind(this),
        },
      }
    );
  }

  groupByClientReference(e) {
    this.dataGrid.instance.clearGrouping();

    // if already grouped by client reference, remove grouping
    if (this.grouped) {
      this.dataGrid.instance.clearGrouping();
      this.dataGrid.instance.refresh();
      this.grouped = false;
    } else {
      this.dataGrid.instance.columnOption("clientReference", "groupIndex", 0);

      this.grouped = true;
    }

    e.component.option({
      text: this.grouped ? "Ungroup" : "Group by reference",
    });

    this.dataGrid.instance.refresh();
  }

  refreshDataGrid() {
    this.dataGrid.instance.clearFilter();
  }

  collapseAllClick(e) {
    this.showClosedCases = !this.showClosedCases;
    const token = this.AuthSvc.accessTokenValue;

    const newdataSource = AspNetData.createStore({
      key: "assetId",
      loadUrl: this.IPAssetSvc.urlGetAll(),
      onBeforeSend(method, ajaxOptions) {
        (ajaxOptions.xhrFields = { withCredentials: true }),
          (ajaxOptions.headers = {
            Authorization: "Bearer " + token,
          });
      },
      loadParams: {
        showClosed: this.showClosedCases,
      },
    });

    this.dataGrid.dataSource = newdataSource;
    this.dataGrid.instance.getDataSource().reload();

    e.component.option({
      text: this.showClosedCases ? "Hide Closed Cases" : "Show Closed Cases",
    });
  }

  onCellHoverChanged(e) {
    if (
      e.rowType === "data" &&
      e.column.dataField === "hasLogo" &&
      e.eventType === "mouseover"
    ) {
      this.hoveredkey = e.key;
      this.popoverTarget = e.cellElement;
      this.defaultVisible = true;
      // this.popOver.instance.show();
    }

    if (e.rowType === "data" && e.eventType === "mouseout") {
      this.defaultVisible = false;
      // this.popOver.instance.hide();
    }
  }

  onExporting(e) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet("IPAssets");

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(
          new Blob([buffer], { type: "application/octet-stream" }),
          "IPAssetOverview.xlsx"
        );
      });
    });
  }

  calculateClientReference(value) {
    if (value.clientReference === null || value.clientReference === undefined) {
      return "";
    }

    const clientRefUpper = value.clientReference;
    return clientRefUpper;
  }
}
