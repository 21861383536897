<h2 class="content-block">Renewal Instruction History</h2>

<dx-data-grid
  id="gridContainer"
  height="85vh"
  [dataSource]="renewalInstructionData"
  keyExpr="RenewalInstructionLogId"
  [columns]="[
    'GivenOn',
    'UserEmail',
    'CaseTypeLabel',
    'ClientReference',
    'CalystaReference',
    'InstructionLabel',
    'Quote'
  ]"
  [showBorders]="true"
>
</dx-data-grid>
