<h2 class="content-block">Your Segments</h2>
<p>
  If this page is blank, you do not have any segments yet. First of all: What is
  a segment? A segment is a grouping of rights which can be based on product
  lines, geographical area’s, cost centers, …. anyway you organize your
  business. A specific right can be allocated to different segments. At Calysta
  we believe that your rights are a business asset and should be allocated to
  specific business structures as you see fit. We also provide you insights on
  cost and turnover based on these segments so you are able to make the right
  decision regarding your business. If you like to have more information on how
  segments can be implemented, please contact your client responsible.
</p>

<dx-data-grid
  id="segmentGrid"
  keyExpr="segmentId"
  [dataSource]="DataSource"
  height="85vh"
  [showBorders]="true"
  [columnAutoWidth]="true"
  [rowAlternationEnabled]="true"
  [allowColumnReordering]="false"
  (onRowInserted)="InsertSegment($event)"
  (onRowUpdated)="UpdateSegment($event)"
  (onRowRemoved)="RemoveSegment($event)"
>
  <dxo-editing
    mode="row"
    [allowUpdating]="true"
    [allowDeleting]="true"
    [allowAdding]="true"
  >
  </dxo-editing>

  <dxo-paging [pageSize]="15"></dxo-paging>
  <dxo-pager
    [showPageSizeSelector]="true"
    [allowedPageSizes]="[15, 50, 100, 250]"
    [showInfo]="true"
  >
  </dxo-pager>
  <dxo-filter-row [visible]="true"></dxo-filter-row>
  <dxi-column dataField="SegmentLabel" caption="Segment"></dxi-column>
  <dxi-column dataField="NrOfAssets" caption="Involved Assets"></dxi-column>
</dx-data-grid>
