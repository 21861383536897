<div class="tabContent">
    <mat-card>
      <mat-card-header
        ><p style="text-decoration: underline;">
          Detailed Information:
        </p></mat-card-header
      >
        <mat-card-content>
          <dx-form
            id="pat-general-form"
            [colCount]="2"
            [formData]="assetData"
            [readOnly]="true"
          >
            <dxi-item dataField="statusLabel" [label]="{ text: 'Detailed Status' }">
            </dxi-item>
            <dxi-item></dxi-item>
            <dxi-item dataField="applicationNumber" [label]="{ text: 'Filing Number' }"></dxi-item>
            <dxi-item dataField="filedDate" [label]="{ text: 'Filing Date' }" editorType="dxDateBox"></dxi-item>
            <dxi-item dataField="registrationNumber" [label]="{ text: 'Registration Number' }"></dxi-item>
            <dxi-item dataField="registrationDate" [label]="{ text: 'Registration Date' }" editorType="dxDateBox"></dxi-item>
          </dx-form>
        </mat-card-content>
    </mat-card>
  </div>
  
  <div class="tabContent">
    <mat-card>
      <mat-card-header
        ><p style="text-decoration: underline;">
          Class Information:
        </p></mat-card-header
      >
      <mat-card-content>
        <dx-data-grid
          [dataSource]="designDevices"
          [columnMinWidth]="50"
          [columnAutoWidth]="true"
          [rowAlternationEnabled]="true"
          [wordWrapEnabled]="true"
        >
        <dxi-column dataField="FileData" [width]="125" [allowFiltering]="false" [allowSorting]="false" cellTemplate="cellTemplate"></dxi-column>
        <dxi-column dataField="DesignClass" [width]="100" caption="Class" ></dxi-column>
        <dxi-column dataField="DesignSubClass" [width]="100" caption="SubClass"></dxi-column>
        <dxi-column dataField="ViewNumber" [width]="100" caption="View #"></dxi-column>
        <dxi-column dataField="DesignGoodsText"></dxi-column>
        <div *dxTemplate="let data of 'cellTemplate'">
          <img [src]="'data:image/png;base64,' + data.value" alt="Red dot"/>
        </div>
        </dx-data-grid>
      </mat-card-content>
    </mat-card>
  </div>
  