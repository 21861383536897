import { Injectable } from '@angular/core';
import { Adapter } from '../../adapter/adapter';

export class SubscribedOrganization {

    constructor(
        public SubscribedOrganizationId: number,
        public NameId: number,
        public OrganizationName: string,
        public SubscriptionModelId: number,
        public SubscriptionModelLabel: string,
        public LicensePayedOn: Date
        ) {}
}

@Injectable({
    providedIn: 'root'
  })
  export class SubscribedOrganizationAdapter implements Adapter<SubscribedOrganization> {
    adapt(item: any): SubscribedOrganization {
      return new SubscribedOrganization(
            item.subscribedOrganizationId,
            item.nameId,
            item.organizationName,
            item.subscriptionModelId,
            item.subscriptionModelLabel,
            item.licensePayedOn
      );
    }
  }
