import { Component, OnInit } from "@angular/core";
import DataSource from "devextreme/data/data_source";
import { Invoice } from "src/app/shared/models/financial/invoice.model";
import { FinancialService } from "src/app/shared/services/Financial/financial.service";

@Component({
  selector: "app-invoiceoverview",
  templateUrl: "./invoiceoverview.component.html",
  styleUrls: ["./invoiceoverview.component.scss"],
})
export class InvoiceoverviewComponent implements OnInit {
  filePreview: any;
  previewPopUpVisible: any;

  DataArray: Invoice[] = [];

  DataSource: DataSource;

  constructor(private FinancialSvc: FinancialService) {
    this.previewInvoice = this.previewInvoice.bind(this);
    this.downloadInvoice = this.downloadInvoice.bind(this);
  }

  ngOnInit() {
    this.FinancialSvc.getAllInvoices().subscribe((res: Invoice[]) => {
      this.DataSource = new DataSource({
        store: res,
        key: "InvoiceHeaderId",
      });
      this.DataArray = res;
    });
  }

  previewInvoice(e) {
    this.filePreview = null;

    this.FinancialSvc.getInvoicePreview(e.row.data.InvoiceNumber).subscribe(
      (res: any) => {
        this.previewPopUpVisible = true;
        this.filePreview = res;
      }
    );
  }

  downloadInvoice(e) {
    this.FinancialSvc.getInvoicePreview(e.row.data.InvoiceNumber).subscribe(
      (res: any) => {
        const blob = res;
        const fileName = e.row.data.InvoiceNumber + ".pdf";
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(link.href);
      }
    );
  }
}
