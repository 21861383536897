<!-- User Creation PopUp -->
<dx-popup
  class="CreationPopup"
  [width]="300"
  [height]="350"
  [showTitle]="true"
  title="Add New User"
  [dragEnabled]="false"
  [closeOnOutsideClick]="true"
  [(visible)]="addUserPopupVisible"
>
  <div *dxTemplate="let data of 'content'">
    <form (submit)="addUserFormSubmit($event)">
      <dx-form id="form" [(formData)]="newUserId">
        <dxi-item dataField="patriciaId">
          <dxo-label text="Patricia Name Id"> </dxo-label>
        </dxi-item>
        <dxi-item
          itemType="button"
          horizontalAlignment="center"
          [buttonOptions]="buttonOptions"
        >
        </dxi-item>
      </dx-form>
    </form>
  </div>
</dx-popup>

<!-- User Grid -->
<dx-data-grid
  #grid
  [dataSource]="userSrc"
  height="85vh"
  showBorders="true"
  [masterDetail]="{
    enabled: true,
    template: 'detail',
    autoExpandAll: false
  }"
  keyExpr="id"
  (onToolbarPreparing)="onToolbarPreparing($event)"
  (onRowRemoved)="RemoveUser($event)"
  [allowColumnResizing]="true"
  [allowColumnReordering]="true"
>
  <dxo-editing
    mode="row"
    [allowUpdating]="true"
    [allowDeleting]="true"
    [allowAdding]="false"
  ></dxo-editing>
  <dxo-filter-row [visible]="true"></dxo-filter-row>
  <dxo-header-filter [visible]="true"></dxo-header-filter>
  <dxo-scrolling mode="virtual"></dxo-scrolling>
  <dxi-column dataField="anaquaId" caption="Patricia Id"> </dxi-column>
  <dxi-column dataField="fullName" caption="Full Name"> </dxi-column>
  <dxi-column dataField="userName" caption="User Name" [allowEditing]="false">
  </dxi-column>
  <dxi-column dataField="email" caption="email"> </dxi-column>
  <dxi-column dataField="creationDate" dataType="date"> </dxi-column>
  <dxi-column
    dataField="activationEmailSendOn"
    dataType="date"
    [sortingMethod]="customSort"
  >
  </dxi-column>
  <dxi-column
    dataField="lastLoggedIn"
    dataType="datetime"
    [customizeText]="formatDateToLocal"
    [sortingMethod]="customSort"
  >
  </dxi-column>
  <dxi-column dataField="active" caption="Active" [width]="100" />
  <dxi-column type="buttons" [width]="110">
    <dxi-button name="edit"></dxi-button>
    <dxi-button name="delete"></dxi-button>
    <dxi-button
      hint="Send Activation Email"
      icon="message"
      [visible]="isNotActivated"
      [onClick]="sendActivationEmailClick"
    ></dxi-button>
    <dxi-button
      [visible]="isAccountDisabled"
      hint="activate account"
      icon="check"
      [onClick]="enableAccount"
    />
    <dxi-button
      [visible]="isAccountActive"
      hint="deactivate account"
      icon="clear"
      [onClick]="disableAccount"
    />
  </dxi-column>

  <!-- Detail Grid  -->
  <div *dxTemplate="let user of 'detail'">
    <div class="master-detail-caption">
      Configuration for: {{ user.data.userName }}
    </div>
    <div class="master-detail-tabs">
      <mat-tab-group mat-align-tabs="start">
        <mat-tab label="Data Permissions">
          <app-user-permission
            [currentUserId]="user.data.id"
          ></app-user-permission>
        </mat-tab>
        <!-- EO Tab to handle the data permissions. -->
        <mat-tab label="Application Roles">
          <app-user-role [currentUserId]="user.data.id"></app-user-role>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</dx-data-grid>

<!-- Load Panel to show when the user is being activated. -->
<dx-load-panel
  #loadPanel
  shadingColor="rgba(0,0,0,0.4)"
  [position]="{ of: '#grid' }"
  [(visible)]="loadingVisible"
  [showIndicator]="true"
  [showPane]="true"
  [shading]="true"
  [closeOnOutsideClick]="false"
>
</dx-load-panel>

<!-- User Activation Popup. -->
<dx-popup
  class="ActivationPopUp"
  [width]="660"
  [height]="200"
  [showTitle]="true"
  [title]="userActivationPopupTitle"
  [dragEnabled]="false"
  [closeOnOutsideClick]="true"
  [(visible)]="activationPopupVisible"
>
  <div *dxTemplate="let info of 'content'" class="ActivationPopUp">
    <div class="popup-property-details">
      <div class="large-text">
        Activation of user: {{ userBeingActivated.fullName }}
      </div>
      <div class="opacity">
        {{ userActivationResult }}
      </div>
    </div>
  </div>
</dx-popup>
