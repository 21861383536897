import { Component, OnInit } from "@angular/core";
import { ERPRenewalinstructionLog } from "src/app/shared/models/ERP/renewalinstructionlog.erp.model";
import { ERPRenewalDeadlineService } from "src/app/shared/services/ERP/erp.renewaldeadline.service";

@Component({
  selector: "app-renewals-instructionlog",
  templateUrl: "./renewals-instructionlog.component.html",
  styleUrls: ["./renewals-instructionlog.component.scss"],
})
export class RenewalsInstructionlogComponent implements OnInit {
  renewalInstructionData: ERPRenewalinstructionLog[] = [];

  constructor(private renewalSvc: ERPRenewalDeadlineService) {}

  ngOnInit(): void {
    this.renewalSvc
      .getRenewalInstructionLog()
      .subscribe(
        (res: ERPRenewalinstructionLog[]) => (this.renewalInstructionData = res)
      );
  }
}
