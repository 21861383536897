import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import DataSource from "devextreme/data/data_source";
import notify from "devextreme/ui/notify";
import { ERPDocumentService } from "src/app/shared/services/ERP/erp.document.service";

@Component({
  selector: "app-document",
  templateUrl: "./documentview.component.html",
  styleUrls: ["./documentview.component.scss"],
})
export class DocumentViewComponent implements OnInit {
  documentDataSrc: DataSource;

  loadingPreview: boolean = false;

  documentPreview: any;

  constructor(
    private documentSvc: ERPDocumentService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    const documentId = this.route.snapshot.paramMap.get("id");

    this.previewRelatedDocument(documentId);
  }

  previewRelatedDocument(documentId) {
    this.loadingPreview = true;
    this.documentSvc.LoadDocumentPreviewV2(documentId).subscribe({
      next: (res) => {
        this.documentPreview = res;
      },
      error: (err) => {
        this.loadingPreview = false;
        notify("error loading document", "Error", 3000);
      },
      complete: () => {
        this.loadingPreview = false;
      },
    });
  }
}
