import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";

import { AuthService, AppInfoService } from "../../services";
import { MatSnackBar } from "@angular/material/snack-bar";
import { UserService } from "../../services/Authentication/user.service";
import { tfaSetupCode } from "../../models/Authentication/tfasetupcode";
import notify from "devextreme/ui/notify";
import { User } from "../../models/user";

@Component({
  selector: "app-twofactorauth-form",
  templateUrl: "./twofactorauth-form.component.html",
  styleUrls: ["./twofactorauth-form.component.scss"],
})
export class TwoFactorAuthComponent implements OnInit {
  login: string;
  submitted = false;
  twoFactorCode = "";
  twoAuthConfirmation: tfaSetupCode;
  twoFactorAuthResult: boolean = false;

  constructor(
    private authService: AuthService,
    public appInfo: AppInfoService,
    private router: Router,
    private userSvc: UserService,
    private snackbar: MatSnackBar,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.login = this.route.snapshot.queryParams.email;

    if (this.login == null || this.login == undefined) {
      this.router.navigate(["/login"]);
    }

    this.twoAuthConfirmation = {
      AuthenticatorKey: "",
      Code: "",
      Email: "",
      FormattedKey: "",
      IsTwoFactEnable: true,
    };
  }

  onValidateClick(args) {
    this.submitted = true;

    if (!args.validationGroup?.validate().isValid) {
      return;
    }

    this.twoAuthConfirmation.AuthenticatorKey = this.twoFactorCode;

    this.authService.verifyTFACode(this.twoAuthConfirmation).subscribe(
      (res) => (this.twoFactorAuthResult = res),
      () => {
        this.WrongTFACode();
      },
      () => {
        if (this.twoFactorAuthResult) {
          this.authService.twoFAPassedSubject.next(true);
          localStorage.setItem(
            "twoFAPassed",
            this.twoFactorAuthResult.toString()
          );

          this.authService
            .checkIfTermsAndConditionsAccepted()
            .subscribe((res) => {
              if (!res) {
                this.router.navigate(["/termsAndConditions"]);
                return;
              }

              this.userSvc.getUserProfile().subscribe(
                (data: User) => {
                  localStorage.setItem("userRoles", JSON.stringify(data.roles));
                  this.authService.userRolesSubject.next(data.roles);
                },
                () => {},
                () => {
                  this.router.navigate(["/"]);
                  this.snackbar.open(
                    "Welcome @ Calysta Client Portal, enjoy your stay",
                    null,
                    {
                      duration: 3000,
                    }
                  );
                }
              );
            });

          this.router.navigate(["/"]);
        } else {
          this.WrongTFACode();
        }
      }
    );
  }

  WrongTFACode(): void {
    const message = `The 2-Factor authentication code provided is not the right one, please try again.`;
    notify(
      {
        message,
        position: {
          my: "center",
          at: "center",
        },
      },
      "error",
      5000
    );
  }

  TFASuccesfull(): void {
    const message = `2 Factor authentication succesfull.`;
    notify(
      {
        message,
        position: {
          my: "center",
          at: "center",
        },
      },
      "success",
      5000
    );
  }
}
