import { Component, Input, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { Observable } from "rxjs";
import { CostsBySegment } from "src/app/shared/models/financial/costsBySegment.model";

@Component({
  selector: "app-costsbysegmentandratiogrid",
  templateUrl: "./costsbysegmentandratiogrid.component.html",
  styleUrls: ["./costsbysegmentandratiogrid.component.scss"],
})
export class CostsbysegmentandratiogridComponent implements OnInit {
  @Input() gridDataSet: Observable<CostsBySegment[]>;

  gridData: CostsBySegment[];

  private gridDataSubscription: Subscription;

  constructor() {}

  dataSource: any;

  ngOnInit() {
    this.gridDataSubscription = this.gridDataSet.subscribe((res) => {
      this.gridData = res;
    });
  }
}
