import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import DataSource from "devextreme/data/data_source";
import { ERPDeadlineCategory } from "src/app/shared/models/ERP/deadlinecategory.erp.model";
import { ERPDeadlineSummary } from "src/app/shared/models/ERP/deadlinesummary.erp.model";
import { ERPDeadlineService } from "src/app/shared/services/ERP/erp.deadline.service";

@Component({
  selector: "app-deadline-widget",
  templateUrl: "./deadline-widget.component.html",
  styleUrls: ["./deadline-widget.component.scss"],
})
export class DeadlineWidgetComponent implements OnInit {
  DeadlineCategories: ERPDeadlineCategory[];
  DeadlineSummary: ERPDeadlineSummary[];

  AdaptedDeadlineSummary: ERPDeadlineSummary[] = [];

  DataSource: DataSource;

  constructor(
    private DeadlineSvc: ERPDeadlineService,
    private router: Router
  ) {}

  displayedColumns: string[] = [
    "DeadlineCategoryLabel",
    "Count",
    "EarliestDeadline",
  ];

  ngOnInit() {
    this.DeadlineSvc.getDeadlineCategories().subscribe(
      (res: ERPDeadlineCategory[]) => (this.DeadlineCategories = res)
    );

    this.DeadlineSvc.getDeadlineSummary().subscribe(
      (res: ERPDeadlineSummary[]) => (this.DeadlineSummary = res),
      () => {},
      () => {
        this.prepareDataSource();
      }
    );
  }

  ToOverviewClick() {
    this.router.navigate(["/deadlines"]);
  }

  prepareDataSource() {
    if (this.DeadlineCategories) {
      for (let item of this.DeadlineCategories) {
        const newDeadlineCategoryId = item.DeadlineCategoryId;
        const newDeadlineCategoryLabel = item.Label;
        let newCount = 0;
        let newEarliestDeadline;
        const statLine = this.DeadlineSummary.find(
          (x) => x.DeadlineCategoryId === item.DeadlineCategoryId
        );
        if (statLine) {
          newCount = statLine.Count;
          newEarliestDeadline = statLine.EarliestDeadline;
        }

        this.AdaptedDeadlineSummary.push(
          new ERPDeadlineSummary(
            newDeadlineCategoryId,
            newDeadlineCategoryLabel,
            newCount,
            newEarliestDeadline
          )
        );
      }
    }

    this.DataSource = new DataSource({
      store: this.AdaptedDeadlineSummary,
      key: "DeadlineCategoryId",
    });
  }
}
