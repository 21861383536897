import { Component, OnInit } from "@angular/core";
import { IPAssetService } from "src/app/shared/services/IPAsset.service";
import { IPAsset } from "src/app/shared/models/ipasset.model";
import { Observable } from "rxjs";
import { UserService } from "src/app/shared/services/Authentication/user.service";
import { Router } from "@angular/router";

@Component({
  templateUrl: "home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit {
  public IPAssets$: Observable<IPAsset[]>;

  Filtered: boolean;
  TwoFAEnabled: boolean = true;
  ProfileRoute: string;

  constructor(
    private IPAssetSvc: IPAssetService,
    private userSvc: UserService
  ) {
    this.Filtered = false;
    this.IPAssetSvc.loadInitialData();
  }

  ngOnInit(): void {
    this.userSvc
      .getUserProfile()
      .subscribe((x) => (this.TwoFAEnabled = x.twoFactorAuthEnabled));
  }
}
