import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Country, CountryAdapter } from "../../models/country.model";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";

const apiUrl = environment.baseUrl + "/api/common/country";

@Injectable({
  providedIn: "root",
})

/// Country Cache has to dissapear and follow the logic of observable and subject.
export class CountryService {
  countryCache: Country[];

  constructor(private http: HttpClient, private adapter: CountryAdapter) {
    this.getCountries().subscribe((res: Country[]) => {
      this.countryCache = res;
    });
  }

  getCountryLabel(ISO2: string): string {
    if (this.countryCache.find((x) => x.ISO2 === ISO2)) {
      return this.countryCache.find((x) => x.ISO2).label;
    }

    return null;
  }

  getCountries() {
    return this.http
      .get<Country[]>(apiUrl)
      .pipe(
        map((res: any) => res.data.map((item) => this.adapter.adapt(item)))
      );
  }
}
