<mat-card class="header-panel">
  <mat-card-header>
    <div id="form-container">
      <dx-form
        id="form"
        [colCount]="3"
        [formData]="asset"
        (onFieldDataChanged)="onFieldChanged($event)"
      >
        <dxi-item
          dataField="caseType"
          [label]="{ text: 'IP Type' }"
          [editorOptions]="{ disabled: true }"
          cssClass="form-item"
        />
        <dxi-item
          [colSpan]="3"
          dataField="clientReference"
          [label]="{ text: 'Your Reference' }"
          [editorOptions]="{ disabled: !isSilver }"
        />
        <dxi-item
          dataField="country"
          [label]="{ text: 'Country' }"
          [editorOptions]="{ disabled: true }"
        />
        <dxi-item
          dataField="calystaReference"
          [label]="{ text: 'Calysta Reference' }"
          [editorOptions]="{ disabled: true }"
        />
        <dxi-item
          dataField="mappedStatusLabel"
          [label]="{ text: 'Global Status' }"
          [editorOptions]="{ disabled: true }"
        />
        <dxi-item
          dataField="filedDate"
          [label]="{ text: 'Filing Date' }"
          editorType="dxDateBox"
          [editorOptions]="{ disabled: true }"
        />
        <dxi-item
          dataField="catchword"
          [label]="{ text: 'Title' }"
          [editorOptions]="{ disabled: true }"
        />
        <dxi-item
          dataField="segments"
          [label]="{ text: 'Segment' }"
          editorType="dxTagBox"
          [editorOptions]="{
            items: segments,
            searchEnabled: 'true',
            multiline: 'false',
            displayExpr: 'SegmentLabel',
            valueExpr: 'SegmentId'
          }"
        />
      </dx-form>
    </div>
  </mat-card-header>
  <mat-card-content>
    <mat-tab-group>
      <mat-tab label="General">
        <div class="tabContent">
          <div *ngIf="getCaseTypeId() === 2">
            <app-pat-general [assetData]="asset"></app-pat-general>
          </div>
          <div *ngIf="getCaseTypeId() === 3">
            <app-des-general [assetData]="asset"></app-des-general>
          </div>
          <div *ngIf="getCaseTypeId() === 4">
            <app-trm-general [assetData]="asset"></app-trm-general>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Parties">
        <div class="tabContent">
          <app-parties [assetId]="assetId"></app-parties>
        </div>
      </mat-tab>
      <mat-tab label="Deadlines">
        <div class="content">
          <app-ipassetdeadlines
            [assetData]="asset"
            class="tabContent"
          ></app-ipassetdeadlines>
        </div>
      </mat-tab>
      <mat-tab label="Documents">
        <div class="content">
          <app-documents [assetId]="assetId" class="tabContent"></app-documents>
        </div>
      </mat-tab>
      <mat-tab *appIfSubscription="[2, 3]" label="Invoices">
        <div class="content">
          <app-invoices [assetData]="asset" class="tabContent"></app-invoices>
        </div>
      </mat-tab>
    </mat-tab-group>
  </mat-card-content>
</mat-card>
