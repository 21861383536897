<dx-data-grid
  id="deadlineGrid"
  keyExpr="deadlineId"
  [dataSource]="deadlinesData"
  [wordWrapEnabled]="true"
  [rowAlternationEnabled]="true"
  [allowColumnReordering]="true"
  [allowColumnResizing]="true"
>
  <dxi-column dataField="date" caption="Deadline" [width]="125" sortOrder="asc">
  </dxi-column>
  <dxi-column dataField="deadlineCategoryId" caption="Category">
    <dxo-lookup
      [dataSource]="deadlineCategoryDict"
      valueExpr="DeadlineCategoryId"
      displayExpr="Label"
    ></dxo-lookup>
  </dxi-column>
  <dxi-column
    dataField="combinedTextClient"
    caption="Required Action"
  ></dxi-column>
  <dxi-column
    dataField="officialDueDate"
    caption="Official Deadline"
    [visible]="false"
  ></dxi-column>
  <!-- <dxi-column dataField="actionTextClient" caption="Required Action"></dxi-column> -->
  <!-- <dxi-column dataField="statusTextClient" caption="Status"></dxi-column> -->
  <!-- <dxi-column dataField="associatedFee" caption="Estimated Fee"></dxi-column> -->
  <dxi-column type="buttons" [width]="90" caption="Document">
    <dxi-button
      hint="Preview Related Document"
      icon="search"
      [visible]="isDocumentAvailable"
      [onClick]="previewRelatedDocument"
    ></dxi-button>
    <dxi-button
      hint="Download Related Document"
      icon="download"
      [visible]="isDocumentAvailable"
      [onClick]="downloadRelatedDocument"
    ></dxi-button>
  </dxi-column>
  <dxi-column type="buttons" [width]="90" caption="Instruct" [visible]="true">
    <dxi-button
      hint="Give Instruction"
      icon="comment"
      [onClick]="openReplyFrame"
    ></dxi-button>
  </dxi-column>
</dx-data-grid>

<!-- Part to handle the reply for the Action. -->
<dx-popup
  [width]="800"
  [height]="680"
  [showTitle]="true"
  [title]="PopupTitle"
  [dragEnabled]="false"
  [closeOnOutsideClick]="true"
  [(visible)]="PopupVisible"
  [contentTemplate]="currentTemplate"
  (onHiding)="clearInstruction()"
  id="PopupWindow"
>
  <div *dxTemplate="let data of 'pdfpreview'">
    <div class="scroll">
      <pdf-preview [BlobFile]="documentPreview" />
    </div>
    <!-- End of scroll view -->
  </div>

  <div *dxTemplate="let data of 'confirmation'">
    <dx-form id="form-confirmation" [formData]="currentDeadline">
      <dxi-item itemType="group" caption="Deadline">
        <dxi-item
          dataField="actionTextClient"
          [label]="{ text: 'Description' }"
          [editorOptions]="{ disabled: true }"
        >
        </dxi-item>
        <dxi-item
          dataField="statusTextClient"
          [label]="{ text: 'To Do' }"
          [editorOptions]="{ disabled: true }"
        >
        </dxi-item>
      </dxi-item>
      <dxi-item itemType="group" caption="Instruction">
        <dxi-item
          dataField="Instruction"
          editorType="dxSelectBox"
          [editorOptions]="{ items: possibleInstructions, value: '' }"
        >
          <dxi-validation-rule
            type="required"
            message="Instruction is required"
          >
          </dxi-validation-rule>
        </dxi-item>
        <dxi-item
          dataField="Remarks"
          editorType="dxTextArea"
          [editorOptions]="{ placeholder: 'Add additional remarks...' }"
        >
        </dxi-item>
        <dxi-item
          itemType="button"
          horizontalAlignment="right"
          [buttonOptions]="submitButtonOptions"
        >
        </dxi-item>
      </dxi-item>
    </dx-form>
  </div>
  <div *dxTemplate="let data of 'openresponse'" id="InstructionForm">
    <dx-load-panel
      #loadPanel
      shadingColor="rgba(0,0,0,0.1)"
      [position]="{ of: '#InstructionForm' }"
      [(visible)]="loadingVisible"
      [showIndicator]="true"
      [showPane]="true"
      [shading]="true"
      [closeOnOutsideClick]="true"
    >
    </dx-load-panel>
    <dx-scroll-view width="100%" height="100%">
      <form
        (submit)="onFormSubmit($event)"
        enctype="multipart/form-data"
        *ngIf="PopupVisible"
      >
        <dx-form id="form-openresponse" [formData]="deadlineInstruction">
          <dxi-item itemType="group" caption="Deadline">
            <dxi-item
              dataField="Todo"
              [label]="{ text: 'Todo: ' }"
              [editorOptions]="{ disabled: true }"
            >
            </dxi-item>
          </dxi-item>
          <dxi-item itemType="group" caption="Instruction">
            <dxi-item
              dataField="Instruction"
              editorType="dxSelectBox"
              [visible]="deadlineInstruction.OptionsVisible"
              [editorOptions]="{
                items: possibleInstructions,
                valueExpr: 'keyValue',
                displayExpr: 'stringValue'
              }"
            >
            </dxi-item>
            <dxi-item
              dataField="FreeTextInstruction"
              editorType="dxTextArea"
              [visible]="deadlineInstruction.FreeTextVisible"
              [label]="{ text: 'Your Remarks:' }"
              [editorOptions]="{
                placeholder: '(Provide your remarks here)',
                autoResizeEnabled: true
              }"
            >
            </dxi-item>
            <dxi-item>
              <div
                *ngIf="deadlineInstruction.DocumentUploadVisible"
                id="fileuploader-container"
              >
                <dx-file-uploader
                  #fileUploader
                  selectButtonText="Upload Document"
                  labelText=""
                  [(value)]="value"
                  accept="*"
                  multiple="true"
                  uploadMode="useForm"
                  name="files[]"
                >
                </dx-file-uploader>
              </div>
            </dxi-item>
            <dxi-item
              itemType="button"
              horizontalAlignment="right"
              [buttonOptions]="submitButtonOptions"
            >
            </dxi-item>
          </dxi-item>
        </dx-form>
      </form>
    </dx-scroll-view>
  </div>
</dx-popup>
