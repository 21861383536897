import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from "@angular/common/http";
import { ERPDocument } from "../../models/ERP/document.erp.model";
import { BehaviorSubject, Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { environment } from "src/environments/environment";

const apiUrl = environment.baseUrl + "/api/erp/document";

@Injectable({
  providedIn: "root",
})
export class ERPDocumentService {
  private DocumentPreviewObs$: BehaviorSubject<String> = new BehaviorSubject(
    ""
  );

  constructor(private http: HttpClient) {}

  getCaseDocuments(caseId: number) {
    return this.http.get<ERPDocument[]>(apiUrl + "/case/" + caseId);
  }

  getDocumentOverview() {
    return this.http.get<ERPDocument[]>(apiUrl + "/overview");
  }

  LoadDocumentPreviewV2(documentId: number) {
    return this.http
      .get(apiUrl + "/preview/" + documentId, { responseType: "blob" })
      .pipe(catchError(this.handleError));
  }

  getDocumentPreview(): Observable<String> {
    return this.DocumentPreviewObs$.asObservable();
  }

  getCaseDocumentsUrl(caseId: number) {
    return apiUrl + "/case/" + caseId;
  }

  getDocumentCategoriesUrl() {
    return apiUrl + "/categories";
  }

  getDocumentSubCategoriesUrl() {
    return apiUrl + "/subcategories";
  }

  getDocument(documentId: number) {
    return this.http
      .get(apiUrl + "/" + documentId, { responseType: "blob" })
      .pipe(catchError(this.handleError));
  }

  getManyDocuments(documentids: number[]) {
    return this.http
      .post(apiUrl + "/downloadMultipeFiles", documentids, {
        responseType: "blob",
      })
      .pipe(catchError(this.handleError));
  }

  deleteDocument(documentId: number) {
    return this.http.delete(apiUrl + "/delete/" + documentId);
  }

  uploadDocument(CaseId: number, body: any) {
    return this.http
      .post<Document>(apiUrl + "/case/" + CaseId + "/upload", body)
      .pipe(catchError(this.handleError));
  }

  updateDocument(document: ERPDocument) {
    return this.http
      .put(apiUrl + "/update", document)
      .pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error("An error occurred:", error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    // return an observable with a user-facing error message
    return throwError("Something bad happened; please try again later.");
  }
}
