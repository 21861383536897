import { Injectable } from '@angular/core';
import { Adapter } from '../adapter/adapter';

export class Dictionary {
    constructor(
    public keyValue: number,
    public stringValue: string,
    ) {}
}

@Injectable({
    providedIn: 'root'
  })
  export class DictionaryAdapter implements Adapter<Dictionary> {
    adapt(item: any): Dictionary {
      return new Dictionary (
        item.key,
        item.value,
      );
    }
  }

