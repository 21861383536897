<mat-card>
  <mat-card-header>
    <div *ngIf="currentUser != null">
      <h2>Profile {{ currentUser.fullName }}</h2>
    </div>
  </mat-card-header>
  <mat-card-content>
    <div>
      <mat-tab-group>
        <mat-tab label="General">
          <div id="form-container">
            <dx-form id="form" [formData]="currentUser" [colCount]="1">
              <dxi-item dataField="fullName" [disabled]="true" />
              <dxi-item dataField="email" />
              <dxi-item
                id="switch"
                dataField="confirmationMailOnInstructionEnabled"
                caption="Enable mail confirmation on instruction"
                editorType="dxSwitch"
                [disabled]="isSilver()"
              >
                <dxo-label
                  text="Enable mail confirmation on sending instruction"
                />
              </dxi-item>
              <!--<dxi-item dataField="activationDate"></dxi-item>-->
              <!--update button-->
              <dxi-item>
                <div class="dx-field-value">
                  <dx-button
                    class="button"
                    text="Update"
                    (onClick)="updateProfile()"
                  />
                </div>
              </dxi-item>
            </dx-form>
          </div>
          <!-- Update your own permissions if you belong to the right group. -->
          <div
            *ngIf="
              this.currentUser != undefined &&
              this.currentUser.userOrganizationPermissions != null &&
              this.currentUser.userOrganizationPermissions != undefined
            "
          >
            <!--todo: issue, it sometimes duplicate this component-->
            <app-profile-permission
              [currentUser]="this.currentUser"
              *appIfUserHasRole="['Employee']"
            />
          </div>
        </mat-tab>
        <mat-tab label="2 Factor Authentication">
          <div>
            <app-profile-twofactorauth />
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </mat-card-content>
</mat-card>
