import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  Pipe,
  PipeTransform,
  ViewChild,
} from "@angular/core";
import { Router } from "@angular/router";
import { DxDataGridComponent } from "devextreme-angular";
import { ERPRenewalDeadline } from "src/app/shared/models/ERP/renewaldeadline.erp.model";
import { Observable, Subscription } from "rxjs";
import { RenewalInstruction } from "../renewals.component";
import { RenewalInstructionSet } from "src/app/shared/models/Renewal/RenewalInstruction.model";

@Component({
  selector: "app-renewal-patents",
  templateUrl: "./renewal-patents.component.html",
  styleUrls: ["./renewal-patents.component.scss"],
})
export class RenewalPatentsComponent implements OnInit, OnDestroy {
  private eventsSubscription: Subscription;

  @Input() events: Observable<void>;
  @Input() patentRenewalData: Observable<ERPRenewalDeadline[]>;
  @Input() patentRenewalInstructionSet: RenewalInstructionSet;

  selectedRows: number[] = [];

  confirmationPopupVisible: boolean;

  @ViewChild(DxDataGridComponent) dataGrid: DxDataGridComponent;

  constructor(private router: Router) {
    const that = this;
    this.onRenewalClick = this.onRenewalClick.bind(this);
    this.onRequestAdviceClick = this.onRequestAdviceClick.bind(this);
    this.onAbandonClick = this.onAbandonClick.bind(this);
    this.onRevertClick = this.onRevertClick.bind(this);
    this.onDetailsClick = this.onDetailsClick.bind(this);
    this.onRenewalGroupClick = this.onRenewalGroupClick.bind(this);
  }

  ngOnInit(): void {
    this.selectedRows = [];
    this.eventsSubscription = this.events.subscribe(() =>
      this.revertAllInstructions()
    );
  }

  onContentReady(e) {
    e.component.option("loadPanel.enabled", false);
  }

  TransformIntoFormData(instructions: RenewalInstruction[]): FormData {
    const formData: FormData = new FormData();
    for (const i in instructions)
      formData.append(
        "PatentRenewalInstructions[${i}]",
        JSON.stringify(instructions[i])
      );

    return formData;
  }

  onDetailsClick(e) {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(["/ipasset/" + e.row.data.CaseId], {})
    );
    window.open(url, "_blank");
  }

  onRenewalClick(e) {
    e.row.data.InstructionGiven = true;

    this.selectedRows.push(e.row.data.CaseId);

    let newRenInstruction: RenewalInstruction = {
      InstructionId: 1,
      CaseType: "Patent",
      InstructionType: "Renew",
      CaseId: e.row.data.CaseId,
      OfficialFee: e.row.data.OfficialFee,
      ServiceFee: e.row.data.ServiceFee,
      CalystaFee: e.row.data.CalystaFee,
      Amount: e.row.data.Quote,
      CatchWord: e.row.data.Catchword,
      Country: e.row.data.Country,
      Reference: e.row.data.ClientReference,
      DeadlineId: e.row.data.DeadlineId,
    };

    this.patentRenewalInstructionSet.addRenewalInstruction(newRenInstruction);
    this.dataGrid.instance.repaintRows([e.row.rowIndex]);
  }

  onRequestAdviceClick(e) {
    e.row.data.InstructionGiven = true;
    this.selectedRows.push(e.row.data.CaseId);

    let newRenInstruction: RenewalInstruction = {
      InstructionId: 3,
      InstructionType: "Verification",
      CaseId: e.row.data.CaseId,
      CaseType: "Patent",
      OfficialFee: 0,
      ServiceFee: 0,
      CalystaFee: 0,
      Amount: 0,
      CatchWord: e.row.data.Catchword,
      Country: e.row.data.Country,
      Reference: e.row.data.ClientReference,
      DeadlineId: e.row.data.DeadlineId,
    };

    this.patentRenewalInstructionSet.addRenewalInstruction(newRenInstruction);
    this.dataGrid.instance.repaintRows([e.row.rowIndex]);
  }

  onAbandonClick(e) {
    e.row.data.InstructionGiven = true;
    this.selectedRows.push(e.row.data.CaseId);

    let newRenInstruction: RenewalInstruction = {
      InstructionId: 2,
      InstructionType: "Abandon",
      CaseType: "Patent",
      CaseId: e.row.data.CaseId,
      OfficialFee: 0,
      ServiceFee: 0,
      CalystaFee: 0,
      Amount: 0,
      CatchWord: e.row.data.Catchword,
      Country: e.row.data.Country,
      Reference: e.row.data.ClientReference,
      DeadlineId: e.row.data.DeadlineId,
    };

    this.patentRenewalInstructionSet.addRenewalInstruction(newRenInstruction);
    this.dataGrid.instance.repaintRows([e.row.rowIndex]);
  }

  onRenewalGroupClick(data) {
    data.data.items.forEach((item: ERPRenewalDeadline) => {
      if (item.InstructionGiven != true) {
        let newRenInstruction: RenewalInstruction = {
          InstructionId: 1,
          InstructionType: "Renew",
          CaseType: "Patent",
          OfficialFee: item.OfficialFee,
          ServiceFee: item.ServiceFee,
          CalystaFee: item.CalystaFee,
          CaseId: item.CaseId,
          Amount: item.Quote,
          CatchWord: item.Catchword,
          Country: item.Country,
          Reference: item.ClientReference,
          DeadlineId: item.DeadlineId,
        };

        item.InstructionGiven = true;

        this.patentRenewalInstructionSet.addRenewalInstruction(
          newRenInstruction
        );
        this.dataGrid.instance.repaint();
      }
    });
  }

  revertAllInstructions() {
    if (this.dataGrid) {
      this.dataGrid.instance.repaint();
    }
  }

  onRevertClick(e) {
    e.row.data.InstructionGiven = false;

    this.patentRenewalInstructionSet.removeRenewalInstruction(
      e.row.data.CaseId
    );
    this.dataGrid.instance.repaintRows([e.row.rowIndex]);
  }

  /// If an Instruction is given, then hide the columns so no additional instruction can be given.
  isInstructionGiven(e) {
    if (e.row.data.InstructionGiven === false) {
      return true;
    }
    return false;
  }

  revertVisible(e) {
    if (e.row.data.InstructionGiven === true) {
      return true;
    }
    return false;
  }

  onConfirmationClick() {
    this.confirmationPopupVisible = true;
  }

  ngOnDestroy(): void {
    this.eventsSubscription.unsubscribe();
  }

  onRowPrepared(e: any) {
    if (e.rowType === "data") {
      const dueDate = e.data.PavisInstructionDeadline;

      /*if due date is less than 60 days, highlight the row in yellow,
      if due date is less than 30 days, highlight the row in orange,
      if it is less than 15 days, highlight the row in red*/
      if (dueDate) {
        const dueDateInMilliseconds = new Date(dueDate).getTime();
        const currentDateInMilliseconds = new Date().getTime();
        const differenceInMilliseconds =
          dueDateInMilliseconds - currentDateInMilliseconds;
        const differenceInDays =
          differenceInMilliseconds / (1000 * 60 * 60 * 24);

        if (differenceInDays <= 60) {
          e.rowElement.style.backgroundColor = "yellow";
        }

        if (differenceInDays <= 30) {
          e.rowElement.style.backgroundColor = "orange";
        }

        if (differenceInDays <= 15) {
          e.rowElement.style.backgroundColor = "red";
        }

        //if the due date has passed, highlight the row in dark red
        if (differenceInDays < 0) {
          e.rowElement.style.backgroundColor = "darkred";
        }
      }
    }
  }
}

@Pipe({ name: "stringifyPatentRenewals" })
export class StringifyPatentRenewals implements PipeTransform {
  transform(employees: ERPRenewalDeadline[]) {
    return employees
      .map((employee) => `${employee.ClientReference} ${employee.Catchword}`)
      .join(", ");
  }
}
