import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { Dictionary } from "src/app/shared/models/dictionary.model";
import { UserOrganizationPermissions } from "src/app/shared/models/userorganizationpermissions.model";
import { UserService } from "src/app/shared/services/Authentication/user.service";
import { OrganizationService } from "src/app/shared/services/organization.service";

@Component({
  selector: "app-user-permission",
  templateUrl: "./user-permission.component.html",
  styleUrls: ["./user-permission.component.scss"],
})
export class UserPermissionComponent implements OnChanges {
  @Input()
  currentUserId: string;

  userOrganizationPermissions: UserOrganizationPermissions[];

  organizationDictionary: Dictionary[];
  rolesDictionary: Dictionary[];
  caseMasterDictionary: Dictionary[];
  instructionTypeDictionary: Dictionary[];
  permissionDictionary: Dictionary[];

  constructor(
    private userSvc: UserService,
    private orgSvc: OrganizationService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    this.userSvc
      .getUserPermissions(this.currentUserId)
      .subscribe((res: UserOrganizationPermissions[]) => {
        this.userOrganizationPermissions = res;
      });

    this.orgSvc
      .getSubscribedOrganizationDictionary()
      .subscribe((dict1: any) => {
        this.organizationDictionary = dict1.data;
      });

    this.orgSvc.getRolesDictionary().subscribe((dict2: any) => {
      this.rolesDictionary = dict2.data;
    });

    this.orgSvc.getCaseMasterDictionary().subscribe((dict3: any) => {
      this.caseMasterDictionary = dict3.data;
    });

    this.orgSvc.getInstructionCatDictionary().subscribe((dict4: any) => {
      this.instructionTypeDictionary = dict4.data;
    });

    this.userSvc.getPermissionDictionary().subscribe((dict5: any) => {
      this.permissionDictionary = dict5.data;
    });
  }

  InsertPermission(e, userId: string) {
    this.userSvc
      .createUserPermission(userId, e.data)
      .subscribe((data: UserOrganizationPermissions) => {});
  }

  UpdatePermission(e, userId: string) {
    this.userSvc.updateUserPermission(userId, e.data).subscribe();
  }

  RemovePermission(e, userId: string) {
    this.userSvc
      .deleteUserPermission(userId, e.data)
      .subscribe((data: UserOrganizationPermissions[]) => {});
  }
}
