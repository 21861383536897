import { Component, OnInit, ViewChild } from "@angular/core";
import { DxChartComponent } from "devextreme-angular";
import * as AspNetData from "devextreme-aspnet-data-nojquery";
import { AuthService } from "src/app/shared/services";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-ip-filing-widget",
  templateUrl: "./ip-filing-widget.component.html",
  styleUrls: ["./ip-filing-widget.component.scss"],
})
export class IpFilingWidgetComponent implements OnInit {
  DataSource: any;
  @ViewChild(DxChartComponent) filingChart: DxChartComponent;

  apiUrl: string = environment.baseUrl + "/api";

  constructor(private authenticationService: AuthService) {}

  ngOnInit(): void {
    var token = this.authenticationService.accessTokenValue;
    var authtoken = "Bearer " + token;

    var requestheaders = new Headers();
    requestheaders.append("Authorization", "Bearer " + token);

    this.DataSource = AspNetData.createStore({
      key: "year",
      loadUrl: this.apiUrl + "/clientportal/dashboard/filingstats",
      onBeforeSend(method, ajaxOptions) {
        ajaxOptions.headers = {
          Authorization: authtoken,
        };
      },
    });
  }
}
