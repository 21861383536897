import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { SubscriptionTypeService } from '../services/subscription.service';

@Directive({
  selector: '[appIfSubscription]'
})
export class IfSubscriptionDirective implements OnInit, OnDestroy {

  private subscription: Subscription[] = [];

  @Input() public appIfSubscription: number[];

  constructor(
    private viewContainerRef: ViewContainerRef,
    private templateRef: TemplateRef<any>,
    private subscriptionService: SubscriptionTypeService
  ) { }

  ngOnInit(): void {
    this.subscription.push(
      this.subscriptionService.getUserSubscriptionModel().subscribe(res => {
        if (!res) {
          // Remove element from DOM
          this.viewContainerRef.clear();
        }
        // If the user has the required role, show the component in the DOM.
        if (this.appIfSubscription.includes(res)) {
          this.viewContainerRef.createEmbeddedView(this.templateRef);
        // Otherwise don't show the required component.
        } else {
          this.viewContainerRef.clear();
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

}
