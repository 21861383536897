<div class="tabContent">
    <mat-card>
      <mat-card-header
        ><p style="text-decoration: underline;">
          Detailed Information:
        </p></mat-card-header
      >
        <mat-card-content>
          <dx-form
            id="des-general-form"
            [colCount]="2"
            [formData]="assetData"
            [readOnly]="true"
          >
            <dxi-item dataField="statusLabel" [label]="{ text: 'Detailed Status' }">
            </dxi-item>
            <dxi-item></dxi-item>
            <dxi-item dataField="applicationNumber" [label]="{ text: 'Filing Number' }"></dxi-item>
            <dxi-item dataField="filedDate" [label]="{ text: 'Filing Date' }" editorType="dxDateBox"></dxi-item>
            <dxi-item dataField="registrationNumber" [label]="{ text: 'Registration Number' }"></dxi-item>
            <dxi-item dataField="registrationDate" [label]="{ text: 'Registration Date' }" editorType="dxDateBox"></dxi-item>
            <dxi-item dataField="publicationNumber" [label]="{ text: 'Publication Number' }"></dxi-item>
            <dxi-item dataField="publicationDate" [label]="{ text: 'Publication Date' }" editorType="dxDateBox"></dxi-item>
          </dx-form>
        </mat-card-content>
    </mat-card>
  </div>
  
  <div class="tabContent">
    <mat-card>
      <mat-card-header
        ><p style="text-decoration: underline;">
          Title(s):
        </p></mat-card-header
      >
      <mat-card-content>
        <dx-data-grid
          [dataSource]="assetTitles"
          [columnMinWidth]="50"
          [columnAutoWidth]="true"
          [rowAlternationEnabled]="true"
          [wordWrapEnabled]="true"
        >
          <dxi-column dataField="Language" [width]="125" sortOrder="asc"></dxi-column>
          <dxi-column dataField="Title"></dxi-column>
        </dx-data-grid>
      </mat-card-content>
    </mat-card>
  </div>
  