<mat-card #pieChart>
  <dx-data-grid
    id="costbysegmentGrid"
    [dataSource]="gridData"
    [showBorders]="true"
    height="75vh"
  >
    <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
    <dxi-column
      dataField="SegmentLabel"
      caption="Segment"
      [sortIndex]="1"
      [groupIndex]="0"
      groupCellTemplate="groupCellTemplate"
    ></dxi-column>
    <dxi-column dataField="Year" caption="Year"></dxi-column>
    <dxi-column
      dataField="AgentFees"
      caption="Agent Costs"
      format="currency"
    ></dxi-column>
    <dxi-column
      dataField="OfficialFees"
      caption="Official Fees"
      format="currency"
    ></dxi-column>
    <dxi-column
      dataField="CalystaFees"
      caption="Calysta Fees"
      format="currency"
    ></dxi-column>
    <dxi-column
      dataField="TotalFees"
      caption="Total Costs"
      format="currency"
    ></dxi-column>
    <dxo-summary>
      <dxi-group-item
        column="TotalFees"
        summaryType="sum"
        valueFormat="currency"
        displayFormat="Total: {0}"
        [alignByColumn]="true"
        [showInGroupFooter]="false"
      >
      </dxi-group-item>
    </dxo-summary>

    <div *dxTemplate="let data of 'groupCellTemplate'">
      {{ data.key }}
    </div>
  </dx-data-grid>
</mat-card>
