import { Component, OnInit } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import { ERPSegment } from 'src/app/shared/models/ERP/segment.erp.model';
import { ERPSegmentService } from 'src/app/shared/services/ERP/erp.segment.service';

@Component({
  selector: 'app-segments',
  templateUrl: './segments.component.html',
  styleUrls: ['./segments.component.scss']
})
export class SegmentsComponent implements OnInit {

  DataSource: DataSource;

  constructor(private segmentService: ERPSegmentService) { }

  ngOnInit() {
    this.segmentService.getContactSegments().subscribe((res: ERPSegment[]) => {
      this.DataSource = new DataSource({
        store: res,
        key: 'SegmentId'
      });
  });

  }

  InsertSegment(e) {
    this.segmentService.createSegment(e.data.SegmentLabel).subscribe();
  }

  UpdateSegment(e) {
    this.segmentService.updateSegment(new ERPSegment(0, e.data.SegmentId, e.data.SegmentLabel, 0)).subscribe();
  }

  RemoveSegment(e) {
    this.segmentService.deleteSegment(e.data.SegmentId).subscribe();
  }
}
