import { Injectable } from "@angular/core";
import { IPService, IpServiceAdapter } from "../models/ipservice.model";
import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from "@angular/common/http";
import { throwError } from "rxjs";
import { environment } from "../../../environments/environment";
import { catchError, map } from "rxjs/operators";

const apiUrl = environment.baseUrl + "/api/erp/ipservice/";

@Injectable({
  providedIn: "root",
})
export class IPServiceService {
  constructor(
    private httpClient: HttpClient,
    private ipServiceAdapter: IpServiceAdapter
  ) {}

  urlGetAll() {
    return apiUrl + "all";
  }

  getAllServices(showClosedCases: boolean) {
    return this.httpClient
      .get<IPService[]>(apiUrl + "all", {
        params: { showClosed: String(showClosedCases) },
      })
      .pipe(
        map((res: any) =>
          res.data.map((item) => this.ipServiceAdapter.adapt(item))
        )
      )
      .pipe(catchError(this.handleError));
  }

  getServiceDetails(assetId: number) {
    return this.httpClient
      .get<IPService>(apiUrl + assetId)
      .pipe(map((res: any) => this.ipServiceAdapter.adapt(res)));
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error("An error occurred:", error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    // return an observable with a user-facing error message
    return throwError("Something bad happened; please try again later.");
  }
}
