import { Injectable } from "@angular/core";
import { Adapter } from "../../adapter/adapter";

export class  tfaSetupCode {
  constructor(
    public Email: string,
    public Code: string,
    public IsTwoFactEnable: Boolean,
    public AuthenticatorKey: string,
    public FormattedKey: string,
) {}
}

@Injectable({
  providedIn: "root",
})
export class TFASetupCodeAdapter implements Adapter<tfaSetupCode> {
  adapt(item: any): tfaSetupCode {
    return new tfaSetupCode(
      item.email,
      item.code,
      item.isTwoFactEnabled,
      item.authenticatorKey,
      item.formattedKey,
    );
  }
}
