<dx-data-grid
  id="gridContainer"
  keyExpr="assetId"
  [dataSource]="dataSource"
  [showBorders]="true"
  height="100vh"
  [rowAlternationEnabled]="true"
  [allowColumnReordering]="true"
  (onCellHoverChanged)="onCellHoverChanged($event)"
  (onToolbarPreparing)="onToolbarPreparing($event)"
  (onExporting)="onExporting($event)"
  [height]="'85vh'"
>
  <dxo-paging [pageSize]="10"></dxo-paging>
  <dxo-grouping [autoExpandAll]="false" [allowCollapsing]="true" />
  <dxo-selection mode="multiple" />

  <dxo-pager
    [showPageSizeSelector]="true"
    [allowedPageSizes]="[10, 25, 50, 100]"
    [showInfo]="true"
  >
  </dxo-pager>

  <dxo-column-chooser [enabled]="true" mode="select"></dxo-column-chooser>

  <dxo-search-panel
    [visible]="true"
    [width]="240"
    placeholder="Search for anything..."
  >
  </dxo-search-panel>

  <dxo-filter-row [visible]="true"></dxo-filter-row>
  <dxi-column
    [showInColumnChooser]="false"
    type="buttons"
    [fixed]="true"
    [allowReordering]="false"
    [allowFixing]="true"
    fixedPosition="left"
    [width]="50"
  >
    <dxi-button hint="View details" icon="info" [onClick]="onDetailsClick">
    </dxi-button>
  </dxi-column>
  <dxi-column
    dataField="clientReference"
    [width]="200"
    caption="Your Reference"
    [fixed]="true"
    [allowFixing]="true"
    [calculateGroupValue]="calculateClientReference"
  ></dxi-column>
  <dxi-column
    dataField="calystaReference"
    [width]="200"
    caption="Calysta Reference"
    [fixed]="true"
    [allowFixing]="true"
  ></dxi-column>
  <dxi-column
    dataField="hasLogo"
    [width]="60"
    dataType="text"
    caption="Logo"
    cellTemplate="logoCellTemplate"
  ></dxi-column>
  <dxi-column
    dataField="catchword"
    [width]="350"
    caption="Catchword"
    [allowFixing]="true"
  ></dxi-column>
  <dxi-column dataField="country" [width]="200" caption="Country"></dxi-column>
  <dxi-column
    dataField="mappedStatusLabel"
    [width]="200"
    caption="Status"
  ></dxi-column>
  <dxi-column dataField="classes" caption="Classes"></dxi-column>

  <div *dxTemplate="let d of 'logoCellTemplate'">
    <ng-container *ngIf="d.data.hasLogo; else elseTemplate">
      <i class="dx-icon-image"></i>
    </ng-container>
    <ng-template #elseTemplate> </ng-template>
  </div>
  <dxo-export [enabled]="true" [allowExportSelectedData]="true"></dxo-export>
</dx-data-grid>

<dx-popover
  [(target)]="popoverTarget"
  [width]="300"
  [(visible)]="defaultVisible"
>
  <div *dxTemplate="let data of 'content'">
    <div>
      <app-ipassetpicture [assetId]="hoveredkey"></app-ipassetpicture>
    </div>
  </div>
</dx-popover>
