<div>
    <div>
        <p
          style="
            margin-bottom: 6pt;
            text-align: center;
            line-height: normal;
            widows: 0;
            orphans: 0;
            font-size: 14pt;
          "
        >
          <span
            style="
              font-family: 'Century Gothic';
              font-weight: bold;
              font-variant: small-caps;
            "
            >General Terms and conditions</span
          >
        </p>
        <p
          style="
            margin-bottom: 6pt;
            text-align: justify;
            line-height: normal;
            widows: 0;
            orphans: 0;
          "
        >
          <span style="font-family: 'Century Gothic'; -aw-import: ignore"
            >&#xa0;</span
          >
        </p>
        <ol type="1" style="margin: 0pt; padding-left: 0pt">
          <li
            style="
              margin-left: 14.17pt;
              margin-bottom: 6pt;
              text-align: justify;
              line-height: normal;
              padding-left: 7.13pt;
              font-family: 'Century Gothic';
              font-weight: bold;
            "
          >
            <a name="co_anchor_a505889_22"></a><span>The Calysta approach</span>
          </li>
        </ol>
        <p
          style="
            margin-left: 21.3pt;
            margin-bottom: 6pt;
            text-align: justify;
            line-height: normal;
          "
        >
          <span style="font-family: 'Century Gothic'"
            >The general terms and conditions hereunder (“</span
          ><span
            style="font-family: 'Century Gothic'; font-weight: bold; color: #ff642c"
            >Conditions</span
          ><span style="font-family: 'Century Gothic'"
            >”) have been drafted so that they are kept </span
          ><span
            style="font-family: 'Century Gothic'; font-weight: bold; color: #ff642c"
            >simple</span
          ><span style="font-family: 'Century Gothic'; color: #ff642c">, </span
          ><span
            style="font-family: 'Century Gothic'; font-weight: bold; color: #ff642c"
            >clear</span
          ><span style="font-family: 'Century Gothic'; color: #ff642c"> </span
          ><span style="font-family: 'Century Gothic'"> and </span
          ><span
            style="font-family: 'Century Gothic'; font-weight: bold; color: #ff642c"
            >user-friendly</span
          ><span style="font-family: 'Century Gothic'">. </span
          ><span
            style="font-family: 'Century Gothic'; font-weight: bold; color: #ff642c"
            >Plain language</span
          ><span style="font-family: 'Century Gothic'; color: #ff642c"> </span
          ><span
            style="font-family: 'Century Gothic'; font-weight: bold; color: #ff642c"
            > is used</span
          ><span style="font-family: 'Century Gothic'; color: #ff642c"> </span
          ><span style="font-family: 'Century Gothic'"
            > wherever possible. These Conditions aim at: (i)</span
          ><span style="font-family: 'Century Gothic'; -aw-import: spaces"
            >&#xa0; </span
          ><span style="font-family: 'Century Gothic'"
            >having clarity around the relationship between Calysta and any party
            making use of Calysta’s services and (ii) </span
          ><span
            style="font-family: 'Century Gothic'; font-weight: bold; color: #ff642c"
            >preventing disputes</span
          ><span style="font-family: 'Century Gothic'">.</span>
        </p>
        <p
          style="
            margin-left: 21.3pt;
            margin-bottom: 6pt;
            text-align: justify;
            line-height: normal;
          "
        >
          <span style="font-family: 'Century Gothic'"
            >These Conditions will apply and you agree to be bound by them whenever
            Calysta renders services to you or has any relationship with you. By the
            simple fact of you making use of Calysta’s services, you agree
            that</span
          ><span style="font-family: 'Century Gothic'; -aw-import: spaces"
            >&#xa0; </span
          ><span style="font-family: 'Century Gothic'">these Conditions are </span
          ><span
            style="font-family: 'Century Gothic'; font-weight: bold; color: #ff642c"
            >binding and enforceable</span
          ><span style="font-family: 'Century Gothic'">.</span>
        </p>
        <p
          style="
            margin-left: 21.3pt;
            margin-bottom: 6pt;
            text-align: justify;
            line-height: normal;
          "
        >
          <span style="font-family: 'Century Gothic'"
            >Calysta and any party bound by these Conditions, agree to apply them in </span
          ><span
            style="font-family: 'Century Gothic'; font-weight: bold; color: #ff642c"
            >good faith</span
          ><span style="font-family: 'Century Gothic'">
            and to the exclusion of any other terms.</span
          >
        </p>
        <p
          style="
            margin-left: 21.3pt;
            margin-bottom: 6pt;
            text-align: justify;
            line-height: normal;
          "
        >
          <a name="co_anchor_a317670_1"></a
          ><a name="co_anchor_a883113_1"
            ><span style="-aw-bookmark-end: co_anchor_a317670_1"></span></a
          ><span
            style="
              font-family: 'Century Gothic';
              font-weight: bold;
              -aw-import: ignore;
            "
            >&#xa0;</span
          >
        </p>
        <ol start="2" type="1" style="margin: 0pt; padding-left: 0pt">
          <li
            style="
              margin-left: 14.17pt;
              margin-bottom: 6pt;
              text-align: justify;
              line-height: normal;
              padding-left: 7.13pt;
              font-family: 'Century Gothic';
              font-weight: bold;
            "
          >
            <span>What are Calysta’s obligations?</span>
          </li>
        </ol>
        <ol type="a" style="margin: 0pt; padding-left: 0pt">
          <li
            class="ListParagraph"
            style="
              margin-left: 14.17pt;
              margin-bottom: 6pt;
              text-align: justify;
              line-height: normal;
              padding-left: 7.13pt;
              font-family: 'Century Gothic';
            "
          >
            <span
              >Calysta NV/SA, a limited company under Belgian law with register
              number BE</span
            ><span>&#xa0;</span
            ><span
              >0686.655.773 and registered office at 5A Lambroekstraat, 1831 Diegem
              (Belgium) and its employees and representatives (“</span
            ><span style="font-weight: bold; color: #ff642c">Calysta</span
            ><span>”) will supply the services (“</span
            ><span style="font-weight: bold; color: #ff642c">Services</span
            ><span>”) in a </span
            ><span style="font-weight: bold; color: #ff642c">timely</span
            ><span style="color: #ff642c"> </span><span>and </span
            ><span style="font-weight: bold; color: #ff642c">professional</span
            ><span style="color: #ff642c"> </span><span>manner, </span
            ><span style="font-weight: bold; color: #ff642c"
              >but Calysta does not guarantee a certain result</span
            ><span style="color: #ff642c"> </span
            ><span>(reasonable effort obligation).</span>
          </li>
          <li
            class="ListParagraph"
            style="
              margin-left: 14.17pt;
              margin-bottom: 6pt;
              text-align: justify;
              line-height: normal;
              padding-left: 7.13pt;
              font-family: 'Century Gothic';
            "
          >
            <span
              >If the Services do not meet the above standards, Calysta will, where
              relevant, </span
            ><span style="font-weight: bold; color: #ff642c">re-perform</span
            ><span style="color: #ff642c"> </span
            ><span
              > the defective Services free of charge. You agree that this is your
              exclusive</span
            ><span style="font-weight: bold"> </span
            ><span style="font-weight: bold; color: #ff642c"
              > remedy against Calysta</span
            ><span style="color: #ff642c"> </span
            ><span> for defective Services.</span>
          </li>
        </ol>
        <p
          class="ListParagraph"
          style="
            margin-left: 21.3pt;
            margin-bottom: 6pt;
            text-align: justify;
            line-height: normal;
          "
        >
          <span style="font-family: 'Century Gothic'; -aw-import: ignore"
            >&#xa0;</span
          >
        </p>
        <ol start="3" type="1" style="margin: 0pt; padding-left: 0pt">
          <li
            style="
              margin-left: 14.17pt;
              margin-bottom: 6pt;
              text-align: justify;
              line-height: normal;
              padding-left: 7.13pt;
              font-family: 'Century Gothic';
              font-weight: bold;
            "
          >
            <a name="co_anchor_a1059649_22"></a
            ><a name="co_anchor_a806875_22"
              ><span style="-aw-bookmark-end: co_anchor_a1059649_22"></span></a
            ><span>What does Calysta expect from you to deliver its Services?</span>
          </li>
        </ol>
        <ol type="a" style="margin: 0pt; padding-left: 0pt">
          <li
            class="ListParagraph"
            style="
              margin-left: 14.17pt;
              margin-bottom: 6pt;
              text-align: justify;
              line-height: normal;
              padding-left: 7.13pt;
              font-family: 'Century Gothic';
            "
          >
            <span>You will:</span>
          </li>
        </ol>
        <p
          class="ListParagraph"
          style="
            margin-left: 35.45pt;
            margin-bottom: 0pt;
            text-indent: -14.2pt;
            text-align: justify;
            line-height: normal;
            -aw-import: list-item;
            -aw-list-level-number: 0;
            -aw-list-number-format: '';
            -aw-list-number-styles: 'bullet';
            -aw-list-padding-sml: 5.46pt;
          "
        >
          <span style="-aw-import: ignore"
            ><span style="font-family: 'Century Gothic'"
              ><span style="font-family: Wingdings"></span></span
            ><span
              style="
                width: 5.46pt;
                font: 7pt 'Times New Roman';
                display: inline-block;
                -aw-import: spaces;
              "
              >&#xa0;&#xa0;&#xa0;
            </span></span
          ><span
            style="font-family: 'Century Gothic'; font-weight: bold; color: #ff642c"
            >co-operate with Calysta</span
          ><span style="font-family: 'Century Gothic'; color: #ff642c"> </span
          ><span style="font-family: 'Century Gothic'"
            > in all matters relating to the Services;</span
          >
        </p>
        <p
          class="ListParagraph"
          style="
            margin-left: 35.45pt;
            margin-bottom: 0pt;
            text-indent: -14.2pt;
            text-align: justify;
            line-height: normal;
            -aw-import: list-item;
            -aw-list-level-number: 0;
            -aw-list-number-format: '';
            -aw-list-number-styles: 'bullet';
            -aw-list-padding-sml: 5.46pt;
          "
        >
          <span style="-aw-import: ignore"
            ><span style="font-family: 'Century Gothic'"
              ><span style="font-family: Wingdings"></span></span
            ><span
              style="
                width: 5.46pt;
                font: 7pt 'Times New Roman';
                display: inline-block;
                -aw-import: spaces;
              "
              >&#xa0;&#xa0;&#xa0;
            </span></span
          ><span style="font-family: 'Century Gothic'">provide Calysta </span
          ><span
            style="font-family: 'Century Gothic'; font-weight: bold; color: #ff642c"
            >in a timely fashion</span
          ><span style="font-family: 'Century Gothic'; color: #ff642c"> </span
          ><span style="font-family: 'Century Gothic'"
            > with the information and materials Calysta requires to supply the
            Services, and ensure that such information is complete, accurate and up
            to date;</span
          >
        </p>
        <p
          class="ListParagraph"
          style="
            margin-left: 35.45pt;
            margin-bottom: 0pt;
            text-indent: -14.2pt;
            text-align: justify;
            line-height: normal;
            -aw-import: list-item;
            -aw-list-level-number: 0;
            -aw-list-number-format: '';
            -aw-list-number-styles: 'bullet';
            -aw-list-padding-sml: 5.46pt;
          "
        >
          <span style="-aw-import: ignore"
            ><span style="font-family: 'Century Gothic'"
              ><span style="font-family: Wingdings"></span></span
            ><span
              style="
                width: 5.46pt;
                font: 7pt 'Times New Roman';
                display: inline-block;
                -aw-import: spaces;
              "
              >&#xa0;&#xa0;&#xa0;
            </span></span
          ><span style="font-family: 'Century Gothic'">provide Calysta with </span
          ><span
            style="font-family: 'Century Gothic'; font-weight: bold; color: #ff642c"
            >contact details </span
          ><span style="font-family: 'Century Gothic'; color: #ff642c"> </span
          ><span style="font-family: 'Century Gothic'"
            >(email or postal address) that are </span
          ><span
            style="font-family: 'Century Gothic'; font-weight: bold; color: #ff642c"
            >always up to date</span
          ><span style="font-family: 'Century Gothic'">;</span>
        </p>
        <p
          class="ListParagraph"
          style="
            margin-left: 35.45pt;
            margin-bottom: 0pt;
            text-indent: -14.2pt;
            text-align: justify;
            line-height: normal;
            -aw-import: list-item;
            -aw-list-level-number: 0;
            -aw-list-number-format: '';
            -aw-list-number-styles: 'bullet';
            -aw-list-padding-sml: 5.46pt;
          "
        >
          <span style="-aw-import: ignore"
            ><span style="font-family: 'Century Gothic'"
              ><span style="font-family: Wingdings"></span></span
            ><span
              style="
                width: 5.46pt;
                font: 7pt 'Times New Roman';
                display: inline-block;
                -aw-import: spaces;
              "
              >&#xa0;&#xa0;&#xa0;
            </span></span
          ><span style="font-family: 'Century Gothic'"
            >obtain and maintain all necessary </span
          ><span
            style="font-family: 'Century Gothic'; font-weight: bold; color: #ff642c"
            >licenses and consents</span
          ><span style="font-family: 'Century Gothic'; color: #ff642c"> </span
          ><span style="font-family: 'Century Gothic'"
            >required for the Services;</span
          >
        </p>
        <p
          class="ListParagraph"
          style="
            margin-left: 35.45pt;
            margin-bottom: 0pt;
            text-indent: -14.2pt;
            text-align: justify;
            line-height: normal;
            -aw-import: list-item;
            -aw-list-level-number: 0;
            -aw-list-number-format: '';
            -aw-list-number-styles: 'bullet';
            -aw-list-padding-sml: 5.46pt;
          "
        >
          <span style="-aw-import: ignore"
            ><span style="font-family: 'Century Gothic'"
              ><span style="font-family: Wingdings"></span></span
            ><span
              style="
                width: 5.46pt;
                font: 7pt 'Times New Roman';
                display: inline-block;
                -aw-import: spaces;
              "
              >&#xa0;&#xa0;&#xa0;
            </span></span
          ><span style="font-family: 'Century Gothic'">comply with all </span
          ><span
            style="font-family: 'Century Gothic'; font-weight: bold; color: #ff642c"
            >applicable laws</span
          ><span style="font-family: 'Century Gothic'">; and</span>
        </p>
        <p
          class="ListParagraph"
          style="
            margin-left: 35.45pt;
            margin-bottom: 6pt;
            text-indent: -14.15pt;
            text-align: justify;
            line-height: normal;
            -aw-import: list-item;
            -aw-list-level-number: 0;
            -aw-list-number-format: '';
            -aw-list-number-styles: 'bullet';
            -aw-list-padding-sml: 5.41pt;
          "
        >
          <span style="-aw-import: ignore"
            ><span style="font-family: 'Century Gothic'"
              ><span style="font-family: Wingdings"></span></span
            ><span
              style="
                width: 5.41pt;
                font: 7pt 'Times New Roman';
                display: inline-block;
                -aw-import: spaces;
              "
              >&#xa0;&#xa0;&#xa0;
            </span></span
          ><a name="co_anchor_a185449_22"></a
          ><span style="font-family: 'Century Gothic'"
            >comply with any additional </span
          ><span
            style="font-family: 'Century Gothic'; font-weight: bold; color: #ff642c"
            >obligations</span
          ><span style="font-family: 'Century Gothic'; color: #ff642c"> </span
          ><span style="font-family: 'Century Gothic'"
            >that would have been agreed to.</span
          >
        </p>
        <ol start="2" type="a" style="margin: 0pt; padding-left: 0pt">
          <li
            class="ListParagraph"
            style="
              margin-left: 14.17pt;
              margin-bottom: 6pt;
              text-align: justify;
              line-height: normal;
              padding-left: 7.13pt;
              font-family: 'Century Gothic';
            "
          >
            <a name="co_anchor_a531297_22"></a
            ><span style="font-weight: bold; color: #ff642c"
              >If you fail to comply</span
            ><span style="color: #ff642c"> </span
            ><a name="_Hlk504465268"><span>with your obligations </span></a
            ><span
              >above, or otherwise prevent or delay the performance of Calysta’s
              obligations under the Agreement (“</span
            ><span style="font-weight: bold; color: #ff642c">Client Default</span
            ><span>”):</span>
          </li>
        </ol>
        <p
          class="ListParagraph"
          style="
            margin-left: 35.45pt;
            margin-bottom: 0pt;
            text-indent: -14.2pt;
            text-align: justify;
            line-height: normal;
            -aw-import: list-item;
            -aw-list-level-number: 0;
            -aw-list-number-format: '';
            -aw-list-number-styles: 'bullet';
            -aw-list-padding-sml: 5.46pt;
          "
        >
          <span style="-aw-import: ignore"
            ><span style="font-family: 'Century Gothic'"
              ><span style="font-family: Wingdings"></span></span
            ><span
              style="
                width: 5.46pt;
                font: 7pt 'Times New Roman';
                display: inline-block;
                -aw-import: spaces;
              "
              >&#xa0;&#xa0;&#xa0;
            </span></span
          ><span style="font-family: 'Century Gothic'">Calysta may </span
          ><span
            style="font-family: 'Century Gothic'; font-weight: bold; color: #ff642c"
            >suspend performance</span
          ><span style="font-family: 'Century Gothic'; color: #ff642c"> </span
          ><span style="font-family: 'Century Gothic'"
            >of the Services until you remedy the Client Default;</span
          >
        </p>
        <p
          class="ListParagraph"
          style="
            margin-left: 35.45pt;
            margin-bottom: 0pt;
            text-align: justify;
            line-height: normal;
          "
        >
          <span style="font-family: 'Century Gothic'; -aw-import: ignore"
            >&#xa0;</span
          >
        </p>
        <p
          class="ListParagraph"
          style="
            margin-left: 35.45pt;
            margin-bottom: 0pt;
            text-indent: -14.2pt;
            text-align: justify;
            line-height: normal;
            -aw-import: list-item;
            -aw-list-level-number: 0;
            -aw-list-number-format: '';
            -aw-list-number-styles: 'bullet';
            -aw-list-padding-sml: 5.46pt;
          "
        >
          <span style="-aw-import: ignore"
            ><span style="font-family: 'Century Gothic'"
              ><span style="font-family: Wingdings"></span></span
            ><span
              style="
                width: 5.46pt;
                font: 7pt 'Times New Roman';
                display: inline-block;
                -aw-import: spaces;
              "
              >&#xa0;&#xa0;&#xa0;
            </span></span
          ><span style="font-family: 'Century Gothic'">Calysta </span
          ><span
            style="font-family: 'Century Gothic'; font-weight: bold; color: #ff642c"
            >is not liable </span
          ><span style="font-family: 'Century Gothic'"
            >for any costs or losses you incur because of the Client Default ;
            and</span
          >
        </p>
        <p
          class="ListParagraph"
          style="
            margin-left: 35.45pt;
            margin-bottom: 6pt;
            text-indent: -14.15pt;
            text-align: justify;
            line-height: normal;
            -aw-import: list-item;
            -aw-list-level-number: 0;
            -aw-list-number-format: '';
            -aw-list-number-styles: 'bullet';
            -aw-list-padding-sml: 5.41pt;
          "
        >
          <span style="-aw-import: ignore"
            ><span style="font-family: 'Century Gothic'"
              ><span style="font-family: Wingdings"></span></span
            ><span
              style="
                width: 5.41pt;
                font: 7pt 'Times New Roman';
                display: inline-block;
                -aw-import: spaces;
              "
              >&#xa0;&#xa0;&#xa0;
            </span></span
          ><span
            style="font-family: 'Century Gothic'; font-weight: bold; color: #ff642c"
            >you will reimburse Calysta</span
          ><span style="font-family: 'Century Gothic'; color: #ff642c"> </span
          ><span style="font-family: 'Century Gothic'"
            >for any costs or losses it incur because of the Client Default.</span
          >
        </p>
        <p
          style="
            margin-left: 21.3pt;
            margin-bottom: 6pt;
            text-align: justify;
            line-height: normal;
          "
        >
          <span
            style="
              font-family: 'Century Gothic';
              font-weight: bold;
              -aw-import: ignore;
            "
            >&#xa0;</span
          >
        </p>
        <ol start="4" type="1" style="margin: 0pt; padding-left: 0pt">
          <li
            style="
              margin-left: 14.17pt;
              margin-bottom: 6pt;
              text-align: justify;
              line-height: normal;
              padding-left: 7.13pt;
              font-family: 'Century Gothic';
              font-weight: bold;
            "
          >
            <span>What are our fees, charges and expenses?</span>
          </li>
        </ol>
        <ol type="a" style="margin: 0pt; padding-left: 0pt">
          <li
            class="ListParagraph"
            style="
              margin-left: 14.17pt;
              margin-bottom: 6pt;
              text-align: justify;
              line-height: normal;
              padding-left: 7.13pt;
              font-family: 'Century Gothic';
            "
          >
            <span
              >Unless there is a written fee agreement providing for a different
              mechanism, Calysta is entitled to charge the fees and you agree to pay
              the fees (“</span
            ><span style="font-weight: bold; color: #ff642c">Fees</span
            ><span>”) in accordance with Calysta’s standard fee rates (“</span
            ><span style="font-weight: bold; color: #ff642c">Standard Rates</span
            ><span>”).</span>
          </li>
        </ol>
        <p
          class="ListParagraph"
          style="
            margin-left: 21.3pt;
            margin-bottom: 6pt;
            text-align: justify;
            line-height: normal;
          "
        >
          <a name="_Hlk504466963"
            ><span style="font-family: 'Century Gothic'">Calysta reserves </span
            ><span
              style="
                font-family: 'Century Gothic';
                font-weight: bold;
                color: #ff642c;
              "
              >the right to modify the Standard Rates at any time</span
            ><span style="font-family: 'Century Gothic'"
              >. The applicable Standard Rates are available on the Client portal or
              are communicated to you by email.</span
            ></a
          >
        </p>
        <p
          class="ListParagraph"
          style="
            margin-left: 21.3pt;
            margin-bottom: 6pt;
            text-align: justify;
            line-height: normal;
          "
        >
          <span style="font-family: 'Century Gothic'"
            >The applicable Standard Rates are those in force at the time you order
            the Services.</span
          >
        </p>
        <ol start="2" type="a" style="margin: 0pt; padding-left: 0pt">
          <li
            class="ListParagraph"
            style="
              margin-left: 14.17pt;
              margin-bottom: 6pt;
              text-align: justify;
              line-height: normal;
              padding-left: 7.13pt;
              font-family: 'Century Gothic';
            "
          >
            <a name="co_anchor_a202380_22"
              ><span style="-aw-bookmark-end: _Hlk504466963"></span></a
            ><span
              >Calysta will charge you, and you agree to pay, the cost and/or
              expenses of </span
            ><span style="font-weight: bold; color: #ff642c"
              >services provided by third parties or taxes or equivalent costs
              charged by authorities and/or governments</span
            ><span style="color: #ff642c"> </span
            ><span>required for the performance of the Services (“</span
            ><span style="font-weight: bold; color: #ff642c">Charges</span
            ><span
              >”) (except when the third-party provider invoices the Charges
              directly to you). These Charges may be increased by handling and/or
              currency risk fee by Calysta.</span
            >
          </li>
          <li
            class="ListParagraph"
            style="
              margin-left: 13.56pt;
              margin-bottom: 6pt;
              text-align: justify;
              line-height: normal;
              padding-left: 7.74pt;
              font-family: 'Century Gothic';
            "
          >
            <span
              >Calysta will charge you, and you agree to pay, any out-of-pocket
              expenses reasonably incurred for the performance of the Services
              including travelling expenses, hotel costs subsistence and any
              associated expenses (“</span
            ><span style="font-weight: bold; color: #ff642c">Expenses</span
            ><span>”). </span>
          </li>
          <li
            class="ListParagraph"
            style="
              margin-left: 14.17pt;
              margin-bottom: 6pt;
              text-align: justify;
              line-height: normal;
              widows: 0;
              orphans: 0;
              padding-left: 7.13pt;
              font-family: 'Century Gothic';
            "
          >
            <span>You agree that If Calysta provides an </span
            ><span style="font-weight: bold; color: #ff642c">estimate</span
            ><span style="color: #ff642c"> </span
            ><span>of its Fees, Charges or Expenses, this estimate is </span
            ><span style="font-weight: bold; color: #ff642c">indicative</span
            ><span> and valid for a period of 30 calendar days.</span>
          </li>
          <li
            class="ListParagraph"
            style="
              margin-left: 14.17pt;
              margin-bottom: 6pt;
              text-align: justify;
              line-height: normal;
              widows: 0;
              orphans: 0;
              padding-left: 7.13pt;
              font-family: 'Century Gothic';
            "
          >
            <span
              >You agree that Calysta is entitled to require and invoice an advance
              payment at any time before starting or during the performance of the
              Services.</span
            >
          </li>
          <li
            class="ListParagraph"
            style="
              margin-left: 11.11pt;
              margin-bottom: 6pt;
              text-align: justify;
              line-height: normal;
              widows: 0;
              orphans: 0;
              padding-left: 10.19pt;
              font-family: 'Century Gothic';
            "
          >
            <span>You agree to pay Calysta’s invoices </span
            ><span style="font-weight: bold; color: #ff642c">within 30 days</span
            ><span style="color: #ff642c"> </span
            ><span>of the date of the invoice.</span>
          </li>
          <li
            class="ListParagraph"
            style="
              margin-left: 14.17pt;
              margin-bottom: 6pt;
              text-align: justify;
              line-height: normal;
              widows: 0;
              orphans: 0;
              padding-left: 7.13pt;
              font-family: 'Century Gothic';
            "
          >
            <a name="_Hlk505265176"
              ><span
                >All fee quotes and the Standard Rates are without VAT, Calysta will
                however charge you and you agree to pay to Calysta the applicable
                VAT amounts at the same time as payment is due for the supply of the
                Services.</span
              ></a
            ><span> </span>
          </li>
          <li
            class="ListParagraph"
            style="
              margin-left: 14.17pt;
              margin-bottom: 6pt;
              text-align: justify;
              line-height: normal;
              widows: 0;
              orphans: 0;
              padding-left: 7.13pt;
              font-family: 'Century Gothic';
            "
          >
            <span
              >You should address any contestation of an invoice within 15 days
              after the issuance of contested invoice.
            </span>
          </li>
          <li
            class="ListParagraph"
            style="
              margin-left: 10.5pt;
              margin-bottom: 6pt;
              text-align: justify;
              line-height: normal;
              widows: 0;
              orphans: 0;
              padding-left: 10.8pt;
              font-family: 'Century Gothic';
            "
          >
            <span style="font-weight: bold; color: #ff642c"
              >If you fail to make a payment</span
            ><span style="color: #ff642c"> </span><span>by the due date: </span>
          </li>
        </ol>
        <p
          class="ListParagraph"
          style="
            margin-left: 35.45pt;
            margin-bottom: 0pt;
            text-indent: -14.2pt;
            text-align: justify;
            line-height: normal;
            -aw-import: list-item;
            -aw-list-level-number: 0;
            -aw-list-number-format: '';
            -aw-list-number-styles: 'bullet';
            -aw-list-padding-sml: 5.46pt;
          "
        >
          <span style="-aw-import: ignore"
            ><span style="font-family: 'Century Gothic'"
              ><span style="font-family: Wingdings"></span></span
            ><span
              style="
                width: 5.46pt;
                font: 7pt 'Times New Roman';
                display: inline-block;
                -aw-import: spaces;
              "
              >&#xa0;&#xa0;&#xa0;
            </span></span
          ><span style="font-family: 'Century Gothic'"
            >you agree to pay to Calysta </span
          ><span
            style="font-family: 'Century Gothic'; font-weight: bold; color: #ff642c"
            >interest</span
          ><span style="font-family: 'Century Gothic'; color: #ff642c"> </span
          ><span style="font-family: 'Century Gothic'"
            >on the overdue sum, whether before or after judgment. Interest will
            accrue each day at 8% a year;
          </span>
        </p>
        <p
          class="ListParagraph"
          style="
            margin-left: 35.45pt;
            margin-bottom: 0pt;
            text-indent: -14.2pt;
            text-align: justify;
            line-height: normal;
            -aw-import: list-item;
            -aw-list-level-number: 0;
            -aw-list-number-format: '';
            -aw-list-number-styles: 'bullet';
            -aw-list-padding-sml: 5.46pt;
          "
        >
          <span style="-aw-import: ignore"
            ><span style="font-family: 'Century Gothic'"
              ><span style="font-family: Wingdings"></span></span
            ><span
              style="
                width: 5.46pt;
                font: 7pt 'Times New Roman';
                display: inline-block;
                -aw-import: spaces;
              "
              >&#xa0;&#xa0;&#xa0;
            </span></span
          ><span style="font-family: 'Century Gothic'"
            >you agree to pay to Calysta an </span
          ><span
            style="font-family: 'Century Gothic'; font-weight: bold; color: #ff642c"
            >agreed compensation</span
          ><span style="font-family: 'Century Gothic'; color: #ff642c"> </span
          ><span style="font-family: 'Century Gothic'"
            >of 10% of the overdue sum for the losses Calysta incurred; and
          </span>
        </p>
        <p
          class="ListParagraph"
          style="
            margin-left: 35.45pt;
            margin-bottom: 6pt;
            text-indent: -14.2pt;
            text-align: justify;
            line-height: normal;
            -aw-import: list-item;
            -aw-list-level-number: 0;
            -aw-list-number-format: '';
            -aw-list-number-styles: 'bullet';
            -aw-list-padding-sml: 5.46pt;
          "
        >
          <span style="-aw-import: ignore"
            ><span style="font-family: 'Century Gothic'"
              ><span style="font-family: Wingdings"></span></span
            ><span
              style="
                width: 5.46pt;
                font: 7pt 'Times New Roman';
                display: inline-block;
                -aw-import: spaces;
              "
              >&#xa0;&#xa0;&#xa0;
            </span></span
          ><span style="font-family: 'Century Gothic'">Calysta may </span
          ><span
            style="font-family: 'Century Gothic'; font-weight: bold; color: #ff642c"
            >suspend</span
          ><span style="font-family: 'Century Gothic'; color: #ff642c"> </span
          ><span style="font-family: 'Century Gothic'"
            >the supply of Services.</span
          >
        </p>
        <ol start="10" type="a" style="margin: 0pt; padding-left: 0pt">
          <li
            class="ListParagraph"
            style="
              margin-left: 10.5pt;
              margin-bottom: 6pt;
              text-align: justify;
              line-height: normal;
              widows: 0;
              orphans: 0;
              padding-left: 10.8pt;
              font-family: 'Century Gothic';
            "
          >
            <span
              >You agree that all amounts due under the Agreement must be paid in
              full </span
            ><span style="font-weight: bold; color: #ff642c"
              >without any set-off or deduction</span
            ><span style="color: #ff642c">.</span>
          </li>
        </ol>
        <p
          style="
            margin-left: 21.3pt;
            margin-bottom: 6pt;
            text-align: justify;
            line-height: normal;
          "
        >
          <a name="co_anchor_a838919_22"></a
          ><span
            style="
              font-family: 'Century Gothic';
              font-weight: bold;
              -aw-import: ignore;
            "
            >&#xa0;</span
          >
        </p>
        <ol start="5" type="1" style="margin: 0pt; padding-left: 0pt">
          <li
            style="
              margin-left: 14.17pt;
              margin-bottom: 6pt;
              text-align: justify;
              line-height: normal;
              padding-left: 7.13pt;
              font-family: 'Century Gothic';
              font-weight: bold;
            "
          >
            <span
              >How to we deal with intellectual property rights resulting from the
              Services?</span
            ><span>&#xa0;</span>
          </li>
        </ol>
        <ol type="a" style="margin: 0pt; padding-left: 0pt">
          <li
            class="ListParagraph"
            style="
              margin-left: 14.17pt;
              margin-bottom: 6pt;
              text-align: justify;
              line-height: normal;
              widows: 0;
              orphans: 0;
              padding-left: 3.83pt;
              font-family: 'Century Gothic';
            "
          >
            <a name="co_anchor_a862422_22"></a
            ><span
              >Calysta is the exclusive owner of any intellectual property rights
              pertaining to the deliverables it produces as part of the Services
              (“</span
            ><span style="font-weight: bold; color: #ff642c">Deliverables</span
            ><span>”).</span>
          </li>
          <li
            class="ListParagraph"
            style="
              margin-left: 14.17pt;
              margin-bottom: 6pt;
              text-align: justify;
              line-height: normal;
              widows: 0;
              orphans: 0;
              padding-left: 3.83pt;
              font-family: 'Century Gothic';
            "
          >
            <span
              >Calysta grants you a fully paid-up, worldwide, non-exclusive,
              royalty-free and irrevocable </span
            ><span style="font-weight: bold; color: #ff642c"
              >license to copy and modify the Deliverables</span
            ><span style="color: #ff642c"> </span
            ><span
              >for receiving and using the Services and the Deliverables in your
              business.</span
            >
          </li>
        </ol>
        <p
          style="
            margin-left: 21.3pt;
            margin-bottom: 6pt;
            text-align: justify;
            line-height: normal;
          "
        >
          <a name="co_anchor_a364240_22"></a
          ><a name="co_anchor_a923729_22"
            ><span style="-aw-bookmark-end: co_anchor_a364240_22"></span></a
          ><span
            style="
              font-family: 'Century Gothic';
              font-weight: bold;
              -aw-import: ignore;
            "
            >&#xa0;</span
          >
        </p>
        <ol start="6" type="1" style="margin: 0pt; padding-left: 0pt">
          <li
            style="
              margin-left: 14.17pt;
              margin-bottom: 6pt;
              text-align: justify;
              line-height: normal;
              padding-left: 7.13pt;
              font-family: 'Century Gothic';
              font-weight: bold;
            "
          >
            <a name="_Hlk87980895"><span>L</span></a
            ><a name="_Hlk503183942"
              ><span>imitations of liability</span><span>&#xa0;</span
              ><span>&#xa0;</span></a
            >
          </li>
        </ol>
        <ol type="a" style="margin: 0pt; padding-left: 0pt">
          <li
            class="ListParagraph"
            style="
              margin-left: 14.17pt;
              margin-bottom: 6pt;
              text-align: justify;
              line-height: normal;
              padding-left: 7.13pt;
              font-family: 'Century Gothic';
            "
          >
            <a name="co_anchor_a127333_22"
              ><span style="-aw-bookmark-end: _Hlk87980895"></span></a
            ><span>You agree that Calysta’s total </span
            ><span style="font-weight: bold; color: #ff642c">liability</span
            ><span style="color: #ff642c"> </span
            ><span
              >for damages that it may cause to you (including the loss of an
              intellectual property right) in connection with the relationship
              between the parties, whether in contract or in tort, </span
            ><span style="font-weight: bold; color: #ff642c">is limited to</span
            ><span style="color: #ff642c"> </span
            ><span
              >the lesser of 250.000 EUR or a maximum of 300% of the total fees paid
              by you to Calysta in connection with the Services that have given rise
              to the damages.</span
            >
          </li>
          <li
            class="ListParagraph"
            style="
              margin-left: 14.17pt;
              margin-bottom: 6pt;
              text-align: justify;
              line-height: normal;
              padding-left: 7.13pt;
              font-family: 'Century Gothic';
            "
          >
            <span
              >The right to indemnification expires if no action is instituted by
              you within 3 months as from the day you have had knowledge of our
              failure to meet one of our obligations or other event that gives rise
              to Calysta’s liability.</span
            >
          </li>
          <li
            class="ListParagraph"
            style="
              margin-left: 13.56pt;
              margin-bottom: 6pt;
              text-align: justify;
              line-height: normal;
              padding-left: 7.74pt;
              font-family: 'Century Gothic';
            "
          >
            <span>The limitations in this clause do not apply to:</span>
          </li>
        </ol>
        <p
          class="ListParagraph"
          style="
            margin-left: 35.45pt;
            margin-bottom: 0pt;
            text-indent: -14.15pt;
            text-align: justify;
            line-height: normal;
            -aw-import: list-item;
            -aw-list-level-number: 0;
            -aw-list-number-format: '';
            -aw-list-number-styles: 'bullet';
            -aw-list-padding-sml: 5.41pt;
          "
        >
          <span style="-aw-import: ignore"
            ><span style="font-family: 'Century Gothic'"
              ><span style="font-family: Wingdings"></span></span
            ><span
              style="
                width: 5.41pt;
                font: 7pt 'Times New Roman';
                display: inline-block;
                -aw-import: spaces;
              "
              >&#xa0;&#xa0;&#xa0;
            </span></span
          ><span style="font-family: 'Century Gothic'"
            >death or personal injury; or</span
          >
        </p>
        <p
          class="ListParagraph"
          style="
            margin-left: 35.45pt;
            margin-bottom: 6pt;
            text-indent: -14.15pt;
            text-align: justify;
            line-height: normal;
            -aw-import: list-item;
            -aw-list-level-number: 0;
            -aw-list-number-format: '';
            -aw-list-number-styles: 'bullet';
            -aw-list-padding-sml: 5.41pt;
          "
        >
          <span style="-aw-import: ignore"
            ><span style="font-family: 'Century Gothic'"
              ><span style="font-family: Wingdings"></span></span
            ><span
              style="
                width: 5.41pt;
                font: 7pt 'Times New Roman';
                display: inline-block;
                -aw-import: spaces;
              "
              >&#xa0;&#xa0;&#xa0;
            </span></span
          ><span style="font-family: 'Century Gothic'"
            >losses resulting from Calysta’s fraud or willful misconduct.</span
          >
        </p>
        <ol start="4" type="a" style="margin: 0pt; padding-left: 0pt">
          <li
            class="ListParagraph"
            style="
              margin-left: 14.17pt;
              margin-bottom: 6pt;
              text-align: justify;
              line-height: normal;
              padding-left: 7.13pt;
              font-family: 'Century Gothic';
            "
          >
            <span style="font-weight: bold; color: #ff642c"
              >You agree that Calysta will never be liable</span
            ><span style="color: #ff642c"> </span
            ><span
              >to you for loss of profits, business, anticipated savings, or for any
              indirect or consequential loss.</span
            >
          </li>
        </ol>
        <p
          style="
            margin-left: 21.3pt;
            margin-bottom: 6pt;
            text-align: justify;
            line-height: normal;
          "
        >
          <a name="co_anchor_a151117_22"
            ><span style="-aw-bookmark-end: _Hlk503183942"></span></a
          ><span
            style="
              font-family: 'Century Gothic';
              font-weight: bold;
              -aw-import: ignore;
            "
            >&#xa0;</span
          >
        </p>
        <ol start="7" type="1" style="margin: 0pt; padding-left: 0pt">
          <li
            style="
              margin-left: 14.17pt;
              margin-bottom: 6pt;
              text-align: justify;
              line-height: normal;
              padding-left: 7.13pt;
              font-family: 'Century Gothic';
              font-weight: bold;
            "
          >
            <span>What are the parties’ confidentiality obligations?</span>
          </li>
        </ol>
        <ol type="a" style="margin: 0pt; padding-left: 0pt">
          <li
            class="ListParagraph"
            style="
              margin-left: 14.17pt;
              margin-bottom: 6pt;
              text-align: justify;
              line-height: normal;
              padding-left: 7.13pt;
              font-family: 'Century Gothic';
            "
          >
            <span style="font-weight: bold; color: #ff642c"
              >Neither party will disclose</span
            ><span style="color: #ff642c"> </span
            ><span
              >to any person any confidential information concerning the business,
              affairs, customers or suppliers of the other party, except as
              permitted below.</span
            >
          </li>
          <li
            class="ListParagraph"
            style="
              margin-left: 14.17pt;
              margin-bottom: 6pt;
              text-align: justify;
              line-height: normal;
              padding-left: 7.13pt;
              font-family: 'Century Gothic';
            "
          >
            <a name="co_anchor_a581024_1"></a
            ><span style="font-weight: bold; color: #ff642c"
              >Each party may disclose</span
            ><span style="color: #ff642c"> </span
            ><span>the other party’s confidential information:</span>
          </li>
        </ol>
        <p
          class="ListParagraph"
          style="
            margin-left: 35.45pt;
            margin-bottom: 0pt;
            text-indent: -14.2pt;
            text-align: justify;
            line-height: normal;
            -aw-import: list-item;
            -aw-list-level-number: 0;
            -aw-list-number-format: '';
            -aw-list-number-styles: 'bullet';
            -aw-list-padding-sml: 5.46pt;
          "
        >
          <span style="-aw-import: ignore"
            ><span style="font-family: 'Century Gothic'"
              ><span style="font-family: Wingdings"></span></span
            ><span
              style="
                width: 5.46pt;
                font: 7pt 'Times New Roman';
                display: inline-block;
                -aw-import: spaces;
              "
              >&#xa0;&#xa0;&#xa0;
            </span></span
          ><span style="font-family: 'Century Gothic'"
            >to its employees, advisers or subcontractors who need to know such
            information for the purpose of the Services. In that case, the
            disclosing party will ensure that its employees, advisers and
            subcontractors who receive such information comply with this clause </span
          ><a href="#co_anchor_a365510_1" style="text-decoration: none"
            ><span style="font-family: 'Century Gothic'; color: #000000">7</span></a
          ><span style="font-family: 'Century Gothic'">; and</span>
        </p>
        <p
          class="ListParagraph"
          style="
            margin-left: 35.45pt;
            margin-bottom: 6pt;
            text-indent: -14.2pt;
            text-align: justify;
            line-height: normal;
            -aw-import: list-item;
            -aw-list-level-number: 0;
            -aw-list-number-format: '';
            -aw-list-number-styles: 'bullet';
            -aw-list-padding-sml: 5.46pt;
          "
        >
          <span style="-aw-import: ignore"
            ><span style="font-family: 'Century Gothic'"
              ><span style="font-family: Wingdings"></span></span
            ><span
              style="
                width: 5.46pt;
                font: 7pt 'Times New Roman';
                display: inline-block;
                -aw-import: spaces;
              "
              >&#xa0;&#xa0;&#xa0;
            </span></span
          ><span style="font-family: 'Century Gothic'"
            >as may be required by law, a court of competent jurisdiction or any
            governmental authority.</span
          >
        </p>
        <p
          class="ListParagraph"
          style="
            margin-left: 21.3pt;
            margin-bottom: 6pt;
            text-align: justify;
            line-height: normal;
          "
        >
          <span style="font-family: 'Century Gothic'; -aw-import: ignore"
            >&#xa0;</span
          >
        </p>
        <ol start="8" type="1" style="margin: 0pt; padding-left: 0pt">
          <li
            style="
              margin-left: 14.17pt;
              margin-bottom: 6pt;
              text-align: justify;
              line-height: normal;
              padding-left: 7.13pt;
              font-family: 'Century Gothic';
              font-weight: bold;
            "
          >
            <span>Suspension of services</span>
            <ol
              type="a"
              style="margin-right: 0pt; margin-left: 0pt; padding-left: 0pt"
            >
              <li
                class="ListParagraph"
                style="
                  margin-left: -7.13pt;
                  margin-bottom: 0pt;
                  text-align: left;
                  padding-left: 3.83pt;
                  font-family: 'Century Gothic';
                  font-weight: normal;
                "
              >
                <span>You agree that Calysta has the right to </span
                ><a name="co_anchor_a346761_22"></a
                ><span style="font-weight: bold; color: #ff642c">suspend</span
                ><span style="color: #ff642c"> </span
                ><span
                  >the supply or performance of Services, including ongoing
                  Services, if you fail to pay any amount due under the Agreement or
                  to comply with any other obligations under the Agreement,
                  including the obligations outlined under clause 3.a.</span
                >
              </li>
            </ol>
          </li>
        </ol>
        <p class="ListParagraph" style="margin-left: 18pt; margin-bottom: 0pt">
          <span style="font-family: 'Century Gothic'; -aw-import: ignore"
            >&#xa0;</span
          >
        </p>
        <ol start="2" type="a" style="margin: 0pt; padding-left: 0pt">
          <li
            class="ListParagraph"
            style="
              margin-left: 14.17pt;
              padding-left: 3.83pt;
              font-family: 'Century Gothic';
            "
          >
            <span
              >In the event Calysta suspends the supply or performance of Services, </span
            ><span style="font-weight: bold; color: #ff642c"
              >Calysta is not liable</span
            ><span>
              for any costs or losses you incur because of the suspension of the
              Services</span
            >
          </li>
        </ol>
        <p
          style="
            margin-left: 21.3pt;
            margin-bottom: 6pt;
            text-align: justify;
            line-height: normal;
          "
        >
          <a name="co_anchor_a359876_22"></a
          ><span
            style="
              font-family: 'Century Gothic';
              font-weight: bold;
              -aw-import: ignore;
            "
            >&#xa0;</span
          >
        </p>
        <ol start="9" type="1" style="margin: 0pt; padding-left: 0pt">
          <li
            style="
              margin-left: 14.17pt;
              margin-bottom: 6pt;
              text-align: justify;
              line-height: normal;
              padding-left: 7.13pt;
              font-family: 'Century Gothic';
              font-weight: bold;
            "
          >
            <span>Circumstances beyond our reasonable control – Force Majeure</span>
          </li>
        </ol>
        <p
          class="ListParagraph"
          style="
            margin-left: 21.3pt;
            margin-bottom: 6pt;
            text-align: justify;
            line-height: normal;
          "
        >
          <span
            style="font-family: 'Century Gothic'; font-weight: bold; color: #ff642c"
            >Neither of the parties is liable</span
          ><span style="font-family: 'Century Gothic'; color: #ff642c"> </span
          ><span style="font-family: 'Century Gothic'"
            >for delay or failure to perform any of its obligations if such delay or
            failure results from circumstances beyond their reasonable control. In
            such circumstances, the affected party is entitled to a reasonable
            extension of the time for performing such obligations.
          </span>
        </p>
        <p
          style="
            margin-left: 21.3pt;
            margin-bottom: 6pt;
            text-align: justify;
            line-height: normal;
          "
        >
          <span
            style="
              font-family: 'Century Gothic';
              font-weight: bold;
              -aw-import: ignore;
            "
            >&#xa0;</span
          >
        </p>
        <ol start="10" type="1" style="margin: 0pt; padding-left: 0pt">
          <li
            style="
              margin-left: 20.29pt;
              margin-bottom: 6pt;
              text-align: justify;
              line-height: normal;
              padding-left: 1.01pt;
              font-family: 'Century Gothic';
              font-weight: bold;
            "
          >
            <span>Client Portal</span>
          </li>
        </ol>
        <p class="ListParagraph" style="margin-left: 18pt; text-align: justify">
          <span style="font-family: 'Century Gothic'"
            >In order to enhance Calysta’s interaction with its clients and their
            access to information relating to them, as well as allowing a more
            flexible way of communication and of transfer of information, Calysta
            has put in place an online portal for its clients (“the Client Portal”
            or “the Software as a Service (Saas)”). The terms of use of this Client
            Portal are listed in </span
          ><span style="font-family: 'Century Gothic'; font-weight: bold"
            >Annex 1</span
          ><span style="font-family: 'Century Gothic'">
            and are an integral part of our General Terms and Conditions.</span
          >
        </p>
        <p
          style="
            margin-left: 21.3pt;
            margin-bottom: 6pt;
            text-align: justify;
            line-height: normal;
          "
        >
          <span
            style="
              font-family: 'Century Gothic';
              font-weight: bold;
              -aw-import: ignore;
            "
            >&#xa0;</span
          >
        </p>
        <ol start="11" type="1" style="margin: 0pt; padding-left: 0pt">
          <li
            style="
              margin-left: 20.29pt;
              margin-bottom: 6pt;
              text-align: justify;
              line-height: normal;
              padding-left: 1.01pt;
              font-family: 'Century Gothic';
              font-weight: bold;
            "
          >
            <span>Miscellaneous matters</span><span>&#xa0;</span><span>&#xa0;</span>
          </li>
        </ol>
        <ol type="a" style="margin: 0pt; padding-left: 0pt">
          <li
            class="ListParagraph"
            style="
              margin-left: 14.17pt;
              margin-bottom: 6pt;
              text-align: justify;
              line-height: normal;
              padding-left: 7.13pt;
              font-family: 'Century Gothic';
            "
          >
            <span
              >These Conditions will always apply and override any other agreement
              between the parties, unless a written agreement between the parties is
              entered into whereby the application of these Conditions is explicitly
              excluded.
            </span>
          </li>
          <li
            class="ListParagraph"
            style="
              margin-left: 14.17pt;
              margin-bottom: 6pt;
              text-align: justify;
              line-height: normal;
              padding-left: 7.13pt;
              font-family: 'Century Gothic';
            "
          >
            <span>If any provision of these Conditions is </span
            ><span style="font-weight: bold; color: #ff642c"
              >invalid, illegal or unenforceable</span
            ><span
              >, the parties will negotiate in good faith to amend such provision so
              that it achieves the intended commercial result of the original
              provision, without entailing the nullity of the Conditions.</span
            >
          </li>
        </ol>
        <p style="margin-bottom: 6pt; text-align: justify; line-height: normal">
          <a name="co_anchor_a312369_1"></a
          ><span
            style="
              font-family: 'Century Gothic';
              font-weight: bold;
              -aw-import: ignore;
            "
            >&#xa0;</span
          >
        </p>
        <ol start="12" type="1" style="margin: 0pt; padding-left: 0pt">
          <li
            style="
              margin-left: 20.29pt;
              margin-bottom: 6pt;
              text-align: justify;
              line-height: normal;
              padding-left: 1.01pt;
              font-family: 'Century Gothic';
              font-weight: bold;
            "
          >
            <span>Changes </span>
          </li>
        </ol>
        <p
          style="
            margin-left: 21.3pt;
            margin-bottom: 6pt;
            text-align: justify;
            line-height: normal;
          "
        >
          <span style="font-family: 'Century Gothic'"
            >Calysta may, from time to time, amend these Conditions. Any changes
            Calysta makes to the Conditions will be posted on Calysta’s website and,
            where appropriate, notified to you by e-mail. Calysta invites you to
            check back on a regular basis to see if any updates or changes have been
            made to these Conditions.</span
          >
        </p>
        <p
          style="
            margin-left: 21.3pt;
            margin-bottom: 6pt;
            text-align: justify;
            line-height: normal;
          "
        >
          <span
            style="
              font-family: 'Century Gothic';
              font-weight: bold;
              -aw-import: ignore;
            "
            >&#xa0;</span
          >
        </p>
        <ol start="13" type="1" style="margin: 0pt; padding-left: 0pt">
          <li
            style="
              margin-left: 20.29pt;
              margin-bottom: 6pt;
              text-align: justify;
              line-height: normal;
              padding-left: 1.01pt;
              font-family: 'Century Gothic';
              font-weight: bold;
            "
          >
            <span>How do we resolve disputes?</span><span>&#xa0;</span
            ><span>&#xa0;</span>
          </li>
        </ol>
        <ol type="a" style="margin: 0pt; padding-left: 0pt">
          <li
            class="ListParagraph"
            style="
              margin-left: 14.17pt;
              margin-bottom: 0pt;
              text-align: justify;
              line-height: normal;
              padding-left: 7.13pt;
              font-family: 'Century Gothic';
            "
          >
            <span>Both parties will try to resolve any dispute </span
            ><span style="font-weight: bold; color: #ff642c">amicably</span
            ><span>. If they are unable to do so, the courts of </span
            ><span style="font-weight: bold; color: #ff642c">Brussels</span
            ><span style="color: #ff642c"> </span
            ><span
              >will have exclusive jurisdiction to settle any dispute related to
              these Conditions.</span
            >
          </li>
          <li
            class="ListParagraph"
            style="
              margin-left: 14.17pt;
              margin-bottom: 6pt;
              text-align: justify;
              line-height: normal;
              padding-left: 7.08pt;
              font-family: 'Century Gothic';
            "
          >
            <span>These Conditions are governed by the </span
            ><span style="font-weight: bold; color: #ff642c">law of Belgium</span
            ><span>.</span>
          </li>
        </ol>
        <p
          style="
            margin-bottom: 6pt;
            text-align: justify;
            line-height: normal;
            widows: 0;
            orphans: 0;
          "
        >
          <span style="font-family: 'Century Gothic'; -aw-import: ignore"
            >&#xa0;</span
          >
        </p>
        <p class="CalNormal" style="line-height: 120%; font-size: 11pt">
          <span style="-aw-import: ignore">&#xa0;</span>
        </p>
        <p
          class="CalNormal"
          style="text-align: center; line-height: 120%; font-size: 14pt"
        >
          <span style="font-weight: bold"
            >ANNEX 1 – Client Portal (Terms of Use)</span
          >
        </p>
        <p style="text-align: justify; line-height: 115%">
          <span style="font-family: 'Century Gothic'; -aw-import: ignore"
            >&#xa0;</span
          >
        </p>
        <p style="text-align: justify; line-height: 115%">
          <span style="font-family: 'Century Gothic'"
            >These terms of use (“the Terms of Use”) have a contractual value and
            apply to the use of the Client Portal, including the use of the
            information offered on the Client Portal. The Terms of Use must be read
            in conjunction with any other applicable terms governing our
            relationship with our clients and they are in addition to our General
            Terms and Conditions and privacy policy (available on our website). In
            case of contradiction between the Terms of Use and our General Terms and
            Conditions, the Terms of Use will prevail with respect to the use of the
            Client Portal.</span
          >
        </p>
        <p style="text-align: justify; line-height: 115%">
          <span style="font-family: 'Century Gothic'"
            >By using the Client Portal you agree to be bound by the Terms of Use
            and you represent that you have the authority to bind the Client / the
            Authorized User with respect to the Terms of Use. If you do not accept
            the Terms of Use, you must refrain from using the Client Portal. If you
            continue to use the Client Portal, you will be deemed to have accepted
            the Terms of Use.</span
          >
        </p>
        <p style="text-align: justify; line-height: 115%">
          <span style="font-family: 'Century Gothic'"
            >We may modify the Terms of Use at any time, and such modifications will
            become effective immediately upon posting the modified Terms of Use on
            the Client Portal. Any use of the Client Portal by you after any such
            modified Terms of Use have been posted will be deemed acceptance by you
            of the modified Terms of Use.</span
          >
        </p>
        <p style="text-align: justify; line-height: 115%">
          <span style="font-family: 'Century Gothic'"
            >We reserve the right to terminate with immediate effect your ability to
            access the Client Portal if you breach the Terms of Use.</span
          >
        </p>
        <p style="text-align: justify; line-height: 115%">
          <span style="font-family: 'Century Gothic'">In the Terms of Use:</span>
        </p>
        <ul
          style="
            margin: 0pt;
            padding-left: 0pt;
            list-style-image: url('images/Aspose.Words.5cecfde5-1970-453f-83e5-88c794ca7866.003.png');
          "
        >
          <li
            class="ListParagraph"
            style="
              margin-left: 32.14pt;
              margin-bottom: 0pt;
              text-align: justify;
              line-height: 115%;
              padding-left: 3.86pt;
              font-family: serif;
              font-size: 8pt;
              -aw-font-family: 'Symbol';
              -aw-font-weight: normal;
              -aw-number-format: '';
            "
          >
            <span
              style="
                line-height: 115%;
                font-family: 'Century Gothic';
                font-size: 11pt;
              "
              >the expressions “we”, “us” and “our” are a reference to Calysta.
              “Calysta” means Calysta NV/SA, a limited company under Belgian law
              with register number BE 0686.655.773 and registered office at 5A
              Lambroekstraat, 1831 Diegem (Belgium);</span
            >
          </li>
          <li
            class="ListParagraph"
            style="
              margin-left: 32.14pt;
              margin-bottom: 0pt;
              text-align: justify;
              line-height: 115%;
              padding-left: 3.86pt;
              font-family: serif;
              font-size: 8pt;
              -aw-font-family: 'Symbol';
              -aw-font-weight: normal;
              -aw-number-format: '';
            "
          >
            <span
              style="
                line-height: 115%;
                font-family: 'Century Gothic';
                font-size: 11pt;
              "
              >the expression “the Client” is a reference to any client to whom
              Calysta has invoiced services and/or for which Calysta is appointed as
              representative of IP assets in official registers and which is
              entitled to use the Client Portal;</span
            >
          </li>
          <li
            class="ListParagraph"
            style="
              margin-left: 32.14pt;
              margin-bottom: 0pt;
              text-align: justify;
              line-height: 115%;
              padding-left: 3.86pt;
              font-family: serif;
              font-size: 8pt;
              -aw-font-family: 'Symbol';
              -aw-font-weight: normal;
              -aw-number-format: '';
            "
          >
            <span
              style="
                line-height: 115%;
                font-family: 'Century Gothic';
                font-size: 11pt;
              "
              >the expression “Authorized User” refers to a person which is not a
              Client but which is expressly authorized by Calysta to use the Client
              Portal on behalf of a specific Client;</span
            >
          </li>
          <li
            class="ListParagraph"
            style="
              margin-left: 32.14pt;
              margin-bottom: 8pt;
              text-align: justify;
              line-height: 115%;
              padding-left: 3.86pt;
              font-family: serif;
              font-size: 8pt;
              -aw-font-family: 'Symbol';
              -aw-font-weight: normal;
              -aw-number-format: '';
            "
          >
            <span
              style="
                line-height: 115%;
                font-family: 'Century Gothic';
                font-size: 11pt;
              "
              >the expression “you” refers to any user of the Client Portal, i.e. a
              Client or an Authorized User.</span
            >
          </li>
        </ul>
        <p style="text-align: justify; line-height: 115%">
          <span style="font-family: 'Century Gothic'; -aw-import: ignore"
            >&#xa0;</span
          >
        </p>
        <p style="text-align: justify; line-height: 115%">
          <span style="font-family: 'Century Gothic'; -aw-import: ignore"
            >&#xa0;</span
          >
        </p>
        <p style="text-align: justify; line-height: 115%">
          <span style="font-family: 'Century Gothic'; -aw-import: ignore"
            >&#xa0;</span
          >
        </p>
        <ol type="1" style="margin: 0pt; padding-left: 0pt">
          <li
            class="ListParagraph"
            style="
              margin-left: 14.17pt;
              text-align: justify;
              padding-left: 0.03pt;
              font-family: 'Century Gothic';
              font-weight: bold;
            "
          >
            <span>Accessing and using information on the Client Portal</span>
          </li>
        </ol>
        <p style="text-align: justify; line-height: 115%">
          <span style="font-family: 'Century Gothic'"
            >We have sole discretion to decide which types of documents and data can
            be uploaded, downloaded or viewed on the Client Portal.</span
          >
        </p>
        <p style="text-align: justify; line-height: 115%">
          <span style="font-family: 'Century Gothic'"
            >In order to be able to access the information offered on the Client
            Portal, you may need to use a specific user ID, email address or other
            login information, such as a password (</span
          ><span
            style="font-family: 'Century Gothic'; font-weight: bold; color: #ff642c"
            >Login Information</span
          ><span style="font-family: 'Century Gothic'"
            >). You must not disclose your Login Information to any person or
            otherwise allow any person to access the Client Portal using your Login
            Information. In the event the Client wishes a third party to have access
            to the Client Portal, said third party must be approved in written by us
            prior any access and the becomes an “Authorized User”. In such an event,
            we will provide the Authorized User with personal Login Information. We
            have sole discretion to decide granting any access to third parties
            proposed by the Client and thus to determine who can become an
            Authorized User.</span
          >
        </p>
        <p style="text-align: justify; line-height: 115%">
          <span style="font-family: 'Century Gothic'">You are </span
          ><span
            style="font-family: 'Century Gothic'; font-weight: bold; color: #ff642c"
            >solely responsible</span
          ><span style="font-family: 'Century Gothic'; color: #ff642c"> </span
          ><span style="font-family: 'Century Gothic'"
            >for (1) authorizing, monitoring, controlling access to and maintaining
            strict confidentiality of your username and password, (2) not allowing
            another person to use your username or password, (3) any changes or
            damage that may be incurred as a result of your neglect to maintain the
            strict confidentiality of your username and password, and (4) promptly
            informing us in writing of any need to deactivate a username due to
            security concerns or otherwise. We are not liable for any harm related
            to the misuse or theft of usernames or passwords, disclosure of
            usernames or passwords, or your authorization to allow another person or
            entity to access and use the Client Portal using your username or
            password.</span
          >
        </p>
        <p style="text-align: justify; line-height: 115%">
          <span style="font-family: 'Century Gothic'"
            >You must not access or use the Client Portal for any purpose that is </span
          ><span
            style="font-family: 'Century Gothic'; font-weight: bold; color: #ff642c"
            >unlawful</span
          ><span style="font-family: 'Century Gothic'">.</span>
        </p>
        <p style="text-align: justify; line-height: 115%">
          <span style="font-family: 'Century Gothic'">You must take your </span
          ><span
            style="font-family: 'Century Gothic'; font-weight: bold; color: #ff642c"
            >own precautions</span
          ><span style="font-family: 'Century Gothic'; color: #ff642c"> </span
          ><span style="font-family: 'Century Gothic'"
            >to ensure that the process which you employ for accessing the Client
            Portal does not expose you to the risk of viruses, malicious computer
            code or other forms of interference which may damage your own computer
            system. For the avoidance of doubt, we do not accept responsibility for
            any interference or damage to your own computer system which arises in
            connection with your use of the Client Portal.</span
          >
        </p>
        <p style="text-align: justify; line-height: 115%">
          <span style="font-family: 'Century Gothic'">You agree to </span
          ><span
            style="font-family: 'Century Gothic'; font-weight: bold; color: #ff642c"
            >indemnify</span
          ><span style="font-family: 'Century Gothic'; color: #ff642c"> </span
          ><span style="font-family: 'Century Gothic'"
            >us and our employees and agents against all actions, claims and demands
            (including the cost of defending any action, claim or demand) which may
            be instituted against us arising out of a failure by you or by any
            person using your Login Information (whether or not you have authorized
            that person to use your Login Information) to comply with the Terms of
            Use.</span
          >
        </p>
        <p style="text-align: justify; line-height: 115%">
          <span style="font-family: 'Century Gothic'"
            >In order to keep track of the use of the Client Portal, Calysta has put
            in place a system where the access and the operations on the Client
            Portal are </span
          ><span
            style="font-family: 'Century Gothic'; font-weight: bold; color: #ff642c"
            >recorded</span
          ><span style="font-family: 'Century Gothic'"
            >. You accept that such tracking will constitute evidence of such access
            and operations.</span
          >
        </p>
        <p style="text-align: justify; line-height: 115%">
          <span style="font-family: 'Century Gothic'; -aw-import: ignore"
            >&#xa0;</span
          >
        </p>
        <ol start="2" type="1" style="margin: 0pt; padding-left: 0pt">
          <li
            class="ListParagraph"
            style="
              margin-left: 14.17pt;
              text-align: justify;
              padding-left: 0.03pt;
              font-family: 'Century Gothic';
              font-weight: bold;
            "
          >
            <span>Warnings regarding information on the Client Portal</span>
          </li>
        </ol>
        <p style="text-align: justify; line-height: 115%">
          <span style="font-family: 'Century Gothic'"
            >The information contained on the Client Portal is provided by us in </span
          ><span
            style="font-family: 'Century Gothic'; font-weight: bold; color: #ff642c"
            >good faith</span
          ><span style="font-family: 'Century Gothic'; color: #ff642c"> </span
          ><span style="font-family: 'Century Gothic'"
            >on an "as is" basis and all users must verify the information before
            using it in any way. We do not give any warranty that the information
            contained on the Client Portal is reliable, accurate or complete or that
            your access to that information will be uninterrupted, timely or secure.
            We do not accept responsibility for loss suffered as a result of
            reliance by you upon the accuracy or currency of information contained
            on the Client Portal.</span
          >
        </p>
        <p style="text-align: justify; line-height: 115%">
          <span style="font-family: 'Century Gothic'"
            >In particular, where the information made available on the Client
            Portal contains opinions or judgements of third parties, we do not
            purport to endorse the contents of that opinion or advice nor the
            accuracy or reliability of that opinion or advice.</span
          >
        </p>
        <p style="text-align: justify; line-height: 115%">
          <span style="font-family: 'Century Gothic'; -aw-import: ignore"
            >&#xa0;</span
          >
        </p>
        <ol start="3" type="1" style="margin: 0pt; padding-left: 0pt">
          <li
            class="ListParagraph"
            style="
              margin-left: 14.17pt;
              margin-bottom: 0pt;
              text-align: justify;
              padding-left: 0.03pt;
              font-family: 'Century Gothic';
              font-weight: bold;
            "
          >
            <span>Specific use of the Client Portal</span>
          </li>
        </ol>
        <p class="ListParagraph" style="margin-bottom: 0pt; text-align: justify">
          <span
            style="
              font-family: 'Century Gothic';
              font-weight: bold;
              -aw-import: ignore;
            "
            >&#xa0;</span
          >
        </p>
        <ol type="a" style="margin: 0pt; padding-left: 0pt">
          <li
            class="ListParagraph"
            style="
              margin-left: 14.17pt;
              margin-bottom: 8pt;
              text-align: justify;
              padding-left: 0.03pt;
              font-family: 'Century Gothic';
              font-weight: bold;
              font-style: italic;
            "
          >
            <span
              >Consultation and downloading of documents, data and other information
              by the Client or the Authorized User</span
            >
          </li>
        </ol>
        <p style="text-align: justify; line-height: 115%">
          <span style="font-family: 'Century Gothic'"
            >When consulting or downloading documents, data or other information
            form the Client Portal, the Client or the Authorized User will make sure </span
          ><span
            style="font-family: 'Century Gothic'; font-weight: bold; color: #ff642c"
            >not to alter</span
          ><span style="font-family: 'Century Gothic'; color: #ff642c"> </span
          ><span style="font-family: 'Century Gothic'"
            >in any way the information present on the Client Portal.</span
          >
        </p>
        <ol start="2" type="a" style="margin: 0pt; padding-left: 0pt">
          <li
            class="ListParagraph"
            style="
              margin-left: 14.2pt;
              margin-bottom: 8pt;
              text-align: justify;
              font-family: 'Century Gothic';
              font-weight: bold;
              font-style: italic;
            "
          >
            <span>Adding documents, data and information</span>
          </li>
        </ol>
        <p style="text-align: justify; line-height: 115%">
          <span style="font-family: 'Century Gothic'"
            >We shall not be responsible for verifying the origin, accuracy or
            completeness of any document, data or information downloaded by the
            Client or the Authorized User onto the Client Portal. The Client or the
            Authorized User shall make sure that all document, data or information
            downloaded by it onto the Client Portal </span
          ><span
            style="font-family: 'Century Gothic'; font-weight: bold; color: #ff642c"
            >can lawfully be so downloaded</span
          ><span style="font-family: 'Century Gothic'">.</span>
        </p>
        <ol start="3" type="a" style="margin: 0pt; padding-left: 0pt">
          <li
            class="ListParagraph"
            style="
              margin-left: 14.17pt;
              margin-bottom: 8pt;
              text-align: justify;
              padding-left: 0.03pt;
              font-family: 'Century Gothic';
              font-weight: bold;
              font-style: italic;
            "
          >
            <span>Giving instructions</span>
          </li>
        </ol>
        <p style="text-align: justify; line-height: 115%">
          <span style="font-family: 'Century Gothic'"
            >Any instruction given to us through the Client Portal shall be </span
          ><span
            style="font-family: 'Century Gothic'; font-weight: bold; color: #ff642c"
            >irrevocable</span
          ><span style="font-family: 'Century Gothic'"
            >, unless we confirm the revocation or change in the instruction before
            it is carried out. Proof of the instruction (or absence of instruction)
            given to us by the Client or the Authorized User can be based on the
            information contained in the Client Portal.</span
          >
        </p>
        <p style="text-align: justify; line-height: 115%">
          <span style="font-family: 'Century Gothic'; -aw-import: ignore"
            >&#xa0;</span
          >
        </p>
        <ol start="4" type="1" style="margin: 0pt; padding-left: 0pt">
          <li
            class="ListParagraph"
            style="
              margin-left: 14.17pt;
              margin-bottom: 8pt;
              text-align: justify;
              padding-left: 0.03pt;
              font-family: 'Century Gothic';
              font-weight: bold;
            "
          >
            <span>Service Availability</span>
          </li>
        </ol>
        <p style="text-align: justify; line-height: 115%">
          <span style="font-family: 'Century Gothic'">We will use our </span
          ><span
            style="font-family: 'Century Gothic'; font-weight: bold; color: #ff642c"
            >best efforts</span
          ><span style="font-family: 'Century Gothic'; color: #ff642c"> </span
          ><span style="font-family: 'Century Gothic'">to provide </span
          ><span
            style="font-family: 'Century Gothic'; font-weight: bold; color: #ff642c"
            >24 hours daily</span
          ><span style="font-family: 'Century Gothic'; color: #ff642c"> </span
          ><span style="font-family: 'Century Gothic'"
            >availability of the Client Portal. However, we make no representation
            or warranty that 24 hours service will be available, especially since a
            third party provides the software and hosting of the Client Portal. You
            agree and acknowledge that the Client Portal will, at times, be
            unavailable due to regularly scheduled maintenance, service upgrades, or
            other mechanical or electronic failures.</span
          >
        </p>
        <p style="text-align: justify; line-height: 115%">
          <span style="font-family: 'Century Gothic'; -aw-import: ignore"
            >&#xa0;</span
          >
        </p>
        <ol start="5" type="1" style="margin: 0pt; padding-left: 0pt">
          <li
            class="ListParagraph"
            style="
              margin-left: 14.17pt;
              margin-bottom: 8pt;
              text-align: justify;
              padding-left: 0.03pt;
              font-family: 'Century Gothic';
              font-weight: bold;
            "
          >
            <span>Limitation of liability </span>
          </li>
        </ol>
        <p style="text-align: justify; line-height: 115%">
          <span style="font-family: 'Century Gothic'">You agree that We shall </span
          ><span
            style="font-family: 'Century Gothic'; font-weight: bold; color: #ff642c"
            >not be responsible</span
          ><span style="font-family: 'Century Gothic'; color: #ff642c"> </span
          ><span style="font-family: 'Century Gothic'"
            >for any error, omission, interruption, deletion, defect, delay in
            operation or transmission, communications line failure, theft or
            destruction or unauthorized access to the Client Portal. We are not
            responsible for any problems or technical malfunctions of any telephone
            or fiber network or lines, computer online systems, servers or
            providers, computer equipment, software, failure of any email to be
            received by us on account of technical problems or traffic congestion on
            the internet or at any website, or any combination thereof, including
            any damage to your computer or peripherals related to use or downloading
            any materials from the Client Portal.</span
          >
        </p>
        <p style="text-align: justify; line-height: 115%">
          <span style="font-family: 'Century Gothic'"
            >We do not accept responsibility for any loss or damage, however caused
            (including through negligence), which any person may directly or
            indirectly suffer in connection with or arising from your use of the
            Client Portal or your use of or reliance on information contained on or
            accessed through the Client Portal. You acknowledge that the Client
            Portal is hosted by a third party and, without limiting the terms of
            this disclaimer, that we are not responsible for the act or omission of
            any third party.</span
          >
        </p>
        <p style="text-align: justify; line-height: 115%">
          <span style="font-family: 'Century Gothic'"
            >This disclaimer set out in the Terms of Use does not attempt to exclude
            or limit liability if, and to the extent, such liability cannot be
            lawfully excluded or limited or if it would be abusive. In particular,
            nothing in the Terms of Use purports to limit or exclude any liability
            for fraud.</span
          >
        </p>
        <p style="text-align: justify; line-height: 115%">
          <span style="font-family: 'Century Gothic'"
            >You agree that the limitations and exclusions set out in the Terms of
            Use are </span
          ><span
            style="font-family: 'Century Gothic'; font-weight: bold; color: #ff642c"
            >reasonable</span
          ><span style="font-family: 'Century Gothic'; color: #ff642c"> </span
          ><span style="font-family: 'Century Gothic'"
            >having regard to the relevant circumstances and the use you are
            permitted to make of the Client Portal.</span
          >
        </p>
        <p style="text-align: justify; line-height: 115%">
          <span style="font-family: 'Century Gothic'; -aw-import: ignore"
            >&#xa0;</span
          >
        </p>
        <ol start="6" type="1" style="margin: 0pt; padding-left: 0pt">
          <li
            class="ListParagraph"
            style="
              margin-left: 14.17pt;
              margin-bottom: 0pt;
              text-align: justify;
              padding-left: 0.03pt;
              font-family: 'Century Gothic';
              font-weight: bold;
            "
          >
            <span>Intellectual Property</span>
          </li>
        </ol>
        <p class="ListParagraph" style="margin-bottom: 0pt; text-align: justify">
          <span
            style="
              font-family: 'Century Gothic';
              font-weight: bold;
              -aw-import: ignore;
            "
            >&#xa0;</span
          >
        </p>
        <ol type="a" style="margin: 0pt; padding-left: 0pt">
          <li
            class="ListParagraph"
            style="
              margin-left: 14.17pt;
              margin-bottom: 8pt;
              text-align: justify;
              padding-left: 0.03pt;
              font-family: 'Century Gothic';
              font-weight: bold;
              font-style: italic;
            "
          >
            <span>General</span>
          </li>
        </ol>
        <p style="text-align: justify; line-height: 115%">
          <span
            style="font-family: 'Century Gothic'; font-weight: bold; color: #ff642c"
            >Except as expressly authorized</span
          ><span style="font-family: 'Century Gothic'; color: #ff642c"> </span
          ><span style="font-family: 'Century Gothic'"
            >by the Terms of Use or by us for a specific Client, you may not in any
            form or by any means, without our written permission, use any part of
            the Client Portal or any data or information obtained from the Client
            Portal for any purpose other than the business activities of the Client.
            Except as expressly authorized by us, you may not commercialize any
            data, information, products or services obtained from any part of the
            Client Portal.</span
          >
        </p>
        <p style="text-align: justify; line-height: 115%">
          <span style="font-family: 'Century Gothic'"
            >Any adaptation, reproduction, distribution, publication or creation
            derivative works from any part of the Client Portal, or any data or
            information obtained from the Client Portal is subject to our rights
            pursuant to article 6 of the Terms of Use.
          </span>
        </p>
        <p style="text-align: justify; line-height: 115%">
          <span style="font-family: 'Century Gothic'"
            >Intellectual Property in the Client Portal, including but not limited
            to copyright (including text, graphics, logos, icons, sound recordings,
            graphic interfaces and software), may be owned or licensed by us.
            Information procured from a third party may be the subject of
            intellectual property right owned by that third party.</span
          >
        </p>
        <ol start="2" type="a" style="margin: 0pt; padding-left: 0pt">
          <li
            class="ListParagraph"
            style="
              margin-left: 14.2pt;
              margin-bottom: 8pt;
              text-align: justify;
              font-family: 'Century Gothic';
              font-weight: bold;
            "
          >
            <span>Patent</span>
          </li>
        </ol>
        <p style="text-align: justify; line-height: 115%">
          <span style="font-family: 'Century Gothic'"
            >The Client Portal technology is protected under the Belgian
            Patent</span
          ><a name="_ftnref1"></a
          ><a href="#_ftn1" style="text-decoration: none"
            ><span
              class="FootnoteReference"
              style="
                font-family: 'Century Gothic';
                font-size: 7.33pt;
                color: #000000;
              "
              >[1]</span
            ></a
          ><span style="font-family: 'Century Gothic'">
            nr BE 2021/5107 owned by us.
          </span>
        </p>
        <ol start="3" type="a" style="margin: 0pt; padding-left: 0pt">
          <li
            class="ListParagraph"
            style="
              margin-left: 14.17pt;
              margin-bottom: 8pt;
              text-align: justify;
              padding-left: 0.03pt;
              font-family: 'Century Gothic';
              font-weight: bold;
            "
          >
            <span>Trademarks</span>
          </li>
        </ol>
        <p style="text-align: justify; line-height: 115%">
          <span style="font-family: 'Century Gothic'"
            >The Client Portal includes different trademarks owned by us, a.o.
          </span>
        </p>
        <p style="text-align: justify; line-height: 115%">
          <span style="font-family: 'Century Gothic'; background-color: #fcfdfd"
            >EU figurative trademark reg. nr 018002551 </span
          ><img
            src="images/Aspose.Words.5cecfde5-1970-453f-83e5-88c794ca7866.004.png"
            width="22"
            height="22"
            alt="Icon&#xA;&#xA;Description automatically generated"
            style="
              -aw-left-pos: 0pt;
              -aw-rel-hpos: column;
              -aw-rel-vpos: paragraph;
              -aw-top-pos: 0pt;
              -aw-wrap-type: inline;
            "
          />
        </p>
        <p style="text-align: justify; line-height: 115%">
          <span style="font-family: 'Century Gothic'; background-color: #fcfdfd"
            >EU semi-figurative trademark reg. nr 018002552 </span
          ><img
            src="images/Aspose.Words.5cecfde5-1970-453f-83e5-88c794ca7866.005.png"
            width="68"
            height="29"
            alt="A picture containing background pattern&#xA;&#xA;Description automatically generated"
            style="
              -aw-left-pos: 0pt;
              -aw-rel-hpos: column;
              -aw-rel-vpos: paragraph;
              -aw-top-pos: 0pt;
              -aw-wrap-type: inline;
            "
          />
        </p>
        <p style="text-align: justify; line-height: 115%">
          <span style="font-family: 'Century Gothic'; background-color: #fcfdfd"
            >EU word trademark reg. nr </span
          ><span
            class="highlightinfo"
            style="font-family: 'Century Gothic'; background-color: #fcfdfd"
            >017523581 </span
          ><span
            class="highlightinfo"
            style="
              font-family: 'Century Gothic';
              font-weight: bold;
              background-color: #fcfdfd;
            "
            >CALYSTA</span
          >
        </p>
        <p style="text-align: justify; line-height: 115%">
          <span style="font-family: 'Century Gothic'">You </span
          ><span
            style="font-family: 'Century Gothic'; font-weight: bold; color: #ff642c"
            >must not use</span
          ><span style="font-family: 'Century Gothic'; color: #ff642c"> </span
          ><span style="font-family: 'Century Gothic'">any of our trademarks </span>
        </p>
        <ul
          style="
            margin: 0pt;
            padding-left: 0pt;
            list-style-image: url('images/Aspose.Words.5cecfde5-1970-453f-83e5-88c794ca7866.003.png');
          "
        >
          <li
            class="ListParagraph"
            style="
              margin-left: 32.14pt;
              margin-bottom: 0pt;
              text-align: justify;
              line-height: 115%;
              padding-left: 3.86pt;
              font-family: serif;
              font-size: 8pt;
              -aw-font-family: 'Symbol';
              -aw-font-weight: normal;
              -aw-number-format: '';
            "
          >
            <span
              style="
                line-height: 115%;
                font-family: 'Century Gothic';
                font-size: 11pt;
              "
              >in connection with activities, products or services which are not
              ours;</span
            >
          </li>
          <li
            class="ListParagraph"
            style="
              margin-left: 32.14pt;
              margin-bottom: 0pt;
              text-align: justify;
              line-height: 115%;
              padding-left: 3.86pt;
              font-family: serif;
              font-size: 8pt;
              -aw-font-family: 'Symbol';
              -aw-font-weight: normal;
              -aw-number-format: '';
            "
          >
            <span
              style="
                line-height: 115%;
                font-family: 'Century Gothic';
                font-size: 11pt;
              "
              >in a manner which may be confusing, misleading or deceptive;</span
            >
          </li>
          <li
            class="ListParagraph"
            style="
              margin-left: 32.14pt;
              margin-bottom: 8pt;
              text-align: justify;
              line-height: 115%;
              padding-left: 3.86pt;
              font-family: serif;
              font-size: 8pt;
              -aw-font-family: 'Symbol';
              -aw-font-weight: normal;
              -aw-number-format: '';
            "
          >
            <span
              style="
                line-height: 115%;
                font-family: 'Century Gothic';
                font-size: 11pt;
              "
              >in a manner that disparages us or our information or services
              (including the Client Portal).</span
            >
          </li>
        </ul>
        <p style="text-align: justify; line-height: 115%">
          <span style="font-family: 'Century Gothic'"
            >You must not authorize or assist any person to do any of the acts
            specified above.</span
          >
        </p>
        <ol start="4" type="a" style="margin: 0pt; padding-left: 0pt">
          <li
            class="ListParagraph"
            style="
              margin-left: 14.2pt;
              margin-bottom: 8pt;
              text-align: justify;
              font-family: 'Century Gothic';
              font-weight: bold;
            "
          >
            <span>Ownership</span>
          </li>
        </ol>
        <p style="text-align: justify; line-height: 115%">
          <span style="font-family: 'Century Gothic'"
            >For all items that would not be covered under 6. a, b and c, all
            content, including imagery and documentation that is published on the
            Client Portal is the </span
          ><span
            style="font-family: 'Century Gothic'; font-weight: bold; color: #ff642c"
            >property of Calysta</span
          ><span style="font-family: 'Century Gothic'"
            >, unless evidence to the contrary is provided.</span
          >
        </p>
        <p style="text-align: justify; line-height: 115%">
          <span style="font-family: 'Century Gothic'; -aw-import: ignore"
            >&#xa0;</span
          >
        </p>
        <ol start="7" type="1" style="margin: 0pt; padding-left: 0pt">
          <li
            class="ListParagraph"
            style="
              margin-left: 14.17pt;
              margin-bottom: 8pt;
              text-align: justify;
              padding-left: 0.03pt;
              font-family: 'Century Gothic';
              font-weight: bold;
            "
          >
            <span>Restricted Use/Confidentiality</span>
          </li>
        </ol>
        <p style="text-align: justify; line-height: 115%">
          <span style="font-family: 'Century Gothic'"
            >Unless we agree otherwise in writing, you are provided with access to
            the Client Portal for your use only in the framework of your
            relationship with Calysta. Without limiting the foregoing, you may not
            without our written permission on sell nor share information obtained
            from the Client Portal to any third party. These </span
          ><span
            style="font-family: 'Century Gothic'; font-weight: bold; color: #ff642c"
            >obligations of confidentiality</span
          ><span style="font-family: 'Century Gothic'; color: #ff642c"> </span
          ><span style="font-family: 'Century Gothic'"
            >do not apply to any information which is already in the public domain,
            other than through a breach by you of this obligation, or which is
            required to be disclosed by law or a regulatory body.</span
          >
        </p>
        <p style="text-align: justify; line-height: 115%">
          <span style="font-family: 'Century Gothic'"
            >Unauthorized copying of the Portal or part thereof is </span
          ><span
            style="font-family: 'Century Gothic'; font-weight: bold; color: #ff642c"
            >expressly forbidden</span
          ><span style="font-family: 'Century Gothic'"
            >. You agree that You will neither copy or duplicate or permit anyone
            else to copy or duplicate the Client Portal or part thereof, or
            (attempt) to create, or permit others to (attempt to) create, by reverse
            engineering or otherwise, the Saas or any part thereof from the Portal
            or from other information made available under the terms of Use.
          </span>
        </p>
        <p style="text-align: justify; line-height: 115%">
          <span style="font-family: 'Century Gothic'; -aw-import: ignore"
            >&#xa0;</span
          >
        </p>
        <ol start="8" type="1" style="margin: 0pt; padding-left: 0pt">
          <li
            class="ListParagraph"
            style="
              margin-left: 14.17pt;
              margin-bottom: 8pt;
              text-align: justify;
              padding-left: 0.03pt;
              font-family: 'Century Gothic';
              font-weight: bold;
            "
          >
            <span>Privacy Policy</span>
          </li>
        </ol>
        <p style="text-align: justify; line-height: 115%">
          <span style="font-family: 'Century Gothic'"
            >We undertake to comply with the terms of our privacy policy which is
            accessible at </span
          ><a
            href="http://www.calysta.eu/wp-content/uploads/2018/12/Calysta-privacy-policy_Website.pdf"
            style="text-decoration: none"
            ><span
              class="Hyperlink"
              style="
                line-height: 115%;
                font-family: 'Century Gothic';
                font-size: 10pt;
              "
              >http://www.calysta.eu/wp-content/uploads/2018/12/Calysta-privacy-policy_Website.pdf</span
            ></a
          ><span style="font-family: 'Century Gothic'"> </span>
        </p>
        <p style="text-align: justify; line-height: 115%">
          <span style="font-family: 'Century Gothic'; -aw-import: ignore"
            >&#xa0;</span
          >
        </p>
        <ol start="9" type="1" style="margin: 0pt; padding-left: 0pt">
          <li
            class="ListParagraph"
            style="
              margin-left: 14.17pt;
              margin-bottom: 8pt;
              text-align: justify;
              padding-left: 0.03pt;
              font-family: 'Century Gothic';
              font-weight: bold;
            "
          >
            <span>Security of Information</span>
          </li>
        </ol>
        <p style="text-align: justify; line-height: 115%">
          <span style="font-family: 'Century Gothic'"
            >Unfortunately, no data transmission over the Internet can be guaranteed
            as totally secure. Whilst we strive to protect such information, </span
          ><span
            style="font-family: 'Century Gothic'; font-weight: bold; color: #ff642c"
            >we do not</span
          ><span style="font-family: 'Century Gothic'; color: #ff642c"> </span
          ><span
            style="font-family: 'Century Gothic'; font-weight: bold; color: #ff642c"
            >warrant</span
          ><span style="font-family: 'Century Gothic'">
            and cannot ensure the security of any information which you transmit to
            us through the Client Portal and vice-versa.</span
          >
        </p>
        <p
          style="text-align: justify; line-height: 115%; background-color: #ffffff"
        >
          <span style="font-family: 'Century Gothic'; -aw-import: ignore"
            >&#xa0;</span
          >
        </p>
        <ol start="10" type="1" style="margin: 0pt; padding-left: 0pt">
          <li
            class="ListParagraph"
            style="
              margin-left: 20.29pt;
              text-align: justify;
              padding-left: 1.01pt;
              font-family: 'Century Gothic';
              font-weight: bold;
              background-color: #ffffff;
            "
          >
            <span>Annual subscription fee</span>
          </li>
        </ol>
        <p
          style="
            margin-bottom: 0pt;
            text-align: justify;
            line-height: 115%;
            background-color: #ffffff;
          "
        >
          <span style="font-family: 'Century Gothic'"
            >After a free of charge test period of 3 months and upon your explicit
            acceptance, the access to the Portal (silver and gold versions) is
            subject to the payment of an upfront annual subscription fee.</span
          >
        </p>
        <p
          style="
            margin-bottom: 0pt;
            text-align: justify;
            line-height: 115%;
            background-color: #ffffff;
          "
        >
          <span style="font-family: 'Century Gothic'; -aw-import: ignore"
            >&#xa0;</span
          >
        </p>
        <p
          style="
            margin-bottom: 0pt;
            text-align: justify;
            line-height: 115%;
            background-color: #ffffff;
          "
        >
          <span style="font-family: 'Century Gothic'"
            >Unless otherwise instructed one month before the expiry of the
            subscription, the subscription will be renewed </span
          ><span
            style="font-family: 'Century Gothic'; font-weight: bold; color: #ff642c"
            >automatically</span
          ><span style="font-family: 'Century Gothic'; color: #ff642c"> </span
          ><span style="font-family: 'Century Gothic'"
            >for a period of one year.</span
          >
        </p>
        <p
          style="
            margin-bottom: 0pt;
            text-align: justify;
            line-height: 115%;
            background-color: #ffffff;
          "
        >
          <span style="font-family: 'Century Gothic'; -aw-import: ignore"
            >&#xa0;</span
          >
        </p>
        <p
          style="
            margin-bottom: 0pt;
            text-align: justify;
            line-height: 115%;
            background-color: #ffffff;
          "
        >
          <span style="font-family: 'Century Gothic'"
            >This annual subscription fee does not cover integration and update in
            the Portal of data related to IP rights CALYSTA is not in charge of
            (i.e. IP rights portfolio managed by another IP firm of directly by
            You). Such integrations and updates can be dealt with by CALYSTA on the
            basis of a flat fee previously communicated and agreed upon by
            You.</span
          >
        </p>
        <p
          style="
            margin-bottom: 0pt;
            text-align: justify;
            line-height: 115%;
            background-color: #ffffff;
          "
        >
          <span style="font-family: 'Century Gothic'; -aw-import: ignore"
            >&#xa0;</span
          >
        </p>
        <ol start="11" type="1" style="margin: 0pt; padding-left: 0pt">
          <li
            class="ListParagraph"
            style="
              margin-left: 20.29pt;
              margin-bottom: 8pt;
              text-align: justify;
              padding-left: 1.01pt;
              font-family: 'Century Gothic';
              font-weight: bold;
              background-color: #ffffff;
            "
          >
            <span>Termination and Suspension of Access</span>
          </li>
        </ol>
        <p
          style="text-align: justify; line-height: 115%; background-color: #ffffff"
        >
          <span style="font-family: 'Century Gothic'"
            >Access to the Client Portal may be adapted at any time by us without
            notice. It can be terminated with a </span
          ><span
            style="font-family: 'Century Gothic'; font-weight: bold; color: #ff642c"
            >reasonable notice</span
          ><span style="font-family: 'Century Gothic'; color: #ff642c"> </span
          ><span style="font-family: 'Century Gothic'"
            >and it will in any event be terminated at the moment that you are no
            longer a Client. The Terms of Use will nevertheless survive any such
            termination.</span
          >
        </p>
        <p style="text-align: justify; line-height: 115%">
          <span style="font-family: 'Century Gothic'"
            >In addition, access to the Client Portal may be </span
          ><span
            style="font-family: 'Century Gothic'; font-weight: bold; color: #ff642c"
            >suspended</span
          ><span style="font-family: 'Century Gothic'; color: #ff642c"> </span
          ><span style="font-family: 'Century Gothic'"
            >in the event the Client does not comply with its obligation in our
            relationship, including in case of non-payment of our invoices.
          </span>
        </p>
        <p style="text-align: justify; line-height: 115%">
          <span style="font-family: 'Century Gothic'"
            >In the event that you would require that information that is kept on
            the Client Portal is communicated to you when your access is terminated,
            you should inform us by email within six months from the termination of
            such access. After such period, we will not be under any obligation to
            communicate any information that is or was on the Client Portal.</span
          >
        </p>
        <p style="text-align: justify; line-height: 115%">
          <span style="font-family: 'Century Gothic'; -aw-import: ignore"
            >&#xa0;</span
          >
        </p>
        <ol start="12" type="1" style="margin: 0pt; padding-left: 0pt">
          <li
            class="ListParagraph"
            style="
              margin-left: 20.29pt;
              margin-bottom: 8pt;
              text-align: justify;
              padding-left: 1.01pt;
              font-family: 'Century Gothic';
              font-weight: bold;
            "
          >
            <span>Termination of relationship</span>
          </li>
        </ol>
        <p style="text-align: justify; line-height: 115%">
          <span style="font-family: 'Century Gothic'"
            >In the event of termination of the relationship between the Client and
            us, the Client (or its Authorized Representative) shall </span
          ><span
            style="font-family: 'Century Gothic'; font-weight: bold; color: #ff642c"
            >retrieve</span
          ><span style="font-family: 'Century Gothic'; color: #ff642c"> </span
          ><span style="font-family: 'Century Gothic'"
            >from the Client Portal the data and information relating to it (as of
            the date of the end of the relationship), in order to transfer the
            necessary information and documentation to the person which would
            take-over our role. Such retrieval should take place no later than two
            weeks after the relationship is terminated.</span
          >
        </p>
        <p style="text-align: justify; line-height: 115%">
          <span style="font-family: 'Century Gothic'; -aw-import: ignore"
            >&#xa0;</span
          >
        </p>
        <ol start="13" type="1" style="margin: 0pt; padding-left: 0pt">
          <li
            class="ListParagraph"
            style="
              margin-left: 20.29pt;
              margin-bottom: 8pt;
              text-align: justify;
              padding-left: 1.01pt;
              font-family: 'Century Gothic';
              font-weight: bold;
            "
          >
            <span>Cookies</span>
          </li>
        </ol>
        <p style="text-align: justify; line-height: 115%">
          <span style="font-family: 'Century Gothic'"
            >We may collect general information such as the pages you access, the
            date and time of your visit, IP address, and the domain name and country
            from which you access the Client Portal. This includes the use of
            "cookies". You can set your browser to refuse all or some browser
            cookies, or to alert you when websites set or access cookies. If you
            disable or refuse cookies, please note that some parts of the Client
            Portal may become inaccessible or not function properly.</span
          >
        </p>
        <p style="text-align: justify; line-height: 115%">
          <span style="font-family: 'Century Gothic'; -aw-import: ignore"
            >&#xa0;</span
          >
        </p>
        <ol start="14" type="1" style="margin: 0pt; padding-left: 0pt">
          <li
            class="ListParagraph"
            style="
              margin-left: 20.29pt;
              margin-bottom: 8pt;
              text-align: justify;
              padding-left: 1.01pt;
              font-family: 'Century Gothic';
              font-weight: bold;
            "
          >
            <span>General</span>
          </li>
        </ol>
        <p style="text-align: justify; line-height: 115%">
          <span style="font-family: 'Century Gothic'"
            >We accept no liability for any failure to comply with the Terms of Use
            or our engagements regarding the Client Portal where such failure is due
            to circumstance </span
          ><span
            style="font-family: 'Century Gothic'; font-weight: bold; color: #ff642c"
            >beyond our reasonable control</span
          ><span style="font-family: 'Century Gothic'"
            >. If we waive any rights available to us under the Terms of Use on one
            occasion, this does not mean that those rights will automatically be
            waived on any other occasion. If any of the Terms of Use are held to be
            invalid, unenforceable or illegal for any reason, the remaining Terms of
            Use shall nevertheless continue in full force.</span
          >
        </p>
        <p
          class="CalNormal"
          style="text-align: center; line-height: 120%; font-size: 11pt"
        >
          <span style="font-weight: bold; -aw-import: ignore">&#xa0;</span>
        </p>
      </div>
      <hr
        style="
          width: 33%;
          height: 1px;
          text-align: left;
          -aw-footnote-numberstyle: 0;
          -aw-footnote-startnumber: 1;
          -aw-footnote-type: 0;
        "
      />
      <div id="_ftn1" style="-aw-footnote-isauto: 1">
        <p class="FootnoteText" style="font-size: 8pt">
          <a href="#_ftnref1" style="text-decoration: none"
            ><span
              class="FootnoteReference"
              style="
                font-family: 'Century Gothic';
                font-size: 5.33pt;
                color: #000000;
              "
              >[1]</span
            ></a
          ><span style="font-family: 'Century Gothic'">
            Patent pending. Publication foreseen for August 2022. Patent application
            can be shared with anybody interested.</span
          >
        </p>
      </div>
</div>

<div style="margin-top: 15px;">
    <dx-button
        stylingMode="outlined"
        text="Accept"
        type="success"
        [width]="120"
        (onClick)="AcceptTAndC($event)">
    </dx-button>
</div>