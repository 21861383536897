import { Component, Input, OnInit } from "@angular/core";
import DataSource from "devextreme/data/data_source";
import { IPFamilyCost } from "src/app/shared/models/financial/ipFamilyCosts.model";
import { IPAssetFamily } from "src/app/shared/models/ipassetfamily.model";
import { FinancialService } from "src/app/shared/services/Financial/financial.service";

@Component({
  selector: "app-financialdetail",
  templateUrl: "./financialdetail.component.html",
  styleUrls: ["./financialdetail.component.scss"],
})
export class FinancialdetailComponent implements OnInit {
  @Input() FamilyFin: IPAssetFamily;

  dataSource: any;
  familyCosts: IPFamilyCost[];

  constructor(private finService: FinancialService) {}

  ngOnInit() {
    this.finService
      .getFamilyCosts(this.FamilyFin.family)
      .subscribe((res: IPFamilyCost[]) => {
        this.dataSource = new DataSource({
          store: res,
          key: "Id",
        });
        this.familyCosts = res;
      });
  }
}
