<mat-card class="tileCard">
  <mat-card-header>
    <div mat-card-avatar class="example-header-image"></div>
    <mat-card-title class="card-container-left"
      >Selection of New Documents</mat-card-title
    >
  </mat-card-header>
  <mat-card-content class="documentTable">
    <dx-data-grid
      id="documentGrid"
      keyExpr="DocumentId"
      [dataSource]="DataSource"
      [showBorders]="false"
      [rowAlternationEnabled]="true"
      [allowColumnReordering]="false"
      class="DocumentOverviewGrid"
    >
      <dxo-paging [pageSize]="5"></dxo-paging>
      <dxo-pager [visible]="false"> </dxo-pager>
      <dxi-column
        [allowSorting]="false"
        dataField="creationDate"
        sortOrder="desc"
        caption="Date"
        dataType="date"
        [width]="100"
      ></dxi-column>
      <dxi-column
        [allowSorting]="false"
        dataField="calystaReference"
        caption="Calysta Ref."
        [width]="150"
      ></dxi-column>
      <dxi-column
        [allowSorting]="false"
        dataField="description"
        caption="Name"
      ></dxi-column>
      <dxi-column type="buttons" [width]="75" caption="">
        <dxi-button
          hint="Preview Related Document"
          icon="search"
          [onClick]="previewRelatedDocument"
        ></dxi-button>
        <dxi-button
          hint="Download Related Document"
          icon="download"
          [onClick]="downloadRelatedDocument"
        ></dxi-button>
      </dxi-column>
    </dx-data-grid>
  </mat-card-content>
  <mat-card-footer>
    <button center mat-button (click)="ToOverviewClick()">
      > To Documents Overview
    </button>
  </mat-card-footer>
</mat-card>

<dx-popup
  [width]="800"
  [height]="680"
  [showTitle]="true"
  [title]="PopupTitle"
  [dragEnabled]="false"
  [closeOnOutsideClick]="true"
  [(visible)]="PopupVisible"
>
  <pdf-preview [BlobFile]="documentPreview" />
</dx-popup>
