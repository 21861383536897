import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { IPAssetParty } from "src/app/shared/models/IPAssets/ipassetparty.model";
import { IPAssetService } from "src/app/shared/services/IPAsset.service";

@Component({
  selector: "app-parties",
  templateUrl: "./parties.component.html",
  styleUrls: ["./parties.component.scss"],
})
export class PartiesComponent implements OnChanges {
  @Input()
  assetId: number;

  partiesdata: IPAssetParty[];

  constructor(private ipAssetSvc: IPAssetService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.assetId) {
      this.ipAssetSvc
        .getIPAssetParties(this.assetId)
        .subscribe((res: any) => {
          this.partiesdata = res.data;
        });
    }
  }
}
