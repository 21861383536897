import { Injectable } from "@angular/core";
import { Adapter } from "../../adapter/adapter";

export class LoginResponse {
  constructor(
    public Expires: Date,
    public Issued: Date,
    public AccessToken: string,
    public ExpiresIn: number,
    public RefreshToken: string,
    public Roles: string[],
    public TokenType: string,
    public Username: string,
    public TwoFactorEnabled: boolean
  ) {}
}

@Injectable({
  providedIn: "root",
})
export class LoginResponseAdapter implements Adapter<LoginResponse> {
  adapt(item: any): LoginResponse {
    return new LoginResponse(
      item.expires,
      item.issued,
      item.access_token,
      item.expires_in,
      item.refresh_token,
      item.roles,
      item.token_type,
      item.username,
      item.twofactorenabled
    );
  }
}
