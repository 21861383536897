<mat-card-header>
  <h2><U>Data permissions</U></h2>
</mat-card-header>
<mat-card-content>
  <dx-data-grid
    [dataSource]="userOrganizationPermissions"
    (onRowInserted)="InsertPermission($event)"
    (onRowUpdated)="UpdatePermission($event)"
    (onRowRemoved)="RemovePermission($event)"
  >
    <dxo-editing
      mode="row"
      [allowUpdating]="true"
      [allowDeleting]="true"
      [allowAdding]="true"
    >
    </dxo-editing>
    <dxi-column
      dataField="OrganizationId"
      caption="Organization"
      [editorOptions]="{ minSearchLength: 3 }"
    >
      <dxi-validation-rule type="required"></dxi-validation-rule>
      <dxo-lookup
        [dataSource]="organizationDictionary"
        valueExpr="keyValue"
        displayExpr="stringValue"
        minSearchLength="3"
      >
      </dxo-lookup>
    </dxi-column>
    <dxi-column dataField="OrganizationRoleTypeId" caption="Roles">
      <dxi-validation-rule type="required"></dxi-validation-rule>
      <dxo-lookup
        [dataSource]="rolesDictionary"
        valueExpr="keyValue"
        displayExpr="stringValue"
      >
      </dxo-lookup>
    </dxi-column>
    <dxi-column dataField="CaseTypeId" caption="Case Type">
      <dxo-lookup
        [dataSource]="caseMasterDictionary"
        valueExpr="keyValue"
        displayExpr="stringValue"
        [allowClearing]="true"
      >
      </dxo-lookup>
    </dxi-column>
  </dx-data-grid>
</mat-card-content>
