import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { User } from '../models/user';
import { environment } from 'src/environments/environment';

const apiUrl = environment.baseUrl;

@Injectable({
  providedIn: 'root'
})
export class SubscriptionTypeService {

  constructor( private http: HttpClient) {
  }

  getUserSubscriptionModel() {
    return this.http.get<number>(apiUrl + '/api/accounts/user/getsubscriptiontype');
  }
}
