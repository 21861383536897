import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import { BehaviorSubject, Observable, throwError } from "rxjs";

import { AuthService } from "./Authentication/auth.service";
import { LoginResponseAdapter } from "../models/Authentication/loginresponse";
import { catchError, filter, switchMap, take } from "rxjs/operators";

// Intercepter which makes sure that every outgoing HTTP
// Gets the required token in the header to allow authentication
// Should always pass the token
// TODO: Make something to refresh the token maybe?
// towards the Calysta API.
@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );

  constructor(
    private authenticationService: AuthService,
    private loginReponseAdapter: LoginResponseAdapter
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // add authorization header with jwt token if available
    const currentUser = this.authenticationService.currentUserValue;
    const token = this.authenticationService.accessTokenValue;

    if (currentUser && token) {
      //if not expired, add token to header
      if (!this.isTokenExpired()) {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`,
          },
          withCredentials: true,
        });
      } else {
        this.authenticationService.logOut();

        /* todo, not workig for now but almost
        if (!this.isRefreshing) {
          this.isRefreshing = true;
          this.refreshTokenSubject.next(null);

          return this.authenticationService.refreshToken().pipe(
            switchMap((token: any) => {
              this.isRefreshing = false;
              this.refreshTokenSubject.next(token.accessToken);

              request = request.clone({
                setHeaders: {
                  Authorization: `Bearer ${token.accessToken}`,
                },
                withCredentials: true,
              });

              return next.handle(request);
            }),
            catchError((error) => {
              this.isRefreshing = false;
              // Handle refresh token failure (e.g., sign out the user)
              //this.authenticationService.logOut();
              return throwError(error);
            })
          );
        } else {
          this.refreshTokenSubject.pipe(
            filter((token) => token !== null),
            take(1),
            switchMap((token) => {
              request = request.clone({
                setHeaders: {
                  Authorization: `Bearer ${token}`,
                },
                withCredentials: true,
              });

              return next.handle(request);
            })
          );
        }*/
      }
    }

    return next.handle(request);
  }

  isTokenExpired(): boolean {
    // check if token is expired
    // if expired, refresh token
    const currentUser = this.authenticationService.currentUserValue;
    // Convert the expiration time string to a Date object
    const expirationTime = new Date(currentUser.Expires);

    // Get the current time
    const currentTime = new Date();

    const timeDifference = expirationTime.getTime() - currentTime.getTime();
    const fiveMinutes = 5 * 60 * 1000; // 5 minutes in milliseconds

    return timeDifference <= 0 || timeDifference <= fiveMinutes;
  }
}
