import { Component, OnInit, ViewChild } from "@angular/core";
import { IPServiceService } from "src/app/shared/services/IPService.service";
import { Router } from "@angular/router";
import DataSource from "devextreme/data/data_source";

import { IPService } from "src/app/shared/models/ipservice.model";
import { DxDataGridComponent } from "devextreme-angular";
import { ERPSegmentService } from "src/app/shared/services/ERP/erp.segment.service";
import { ERPSegment } from "src/app/shared/models/ERP/segment.erp.model";
@Component({
  selector: "app-ipserviceoverview",
  templateUrl: "./ipserviceoverview.component.html",
  styleUrls: ["./ipserviceoverview.component.scss"],
})
export class IpserviceoverviewComponent implements OnInit {
  @ViewChild(DxDataGridComponent) dataGrid: DxDataGridComponent;
  dataSource: DataSource;
  url: string;
  Logo: string;

  segments: ERPSegment[];

  showClosedCases = false;

  constructor(
    private ipService: IPServiceService,
    private segmentService: ERPSegmentService,
    private router: Router
  ) {
    this.onDetailsClick = this.onDetailsClick.bind(this);
  }

  onDetailsClick(e) {
    this.router.navigate(["/ipservice/" + e.row.data.AssetId]);
  }

  ngOnInit() {
    this.ipService.getAllServices(this.showClosedCases).subscribe(
      (res: IPService[]) => {
        this.dataSource = new DataSource({
          store: res,
          key: "AssetId",
        });
      },
      () => {}
    );

    this.segmentService.getContactSegments().subscribe((res: ERPSegment[]) => {
      this.segments = res;
    });
  }

  onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift(
      {
        location: "after",
        widget: "dxButton",
        options: {
          width: 200,
          text: "Show Closed Cases",
          onClick: this.collapseAllClick.bind(this),
        },
      },
      {
        location: "after",
        widget: "dxButton",
        options: {
          icon: "refresh",
          text: "Clear",
          hint: "Clear all filters",
          onClick: this.refreshDataGrid.bind(this),
        },
      }
    );
  }

  refreshDataGrid() {
    this.dataGrid.instance.clearFilter();
  }

  collapseAllClick(e) {
    this.showClosedCases = !this.showClosedCases;

    this.ipService.getAllServices(this.showClosedCases).subscribe(
      (res: IPService[]) => {
        this.dataSource = new DataSource({
          store: res,
          key: "AssetId",
        });
      },
      () => {},
      () => {}
    );

    this.dataGrid.instance.option("dataSource", this.dataSource);
    this.dataGrid.instance.getDataSource().reload();

    e.component.option({
      text: this.showClosedCases ? "Hide Closed Cases" : "Show Closed Cases",
    });
  }

  cellTemplate(container, options) {
    // tslint:disable-next-line:one-variable-per-declaration
    const noBreakSpace = "\u00A0",
      text = (options.value || [])
        .map((element) => {
          return options.column.lookup.calculateCellValue(element);
        })
        .join(", ");
    container.textContent = text || noBreakSpace;
    container.title = text;
  }

  calculateFilterExpression(filterValue, selectedFilterOperation, target) {
    if (target === "search" && typeof filterValue === "string") {
      return [(this as any).dataField, "contains", filterValue];
    }

    return (data) => (data.segments || []).indexOf(filterValue) !== -1;
  }
}
