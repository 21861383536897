import { ApplicationType } from './applicationType.model';
import { Injectable } from '@angular/core';
import { Adapter } from '../adapter/adapter';

export class IPAsset {
  constructor(
    public ClientId: number,
    public assetId: number,
    public clientReference: string,
    public calystaReference: string,
    public family: string,
    public applicationType: string,
    public applicationTypeId: number,
    public caseType: string,
    public caseTypeLabel: string,
    public catchword: string,
    public caseTypeId: number,
    public country: string,
    public countryId: string,
    public mappedStatusLabel: string,
    public mappedStatusId: number,
    public statusId: number,
    public statusLabel: string,
    public applicationNumber: string,
    public filedDate: Date,
    public publicationDate: Date,
    public publicationNumber: string,
    public registrationNumber: string,
    public registrationDate: Date,
    public renewalDueDate: Date,
    public classes: string,
    public secondaryLawFirm: string,
    public secondaryLawFirmReference: string,
    public segments: [],
    public HasLogo: boolean) {}
  }

@Injectable({
    providedIn: 'root'
  })
  export class IpAssetAdapter implements Adapter<IPAsset> {
    adapt(item: any): IPAsset {
      return new IPAsset (
        item.clientId,
        item.assetId,
        item.clientReference,
        item.calystaReference,
        item.family,
        item.applicationType,
        item.applicationTypeId,
        item.caseType,
        item.caseTypeLabel,
        item.catchword,
        item.caseTypeId,
        item.country,
        item.countryId,
        item.statusLabel,
        item.statusId,
        item.mappedStatusLabel,
        item.mappedStatusId,
        item.applicationNumber,
        item.filedDate,
        item.publicationDate,
        item.publicationNumber,
        item.registrationNumber,
        item.registrationDate,
        item.renewalDueDate,
        item.classes,
        item.secondaryLawFirm,
        item.secondaryLawFirmReference,
        item.segments,
        item.hasLogo
      );
    }
  }
