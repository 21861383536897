import { Component, Input, OnInit } from "@angular/core";
import DataSource from "devextreme/data/data_source";
import {
  InvoiceDetails,
  InvoiceDetails2,
} from "src/app/shared/models/financial/invoiceDetails.model";
import { FinancialService } from "src/app/shared/services/Financial/financial.service";

@Component({
  selector: "app-invoiceoverview-detailgrid",
  templateUrl: "./invoiceoverview-detailgrid.component.html",
  styleUrls: ["./invoiceoverview-detailgrid.component.scss"],
})
export class InvoiceoverviewDetailgridComponent implements OnInit {
  @Input() InvoiceHeaderId: number;

  GridDataSource: DataSource;

  constructor(private FinService: FinancialService) {}

  ngOnInit(): void {
    this.FinService.getInvoiceDetails(this.InvoiceHeaderId).subscribe(
      (res: InvoiceDetails2[]) => {
        this.GridDataSource = new DataSource({
          store: res,
        });
      }
    );
  }
}
