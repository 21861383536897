import { Injectable } from '@angular/core';
import { Adapter } from '../adapter/adapter';

export class  DeadlineInstruction {
  constructor(
    public EventSchemeId: number,
    public AssetId: number,
    public DeadlineDate: Date,
    public Instruction: string,
    public FreeTextInstruction: string,
    public Todo: string,
    public UploadedFile: File[],
    public OptionsVisible: boolean,
    public DocumentUploadVisible: boolean,
    public FreeTextVisible: boolean,
) {}
}
