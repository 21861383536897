import { Component, OnInit, ViewChild } from "@angular/core";
import { DxDataGridComponent } from "devextreme-angular";
import DataSource from "devextreme/data/data_source";
import { Dictionary } from "src/app/shared/models/dictionary.model";
import { SubscribedOrganization } from "src/app/shared/models/Portal/subscribedOrganization";
import { OrganizationService } from "src/app/shared/services/organization.service";

@Component({
  selector: "app-admin-client",
  templateUrl: "./client.component.html",
  styleUrls: ["./client.component.scss"],
})
export class AdminClientComponent implements OnInit {
  @ViewChild(DxDataGridComponent) dataGrid: DxDataGridComponent;

  organizationSrc: DataSource;
  subscriptionModelDict: Dictionary[];
  addClientPopupVisible: boolean;

  newClient: any;

  buttonOptions: any = {
    text: "Add Client",
    type: "warning",
    useSubmitBehavior: true,
  };

  constructor(private orgSvc: OrganizationService) {}

  ngOnInit() {
    this.addUserFormSubmit = this.addUserFormSubmit.bind(this);

    this.orgSvc
      .getSubscribedOrganizations()
      .subscribe((data: SubscribedOrganization[]) => {
        this.organizationSrc = new DataSource({
          store: data,
          key: "SubscribedOrganizationId",
        });
      });

    this.orgSvc.getSubscriptionModelDictionary().subscribe((dict2: any) => {
      this.subscriptionModelDict = dict2.data;
    });
  }

  onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift({
      location: "after",
      widget: "dxButton",
      options: {
        icon: "add",
        text: "Add new Client",
        onClick: this.addClientPopup.bind(this),
      },
    });
  }

  addClientPopup() {
    this.addClientPopupVisible = true;
  }

  UpdateSubscription(e) {
    this.orgSvc
      .updateSubscriptionModel(e.data.SubscribedOrganizationId, e.data)
      .subscribe();
  }

  AddSubscription(e) {
    this.orgSvc.createSubscription(e.data).subscribe();
  }

  RemoveSubscription(e) {
    this.orgSvc.deleteSubscription(e.data.SubscribedOrganizationId).subscribe();
  }

  addUserFormSubmit(e) {
    this.orgSvc.createSubscription(+this.newClient.nameId).subscribe(
      (res: SubscribedOrganization) => {
        this.organizationSrc
          .store()
          .insert(res)
          .then(() => {
            this.organizationSrc.reload();
          });
      },
      () => {},
      () => {
        this.addClientPopupVisible = false;
        this.dataGrid.instance.refresh();
      }
    );
    e.preventDefault();
  }
}
