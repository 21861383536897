import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ViewChild,
} from "@angular/core";
import { IPAssetService } from "src/app/shared/services/IPAsset.service";
import { ActivatedRoute, Router } from "@angular/router";
import { IPAsset } from "src/app/shared/models/ipasset.model";
import { DxDataGridComponent } from "devextreme-angular";
import { ERPSegment } from "src/app/shared/models/ERP/segment.erp.model";
import { ERPSegmentService } from "src/app/shared/services/ERP/erp.segment.service";
import { CountryService } from "src/app/shared/services/Common/common.country.service";
import { Country } from "src/app/shared/models/country.model";
import { Observable } from "rxjs";
import { exportDataGrid } from "devextreme/excel_exporter";
import { Workbook } from "exceljs";
import { saveAs } from "file-saver";
import { europeanUnionCountries } from "src/app/shared/utils";

@Component({
  selector: "app-ipassetoverviewsilvergrid",
  templateUrl: "./ipassetoverviewsilvergrid.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ["./ipassetoverviewsilvergrid.component.scss"],
})
export class IpassetoverviewsilvergridComponent implements OnInit {
  @ViewChild(DxDataGridComponent) dataGrid: DxDataGridComponent;
  dataSource: Observable<IPAsset[]>;

  filterValue: Array<any>;
  segments: ERPSegment[];
  countries: Country[];
  CountryFilterValues: String[] = ["Spain"];

  url: string;
  Logo: string;
  showClosedCases = false;
  grouped = false;
  defaultVisible: boolean;
  popoverTarget: any;
  hoveredkey: number;
  countryParam: string | null;

  countryName: string;
  countryParameter: string;

  constructor(
    private IPAssetSvc: IPAssetService,
    private segmentService: ERPSegmentService,
    private countrySvc: CountryService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.onDetailsClick = this.onDetailsClick.bind(this);
    this.defaultVisible = false;
    this.filterValue = [];

    this.activatedRoute.queryParams.subscribe((params) => {
      this.countryParameter = params.country;
      this.IPAssetSvc.loadInitialData();
    });
  }

  toggleDefault() {
    this.defaultVisible = !this.defaultVisible;
  }

  onDetailsClick(e) {
    this.router.navigate(["/ipasset/" + e.row.data.assetId]);
  }

  ngOnInit() {
    this.countryParam = this.activatedRoute.snapshot.queryParams["country"];

    this.segmentService.getContactSegments().subscribe((res: ERPSegment[]) => {
      this.segments = res;
    });

    this.countrySvc.getCountries().subscribe((res: Country[]) => {
      this.countries = res;
    });

    this.dataSource = this.IPAssetSvc.IPAssets;

    //filter datasource by country
    if (this.countryParam) {
      this.filterValue = ["countryId", "=", this.countryParam];
      const countryCodes = europeanUnionCountries.map((x) => x.code);
      //if country is in europe take coutryparam + eu code
      if (countryCodes.includes(this.countryParam)) {
        //add country code = EU to the filter array
        this.filterValue = [
          ["countryId", "=", this.countryParam],
          "or",
          ["countryId", "=", "EU"],
        ];
      }
    }
  }

  onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift(
      {
        location: "after",
        widget: "dxButton",
        options: {
          width: 200,
          text: "Group by reference",
          onClick: this.groupByClientReference.bind(this),
        },
      },
      {
        location: "after",
        widget: "dxButton",
        options: {
          width: 200,
          text: "Show Closed Cases",
          onClick: this.collapseAllClick.bind(this),
        },
      },
      {
        location: "after",
        widget: "dxButton",
        options: {
          icon: "refresh",
          text: "Clear",
          hint: "Clear all filters",
          onClick: this.refreshDataGrid.bind(this),
        },
      }
    );
  }

  refreshDataGrid() {
    this.dataGrid.instance.clearFilter();
  }

  collapseAllClick(e) {
    this.showClosedCases = !this.showClosedCases;

    this.IPAssetSvc.getAllAssets(this.showClosedCases);

    e.component.option({
      text: this.showClosedCases ? "Hide Closed Cases" : "Show Closed Cases",
    });
  }

  groupByClientReference(e) {
    this.dataGrid.instance.clearGrouping();

    // if already grouped by client reference, remove grouping
    if (this.grouped) {
      this.dataGrid.instance.clearGrouping();
      this.dataGrid.instance.refresh();
      this.grouped = false;
    } else {
      this.dataGrid.instance.columnOption("clientReference", "groupIndex", 0);

      this.grouped = true;
    }

    e.component.option({
      text: this.grouped ? "Ungroup" : "Group by reference",
    });

    this.dataGrid.instance.refresh();
  }

  onCellHoverChanged(e) {
    if (
      e.rowType === "data" &&
      e.column.dataField === "hasLogo" &&
      e.data.hasLogo &&
      e.eventType === "mouseover"
    ) {
      this.hoveredkey = e.key;
      this.popoverTarget = e.cellElement;
      this.defaultVisible = true;
      // this.popOver.instance.show();
    }

    if (e.rowType === "data" && e.eventType === "mouseout") {
      this.defaultVisible = false;
      // this.popOver.instance.hide();
    }
  }

  calculateFilterExpression(filterValue, selectedFilterOperation, target) {
    if (target === "search" && typeof filterValue === "string") {
      return [(this as any).dataField, "contains", filterValue];
    }

    return function (data) {
      return (data.segments || []).indexOf(filterValue) !== -1;
    };
  }

  cellTemplate(container, options) {
    // tslint:disable-next-line:one-variable-per-declaration
    var noBreakSpace = "\u00A0",
      text = (options.value || [])
        .map((element) => {
          return options.column.lookup.calculateCellValue(element);
        })
        .join(", ");
    container.textContent = text || noBreakSpace;
    container.title = text;
  }

  onExporting(e) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet("IPAssets");

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(
          new Blob([buffer], { type: "application/octet-stream" }),
          "IPAssetOverview.xlsx"
        );
      });
    });
  }

  updateGridDimensions() {
    this.dataGrid.instance.updateDimensions();
  }

  calculateClientReference(value) {
    if (value.clientReference === null || value.clientReference === undefined) {
      return "";
    }

    const clientRefUpper = value.clientReference;
    return clientRefUpper;
  }
}
