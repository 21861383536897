import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { DxDataGridComponent } from "devextreme-angular";
import { ERPPictureService } from "src/app/shared/services/ERP/erp.picture.service";

@Component({
  selector: "app-ipassetpicture",
  templateUrl: "./ipassetpicture.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ["./ipassetpicture.component.scss"],
})
export class IpassetpictureComponent implements OnInit {
  @Input()
  assetId: number;

  @Output()
  updateDimensions = new EventEmitter<void>();

  imageLoading: boolean;
  logo: any;
  objectURL: any;

  constructor(
    private pictureSvc: ERPPictureService,
    public sanitizer: DomSanitizer,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.imageLoading = true;

    this.pictureSvc.loadHeadPicture(this.assetId).subscribe((x: Blob) => {
      const blob = x;
      this.objectURL = URL.createObjectURL(blob);
      this.logo = this.sanitizer.bypassSecurityTrustUrl(this.objectURL);
      this.imageLoading = false;
      this.cdr.detectChanges();
      this.updateDimensions.emit();
    });
  }
}
