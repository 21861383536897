import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";
import * as Sentry from "@sentry/angular-ivy";

Sentry.init({
  dsn: environment.sentryDsn,
  integrations: [
    new Sentry.BrowserProfilingIntegration(),
    new Sentry.BrowserTracing(),
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: environment.sentryTracesSampleRate, //  Capture 100% of the transactions
  debug: !environment.production, // Set this to `false` in production to not log to `console.log()`
  /*tracePropagationTargets: [
    'localhost',
    /^https:\/\/calystaadminapi-test.azurewebsites\.net\/api/,
  ],*/
  // Session Replay
  replaysSessionSampleRate: environment.production ? 0.1 : 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
