import { Injectable } from "@angular/core";
import { Adapter } from "../adapter/adapter";

export class IPService {
  constructor(
    public ClientId: number,
    public AssetId: number,
    public ClientReference: string,
    public CalystaReference: string,
    public Family: string,
    public ApplicationType: string,
    public ApplicationTypeId: number,
    public CaseType: string,
    public CaseTypeLabel: string,
    public CaseTypeId: number,
    public Catchword: string,
    public Country: string,
    public CountryId: string,
    public CreationDate: Date,
    public StatusLabel: string,
    public StatusId: number,
    public MappedStatusLabel: string,
    public MappedStatusId: number,
    public OpenDate: Date,
    public RenewalDueDate: Date,
    public Project: string,
    public ProjectId: number,
    public SubProject: string,
    public Segments: [],
    public SubProjectId: number
  ) {}
}

@Injectable({
  providedIn: "root",
})
export class IpServiceAdapter implements Adapter<IPService> {
  adapt(item: any): IPService {
    return new IPService(
      item.clientId,
      item.assetId,
      item.clientReference,
      item.calystaReference,
      item.family,
      item.applicationType,
      item.applicationTypeId,
      item.caseType,
      item.caseTypeLabel,
      item.caseTypeId,
      item.catchword,
      item.country,
      item.countryId,
      item.creationDate,
      item.statusLabel,
      item.statusId,
      item.mappedStatusLabel,
      item.mappedStatusId,
      item.openDate,
      item.renewalDueDate,
      item.project,
      item.projectId,
      item.subProject,
      item.segments,
      item.subProjectId
    );
  }
}
