<dx-data-grid
  id="gridContainer"
  keyExpr="assetId"
  [dataSource]="dataSource | async"
  [showBorders]="true"
  [rowAlternationEnabled]="true"
  [filterSyncEnabled]="true"
  [filterValue]="filterValue"
  [allowColumnReordering]="true"
  (onCellHoverChanged)="onCellHoverChanged($event)"
  (onToolbarPreparing)="onToolbarPreparing($event)"
  (onExporting)="onExporting($event)"
  [height]="'80vh'"
>
  <dxo-scrolling showScrollbar="always" [useNative]="false" mode="virtual" />
  <dxo-selection mode="multiple" />

  <dxo-pager
    [showPageSizeSelector]="true"
    [allowedPageSizes]="[10, 25, 50, 100]"
    [showInfo]="true"
  />

  <dxo-column-chooser [enabled]="true" mode="select" />

  <dxo-search-panel
    [visible]="true"
    [width]="240"
    placeholder="Search for anything..."
  />
  <dxo-header-filter [visible]="true" />
  <dxo-filter-row [visible]="true" />
  <dxi-column
    [showInColumnChooser]="false"
    type="buttons"
    dataType="object"
    [fixed]="true"
    [allowReordering]="false"
    [allowFixing]="true"
    fixedPosition="left"
    [width]="50"
  >
    <dxi-button hint="View details" icon="info" [onClick]="onDetailsClick">
    </dxi-button>
  </dxi-column>
  <dxi-column
    dataField="clientReference"
    [width]="200"
    dataType="string"
    caption="Your Reference"
    [fixed]="true"
    [allowFixing]="true"
    [calculateGroupValue]="calculateClientReference"
  />
  <dxi-column
    dataField="calystaReference"
    [width]="200"
    dataType="string"
    caption="Calysta Reference"
    [fixed]="true"
    [allowFixing]="true"
  />
  <dxi-column
    dataField="hasLogo"
    [width]="125"
    dataType="string"
    caption="Logo"
    cellTemplate="logoCellTemplate"
  />
  <dxi-column
    dataField="catchword"
    [width]="350"
    dataType="string"
    caption="Catchword"
    [allowFixing]="true"
  />
  <dxi-column
    dataField="country"
    [width]="200"
    dataType="string"
    caption="Country"
    [filterValues]="CountryFilterValues"
  />
  <dxi-column
    dataField="countryId"
    [width]="200"
    dataType="string"
    [visible]="false"
    [showInColumnChooser]="false"
    caption="Country code"
  />
  <dxi-column
    dataField="mappedStatusLabel"
    [width]="200"
    dataType="string"
    caption="Status"
  />
  <dxi-column
    dataField="classes"
    [width]="200"
    dataType="string"
    caption="Classes"
  />
  <dxi-column
    dataField="caseTypeLabel"
    [width]="150"
    dataType="string"
    caption="Asset Type"
  />
  <dxi-column
    dataField="applicationType"
    [width]="150"
    dataType="string"
    caption="Application Type"
  />
  <dxi-column
    dataField="applicationNumber"
    [width]="150"
    dataType="string"
    caption="Application Number"
  />
  <dxi-column
    dataField="filedDate"
    [width]="150"
    dataType="date"
    caption="Filed Date"
  />
  <dxi-column
    dataField="publicationDate"
    [width]="150"
    dataType="date"
    caption="Publication Date"
  />
  <dxi-column
    dataField="registrationNumber"
    [width]="150"
    dataType="string"
    caption="Registration Number"
  />
  <dxi-column
    dataField="registrationDate"
    [width]="150"
    dataType="date"
    caption="Registration Date"
  />
  <dxi-column
    dataField="renewalDueDate"
    [width]="150"
    dataType="date"
    caption="Renewal Due Date"
  />
  <dxi-column
    dataField="segments"
    dataType="string"
    [width]="350"
    caption="Segment"
    editCellTemplate="tagBoxEditor"
    [cellTemplate]="cellTemplate"
    [calculateFilterExpression]="calculateFilterExpression"
  >
    <dxo-lookup
      [dataSource]="segments"
      searchEnabled="true"
      valueExpr="SegmentId"
      displayExpr="SegmentLabel"
    />
  </dxi-column>

  <div *dxTemplate="let cellInfo of 'tagBoxEditor'">
    <dx-tag-box
      [dataSource]="segments"
      [value]="cellInfo.value"
      valueExpr="SegmentId"
      displayExpr="SegmentLabel"
      [showSelectionControls]="true"
      [maxDisplayedTags]="3"
      [showMultiTagOnly]="false"
      applyValueMode="useButtons"
      [searchEnabled]="true"
      (onValueChanged)="cellInfo.setValue($event.value)"
      (onSelectionChanged)="cellInfo.component.updateDimensions()"
    />
  </div>

  <dxo-export [enabled]="true" [allowExportSelectedData]="true" />
  <dxo-grouping [autoExpandAll]="false" [allowCollapsing]="true" />

  <dxi-column
    dataField="secondaryLawFirmReference"
    caption="Secondary Law Firm Reference"
  />
  <div *dxTemplate="let data of 'logoCellTemplate'">
    <div *ngIf="data.value === true">
      <app-ipassetpicture
        [assetId]="data.data.assetId"
        (updateDimensions)="updateGridDimensions()"
      />
    </div>
    <div *ngIf="data.value === false"></div>
  </div>
</dx-data-grid>
