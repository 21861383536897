<ng-container *ngIf="isAuthorized(); else notAuthorized">
    <app-side-nav-outer-toolbar title="{{appInfo.title}}">
        <router-outlet></router-outlet>
        <app-footer>
            Copyright © 2023 Calysta
        </app-footer>
    </app-side-nav-outer-toolbar>
</ng-container>

<ng-template #notAuthorized>
    <app-single-card class="WithBackground">
        <router-outlet></router-outlet>
    </app-single-card>
</ng-template>
