import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Dictionary, DictionaryAdapter } from '../../models/dictionary.model';
import { map } from 'rxjs/operators';

const apiUrl = environment.baseUrl + '/api/erp/iptype';

@Injectable({
  providedIn: 'root'
})

/// Service to get all the contact information: note => Git commit.
export class ERPIPTypeService {

  constructor(private http: HttpClient,
              private dictionaryAdapter: DictionaryAdapter
              ) {}

   getCaseMasterDictionary() {
      return this.http.get<Dictionary[]>(apiUrl + '/masterlookup')
      .pipe(
        map((res: any) =>
          res.map(item => this.dictionaryAdapter.adapt(item))
        )
      );
   }
}
