<div id="filingChart"><dx-chart
    id="filingChart"
    title="# Filings / Year"
    [dataSource]="DataSource">
    <dxi-series
        argumentField="reportYear"
        valueField="filings"
        name="filings"
        type="bar"
        color="#ffaa66">
    </dxi-series>
    <dxo-argument-axis type="continuous" argumentType="string">
        <dxo-label overlappingBehavior="rotate">
        </dxo-label>
    </dxo-argument-axis>
</dx-chart>
</div>
