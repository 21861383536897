<dx-responsive-box>
    <dxi-row [ratio]="1"></dxi-row>
    <dxi-row [ratio]="4"></dxi-row>

    <dxi-col></dxi-col>
    <dxi-col></dxi-col>
    
    <dxi-item>
        <dxi-location [row]="0" [col]="0" [colspan]="1" [rowspan]="1"></dxi-location>
        <div>
            <app-costbysegmentandratiopiechart id="pieChartBox" [pieChartDataSet]="pieChartDataSet" [rerenderEvent]="pieChartRerenderSubject">
            </app-costbysegmentandratiopiechart>
        </div>
    </dxi-item>

    <dxi-item>
        <dxi-location [row]="0" [col]="1" [colspan]="1" [rowspan]="1"></dxi-location>
        <div>
            <app-costsbysegmentandratioevolution id="evolutionBox" [barChartData]="CommonDataSet">
            </app-costsbysegmentandratioevolution>
        </div>
    </dxi-item>

    <dxi-item>
        <dxi-location [row]="1" [col]="0" [colspan]="2" [rowspan]="1"></dxi-location>
        <app-costsbysegmentandratiogrid id="gridBox" [gridDataSet]="CommonDataSet">
        </app-costsbysegmentandratiogrid>
    </dxi-item>    
</dx-responsive-box>

        