import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { IPAsset } from "src/app/shared/models/ipasset.model";
import { IPAssetService } from "src/app/shared/services/IPAsset.service";
import { IpAssetClass } from "src/app/shared/models/IPAssets/ipassetclass.model";
import { ERPPictureService } from "src/app/shared/services/ERP/erp.picture.service";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: "app-trm-general",
  templateUrl: "./trm-general.component.html",
  styleUrls: ["./trm-general.component.scss"],
})
export class TrmGeneralComponent implements OnChanges {
  @Input()
  assetData: IPAsset;
  classInfo: IpAssetClass[];
  logo: any;

  constructor(
    private ipAssetSvc: IPAssetService,
    private pictureSvc: ERPPictureService,
    public sanitizer: DomSanitizer
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.assetData) {
      this.ipAssetSvc
        .getClasses(this.assetData.assetId)
        .subscribe((res: IpAssetClass[]) => {
          this.classInfo = res;
        });

      this.pictureSvc
        .loadHeadPicture(this.assetData.assetId)
        .subscribe((x: Blob) => {
          const blob = x;
          const objectURL = URL.createObjectURL(blob);
          this.logo = this.sanitizer.bypassSecurityTrustUrl(objectURL);
        });
    }
  }
}
