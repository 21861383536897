import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import {
  LoginResponse,
  LoginResponseAdapter,
} from "../../models/Authentication/loginresponse";
import {
  TFASetupCodeAdapter,
  tfaSetupCode,
} from "../../models/Authentication/tfasetupcode";
import {
  TermsAndConditions,
  TermsAndConditionsAdapter,
} from "../../models/termsAndConditions.model";
import { UserActivation } from "../../models/useractivation.model";
import { User } from "../../models/user";
import { ResetPassword } from "../../models/resetPassword.model";

const apiUrl = environment.baseUrl;

@Injectable({ providedIn: "root" })
export class AuthService {
  private currentUserSubject: BehaviorSubject<LoginResponse>;
  private accessTokenSubject: BehaviorSubject<string>;
  public userRolesSubject: BehaviorSubject<string[]>;
  public twoFAPassedSubject: BehaviorSubject<boolean>;
  private tAndCAcceptedSubject: BehaviorSubject<boolean>;

  public currentUser: Observable<LoginResponse>;
  public userRoles: Observable<string[]>;
  public accessToken: Observable<string>;
  public twoFAPassed: Observable<boolean>;
  public tAndCAccepted: Observable<boolean>;
  public TwoFactorAuthActivated: Observable<boolean>;
  public TwoFactorAuthSuccessFull: Observable<boolean>;

  constructor(
    private router: Router,
    private http: HttpClient,
    private loginReponseAdapter: LoginResponseAdapter,
    private TermsAndConditionsAdapter: TermsAndConditionsAdapter,
    private TFASetupCodeAdapter: TFASetupCodeAdapter
  ) {
    this.currentUserSubject = new BehaviorSubject<LoginResponse>(
      JSON.parse(localStorage.getItem("currentUser"))
    );
    this.accessTokenSubject = new BehaviorSubject<string>(
      JSON.parse(localStorage.getItem("accessToken"))
    );
    this.userRolesSubject = new BehaviorSubject<string[]>(
      JSON.parse(localStorage.getItem("userRoles"))
    );
    this.tAndCAcceptedSubject = new BehaviorSubject<boolean>(
      JSON.parse(localStorage.getItem("TAndCAccepted"))
    );
    this.twoFAPassedSubject = new BehaviorSubject<boolean>(
      JSON.parse(localStorage.getItem("twoFAPassed"))
    );

    this.currentUser = this.currentUserSubject.asObservable();
    this.accessToken = this.accessTokenSubject.asObservable();
    this.userRoles = this.userRolesSubject.asObservable();
    this.twoFAPassed = this.twoFAPassedSubject.asObservable();
  }

  public get currentUserValue(): LoginResponse {
    if (this.currentUserSubject.value) {
      return this.currentUserSubject.value;
    }

    if (JSON.parse(localStorage.getItem("currentUser"))) {
      return JSON.parse(localStorage.getItem("currentUser"));
    }
  }

  public get twoFAPAssedValue(): boolean {
    if (this.currentUserValue.TwoFactorEnabled === false) {
      return true;
    }

    if (this.twoFAPassedSubject.value === true) {
      return true;
    }

    if (JSON.parse(localStorage.getItem("twoFAPassed")) === true) {
      return true;
    }
  }

  public get userRolesValue(): string[] {
    return this.userRolesSubject.value;
  }

  public get accessTokenValue(): string {
    return this.accessTokenSubject.value;
  }

  logIn(username: string, password: string) {
    const headers = new HttpHeaders({
      "Content-Type": "application/x-www-form-urlencoded",
    });

    const options = { headers };

    const creds =
      "username=" +
      username +
      "&password=" +
      password +
      "&grant_type=password" +
      "&credentials=true";

    // Check if the credentials provided are right and make sure we verify if the user has 2-FA activated or not.
    return this.http
      .post<LoginResponse>(apiUrl + "/oauth/token", creds, options)
      .pipe(
        map((user) => {
          var loginResponse = this.loginReponseAdapter.adapt(user);

          this.accessTokenSubject.next(loginResponse.AccessToken);
          this.currentUserSubject.next(loginResponse);

          localStorage.setItem("currentUser", JSON.stringify(loginResponse));
          localStorage.setItem(
            "accessToken",
            JSON.stringify(loginResponse.AccessToken)
          );

          // When Two Factor is not enabled, pass it and move to the login directly.
          if (loginResponse.TwoFactorEnabled === false) {
            this.twoFAPassedSubject.next(true);
          }

          return user;
        })
      );
  }

  refreshToken() {
    const headers = new HttpHeaders({
      "Content-Type": "application/x-www-form-urlencoded",
    });

    const options = { headers };

    const refreshToken = this.currentUserValue.RefreshToken;

    const creds = "refresh_token=" + refreshToken;

    // Check if the credentials provided are right and make sure we verify if the user has 2-FA activated or not.
    return this.http
      .post<LoginResponse>(apiUrl + "/oauth/refresh", creds, options)
      .pipe(
        map((user) => {
          var loginResponse = this.loginReponseAdapter.adapt(user);

          this.accessTokenSubject.next(loginResponse.AccessToken);
          this.currentUserSubject.next(loginResponse);

          localStorage.setItem("currentUser", JSON.stringify(loginResponse));
          localStorage.setItem(
            "accessToken",
            JSON.stringify(loginResponse.AccessToken)
          );

          // When Two Factor is not enabled, pass it and move to the login directly.
          if (loginResponse.TwoFactorEnabled === false) {
            this.twoFAPassedSubject.next(true);
          }

          return user;
        })
      );
  }

  // Has to be moved to the user service.
  sendRecoverPwdMail(Email: string) {
    return this.http.post<any>(apiUrl + "/api/user/sendResetPwdMail", {
      Email: Email,
    });
  }

  logOut() {
    this.removeCacheItems();

    this.userRolesSubject.next(null);
    this.currentUserSubject.next(null);
    this.twoFAPassedSubject.next(false);
    this.accessTokenSubject.next(null);
    this.tAndCAcceptedSubject.next(null);

    this.router.navigate(["/login"]);
  }

  removeCacheItems() {
    localStorage.removeItem("currentUser");
    localStorage.removeItem("userRoles");
    localStorage.removeItem("termsAndConditions");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("TAndCAccepted");
    localStorage.removeItem("twoFAPassed");
  }

  // Get a new set up code for 2FA.
  getTFACode(): Observable<tfaSetupCode> {
    return this.http
      .get(apiUrl + "/api/accounts/user/GetTFASetup")
      .pipe(map((res: any) => this.TFASetupCodeAdapter.adapt(res)));
  }

  isTFAEnabled(): boolean {
    return this.currentUserValue.TwoFactorEnabled;
  }

  // Set up 2FA authentication for a user.
  // Returns a boolean indicating success and 2FA will be enabled.
  setupTFACode(tfasetupCode: tfaSetupCode): Observable<boolean> {
    return this.http.post<boolean>(
      apiUrl + "/api/accounts/user/ConfirmTFASetup",
      tfasetupCode
    );
  }

  // Verify 2FA authentication for a user.
  // Returns a boolean indicating success to allow the user to login.
  verifyTFACode(tfasetupCode: tfaSetupCode): Observable<boolean> {
    return this.http.post<boolean>(
      apiUrl + "/api/accounts/user/VerifyTFACode",
      tfasetupCode
    );
  }

  disableTFA(): Observable<boolean> {
    return this.http.post<boolean>(
      apiUrl + "/api/accounts/user/DisableTFASetup",
      null
    );
  }

  checkIfTermsAndConditionsAccepted() {
    return this.http
      .get<boolean>(apiUrl + "/api/accounts/user/TermsAndConditionsAccepted")
      .pipe(
        map((res) => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem("TAndCAccepted", JSON.stringify(res));
          this.tAndCAcceptedSubject.next(res);
          return res;
        })
      );
  }

  getLatestTermsAndConditions() {
    return this.http
      .get<TermsAndConditions>(apiUrl + "/api/accounts/user/TermsAndConditions")
      .pipe(map((res: any) => this.TermsAndConditionsAdapter.adapt(res)));
  }

  acceptTermsAndConditions(termsAndConditions: TermsAndConditions) {
    return this.http
      .post<boolean>(
        apiUrl + "/api/accounts/user/acceptTermsAndCond",
        termsAndConditions
      )
      .pipe(
        map((res: boolean) => {
          localStorage.setItem("TAndCAccepted", JSON.stringify(true));
          this.tAndCAcceptedSubject.next(true);
        })
      );
  }

  sendActivationMail(UserId: string) {
    return this.http.post(
      apiUrl + "/api/accounts/user/" + UserId + "/activate/getactivationtoken",
      {}
    );
  }

  activateUser(userAct: UserActivation) {
    return this.http.post<User>(
      apiUrl + "/api/accounts/user/activate",
      userAct
    );
  }

  resetPassword(resetPassword: ResetPassword) {
    return this.http.post(apiUrl + "/api/user/resetpassword", resetPassword);
  }

  public get TAndCAcceptedValue(): boolean {
    if (this.tAndCAcceptedSubject.value) {
      return this.tAndCAcceptedSubject.value;
    }

    const localStorageAccepted = JSON.parse(
      localStorage.getItem("TAndCAccepted")
    );
    if (localStorageAccepted) {
      return localStorageAccepted;
    }

    this.checkIfTermsAndConditionsAccepted().subscribe((res) => {
      return res;
    });
  }

  checkIfUserIsAuthorized() {
    return this.http.get(apiUrl + "/api/accounts/user/Ping");
  }
}

@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(private router: Router, private authService: AuthService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = this.authService.currentUserValue;
    if (!currentUser) {
      this.router.navigate(["/login"], {
        queryParams: { returnUrl: state.url },
      });
      return false;
    }

    //allow to logout user if he has wrong access items
    this.authService.checkIfUserIsAuthorized().subscribe(
      (res) => {},
      (error) => {
        this.authService.logOut();
        this.router.navigate(["/login"], {
          queryParams: { returnUrl: state.url },
        });
        return false;
      }
    );

    const twoFAAuthPassed = this.authService.twoFAPAssedValue;
    const TAndCAccepted = this.authService.TAndCAcceptedValue;

    if (!twoFAAuthPassed) {
      this.router.navigate([
        "/tfauth",
        {
          queryParams: { email: currentUser.Username },
        },
      ]);
      return false;
    }

    if (!TAndCAccepted) {
      this.router.navigate(["/termsAndConditions"], {
        queryParams: { returnUrl: state.url },
      });
      return false;
    }

    return true;
  }
}

@Injectable()
export class AuthenticatedService implements CanActivate {
  constructor(private router: Router, private authService: AuthService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = this.authService.currentUserValue;
    const twoFAAuthPassed = this.authService.twoFAPAssedValue;

    if (currentUser && twoFAAuthPassed) {
      return true;
    }

    this.router.navigate(["/login"], { queryParams: { returnUrl: state.url } });
    return false;
  }
}

@Injectable()
export class RoleGuardService implements CanActivate {
  constructor(private authSvc: AuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const expectedRole = route.data.expectedRole;
    const currentUser = this.authSvc.currentUserValue;
    if (currentUser) {
      const userRoles = localStorage.getItem("userRoles");
      if (userRoles != null && userRoles.includes(expectedRole)) {
        return true;
      }
      this.router.navigate(["/home"]);
      return false;
    }

    this.router.navigate(["/login"], { queryParams: { returnUrl: state.url } });
    return false;
  }
}
