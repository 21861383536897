<div class="tabContent">
  <mat-card>
    <mat-card-header
      ><p style="text-decoration: underline;">
        Detailed Information:
      </p></mat-card-header
    >
    <div *ngIf="this.logo">
      <mat-card-content>
        <dx-form
          id="trm-general-form"
          [colCount]="3"
          [formData]="assetData"
          [readOnly]="true"
        >
          <dxi-item>
            <a [href]="this.logo"><img [src]="this.logo" width="175" height="75"/></a>
          </dxi-item>
          <dxi-item dataField="statusLabel" [label]="{ text: 'Detailed Status' }"></dxi-item>
          <dxi-item></dxi-item>
          <dxi-item dataField="applicationNumber" [label]="{ text: 'Filing Number' }"></dxi-item>
          <dxi-item dataField="filedDate" [label]="{ text: 'Filing Date' }" editorType="dxDateBox"></dxi-item>
          <dxi-item dataField="registrationNumber" [label]="{ text: 'Registration Number' }"></dxi-item>
          <dxi-item dataField="registrationDate" [label]="{ text: 'Registration Date' }" editorType="dxDateBox"></dxi-item>
          <dxi-item dataField="publicationNumber" [label]="{ text: 'Publication Number' }"></dxi-item>
          <dxi-item dataField="publicationDate" [label]="{ text: 'Publication Date' }" editorType="dxDateBox"></dxi-item>
        </dx-form>
      </mat-card-content>
    </div>
    <div *ngIf="!this.logo">
      <mat-card-content>
        <dx-form
          id="trm-general-form"
          [colCount]="2"
          [formData]="assetData"
          [readOnly]="true"
        >
          <dxi-item dataField="statusLabel" [label]="{ text: 'Detailed Status' }">
          </dxi-item>
          <dxi-item dataField="applicationNumber" [label]="{ text: 'Filing Number' }"></dxi-item>
          <dxi-item dataField="filedDate" [label]="{ text: 'Filing Date' }" editorType="dxDateBox"></dxi-item>
          <dxi-item dataField="registrationNumber" [label]="{ text: 'Registration Number' }"></dxi-item>
          <dxi-item dataField="registrationDate" [label]="{ text: 'Registration Date' }" editorType="dxDateBox"></dxi-item>
          <dxi-item dataField="publicationNumber" [label]="{ text: 'Publication Number' }"></dxi-item>
          <dxi-item dataField="publicationDate" [label]="{ text: 'Publication Date' }" editorType="dxDateBox"></dxi-item>
        </dx-form>
      </mat-card-content>
    </div>
  </mat-card>
</div>

<div class="tabContent">
  <mat-card>
    <mat-card-header
      ><p style="text-decoration: underline;">
        Class Information:
      </p></mat-card-header
    >
    <mat-card-content>
      <dx-data-grid
        [dataSource]="classInfo"
        [columnMinWidth]="50"
        [columnAutoWidth]="true"
        [rowAlternationEnabled]="true"
        [wordWrapEnabled]="true"
      >
        <dxi-column dataField="classNumber" [width]="125" sortOrder="asc"></dxi-column>
        <dxi-column dataField="classDescription"></dxi-column>
      </dx-data-grid>
    </mat-card-content>
  </mat-card>
</div>
