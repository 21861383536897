import { Component, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import { CostsBySegment } from "src/app/shared/models/financial/costsBySegment.model";
import { FinancialService } from "src/app/shared/services/Financial/financial.service";

@Component({
  selector: "app-costbysegmentandratio",
  templateUrl: "./costbysegmentandratio.component.html",
  styleUrls: ["./costbysegmentandratio.component.scss"],
})
export class CostbysegmentandratioComponent implements OnInit {
  pieChartRerenderSubject: Subject<void> = new Subject<void>();

  CommonDataSet: Subject<CostsBySegment[]> = new Subject<CostsBySegment[]>();

  costsBySegmentArray: CostsBySegment[] = [];
  pieChartDataSet: CostsBySegment[] = [];

  constructor(private financialSvc: FinancialService) {}

  ngOnInit() {
    this.financialSvc.getCostsBySegmentAndRatio().subscribe(
      (res: CostsBySegment[]) => {
        this.costsBySegmentArray = res;
      },
      () => {},
      () => {
        this.CommonDataSet.next(this.costsBySegmentArray);
        this.costsBySegmentArray.map((element) => {
          if (
            this.pieChartDataSet.find(
              (x) => x.ClientSegmentId === element.ClientSegmentId
            )
          ) {
            let i = this.pieChartDataSet.findIndex(
              (x) => x.ClientSegmentId === element.ClientSegmentId
            );
            this.pieChartDataSet[i].TotalFees =
              element.TotalFees + this.pieChartDataSet[i].TotalFees;
            this.pieChartDataSet[i].CalystaFees =
              element.CalystaFees + this.pieChartDataSet[i].CalystaFees;
            this.pieChartDataSet[i].AgentFees =
              element.AgentFees + this.pieChartDataSet[i].AgentFees;
            this.pieChartDataSet[i].OfficialFees +=
              element.OfficialFees + this.pieChartDataSet[i].OfficialFees;
          } else {
            this.pieChartDataSet.push(
              new CostsBySegment(
                element.ClientId,
                element.ClientSegmentId,
                element.SegmentLabel,
                element.Year,
                element.CalystaFees,
                element.AgentFees,
                element.OfficialFees,
                element.TotalFees
              )
            );
          }
        });
        this.pieChartDataSet.forEach(
          (x) => (x.TotalFees = +x.TotalFees.toFixed(2))
        );
        this.pieChartRerenderSubject.next();
      }
    );
  }

  rerenderPieChart() {
    this.pieChartRerenderSubject.next();
  }
}
