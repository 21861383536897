import { Injectable } from '@angular/core';
import { Adapter } from '../../adapter/adapter';

export class ERPDeadlineCategory {

    constructor(
        public DeadlineCategoryId: number,
        public Label: string,
        public Color: string,
        ) {}
}

@Injectable({
    providedIn: 'root'
  })
  export class ERPDeadlineCategoryAdapter implements Adapter<ERPDeadlineCategory> {
    adapt(item: any): ERPDeadlineCategory {
      return new ERPDeadlineCategory(
          item.deadlineCategoryId,
          item.label,
          item.color,
      );
    }
  }
