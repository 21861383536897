import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { IPAsset } from "src/app/shared/models/ipasset.model";
import { DesignDevice } from "src/app/shared/models/IPAssets/designdevice.model";
import { IPAssetService } from "src/app/shared/services/IPAsset.service";

@Component({
  selector: "app-des-general",
  templateUrl: "./des-general.component.html",
  styleUrls: ["./des-general.component.scss"],
})
export class DesGeneralComponent implements OnChanges {
  @Input()
  assetData: IPAsset;
  designDevices: DesignDevice[];

  constructor(private ipAssetSvc: IPAssetService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.assetData) {
      this.ipAssetSvc
        .getDesignDevices(this.assetData.assetId)
        .subscribe((res: DesignDevice[]) => {
          this.designDevices = res;
        });
    }
  }
}
