import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from "@angular/common/http";
import { BehaviorSubject, Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import {
  ERPRenewalDeadline,
  ERPRenewalDeadlineAdapter,
} from "../../models/ERP/renewaldeadline.erp.model";
import { ERPRenewalinstructionLog, ERPRenewalinstructionLogAdapter } from "../../models/ERP/renewalinstructionlog.erp.model";
import { ERPTRMRenewalDeadline, ERPTRMRenewalDeadlineAdapter } from "../../models/ERP/trmrenewaldeadline.erp.model";
import { ERPDESRenewalDeadline, ERPDESRenewalDeadlineAdapter } from "../../models/ERP/desrenewaldeadline.erp.model";

const apiUrl = environment.baseUrl + "/api/erp/renewal";

@Injectable({
  providedIn: "root",
})
export class ERPRenewalDeadlineService {
  private RenewalDeadlinesObs$: BehaviorSubject<ERPRenewalDeadline[]> = new BehaviorSubject([]);
  private TRMRenewalDeadlinesObs$: BehaviorSubject<ERPTRMRenewalDeadline[]> = new BehaviorSubject([]);
  private DESRenewalDeadlinesObs$: BehaviorSubject<ERPDESRenewalDeadline[]> = new BehaviorSubject([]);
  private RenewalInstructionLogObs$: BehaviorSubject<ERPRenewalinstructionLog[]> = new BehaviorSubject([]);

  constructor(
    private http: HttpClient,
    private renewaldeadlineAdapter: ERPRenewalDeadlineAdapter,
    private renewaltrmdeadlineAdapter: ERPTRMRenewalDeadlineAdapter,
    private renewaldesdeadlineAdapter: ERPDESRenewalDeadlineAdapter,
    private renewalInstructionLogAdapter: ERPRenewalinstructionLogAdapter,
  ) {
      this.loadRenewalDeadlines();
  }

  getRenewalDeadlinesObs(): Observable<ERPRenewalDeadline[]> {
    return this.RenewalDeadlinesObs$.asObservable();
  }

  getTRMRenewalDeadlinesObs(): Observable<ERPTRMRenewalDeadline[]> {
    return this.TRMRenewalDeadlinesObs$.asObservable();
  }

  getDESRenewalDeadlinesObs(): Observable<ERPDESRenewalDeadline[]> {
    return this.DESRenewalDeadlinesObs$.asObservable();
  }

  clearAllInstructions() {
    let currentTRMVal = this.TRMRenewalDeadlinesObs$.value;
    currentTRMVal.forEach(element => {
      element.InstructionGiven = false;
      element.InstructionType = null;
    });

    this.TRMRenewalDeadlinesObs$.next(currentTRMVal);

    let currentPatVal = this.RenewalDeadlinesObs$.value;
    currentPatVal.forEach(element => {
      element.InstructionGiven = false;
      element.InstructionType = null;
    });

    this.RenewalDeadlinesObs$.next(currentPatVal);

    let currentDesVal = this.DESRenewalDeadlinesObs$.value;
    currentDesVal.forEach(element => {
      element.InstructionGiven = false;
      element.InstructionType = null;
    });

    this.DESRenewalDeadlinesObs$.next(currentDesVal);
  }

  loadRenewalDeadlines() {
    this.getRenewalDeadlines()
      .subscribe((result) => this.RenewalDeadlinesObs$.next(result));

    this.getTRMRenewalDeadlines()
      .subscribe((result) => this.TRMRenewalDeadlinesObs$.next(result));

    this.getDESRenewalDeadlines()
      .subscribe((result) => this.DESRenewalDeadlinesObs$.next(result));
  }

  getRenewalDeadlines() {
    return this.http
      .get<ERPRenewalDeadline[]>(apiUrl + "/overview")
      .pipe(
        map((res: any) =>
          res.data.map((item) => this.renewaldeadlineAdapter.adapt(item))
        )
      )
      .pipe(catchError(this.handleError));
  }

  getTRMRenewalDeadlines() {
    return this.http
      .get<ERPTRMRenewalDeadline[]>(apiUrl + "/overview/trm")
      .pipe(
        map((res: any) =>
          res.data.map((item) => this.renewaltrmdeadlineAdapter.adapt(item))
        )
      )
      .pipe(catchError(this.handleError));
  }

  getDESRenewalDeadlines() {
    return this.http
      .get<ERPDESRenewalDeadline[]>(apiUrl + "/overview/des")
      .pipe(
        map((res: any) =>
          res.data.map((item) => this.renewaldesdeadlineAdapter.adapt(item))
        )
      )
      .pipe(catchError(this.handleError));
  }

  getRenewalInstructionLog(): Observable<ERPRenewalinstructionLog[]> {
    return this.http
      .get<ERPRenewalinstructionLog[]>(apiUrl + "/log")
      .pipe(
        map((res: any) =>
          res.data.map((item) => this.renewalInstructionLogAdapter.adapt(item))
        )
      )
      .pipe(catchError(this.handleError));
  }

  // Handle an error.
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error("An error occurred:", error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    // return an observable with a user-facing error message
    return throwError("Something bad happened; please try again later.");
  }
}
