<h2 class="content-block">Renewals</h2>

<!-- Info Card and confirmation part. -->
<mat-card>
  <mat-card-header>
    <mat-card-subtitle>
      <i>
        <u>Remark:</u> If you want Calysta to make an analysis of your rights in
        order to identify if everything is still pertinent, necessary to renew,
        etc. <br />
        Please click here =>
        <i class="dx-icon-tips" style="color: orange"></i> <= and your contact
        person at Calysta will arrange a meeting with you to review your
        portfolio.<br />
        If only you want to discuss a specific right, click on the lightbulb
        icon behind it.
      </i>
    </mat-card-subtitle>
  </mat-card-header>
  <br />

  <div
    *ngIf="(this.renewalInstructions | async).length > 0"
    class="selected-data"
  >
    <mat-card-content>
      <mat-card>
        <dx-box direction="row" width="100%">
          <dxi-item [ratio]="1">
            Total Instructions: {{ (renewalInstructions | async).length }}<br />
            Total Costs: € {{ getTotalInstructionAmount() }}
          </dxi-item>
          <dxi-item [ratio]="1"></dxi-item>
          <dxi-item [ratio]="1"
            ><dx-button
              stylingMode="contained"
              style="align-self: flex-end"
              text="Confirm"
              type="success"
              [width]="120"
              (onClick)="onConfirmationClick()"
            >
            </dx-button>

            <dx-button
              stylingMode="contained"
              style="align-self: flex-end"
              text="Revert all"
              type="danger"
              [width]="120"
              (onClick)="onRevertAllClick()"
            >
            </dx-button>
          </dxi-item>
        </dx-box>
      </mat-card>
      <br />
    </mat-card-content>
  </div>
</mat-card>
<br />

<mat-tab-group>
  <mat-tab label="Patents ( {{ (this.patentRenewalData | async).length }} )">
    <app-renewal-patents
      [events]="revertAllSubject.asObservable()"
      [patentRenewalData]="patentRenewalData"
      [patentRenewalInstructionSet]="renewalInstructionSet"
    ></app-renewal-patents>
  </mat-tab>
  <mat-tab
    label="Trademarks ( {{ (this.trademarkRenewalData | async).length }} )"
  >
    <app-renewals-trademark
      [events]="revertAllSubject.asObservable()"
      [trademarkRenewalData]="trademarkRenewalData"
      [trmRenewalInstructionSet]="renewalInstructionSet"
    ></app-renewals-trademark>
  </mat-tab>
  <mat-tab label="Designs ( {{ (this.designRenewalData | async).length }} )">
    <app-renewals-design
      [events]="revertAllSubject.asObservable()"
      [desRenewalInstructionSet]="renewalInstructionSet"
      [designRenewalData]="designRenewalData"
    ></app-renewals-design>
  </mat-tab>
</mat-tab-group>

<!-- Pop up to allow the confirmation of the instructions given. -->
<dx-popup
  [width]="1000"
  [height]="600"
  [showTitle]="true"
  title="Confirmation"
  [dragEnabled]="false"
  [hideOnOutsideClick]="false"
  [showCloseButton]="true"
  container=".dx-viewport"
  [(visible)]="confirmationPopupVisible"
>
  <dxi-toolbar-item
    widget="dxButton"
    toolbar="bottom"
    location="before"
    [options]="closeButtonOptions"
  >
  </dxi-toolbar-item>
  <dxi-toolbar-item
    widget="dxButton"
    toolbar="bottom"
    location="after"
    [options]="confirmButtonOptions"
  >
  </dxi-toolbar-item>
  <!-- <dxo-position at="bottom" my="center" [of]="positionOf"> </dxo-position> -->
  <div *dxTemplate="let data of 'content'">
    <dx-scroll-view width="100%" height="100%">
      <p>Please confirm your following instructions:</p>

      <dx-data-grid
        id="confirmationGridContainer"
        [dataSource]="renewalInstructions | async"
        keyExpr="CaseId"
        [columns]="[
          'CaseType',
          'CatchWord',
          'Reference',
          'Country',
          'InstructionType',
          'Amount'
        ]"
        [showBorders]="true"
        [allowColumnResizing]="true"
        [columnAutoWidth]="true"
      >
        <dxo-summary>
          <dxi-total-item column="Catchword" summaryType="count">
          </dxi-total-item>
          <dxi-total-item
            column="Amount"
            summaryType="sum"
            valueFormat="currency"
          >
          </dxi-total-item>
        </dxo-summary>
      </dx-data-grid>
    </dx-scroll-view>
  </div>
</dx-popup>
