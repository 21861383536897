import { Component } from "@angular/core";
import { UserService } from "src/app/shared/services/Authentication/user.service";
import { OrganizationService } from "src/app/shared/services/organization.service";
import { ERPContactService } from "src/app/shared/services/ERP/erp.contact.service";

@Component({
  selector: "app-admin",
  templateUrl: "./admin.component.html",
  styleUrls: ["./admin.component.scss"],
  providers: [UserService, OrganizationService, ERPContactService],
})
export class AdminComponent {}
