<div class="login-header">
  <div class="title">Calysta</div>
  <div>Please choose your password and activate.</div>
</div>

<form action="your-action" (submit)="onFormSubmit($event)">
  <dx-form id="form" [(formData)]="userActivation" [showValidationSummary]="true">
    <dxi-item dataField="email" [editorOptions]="{ disabled: true }">
      <dxo-label text="Username"> </dxo-label>
    </dxi-item>
    <dxi-item dataField="password" [editorOptions]="{ mode: 'password' }">
      <dxi-validation-rule type="required" message="Password is required"></dxi-validation-rule>
      <dxi-validation-rule type="pattern" pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{6,}$"
          message="Password requires minimum six characters, at least one uppercase letter and one number"></dxi-validation-rule>
    </dxi-item>
    <dxi-item editorType="dxTextBox" [editorOptions]="{ mode: 'password' }">
      <dxo-label text="Confirm Password"> </dxo-label>
      <dxi-validation-rule
        type="required"
        message="Confirm Password is required"
      >
      </dxi-validation-rule>
      <dxi-validation-rule
        type="compare"
        [comparisonTarget]="passwordComparison"
        message="Password and Confirm Password do not match"
      >
      </dxi-validation-rule>
    </dxi-item>
    <dxi-item
      itemType="button"
      horizontalAlignment="center"
      [buttonOptions]="buttonOptions"
    >
    </dxi-item>
  </dx-form>
</form>
