<div *ngIf="(designRenewalData | async).length > 0">
  <dx-data-grid
    #DESRenewalgrid
    id="DESgridContainer"
    [dataSource]="designRenewalData | async"
    keyExpr="CaseId"
    [rowAlternationEnabled]="true"
    [showBorders]="true"
    (onContentReady)="onContentReady($event)"
    (onRowPrepared)="onRowPrepared($event)"
    [rowAlternationEnabled]="false"
    height="79vh"
  >
    <dxo-load-panel [enabled]="true"></dxo-load-panel>
    <dxo-paging [pageSize]="250"> </dxo-paging>
    <dxi-column
      style="height: 100%"
      [showInColumnChooser]="false"
      type="buttons"
      dataType="object"
      [allowReordering]="false"
      [allowFixing]="true"
      fixedPosition="left"
      [width]="75"
      name="InfoButton"
    >
      <dxi-button
        hint="View details"
        icon="info"
        [onClick]="onDetailsClick"
      ></dxi-button>
    </dxi-column>
    <dxi-column
      dataField="HasLogo"
      [width]="125"
      [allowFiltering]="false"
      [allowSorting]="false"
      caption="Image"
      cellTemplate="cellTemplate"
    ></dxi-column>
    <dxi-column
      dataField="FamilyNumber"
      [groupIndex]="0"
      groupCellTemplate="groupCellTemplate"
    ></dxi-column>
    <dxi-column
      dataField="ClientReference"
      caption="Reference"
      width="180"
      sortIndex="1"
    ></dxi-column>
    <dxi-column
      dataField="Catchword"
      caption="CatchWord"
      sortIndex="2"
    ></dxi-column>
    <dxi-column
      dataField="Country"
      caption="Country"
      width="180"
      sortIndex="3"
    ></dxi-column>
    <dxi-column
      dataField="GrantDate"
      dataType="date"
      caption="Grant Date"
      width="130"
    ></dxi-column>
    <dxi-column
      dataField="GrantNumber"
      caption="Grant Number"
      width="130"
    ></dxi-column>
    <dxi-column
      dataField="Classes"
      dataType="string"
      caption="Classes"
      width="130"
    ></dxi-column>
    <dxi-column
      dataField="PavisInstructionDeadline"
      caption="Due date"
      dataType="date"
      sortOrder="asc"
      width="125"
    ></dxi-column>
    <dxi-column
      dataField="Quote"
      caption="Price (€)"
      format="currency"
      width="100"
    ></dxi-column>
    <dxi-column type="buttons" dataType="object" [width]="110">
      <dxi-button
        hint="Renew"
        icon="check"
        [visible]="isInstructionGiven"
        [disabled]="false"
        [onClick]="onRenewalClick"
      ></dxi-button>
      <dxi-button
        hint="Request advice"
        icon="tips"
        [visible]="isInstructionGiven"
        [disabled]="false"
        [onClick]="onRequestAdviceClick"
      ></dxi-button>
      <dxi-button
        hint="Abandon"
        icon="clear"
        [visible]="isInstructionGiven"
        [disabled]="false"
        [onClick]="onAbandonClick"
      ></dxi-button>
      <dxi-button
        hint="Revert Instruction"
        icon="revert"
        [visible]="revertVisible"
        [disabled]="false"
        [onClick]="onRevertClick"
      ></dxi-button>
    </dxi-column>

    <dxo-summary>
      <dxi-group-item column="FamilyNumber" summaryType="count">
      </dxi-group-item>
    </dxo-summary>
    <dxi-sort-by-group-summary-info
      summaryItem="min"
      groupColumn="Deadline"
      sortOrder="asc"
    >
    </dxi-sort-by-group-summary-info>

    <div *dxTemplate="let data of 'groupCellTemplate'">
      <div>
        Family: {{ data.value }}
        <div style="float: right">
          <dx-button
            hint="Renew"
            (onClick)="onRenewalGroupClick(data)"
            icon="check"
            type="warning"
            text="Renew family"
          ></dx-button>
        </div>
      </div>
    </div>

    <div *dxTemplate="let data of 'cellTemplate'">
      <div *ngIf="data.value === 1">
        <!-- <app-ipassetpicture [assetId]="data.data.CaseId"></app-ipassetpicture> -->
      </div>
    </div>
  </dx-data-grid>
</div>

<div *ngIf="(designRenewalData | async).length === 0">
  <mat-card>
    <p style="text-align: center">
      <i class="dx-icon-check" style="color: green"></i> No upcomming Design
      Renewals
    </p>
  </mat-card>
</div>
