import { Injectable } from "@angular/core";
import { Adapter } from "../../adapter/adapter";

export class Invoice {
  constructor(
    public InvoiceHeaderId: number,
    public InvoiceNumber: number,
    public InvoiceDate: Date,
    public InvoiceCategory: string,
    public AmountNonVat: number,
    public VATAmount: number,
    public TotalAmount: number,
    public IsPayed: boolean,
    public HasSubsidy: boolean
  ) {}
}

@Injectable({
  providedIn: "root",
})
export class InvoiceAdapter implements Adapter<Invoice> {
  adapt(item: any): Invoice {
    return new Invoice(
      item.invoiceHeaderId,
      item.invoiceNumber,
      item.invoiceDate,
      item.invoiceCategory,
      item.amountNonVat,
      item.vatAmount,
      item.totalAmount,
      item.isPayed,
      item.hasSubsidy
    );
  }
}
