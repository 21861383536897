import { Component, OnInit, Input, ViewChild, Inject } from "@angular/core";
import {
  IPAssetFamily,
  FamilyCoverage,
} from "src/app/shared/models/ipassetfamily.model";
import { IPFamilyService } from "src/app/shared/services/IPAssetFamily.service";
import DataSource from "devextreme/data/data_source";
import { TrmClass } from "src/app/shared/models/ERP/trmclass.erp.model";
import * as mapsData from "src/assets/Maps/world.js";
import { DxVectorMapComponent } from "devextreme-angular";
import { DOCUMENT } from "@angular/common";
import { saveAs } from "file-saver";
import { Workbook } from "exceljs";
import { exportDataGrid } from "devextreme/excel_exporter";

@Component({
  selector: "app-trademarkfamilydetail",
  templateUrl: "./trademarkfamilydetail.component.html",
  styleUrls: ["./trademarkfamilydetail.component.scss"],
})
export class TrademarkfamilydetailComponent implements OnInit {
  @ViewChild(DxVectorMapComponent) vectorMap: DxVectorMapComponent;

  @Input() Family: IPAssetFamily;

  DetailDataSource: DataSource;
  MapDataSource: DataSource;
  GridDataSource: DataSource;
  CoverageData: FamilyCoverage[];

  worldMap: any = mapsData.world;

  constructor(
    private IPFamilySvc: IPFamilyService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.CoverageData = [];
    this.customizeLayers = this.customizeLayers.bind(this);
  }

  ngOnInit() {
    this.IPFamilySvc.getTRMFamilyCoverage(this.Family.family).subscribe(
      (res: FamilyCoverage[]) => {
        this.vectorMap.instance.beginUpdate();
        this.vectorMap.instance.showLoadingIndicator();
        this.CoverageData = res;
        this.MapDataSource = new DataSource({
          store: res,
        });

        this.GridDataSource = new DataSource({
          store: res.filter((x) => x.ShowOnTable === true),
        });

        this.vectorMap.instance.option("layers[0].dataSource", this.worldMap);
        this.vectorMap.instance.hideLoadingIndicator();
      },
      (err) => {},
      () => {
        this.vectorMap.instance.render();
        this.vectorMap.instance.endUpdate();
      }
    );
  }

  listFamilyClasses(data: TrmClass[]): string {
    let classes = "";
    if (data != null) {
      const basePath = this.document.location.origin;
      data = data.sort((a, b) => a.classNumber - b.classNumber);
      data.forEach((element) => {
        if (classes === "") {
          classes =
            "<a href=" +
            basePath +
            "/ipasset/" +
            element.assetId +
            ' target="_blank">' +
            element.classNumber +
            "</a>";
        } else {
          // tslint:disable-next-line:max-line-length
          classes =
            classes +
            ",  <a href=" +
            basePath +
            "/ipasset/" +
            element.assetId +
            ' target="_blank">' +
            element.classNumber +
            "</a>";
        }
      });
    }
    return classes;
  }

  customizeLayers(elements) {
    elements.forEach((element) => {
      if (
        this.CoverageData.filter(
          (x) => x.CountryId === element.attribute("iso_a2")
        ).length > 0
      ) {
        switch (
          this.CoverageData.find(
            (x) => x.CountryId === element.attribute("iso_a2")
          ).MappedStatusLabel
        ) {
          case "Registered/Granted":
            element.applySettings({ color: "#008000" });
            element.attribute("status", 1);
            break;
          case "Pending":
            element.applySettings({ color: "#2727ff" });
            element.attribute("status", 2);
            break;
          case "Closed":
            element.applySettings({ color: "#808080" });
            element.attribute("status", 3);
            break;
          default:
            break;
        }
      }
    }, this);
  }

  customizeLegendText(arg) {
    let text;
    if (arg.start === 1) {
      text = "Registered";
    } else if (arg.start === 2) {
      text = "Pending";
    } else {
      text = "Closed";
    }
    return text;
  }

  customizeItems(items) {
    items.push("test");
    return items;
  }

  onExporting(e) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet("TrademarkFamily");

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(
          new Blob([buffer], { type: "application/octet-stream" }),
          "TrademarkFamily.xlsx"
        );
      });
    });
  }
}
