import { Injectable } from '@angular/core';
import { Adapter } from '../../adapter/adapter';

export class ERPSegment {

    constructor(
        public ClientId: number,
        public SegmentId: number,
        public SegmentLabel: string,
        public NrOfAssets: number,
        ) {}
}

@Injectable({
    providedIn: 'root'
  })
  export class ERPSegmentAdapter implements Adapter<ERPSegment> {
    adapt(item: any): ERPSegment {
      return new ERPSegment(
          item.clientId,
          item.segmentId,
          item.segmentLabel,
          item.nrOfAssets
      );
    }
  }
