<div class="row">
  <dx-responsive-box>
    <dxi-row [ratio]="1"></dxi-row>
    <dxi-row [ratio]="2"></dxi-row>

    <dxi-col></dxi-col>
    <dxi-col></dxi-col>
    <dxi-col></dxi-col>

    <dxi-item>
      <dxi-location
        [row]="0"
        [col]="0"
        [colspan]="1"
        [rowspan]="1"
      ></dxi-location>
      <mat-card>
        <mat-card-header>
          <p style="text-decoration: underline">Yearly Expenses:</p>
        </mat-card-header>
        <mat-card-content>
          <div>
            <dx-chart id="chart" [dataSource]="financialsData">
              <dxo-tooltip
                [enabled]="true"
                [customizeTooltip]="customizeTooltip"
              >
              </dxo-tooltip>
              <dxo-size [height]="250"> </dxo-size>
              <dxi-series
                argumentField="year"
                valueField="costs"
                name="Yearly Expenses"
                type="bar"
                color="#ffaa66"
              >
              </dxi-series>
            </dx-chart>
          </div>
        </mat-card-content>
      </mat-card>
    </dxi-item>
    <dxi-item>
      <dxi-location
        [row]="1"
        [col]="0"
        [colspan]="3"
        [rowspan]="1"
      ></dxi-location>
      <mat-card>
        <mat-card-header>
          <p style="text-decoration: underline">Details:</p>
        </mat-card-header>
        <mat-card-content>
          <dx-data-grid
            [dataSource]="invoicesData"
            [rowAlternationEnabled]="true"
            [showBorders]="true"
            keyExpr="invoicenumber"
          >
            <dxi-column
              dataField="InvoiceDate"
              caption="Date"
              dataType="date"
              [width]="150"
              sortOrder="desc"
            ></dxi-column>
            <dxi-column
              dataField="InvoiceNumber"
              caption="Invoice Number"
              [width]="125"
            ></dxi-column>
            <dxi-column
              dataField="OfficialFees"
              caption="Official Fees"
              format="currency"
              [width]="200"
            >
            </dxi-column>
            <dxi-column
              dataField="CalystaFees"
              caption="Calysta Fees"
              format="currency"
              [width]="200"
            >
            </dxi-column>
            <dxi-column
              dataField="AgentFees"
              caption="Agent Fees"
              format="currency"
              [width]="200"
            >
            </dxi-column>
            <dxi-column
              dataField="AmountOnCase"
              caption="Amount On This Asset"
              [width]="150"
              dataType="number"
              format="currency"
            ></dxi-column>
            <dxi-column type="buttons" [width]="75" caption="Document">
              <dxi-button
                hint="Preview Document"
                icon="search"
                [onClick]="previewDocument"
              ></dxi-button>
            </dxi-column>
          </dx-data-grid>
        </mat-card-content>
      </mat-card>
    </dxi-item>
  </dx-responsive-box>
</div>

<dx-popup
  [width]="800"
  [height]="680"
  [showTitle]="true"
  title="Invoice Preview"
  [dragEnabled]="false"
  [closeOnOutsideClick]="true"
  [(visible)]="previewPopUpVisible"
>
  <pdf-preview [BlobFile]="filePreview" />
</dx-popup>
