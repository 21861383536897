import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";
import { DxChartComponent } from "devextreme-angular";
import { Observable, Subscription } from "rxjs";
import { CostsBySegment } from "src/app/shared/models/financial/costsBySegment.model";

@Component({
  selector: "app-costsbysegmentandratioevolution",
  templateUrl: "./costsbysegmentandratioevolution.component.html",
  styleUrls: ["./costsbysegmentandratioevolution.component.scss"],
})
export class CostsbysegmentandratioevolutionComponent
  implements OnInit, AfterViewInit
{
  @ViewChild(DxChartComponent, { static: false }) barChart: DxChartComponent;

  @Input() barChartData: Observable<CostsBySegment[]>;

  barChartDataSet: CostsBySegment[];

  private barChartSubscription: Subscription;

  constructor() {}
  ngAfterViewInit(): void {
    this.barChart.instance.render();
  }

  ngOnInit() {
    this.barChartSubscription = this.barChartData.subscribe(
      (res: CostsBySegment[]) => {
        this.barChartDataSet = [];
        res.map((element) => {
          if (this.barChartDataSet.find((x) => x.Year === element.Year)) {
            let i = this.barChartDataSet.findIndex(
              (x) => x.Year === element.Year
            );
            this.barChartDataSet[i].TotalFees += element.TotalFees;
            this.barChartDataSet[i].CalystaFees += element.CalystaFees;
            this.barChartDataSet[i].AgentFees += element.AgentFees;
            this.barChartDataSet[i].OfficialFees += element.OfficialFees;
          } else {
            this.barChartDataSet.push(
              new CostsBySegment(
                0,
                0,
                "",
                element.Year,
                element.CalystaFees,
                element.AgentFees,
                element.OfficialFees,
                element.TotalFees
              )
            );
          }
          this.barChartDataSet.sort((a, b) => +a.Year - +b.Year);
        });
      },
      () => {},
      () => {
        this.barChart.instance.render();
      }
    );
  }
}
