import { Component, OnInit } from "@angular/core";
import notify from "devextreme/ui/notify";
import { tfaSetupCode } from "src/app/shared/models/Authentication/tfasetupcode";
import { AuthService } from "src/app/shared/services";
import { UserService } from "src/app/shared/services/Authentication/user.service";

@Component({
  selector: "app-profile-twofactorauth",
  templateUrl: "./profile-twofactorauth.component.html",
  styleUrls: ["./profile-twofactorauth.component.scss"],
})
export class ProfileTwofactorauthComponent implements OnInit {
  isLoading: boolean = false;
  tfaEnabled: boolean = false;
  showError: boolean = false;
  errorMessage: string = "";
  qrInfo: string = "";
  authenticatorKey: string = "";

  twoAuthConfirmation: tfaSetupCode;

  setupResult: Boolean = false;

  constructor(public UserSvc: UserService, private AuthSvc: AuthService) {}

  ngOnInit(): void {
    this.tfaEnabled = this.AuthSvc.currentUserValue.TwoFactorEnabled;

    this.AuthSvc.getTFACode().subscribe((res) => {
      this.qrInfo = res.Code;
      this.authenticatorKey = res.FormattedKey;
    });

    this.twoAuthConfirmation = new tfaSetupCode("", "", false, "", "");
  }

  // Button for activating the 2FA.
  buttonOptions: any = {
    text: "Enable Two-Factor Authentication",
    type: "success",
    useSubmitBehavior: true,
  };

  handleSubmit(e) {
    this.AuthSvc.setupTFACode(this.twoAuthConfirmation).subscribe(
      (res) => (this.setupResult = res),
      () => {
        if (this.setupResult) {
          this.TwoFASetupSuccessfull();
          this.tfaEnabled = true;
        } else {
          this.TwoFASetupFailed();
          this.twoAuthConfirmation.AuthenticatorKey = "";
        }
      }
    );

    e.preventDefault();
  }

  // Disable 2 factor auth for the user.
  disableTFA(): void {
    var result = false;

    this.AuthSvc.disableTFA().subscribe(
      (res) => (result = res),
      () => {
        //Handle when an error occurs.
      },
      () => {
        if (result === true) {
          // 2 FA has been successfully deactivated.
          this.tfaEnabled = false;
          // Show a notification indicating that it has been deactivated.
        } else if (result === false) {
          // Show a notification indicating that it has not been deactivated.
        }
      }
    );
  }

  TwoFASetupFailed(): void {
    const message = `2-Factor Authentication setup failed, please try again.`;
    notify(
      {
        message,
        position: {
          my: "center",
          at: "center",
        },
      },
      "error",
      5000
    );
  }

  TwoFASetupSuccessfull(): void {
    const message = `2-Factor Authentication set up succesfully. It will be prompted on the next login attempt.`;
    notify(
      {
        message,
        position: {
          my: "center",
          at: "center",
        },
      },
      "success",
      5000
    );
  }
}

export class TwoFAAuthConfirm {
  AuthenticatorCode: string;
}
