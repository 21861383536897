<mat-card class="example-card">
    <mat-card-content>
      <mat-grid-list cols="4">
        <mat-grid-tile [colspan]="4">
          <dx-data-grid id="financialGrid" [dataSource]="dataSource" keyExpr="Id" [showBorders]="true">
            <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
              <dxi-column dataField="CountryLabel"caption="Country" [groupIndex]="0" groupCellTemplate="groupCellTemplate"></dxi-column>
              <dxi-column dataField="Year" caption="Year"></dxi-column>
              <dxi-column dataField="Costs" caption="Costs" format="currency"></dxi-column>
              <div *dxTemplate="let data of 'groupCellTemplate'">  
                {{data.key}}  
              </div> 
              <dxo-summary>
                <dxi-group-item
                    column="Costs"
                    summaryType="sum"
                    valueFormat="currency"
                    [showInGroupFooter]="false"
                    displayFormat="{0}"
                    [alignByColumn]="true">
                </dxi-group-item>
            </dxo-summary>
          </dx-data-grid>
        </mat-grid-tile>
    </mat-grid-list>
  </mat-card-content>
  </mat-card>