<dx-validation-group>
    <div class="login-header">
        <div class="title">Reset Password</div>
        <div>An email will be send to you, allowing to recover your password.</div>
    </div>
    <div class="dx-field">
        <dx-text-box [(value)]="userName" placeholder="Email" width="100%">
            <dx-validator>
                <dxi-validation-rule type="required" message="Email is required"></dxi-validation-rule>
            </dx-validator>
        </dx-text-box>
    </div>
    <div class="dx-field">
        <dx-button type="default" text="Send Reset Mail" (onClick)="onRecoveryClick($event)" width="100%"></dx-button>
    </div>
</dx-validation-group>
