import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse
} from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import {
  ERPDeadline,
  ERPDeadlineAdapter
} from '../../models/ERP/deadline.erp.model';
import { ERPDeadlineCategory, ERPDeadlineCategoryAdapter } from '../../models/ERP/deadlinecategory.erp.model';
import { environment } from 'src/environments/environment';
import { ERPDeadlineSummaryAdapter } from '../../models/ERP/deadlinesummary.erp.model';

const apiUrl = environment.baseUrl + '/api/erp/deadline';

@Injectable({
  providedIn: 'root'
})
export class ERPDeadlineService {
  constructor(
    private http: HttpClient,
    private deadlineAdapter: ERPDeadlineAdapter,
    private deadlineCategoryAdapter: ERPDeadlineCategoryAdapter,
    private deadlineSummaryAdapter: ERPDeadlineSummaryAdapter
  ) {}

  getCaseDeadlines(caseId: number) {
    return this.http
      .get<ERPDeadline[]>(apiUrl + '/case/' + caseId)
      .pipe(
        map((res: any) =>
          res.data.map(item => this.deadlineAdapter.adapt(item))
        )
      )
      .pipe(catchError(this.handleError));
  }

  getDeadlines() {
    return this.http
      .get<ERPDeadline[]>(apiUrl)
      .pipe(
        map((res: any) =>
          res.data.map(item => this.deadlineAdapter.adapt(item))
        )
      )
      .pipe(catchError(this.handleError));
  }

  getDeadlineCategories() {
    return this.http
      .get<ERPDeadlineCategory[]>(apiUrl + '/category')
      .pipe(
        map((res: any) =>
          res.data.map(item => this.deadlineCategoryAdapter.adapt(item))
        )
      )
      .pipe(catchError(this.handleError));
  }

  getPrimaryDocument(deadlineId: number) {
    return this.http
      .get(apiUrl + '/' + deadlineId + '/primarydocument', { responseType: 'blob' })
      .pipe(catchError(this.handleError));
  }

  getDocuments(deadlineId: number) {
    return this.http
    .get(apiUrl + '/' + deadlineId + '/documents', { responseType: 'blob', observe: 'response' })
    .pipe(catchError(this.handleError));
  }

  getDeadlineSummary() {
    return this.http
      .get(apiUrl + '/summary')
      .pipe(
        map((res: any) =>
          res.data.map(item => this.deadlineSummaryAdapter.adapt(item))
        )
      )
      .pipe(catchError(this.handleError));
  }

  // Handle an error.
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    // return an observable with a user-facing error message
    return throwError('Something bad happened; please try again later.');
  }
}
